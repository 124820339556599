import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MerchantData } from "containers/Matching";

interface RegisterMemberInitialState {
  merchant?: MerchantData;
  is_loading: boolean;
}

const initialState: RegisterMemberInitialState = {
  merchant: undefined,
  is_loading: false,
};

const registerMemberSlice = createSlice({
  name: "registerMember",
  initialState,
  reducers: {
    setMerchant(state, action: PayloadAction<MerchantData>) {
      state.merchant = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.is_loading = action.payload;
    },
  },
});

export const { setMerchant, setLoading } = registerMemberSlice.actions;
export default registerMemberSlice.reducer;
