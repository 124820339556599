import { OldSub } from "components/Text";
import React from "react";
import { ModalButtonProps, ModalProps, ModalType } from "./interface";
import { ModalButton } from "./ModalButton";
import { ModalBox, ModalContainer, Title, Content, Dimmer, ModalButtonFrame } from "./ModalStyle";

const Modal = ({
  z_index,
  is_dimmer,
  title,
  content,
  element,
  type,
  buttons,
  is_dimmer_close,
  closeModal,
}: ModalProps): JSX.Element => {
  const modal_z_index = z_index || 40;
  const modal_type = type || ModalType.NORMAL;
  return (
    <ModalContainer z_index={modal_z_index}>
      <ModalBox z_index={modal_z_index + 1}>
        {title && <Title>{title}</Title>}
        {content && <Content>{content}</Content>}
        {element}
        {buttons && <ModalButtons buttons={buttons} type={modal_type} closeModal={closeModal} />}
      </ModalBox>
      <Dimmer
        z_index={modal_z_index + 1}
        is_dimmer={Boolean(is_dimmer)}
        onClick={is_dimmer_close ? closeModal : () => null}
      />
    </ModalContainer>
  );
};

const ModalButtons = ({
  buttons,
  type,
  closeModal,
}: {
  type?: ModalType;
  buttons: ModalButtonProps[];
  closeModal?: () => void;
}): JSX.Element | null => {
  let widths: number[];
  let heights: number[];
  switch (type) {
    case ModalType.NORMAL:
      widths = [136, 136];
      heights = [40, 40];
      break;
    case ModalType.SIMPLE:
      widths = [280, 0];
      heights = [40, 0];
      break;
    case ModalType.ENLARGED:
      widths = [81, 191];
      heights = [40, 40];
      break;
    case ModalType.STACKED:
      widths = [280, 280];
      heights = [40, 40];
      break;
    default:
      return null;
  }
  return (
    <ModalButtonFrame is_stacked={type === ModalType.STACKED}>
      {buttons.map((button, index) => (
        <ModalButton
          width={widths[index]}
          height={heights[index]}
          key={`modalbutton_${index}`}
          onClick={button.onClick || closeModal}
          theme={button.theme}
          image_src={button.image_src}
          disabled={button.disabled}
        >
          <OldSub>{button.text}</OldSub>
        </ModalButton>
      ))}
    </ModalButtonFrame>
  );
};

export default Modal;
