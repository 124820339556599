import React, { lazy, Suspense } from "react";
import { useHistory } from "react-router-dom";
import { BlurLoading } from "components/Loading";
import { TabNavigation } from "containers/TopNavigation";

const LicenseFooter = lazy(() => import("components/Footer").then(({ LicenseFooter }) => ({ default: LicenseFooter })));
const Section1 = lazy(() => import("components/HomeDesktop").then(({ Section1 }) => ({ default: Section1 })));
const Section2 = lazy(() => import("components/HomeDesktop").then(({ Section2 }) => ({ default: Section2 })));
const Section3 = lazy(() => import("components/HomeDesktop").then(({ Section3 }) => ({ default: Section3 })));
const Section4 = lazy(() => import("components/HomeDesktop").then(({ Section4 }) => ({ default: Section4 })));
const Section5 = lazy(() => import("components/HomeDesktop").then(({ Section5 }) => ({ default: Section5 })));
const Section6 = lazy(() => import("components/HomeDesktop").then(({ Section6 }) => ({ default: Section6 })));
const Section7 = lazy(() => import("components/HomeDesktop").then(({ Section7 }) => ({ default: Section7 })));
const Section8 = lazy(() => import("components/HomeDesktop").then(({ Section8 }) => ({ default: Section8 })));

const HomeDesktop = (): JSX.Element => {
  const history = useHistory();
  const goMatching = () => history.push("/matching");
  const goToSection2 = () => window.scrollTo({ top: 794, behavior: "smooth" });
  return (
    <>
      <TabNavigation />
      <Suspense fallback={<BlurLoading />}>
        <Section1 goMatching={goMatching} goToNext={goToSection2} />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 goMatching={goMatching} />
        <LicenseFooter />
      </Suspense>
    </>
  );
};

export default HomeDesktop;
