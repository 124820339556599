import React from "react";
import { RouteProps, useHistory, useLocation } from "react-router";
import { Route } from "globals/utils";

interface AuthRouteProps extends RouteProps {
  redirect_url?: string;
}

export const AuthRoute = (props: AuthRouteProps): JSX.Element | null => {
  const history = useHistory();
  const location = useLocation();
  const access_token = window.sessionStorage.getItem("access_token");
  const redirect_url = props.redirect_url ? props.redirect_url : location.pathname + location.search;
  if (!access_token) {
    history.push(`/login/select?redirect_url=${encodeURIComponent(redirect_url)}`);
    return null;
  }
  return (
    <Route {...props} path={props.path} component={props.component}>
      {props.children}
    </Route>
  );
};

export default AuthRoute;
