import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavigationType, PageNavData } from "./interfaces";
import { selectNavigation, setPageNavigation } from "./slice";

const PageNavigation = (data: PageNavData): null => {
  const dispatch = useDispatch();
  const selectNavigationDispatch = () => dispatch(selectNavigation(NavigationType.PAGE));
  const setPageNavigationDispatch = (nav_data: PageNavData) => dispatch(setPageNavigation(nav_data));

  useEffect(() => {
    selectNavigationDispatch();
    setPageNavigationDispatch(data);
    return;
  }, [data]);
  return null;
};

export default PageNavigation;
