import React, { useEffect, useState } from "react";
import { Route as OriginRoute, RouteProps as OriginRouteProps, RouteComponentProps } from "react-router";
import styled from "styled-components";

interface RouteProps extends OriginRouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  desktop_component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const Route = (props: RouteProps): JSX.Element | null => {
  const [is_mobile, setMobile] = useState<boolean>(true);
  useEffect(() => {
    setMobile(window.innerWidth < 1120);
    return;
  }, []);

  const component = !is_mobile && !!props.desktop_component ? props.desktop_component : props.component;

  return (
    <Container is_desktop={!is_mobile && !!props.desktop_component}>
      <OriginRoute {...props} path={props.path} component={component}>
        {props.children}
      </OriginRoute>
    </Container>
  );
};

const Container = styled.div<{ is_desktop: boolean }>`
  width: 100%;
  max-width: ${(props) => !props.is_desktop && "640px"};
`;

export default Route;
