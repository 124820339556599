import React from "react";
import styled from "styled-components";
import { H2, Body2 } from "components/Text";
import Space from "components/Space";
import { ReactComponent as ImageSecure } from "./assets/img_secure.svg";
import { useScrollFadeIn } from "globals/utils";

const Section5 = (): JSX.Element => (
  <Container>
    <Space height={60} />
    <MainText {...useScrollFadeIn(1, 0.3)}>
      금전 사기로부터
      <br />
      안전하게 계정 공유!
    </MainText>
    <Space height={16} />
    <SubText {...useScrollFadeIn(1, 0.4)}>
      피클플러스에서는 파티에 문제가 생기면 언제든지
      <br />
      다른 파티로 변경하거나 환불받을 수 있어요.
    </SubText>
    <Space height={48} />
    <div {...useScrollFadeIn(1, 0.3)}>
      <ImageSecure title="image secure" />
    </div>
    <Space height={60} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--gray-050);
  overflow: hidden;
  align-items: center;
`;

const MainText = styled(H2)`
  text-align: center;
`;

const SubText = styled(Body2)`
  text-align: center;
  color: var(--gray-700);
`;

export default Section5;
