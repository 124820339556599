import { AxiosResponse } from "axios";
import { OLD_NODE_URL } from "globals/setting";
import { AuthAxios } from "./default";

interface sendMsgReqData {
  party_id: number;
  member_id: string;
  msg: string;
  include_phone: boolean;
}

export const sendMsg = async ({
  party_id,
  member_id,
  msg,
  include_phone,
}: sendMsgReqData): Promise<AxiosResponse<{ code: string; msg: string }>> => {
  const response = await AuthAxios({
    url: `${OLD_NODE_URL}/party/parties/${party_id}/members/${member_id}/messages`,
    method: "POST",
    data: { msg, include_phone },
  });
  return response;
};
