import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavigationType } from "./interfaces";
import { selectNavigation } from "./slice";

const TabNavigation = (): null => {
  const dispatch = useDispatch();
  const selectNavigationDispatch = () => dispatch(selectNavigation(NavigationType.TAB));
  useEffect(() => {
    selectNavigationDispatch();
    return;
  }, []);
  return null;
};

export default TabNavigation;
