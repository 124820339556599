import amplitude from "amplitude-js";
import { AMPLITUDE_ID } from "globals/setting";

export const Amplitude = {
  initClient: (): void => {
    if (!AMPLITUDE_ID) return;
    amplitude.getInstance().init(AMPLITUDE_ID, undefined, {
      includeGclid: true,
      includeReferrer: true,
      includeUtm: true,
    });
    Amplitude.pagePath();
  },
  setUser: ({
    hashed_user_id,
    phone,
    real_name,
  }: {
    hashed_user_id: string;
    phone: string;
    real_name: string;
  }): void => {
    amplitude.getInstance().setUserId(hashed_user_id);
    amplitude.getInstance().setUserProperties({ phone, real_name });
  },
  pagePath: (): void => {
    const page_path = window.location.pathname;
    const query_params = window.location.search;
    const hash = window.location.hash;
    const host = window.location.host;
    amplitude.getInstance().logEvent("PagePath", {
      page_path,
      query_params,
      hash,
      host,
    });
  },
  signUp: ({ imp_uid }: { imp_uid: string }): void => {
    amplitude.getInstance().logEvent("Sign Up Complete", { imp_uid });
  },
  createParty: (data: {
    merchandise_name: string;
    merchandise_id: number;
    merchant_name: string;
    paid_amount: number;
  }): void => {
    amplitude.getInstance().logEvent("Create Party", data);
    const revenue = new amplitude.Revenue()
      .setProductId(`membership_${data.merchandise_id}`)
      .setPrice(data.paid_amount)
      .setRevenueType("membership_host");
    amplitude.getInstance().logRevenueV2(revenue);
  },
  logOut: (): void => {
    amplitude.getInstance().logEvent("Log Out");
    amplitude.getInstance().setUserId(null);
    amplitude.getInstance().regenerateDeviceId();
  },
  registerMemberMatching: (data: { merchant_name?: string }): void => {
    amplitude.getInstance().logEvent("Register Member Matching", data);
  },
  cancelMemberMatching: (data: { merchant_name?: string }): void => {
    amplitude.getInstance().logEvent("Cancel Member Matching", data);
  },
  tryDeleteParty: (data: { merchant_name?: string }): void => {
    amplitude.getInstance().logEvent("Try Delete Party", data);
  },
  tryLeaveParty: (data: { merchant_name?: string }): void => {
    amplitude.getInstance().logEvent("Try Leave Party", data);
  },
};
