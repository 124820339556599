import { OldSmall2 } from "components/Text";
import React from "react";
import styled from "styled-components";

const Toast = ({
  content,
  z_index,
  is_active,
}: {
  content: string | JSX.Element;
  z_index?: number;
  is_active: boolean;
}): JSX.Element => (
  <ToastContainer z_index={z_index} is_active={is_active}>
    <ToastText>{content}</ToastText>
  </ToastContainer>
);

interface ToastProps {
  z_index?: number;
  is_active: boolean;
}

const ToastContainer = styled.div<ToastProps>`
  position: fixed;
  width: 100%;
  max-width: 640px;
  padding-top: 16px;
  padding-bottom: 11px;
  text-align: center;
  top: 72px;
  background-color: var(--yellow-100);
  z-index: ${(props) => props.z_index || 40};
`;

const ToastText = styled(OldSmall2)`
  color: var(--yellow-700);
`;

export default Toast;
