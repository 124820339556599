import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import qs from "qs";
import { useRootState } from "globals/utils";
import { BlurLoading } from "components/Loading";
import icon_checked from "./assets/icon_checked.png";
import icon_unchecked from "./assets/icon_unchecked.png";
import { WATCHA_MERCHANT_ID_HARD, LAFTEL_MERCHANT_ID_HARD } from "globals/variables";
import { ReactComponent as ArrowIcon } from "./assets/icon_arrow_blue.svg";
import { Device } from "./enum";
import { setProfileDeleteConfirm } from "./slice";
import { OldH6 } from "components/Text";
import { PARTYLEAVE_SET_MERCHANT_INFO_SAGA } from "./saga";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const Space = lazy(() => import("components/Space"));
const Button = lazy(() => import("components/Button"));
const ProfileDeleteOrders = lazy(() =>
  import("components/PartyLeave").then(({ ProfileDeleteOrders }) => ({ default: ProfileDeleteOrders })),
);

const ProfileDelete = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const party_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).party_id,
  );

  const merchant_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_id,
  );

  const goBack = () => history.push(`/myparty/info?merchant_id=${merchant_id}`);

  const setMerchantSagaDispatch = () =>
    dispatch({ type: PARTYLEAVE_SET_MERCHANT_INFO_SAGA, payload: { goBack, party_id } });

  const is_merchant_loading = useRootState((state) => state.partyleave.is_merchant_loading);
  const service_url = useRootState((state) => state.partyleave.merchant_info?.service_url);
  const merchant_name = useRootState((state) => state.partyleave.merchant_info?.merchant_name);
  const setProfileDeleteConfirmDispatch = () => dispatch(setProfileDeleteConfirm(true));
  const onClick = () => {
    setProfileDeleteConfirmDispatch();
    history.push(`/myparty/leave/init?merchant_id=${merchant_id}&party_id=${party_id}`);
  };

  useEffect(() => {
    setMerchantSagaDispatch();
  }, []);

  const [remove_device, setRemoveDevice] = useState<Device>(Device.APP);
  const [is_profile_deleted, setProfileDeleted] = useState<boolean>(false);

  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation
        title={
          is_merchant_loading
            ? merchant_id === WATCHA_MERCHANT_ID_HARD || merchant_id === LAFTEL_MERCHANT_ID_HARD
              ? "프로필 삭제하기"
              : "프로필 닉네임 변경"
            : merchant_id === WATCHA_MERCHANT_ID_HARD || merchant_id === LAFTEL_MERCHANT_ID_HARD
            ? `${merchant_name} 프로필 삭제하기`
            : `${merchant_name} 프로필 닉네임 변경`
        }
        back_path={`/myparty/leave/init?merchant_id=${merchant_id}&party_id=${party_id}`}
      />
      {!is_merchant_loading && (
        <Container>
          <Title>
            파티탈퇴를 위해 회원님이 이용했던
            <br />
            {merchant_id === WATCHA_MERCHANT_ID_HARD || merchant_id === LAFTEL_MERCHANT_ID_HARD
              ? `${merchant_name} 프로필을 삭제해주세요`
              : `${merchant_name} 프로필 닉네임을 변경해주세요`}
          </Title>
          <Space height={4} />
          <Tab onClick={() => window.open(service_url)}>
            {merchant_name} 바로가기&nbsp;
            <ImgWrapper>
              <ArrowIcon />
            </ImgWrapper>
          </Tab>
          <Space height={24} />
          <SelectorWrapper>
            <Selector
              onClick={() => setRemoveDevice(Device.APP)}
              is_selected={remove_device === Device.APP}
              borderRadius="8px 0px 0px 8px"
            >
              APP에서{" "}
              {merchant_id === WATCHA_MERCHANT_ID_HARD || merchant_id === LAFTEL_MERCHANT_ID_HARD ? "삭제" : "변경"}
            </Selector>
            <Selector
              onClick={() => setRemoveDevice(Device.PC)}
              is_selected={remove_device === Device.PC}
              borderRadius="0px 8px 8px 0px"
            >
              PC에서{" "}
              {merchant_id === WATCHA_MERCHANT_ID_HARD || merchant_id === LAFTEL_MERCHANT_ID_HARD ? "삭제" : "변경"}
            </Selector>
          </SelectorWrapper>
          <Space height={20} />
          <ProfileDeleteOrders device={remove_device} merchant_id={merchant_id} />
          <Space height={30} />
          <TermRow onClick={() => setProfileDeleted(!is_profile_deleted)}>
            <TermCheck src={is_profile_deleted ? icon_checked : icon_unchecked} />
            <TermText>
              {merchant_id === WATCHA_MERCHANT_ID_HARD || merchant_id === LAFTEL_MERCHANT_ID_HARD
                ? "내 프로필 삭제를 완료했어요"
                : "'빈자리'로 이름 변경을 완료했어요"}
            </TermText>
          </TermRow>
          <Space height={45} />
          <Button disabled={!is_profile_deleted} onClick={onClick}>
            완료
          </Button>
        </Container>
      )}
    </Suspense>
  );
};

const ImgWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-top: 3px;
`;
const Container = styled.div`
  padding: 40px 24px 32px 24px;
`;

const Title = styled(OldH6)`
  color: var(--gray-900);
  font-weight: normal;
`;

const SelectorWrapper = styled.div`
  display: flex;
  height: 32px;
  border: 1px solid var(--primary-500);
  border-radius: 8px;
`;

const Selector = styled.div<{ is_selected: boolean; borderRadius: string }>`
  background-color: ${(props) => (props.is_selected ? "var(--primary-500)" : "var(--white)")};
  color: ${(props) => (props.is_selected ? "var(--white)" : "var(--primary-500)")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  flex: 1;
  &:hover {
    cursor: pointer;
  }
  border-radius: ${(props) => props.borderRadius};
`;

const TermRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 24px;
  cursor: pointer;
`;

const TermCheck = styled.input.attrs({
  type: "image",
  alt: "icon_check",
})`
  width: 24px;
  height: 24px;
`;

const TermText = styled.div`
  color: var(--gray-900);
  margin-left: 13px;
  padding-top: 1px;
`;

export const Tab = styled.div`
  display: flex;
  position: relative;
  color: var(--blue-500);
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
`;

export default ProfileDelete;
