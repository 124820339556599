import React from "react";
import { ButtonPropsLegacy } from "components/Button";
import styled from "styled-components";
import InputWithLabel from "components/Input/InputWithLabel";

interface SpsProps {
  sps: {
    key: string;
    value: string;
    pin?: string;
  };
  openToast: (content: string) => void;
  closeToast: () => void;
}

const PartySps = ({ sps, openToast, closeToast }: SpsProps): JSX.Element => {
  const id_button_props: ButtonPropsLegacy = {
    theme: "primary-weak",
    width: 100,
    height: 40,
    children: "복사",
    onClick: () => copyText(sps.key),
  };
  const pw_button_props: ButtonPropsLegacy = {
    theme: "primary-weak",
    width: 100,
    height: 40,
    children: "복사",
    onClick: () => copyText(sps.value),
  };
  const pin_button_props: ButtonPropsLegacy = {
    theme: "primary-weak",
    width: 100,
    height: 40,
    children: "복사",
    onClick: () => copyText(sps?.pin || ""),
  };

  const copyText = (copytext: string) => {
    const tmp = document.createElement("textarea");
    tmp.value = copytext;
    document.body.appendChild(tmp);
    tmp.select();
    document.execCommand("copy");
    document.body.removeChild(tmp);
    openToast("클립보드에 복사 되었습니다");
    setTimeout(() => {
      closeToast();
    }, 1000);
  };

  return sps ? (
    <InputContainer>
      <InputWithLabel
        value={sps.key}
        is_complete={true}
        label="아이디"
        readonly
        is_have_button={true}
        button_props={id_button_props}
      />
      <Space />
      <InputWithLabel
        value={sps.value}
        is_complete
        label="비밀번호"
        is_have_button
        button_props={pw_button_props}
        readonly
      />
      {sps.pin && (
        <>
          <Space />
          <InputWithLabel
            value={sps.pin}
            is_complete
            label="성인 비밀번호(4자리)"
            is_have_button
            button_props={pin_button_props}
            readonly
          />
        </>
      )}
    </InputContainer>
  ) : (
    <div />
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 33px 24px 32px 24px;
`;
const Space = styled.div`
  height: 32px;
`;

export default PartySps;
