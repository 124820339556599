import React from "react";
import styled from "styled-components";
import Space from "components/Space";
import { OldH6, OldSub, OldBody1 } from "components/Text";
import icon_arrow_blue from "./assets/icon_diagonal_arrow_blue.png";
import { Color } from "globals/interfaces";

interface H6TitleProps {
  title: React.ReactNode;
  sub?: JSX.Element;
  sub_color?: Color;
  link?: { link_url: string; link_text: string };
}

const H6Title = ({ title, sub, sub_color, link }: H6TitleProps): JSX.Element => (
  <>
    <Space height={40} />
    <OldH6>{title}</OldH6>
    {!!sub && (
      <>
        <Space height={4} />
        <OldSub color={sub_color || "var(--gray-700)"}>{sub}</OldSub>
      </>
    )}
    {!!link && (
      <>
        <Space height={4} />
        <LinkRow onClick={() => window.open(link.link_url)}>
          <OldBody1 color="var(--blue-500)" font_weight="bold">
            {link.link_text}
            <LinkArrow />
          </OldBody1>
        </LinkRow>
      </>
    )}
    <Space height={16} />
  </>
);

const LinkRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const LinkArrow = styled.img.attrs({
  src: icon_arrow_blue,
  alt: "icon_arrow_blue",
})`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`;

export default H6Title;
