import Space from "components/Space";
import React from "react";
import styled from "styled-components";

interface CardGuideProps {
  line_length: number;
  order: JSX.Element;
  main_text: JSX.Element;
  sub_text: JSX.Element;
  icon: JSX.Element;
  is_first: boolean;
  is_last: boolean;
  width?: number;
}

const CardGuide = ({
  line_length,
  order,
  main_text,
  sub_text,
  icon,
  is_first,
  is_last,
  width,
}: CardGuideProps): JSX.Element => (
  <Container is_first={is_first} is_last={is_last} width={width}>
    {order}
    <Space height={8} />
    {main_text}
    <Space height={6} />
    <Line line_length={line_length} is_first={is_first} />
    <Space height={6} />
    {sub_text}
    <Space height={8} />
    <IconWrapper>{icon}</IconWrapper>
  </Container>
);

const Container = styled.div<{ is_first: boolean; is_last: boolean; width?: number }>`
  display: flex;
  background-color: var(--white);
  margin-left: ${(props) => (props.is_first ? 20 : 16)}px;
  margin-right: ${(props) => (props.is_last ? 20 : 0)}px;
  flex-direction: column;
  min-width: ${(props) => (props.width ? props.width : 152)}px;
  height: 232px;
  position: relative;
  padding: 16px;
  border-radius: 10px;
  box-shadow: var(--shadow-medium);
`;

const Line = styled.div<{ line_length: number; is_first: boolean }>`
  width: ${(props) => props.line_length}px;
  height: 2px;
  background-color: var(--gray-300);
  border-radius: 10px;
  margin-left: -${(props) => (props.is_first ? props.line_length / 2 + 16 : props.line_length / 2 + 20)}px;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 17px;
  bottom: 17px;
`;

export default CardGuide;
