import React from "react";
import { Redirect, Switch } from "react-router-dom";
import SelectLogin from "./SelectLogin";
import PhoneLogin from "./PhoneLogin";
import KakaoRedirect from "./KakaoRedirect";
import { Route } from "globals/utils";

const RegisterRoute = (): JSX.Element => (
  <Switch>
    <Route path="/login/select" component={SelectLogin} />
    <Route path="/login/kakao" component={KakaoRedirect} />
    <Route path="/login/phone" component={PhoneLogin} />
    <Redirect to="/login/select" />
  </Switch>
);

export default RegisterRoute;
