import { AxiosResponse } from "axios";
import { OLD_DJANGO_URL } from "globals/setting";
import { AuthAxios } from "./default";

export interface registerSpsReqData {
  merchant_id: number;
  sps_key: string;
  sps_value: string;
  sps_pin?: string;
}

export const registerSps = async (data: registerSpsReqData): Promise<AxiosResponse> => {
  const { sps_key, sps_value, sps_pin, merchant_id } = data;
  if (sps_pin) {
    const response = await AuthAxios({
      url: `${OLD_DJANGO_URL}/party/parties`,
      method: "POST",
      data: {
        merchant_id,
        pay_day: new Date().getDate(),
        sps_key,
        sps_value,
        sps_pin,
        agree_yn: true,
        create_route: "direct",
      },
    });
    return response;
  } else {
    const response = await AuthAxios({
      url: `${OLD_DJANGO_URL}/party/parties`,
      method: "POST",
      data: {
        merchant_id,
        pay_day: new Date().getDate(),
        sps_key,
        sps_value,
        agree_yn: true,
        create_route: "direct",
      },
    });
    return response;
  }
};

export const changeSps = async (data: {
  party_id: number;
  sps: { sps_key?: string; sps_value?: string; sps_pin?: string };
}): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/party/parties/${data.party_id}`,
    method: "PATCH",
    data: data.sps,
  });
  return response;
};

export const setMerchantProfileName = async (
  party_id: number,
  member_id: string,
  profile_name: string,
): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/party/parties/${party_id}/members/${member_id}`,
    method: "PATCH",
    data: { profile_name },
  });
  return response;
};
