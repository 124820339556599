import { BlurLoading, FullLoading } from "components/Loading";
import React, { lazy, Suspense, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { membershipSelector, useRootState } from "globals/utils";
import { LevelStatus } from "components/LevelTab";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { WATCHA_MERCHANT_ID_HARD, LAFTEL_MERCHANT_ID_HARD } from "globals/variables";
import { OldH6 } from "components/Text";
import { PARTYLEAVE_LEAVE_PARTY_SAGA, PARTYLEAVE_SET_MERCHANT_INFO_SAGA } from "./saga";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const Button = lazy(() => import("components/Button"));
const LevelTab = lazy(() => import("components/LevelTab"));
const Space = lazy(() => import("components/Space"));

const PartyLeaveInit = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const party_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).party_id,
  );
  const merchant_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_id,
  );

  const goBack = () => history.push(`/myparty/info?merchant_id=${merchant_id}`);

  const is_merchant_loading = useRootState((state) => state.partyleave.is_merchant_loading);
  const is_refund_done = useRootState((state) => state.partyleave.is_refund_done);
  const is_profile_delete_done = useRootState((state) => state.partyleave.is_profile_delete_done);
  const merchant_name = useRootState((state) => state.partyleave.merchant_info?.merchant_name);
  const is_full_loading = useRootState((state) => state.partyleave.is_full_loading);
  const is_promotion = useRootState(membershipSelector).membership_data?.is_promotion;

  const setMerchantSagaDispatch = () =>
    dispatch({ type: PARTYLEAVE_SET_MERCHANT_INFO_SAGA, payload: { goBack, party_id } });
  const leavePartySagaDispatch = () => {
    if (!is_promotion) {
      dispatch({
        type: PARTYLEAVE_LEAVE_PARTY_SAGA,
        payload: { party_id, goBack, merchant_name },
      });
      return;
    }
    if (confirm("더이상 (구)피클플러스 특가를 적용받지 못하시게 됩니다. 진행하시겠습니까?")) {
      dispatch({
        type: PARTYLEAVE_LEAVE_PARTY_SAGA,
        payload: { party_id, goBack },
      });
    } else {
      goBack();
    }
  };

  const getRefundStatus = () => {
    if (is_refund_done) return LevelStatus.DONE;
    if (!is_refund_done && !is_profile_delete_done) return LevelStatus.RECOMMEND;
    return LevelStatus.STANDBY;
  };
  const getProfileDeleteStatus = () => {
    if (is_refund_done && is_profile_delete_done) return LevelStatus.DONE;
    if (is_refund_done && !is_profile_delete_done) return LevelStatus.RECOMMEND;
    return LevelStatus.STANDBY;
  };

  useEffect(() => {
    setMerchantSagaDispatch();
  }, []);

  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation title="파티탈퇴" back_path={`/myparty/info?merchant_id=${merchant_id}`} />
      {!is_merchant_loading && !is_full_loading ? (
        <Container>
          <Title>
            파티탈퇴를 위해
            <br />
            다음 절차를 진행해주세요
          </Title>
          <Space height={40} />
          <LevelTab
            title="1. 환불 예정액 확인"
            content={
              <>
                카드 부분 환불이 진행될
                <br />
                금액을 확인해주세요
              </>
            }
            status={getRefundStatus()}
            onClick={() =>
              history.push({
                pathname: "/myparty/leave/refund",
                state: location.pathname,
                search: `?merchant_id=${merchant_id}&party_id=${party_id}`,
              })
            }
          />
          <Space height={12} />
          <LevelTab
            title={`2. ${merchant_name} 프로필 ${
              merchant_id === WATCHA_MERCHANT_ID_HARD || merchant_id === LAFTEL_MERCHANT_ID_HARD
                ? "삭제하기"
                : "닉네임 변경"
            }`}
            content={
              <>
                다음 파티원을 위해 이용했던
                <br />
                {merchant_id === WATCHA_MERCHANT_ID_HARD || merchant_id === LAFTEL_MERCHANT_ID_HARD
                  ? "프로필을 삭제해주세요"
                  : "프로필의 닉네임을 변경해주세요"}
              </>
            }
            status={getProfileDeleteStatus()}
            onClick={() =>
              history.push({
                pathname: "/myparty/leave/profile_delete",
                state: location.pathname,
                search: `?merchant_id=${merchant_id}&party_id=${party_id}`,
              })
            }
          />
          <Space height={56} />
          <Button
            disabled={!(is_profile_delete_done && is_refund_done)}
            onClick={() => is_profile_delete_done && is_refund_done && leavePartySagaDispatch()}
            theme="red-fill"
          >
            파티 탈퇴하기
          </Button>
        </Container>
      ) : (
        <FullLoading />
      )}
    </Suspense>
  );
};

const Container = styled.div`
  padding: 40px 24px 32px 24px;
`;

const Title = styled(OldH6)`
  color: var(--gray-900);
  font-weight: normal;
`;

export default PartyLeaveInit;
