import React, { useEffect, useState, useRef, lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { openToast, closeToast } from "containers/Toast";
import { OldInputState } from "components/Input";
import { ModalType } from "components/Modal";
import { closeModal, ModalState, openModal } from "containers/Modal";
import { BlurLoading } from "components/Loading";
import qs from "qs";
import { cleanupSelectedParty } from "./slice";
import { useRootState } from "globals/utils";
import { ModalText, HighlightText } from "components/OldHostParty";
import {
  OLDHOSTPARTY_SET_ITEM_PROFILE_NAME_SAGA,
  OLDHOSTPARTY_SEND_MSG_SAGA,
  OLDHOSTPARTY_DELETE_PARTY_SAGA,
  OLDHOSTPARTY_SET_PARTY_MATCHING_SAGA,
  OLDHOSTPARTY_SET_MATCHING_ON_SAGA,
  OLDHOSTPARTY_SET_PARTY_SAGA,
} from "./saga";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const PartyItem = lazy(() => import("components/OldHostParty").then(({ PartyItem }) => ({ default: PartyItem })));
const PartyProfiles = lazy(() =>
  import("components/OldHostParty").then(({ PartyProfiles }) => ({ default: PartyProfiles })),
);
const PartySps = lazy(() => import("components/OldHostParty").then(({ PartySps }) => ({ default: PartySps })));
const Content = lazy(() => import("components/OldHostParty").then(({ Content }) => ({ default: Content })));
const Tab = lazy(() => import("components/OldHostParty").then(({ Tab }) => ({ default: Tab })));
const ArrowIcon = lazy(() => import("components/OldHostParty").then(({ ArrowIcon }) => ({ default: ArrowIcon })));
const OldDivider = lazy(() => import("components/Divider").then(({ OldDivider }) => ({ default: OldDivider })));
const OldInput = lazy(() => import("components/Input").then(({ OldInput }) => ({ default: OldInput })));
const Modal = lazy(() => import("components/Modal"));
const Space = lazy(() => import("components/Space"));

const CheckParty = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation<{ party_id: number }>();
  const party_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).party_id,
  );
  const dispatch = useDispatch();
  const party = useRootState((state) => state.oldhostparty.selected_party.party);
  const is_loading = useRootState((state) => state.oldhostparty.selected_party.is_loading);
  const is_matching = useRootState((state) => state.oldhostparty.selected_party.is_matching);

  const cleanupSelectedPartyDispatch = () => dispatch(cleanupSelectedParty());
  const selectPartySagaDispatch = () =>
    dispatch({
      type: OLDHOSTPARTY_SET_PARTY_SAGA,
      payload: { party_id, goBack: () => history.push("/myparty/old") },
    });
  const setPartyMatchingSagaDispatch = () =>
    dispatch({ type: OLDHOSTPARTY_SET_PARTY_MATCHING_SAGA, payload: { party_id } });
  const openToastDisptach = (content: string) => dispatch(openToast(content));
  const closeToastDispatch = () => dispatch(closeToast());
  const setItemProfileNameSagaDispatch = (profile_name: string) =>
    dispatch({
      type: OLDHOSTPARTY_SET_ITEM_PROFILE_NAME_SAGA,
      payload: {
        profile_name,
        closeProfileModal: closeProfileModal,
        party_id,
        my_member_id: party?.my_member_id,
        item_short_name: party?.merchant_name,
      },
    });
  const goChangeSps = () => history.push(`/myparty/old/change?party_id=${party_id}`);

  const openModalDispatch = (content: ModalState) => dispatch(openModal(content));
  const closeModalDispatch = () => dispatch(closeModal());
  const sendMsgSagaDispatch = (msg: string) =>
    dispatch({
      type: OLDHOSTPARTY_SEND_MSG_SAGA,
      payload: {
        msg,
        party_id,
        members_id: party?.members_id,
      },
    });
  const deletePartySagaDispatch = () =>
    dispatch({
      type: OLDHOSTPARTY_DELETE_PARTY_SAGA,
      payload: { party_id, item_short_name: party?.merchant_name },
    });
  const setMatchingOnSagaDispatch = () => {
    dispatch({ type: OLDHOSTPARTY_SET_MATCHING_ON_SAGA, payload: { party_id } });
    closeModalDispatch();
  };

  const matching_modal: ModalState = {
    title: "매칭을 활성화 하시겠어요?",
    type: ModalType.ENLARGED,
    is_dimmer: true,
    is_dimmer_close: true,
    content: (
      <>
        <ModalText>빈자리가 모두 채워지며 매달 넷플릭스 구독료 3/4을 이체 받게 돼요</ModalText>
        <br />
        <HighlightText>⚠️ 한번 활성화 하면 다시 비활성화 할 수 없어요</HighlightText>
      </>
    ),
    buttons: [
      {
        onClick: closeModalDispatch,
        text: "아니요",
        theme: "gray-ghost",
      },
      {
        onClick: setMatchingOnSagaDispatch,
        text: "확인",
        theme: "primary-fill",
      },
    ],
  };

  const delete_party_modal: ModalState = {
    title: "파티를 해체하시겠어요?",
    type: ModalType.ENLARGED,
    is_dimmer: true,
    is_dimmer_close: true,
    content: (
      <>
        <ModalText>파티를 해체하면 이용기간만큼 일할계산하여 이체 받게 돼요</ModalText>
        <br />
        <HighlightText>
          ⚠️ 파티해체 후 다시 이용을 원하면 <br /> (신)피클플러스 멤버십을 이용해야 해요
        </HighlightText>
      </>
    ),
    buttons: [
      { onClick: closeModalDispatch, text: "아니요", theme: "gray-ghost" },
      {
        onClick: deletePartySagaDispatch,
        text: "확인",
        theme: "primary-fill",
      },
    ],
  };

  const [is_profile_modal_open, setProfileModal] = useState<boolean>(false);
  const closeProfileModal = () => setProfileModal(false);
  const openProfileModal = () => setProfileModal(true);
  const [profile_name, setProfileName] = useState<string>("");
  const profile_modal_props: ModalState = {
    is_dimmer: true,
    is_dimmer_close: true,
    title: "내 프로필 이름 변경",
    type: ModalType.SIMPLE,
    element: (
      <>
        <Content>최대 6자리까지 입력 가능해요</Content>
        <OldInput
          width={"280px"}
          state={OldInputState.READY}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProfileName(e.currentTarget.value)}
        />
      </>
    ),
    buttons: [
      {
        text: "변경 완료",
        theme: "primary-fill",
        onClick: () =>
          profile_name.length <= 6 && profile_name.length !== 0 && setItemProfileNameSagaDispatch(profile_name),
        disabled: profile_name.length > 6 || profile_name.length === 0,
      },
    ],
  };

  const ref = useRef<HTMLInputElement>(null);
  const sendMsg = () => {
    if (!ref.current) {
      setMsgModalOpen(false);
      return;
    }
    sendMsgSagaDispatch(ref.current.value);
    setMsgModalOpen(false);
  };

  const msg_modal_props: ModalState = {
    is_dimmer: true,
    is_dimmer_close: true,
    title: "파티원들에게 문자 보내기",
    type: ModalType.SIMPLE,
    element: (
      <>
        <Content>공지 내용과 휴대폰번호가 파티원들에게 문자로 전달됩니다</Content>
        <OldInput ref={ref} width={"280px"} state={OldInputState.READY} />
      </>
    ),
    buttons: [
      {
        text: "문자 전송",
        theme: "primary-fill",
        onClick: sendMsg,
      },
    ],
  };
  const [is_modal_open, setMsgModalOpen] = useState<boolean>(false);

  const tab_list = [
    {
      name: `${party?.merchant_name} 아이디/비밀번호를 바꿨어요`,
      onClick: goChangeSps,
      color: "var(--gray-900)",
    },
    {
      name: "파티원들에게 연락하고 싶어요",
      onClick: () => setMsgModalOpen(true),
      color: "var(--gray-900)",
    },
    {
      name: "이용에 문제가 있으신가요?",
      onClick: () => window.ChannelIO("show"),
      color: "var(--gray-900)",
    },
    {
      name: "파티해체",
      onClick: () => openModalDispatch(delete_party_modal),
      color: "var(--red-500)",
    },
  ];

  useEffect(() => {
    selectPartySagaDispatch();
    setPartyMatchingSagaDispatch();
    return () => {
      cleanupSelectedPartyDispatch();
    };
  }, []);

  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation
        back_path="/myparty/old"
        title={is_loading ? "파티" : `(구)${party?.merchant_name} 파티`}
        is_loading={is_loading}
      />
      {!is_loading && party && (
        <>
          {is_modal_open && <Modal {...msg_modal_props} closeModal={() => setMsgModalOpen(false)} />}
          {is_profile_modal_open && <Modal {...profile_modal_props} closeModal={() => closeProfileModal()} />}
          <PartyItem item_img_url={party.img_url} item_name={party.merchant_name} />
          <PartySps
            sps={{
              key: party.sps_key,
              value: party.sps_value,
              pin: party.sps_pin,
            }}
            openToast={openToastDisptach}
            closeToast={closeToastDispatch}
          />
          <PartyProfiles
            openChangeProfileNameModal={openProfileModal}
            my_profile_name={party.my_profile_name}
            profile_names={party.profile_names}
            item_short_name={party.merchant_name}
            reward={party.reward}
            pay_day={party.pay_day}
            is_matching={is_matching}
            openMatchingModal={openModalDispatch}
            matching_modal={matching_modal}
            member_cnt={party.members_id.length}
          />
          <Tab onClick={() => window.open(party.item_service_url)}>
            {party.merchant_name} 바로가기
            <ArrowIcon />
          </Tab>
          <OldDivider />
          {tab_list.map((tab) => (
            <Tab key={`${tab.name}_key`} color={tab.color} onClick={tab.onClick}>
              {tab.name}
            </Tab>
          ))}
          <Space height={32} />
        </>
      )}
    </Suspense>
  );
};

export default CheckParty;
