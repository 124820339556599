import React from "react";
import styled from "styled-components";
import { OldBody1 } from "components/Text";
import Space from "components/Space";
import { Color } from "globals/interfaces";

interface ListProps {
  title?: string;
  list: React.ReactNode[];
  bullet_color?: Color;
  list_space?: number;
}

const List = ({ title, list, list_space, bullet_color }: ListProps): JSX.Element => (
  <>
    {!!title && (
      <>
        <OldBody1 color="var(--gray-500)" font_weight="bold">
          {title}
        </OldBody1>
        <Space height={16} />
      </>
    )}
    <Ul>
      {list.map((element, index) => (
        <Li key={`list_element_${index}`} margin_bottom={list_space} bullet_color={bullet_color}>
          {element}
        </Li>
      ))}
    </Ul>
  </>
);

const Ul = styled.ul`
  list-style: disc;
  margin: 0;
  padding: 0 0 0 8px;
`;

const Li = styled.li<{ margin_bottom?: number; bullet_color?: string }>`
  ::marker {
    font-size: 2px;
    color: ${(props) => props.bullet_color || "var(--gray-400)"};
  }
  margin-bottom: ${(props) => props.margin_bottom || 8}px;
  color: var(--gray-500);
`;

export default List;
