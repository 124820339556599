import React from "react";
import styled from "styled-components";
import { H2, Body2 } from "components/Text";
import Space from "components/Space";
import { EmojiMoneyWithWings } from "assets/emojis";
import { useScrollFadeIn } from "globals/utils";
import { img_alim_talk } from "./assets";

const Section4 = (): JSX.Element => (
  <Container>
    <Space height={60} />
    <MainText {...useScrollFadeIn(1, 0.3)}>
      정산 · 송금할 필요없이
      <br />
      <MainTextWrapper>
        매달 간편 자동 정산!&nbsp;
        <EmojiMoneyWithWings />
      </MainTextWrapper>
    </MainText>
    <Space height={21} />
    <SubText {...useScrollFadeIn(1, 0.3)}>
      1/N 매번 이체하기 귀찮고 눈치보이는 송금 스트레스,
      <br />
      피클플러스는 매달 간편하게 자동 정산해줘요.
    </SubText>
    <Space height={56} />
    <div {...useScrollFadeIn(1, 0.3)}>
      <Image src={img_alim_talk} />
    </div>
    <Space height={70} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #cef8e8;
  overflow: hidden;
  align-items: center;
`;

const MainText = styled(H2)`
  text-align: center;
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubText = styled(Body2)`
  text-align: center;
  color: var(--gray-700);
`;

const Image = styled.img`
  height: auto;
  width: 335px;
`;

export default Section4;
