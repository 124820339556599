import React from "react";
import { Switch, Redirect } from "react-router";
import ManageBilling from "./MangeBilling";
import ManageAccount from "./ManageAccount";
import AddAccountInit from "./AddAccountInit";
import AddAccountInput from "./AddAccountInput";
import MyPage from "./MyPage";
import AddCard from "./AddCard";
import { RestrictRoute, useRootState, Route } from "globals/utils";

const MyPageRoute = (): JSX.Element => {
  const selected_bank = useRootState((state) => state.mypage.add_account.selected_bank);

  return (
    <Switch>
      <Route exact path="/mypage" component={MyPage} />
      <Route path="/mypage/managebilling" component={ManageBilling} />
      <Route path="/mypage/addbilling" component={AddCard} />
      <Route path="/mypage/manageaccount" component={ManageAccount} />
      <Route path="/mypage/addaccount/init" component={AddAccountInit} />
      <RestrictRoute
        is_pass={!!selected_bank}
        redirect_url="/mypage/addaccount/init"
        path="/mypage/addaccount/input"
        component={AddAccountInput}
      />
      <Redirect to="/mypage" />
    </Switch>
  );
};

export default MyPageRoute;
