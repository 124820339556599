import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavigationState, NavigationType, PageNavData } from "./interfaces";

const initialState: NavigationState = {
  current_navigation: NavigationType.TAB,
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setPageNavigation(state, action: PayloadAction<PageNavData>) {
      state.page_navigation = action.payload;
    },
    selectNavigation(state, action: PayloadAction<NavigationType>) {
      state.current_navigation = action.payload;
    },
  },
});

export const { setPageNavigation, selectNavigation } = navigationSlice.actions;
export default navigationSlice.reducer;
