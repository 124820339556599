import {
  adminGetPartyBotLogs,
  getServiceVariables,
  adminSetServiceVariable,
  adminStartPartyMatching,
} from "apis/admin";
import { logError, StatusCodes } from "globals/utils";
import { call, put, takeLatest } from "redux-saga/effects";
import { setLoading, setVariableList, setBotLogs } from "./slice";

function* checkUserAdminSaga() {
  try {
    yield put(setLoading(true));
    yield call(getServiceVariables);
    yield put(setLoading(false));
  } catch (e) {
    window.location.href = "/";
    logError(e.response);
  }
}
export const ADMIN_CHECK_USER_ADMIN_SAGA = "admin/checkUserAdminSaga";

function* getServiceVariableListSaga() {
  try {
    const response = yield call(getServiceVariables);
    const { data } = response.data;
    yield put(setVariableList(data));
  } catch (e) {
    logError(e);
  }
}
export const ADMIN_GET_SERVICE_VARIABLE_LIST_SAGA = "admin/getServiceVariableListSaga";

function* setServiceVariableSaga(action: {
  type: string;
  payload: {
    key: string;
    value: string | number | boolean;
    type: string;
  };
}) {
  try {
    const response = yield call(adminSetServiceVariable, action.payload);
    const { data } = response.data;
    alert(`변수 변경완료 ${data.value}`);
    window.location.reload();
  } catch (e) {
    logError(e);
  }
}
export const ADMIN_SET_SERVICE_VARIABLE_SAGA = "admin/setServiceVariableSaga";

function* setBotLogsSaga(action: {
  type: string;
  payload: {
    party_id?: number;
    is_success?: boolean;
    page?: number;
  };
}) {
  try {
    const response = yield call(adminGetPartyBotLogs, action.payload);
    const { data } = response.data;
    yield put(setBotLogs(data));
  } catch (e) {
    logError(e);
  }
}
export const ADMIN_SET_BOT_LOGS_SAGA = "admin/setBotLogsSaga";

function* startPartyMatchingSaga(action: {
  type: string;
  payload: {
    party_id: number;
    onSuccess?: () => void;
  };
}) {
  try {
    yield call(adminStartPartyMatching, action.payload.party_id);
    alert("매칭 시작 완료");
    action.payload?.onSuccess && action.payload?.onSuccess();
  } catch (e) {
    if (e.response) {
      switch (e.response.status) {
        case StatusCodes.CONFLICT_409:
          alert("이미 매칭 중입니다.");
          break;
        default:
          console.error(e.response);
          alert("에러 발생, 개발자에게 문의바람.");
      }
    } else {
      logError(e);
    }
  }
}
export const ADMIN_START_PARTY_MATCHING_SAGA = "admin/startPartyMatchingSaga";

function* adminSaga(): Generator {
  yield takeLatest(ADMIN_CHECK_USER_ADMIN_SAGA, checkUserAdminSaga);
  yield takeLatest(ADMIN_GET_SERVICE_VARIABLE_LIST_SAGA, getServiceVariableListSaga);
  yield takeLatest(ADMIN_SET_SERVICE_VARIABLE_SAGA, setServiceVariableSaga);
  yield takeLatest(ADMIN_SET_BOT_LOGS_SAGA, setBotLogsSaga);
  yield takeLatest(ADMIN_START_PARTY_MATCHING_SAGA, startPartyMatchingSaga);
}

export default adminSaga;
