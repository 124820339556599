import { ChannelIoUserData } from "./auth/interface";

export const NODE_ENV = process.env.NODE_ENV;
export const MAIN_API_URL = process.env.REACT_APP_MAIN_API_URL;
export const OLD_NODE_URL = process.env.REACT_APP_OLD_NODE_URL;
export const OLD_DJANGO_URL = process.env.REACT_APP_OLD_DJANGO_URL;
export const IMP_CODE = process.env.REACT_APP_IMP_CODE;
export const KAKAO_JS_KEY = process.env.REACT_APP_KAKAO_JS_KEY;
export const KAKAO_REST_KEY = process.env.REACT_APP_KAKAO_REST_KEY;
export const KAKAO_CLIENT_SECRET = process.env.REACT_APP_KAKAO_CLIENT_SECRET;
export const CHANNEL_IO = process.env.REACT_APP_CHANNEL_IO;
export const IMP = window.IMP;
export const OLD_REAL_NODE_URL = process.env.REACT_APP_OLD_REAL_NODE_URL;
export const OLD_REAL_DJANGO_URL = process.env.REACT_APP_OLD_REAL_DJANGO_URL;
export const KAKAO_MOMENTS = process.env.REACT_APP_KAKAO_MOMENTS;
export const AMPLITUDE_ID = process.env.REACT_APP_AMPLITUDE_ID;
export const GTAG = process.env.REACT_APP_GTAG;
export const PIXEL_ID = process.env.REACT_APP_PIXEL;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

declare global {
  interface Window {
    Kakao: {
      init: (key: string) => unknown;
      isInitialized: () => boolean;
      Auth: {
        authorize: (params: { redirectUri?: string; scope?: string; state?: string }) => unknown;
        setAccessToken: (token: string) => unknown;
        getAccessToken: () => string | null | undefined;
        login: (params: { success?: () => void; fail?: () => void }) => void;
      };
      API: {
        request: (params: {
          url: "/v1/user/unlink" | "/v2/user/me" | "/v1/user/update_profile";
          success?: () => void;
          fail?: () => void;
        }) => unknown;
      };
    };
    IMP: {
      init: (imp_code: string) => void;
      certification: (data: unknown, callback: (response_imp: { success: boolean; imp_uid: string }) => void) => void;
    };
    kakaoPixel: (
      kakao_pixel_id?: string,
    ) => {
      pageView: (tag?: string) => void;
      completeRegistration: (tag?: string) => void;
      purchase: (tag?: string) => void;
    };
    ChannelIO: (command: string, options?: ChannelIOOptionsData) => void;
    ChannelIOInitialized: boolean;
    gtag: (
      type: "event" | "config" | "js",
      action: "purchase" | "refund" | "login" | "sign_up",
      params: {
        event_category?: string;
        event_label?: string;
        value?: number;
        transaction_id?: string;
        affiliation?: string;
        currency?: string;
        tax?: string;
        items?: [
          {
            id?: string;
            name?: string;
            list_name?: string;
            brand?: string;
            category?: string;
            variant?: string;
            list_position?: number;
            quantity?: number;
            price?: number;
          },
        ];
      },
    ) => void;
    fbq: (
      type: "track" | "init",
      action: "Purchase" | string,
      params: {
        currency?: "KRW";
        value?: number;
        content_type?: "product";
        content_name?: string;
        content_ids?: number | string[];
        contents?: [
          {
            id?: string;
            quantity?: number;
            content_name?: string;
          },
        ];
        external_id?: string;
      },
    ) => void;
  }
}

interface ChannelIOOptionsData extends ChannelIoUserData {
  pluginKey?: string;
  hideChannelButtonOnBoot?: boolean;
  language?: "ko" | "en";
}
