import React from "react";
import styled from "styled-components";
import { media } from "globals/style";
import icon_arrow_down from "./assets/icon_arrow_down.png";
import icon_arrow_up from "./assets/icon_arrow_up.png";
import { OldH6 } from "components/Text";

interface GuideToggleProps {
  is_open: boolean;
  height: number;
  desktop_height: number;
  icon_src: string;
  title: string;
  children: React.ReactNode;
  onClick: () => void;
}

const GuideToggle = ({
  is_open,
  height,
  desktop_height,
  icon_src,
  title,
  children,
  onClick,
}: GuideToggleProps): JSX.Element => (
  <>
    <ToggleContainer onClick={onClick} is_open={is_open} block_height={height} desktop_height={desktop_height}>
      <Tab>
        <Icon src={icon_src} />
        <div>{title}</div>
        <ArrowIcon src={is_open ? icon_arrow_up : icon_arrow_down} />
      </Tab>
      {children}
    </ToggleContainer>
    {!is_open && <Border />}
  </>
);

export const Title = styled(OldH6)`
  padding-left: 24px;
  color: var(--gray-900);
`;

export const ToggleContainer = styled.div<{ is_open: boolean; block_height: number; desktop_height: number }>`
  height: ${(props) => (props.is_open ? `${props.block_height + 63}px` : "63px")};
  overflow: hidden;
  transition: height 0.3s ease-in;
  ${media.desktop} {
    height: ${(props) => (props.is_open ? `${props.desktop_height + 63}px` : "63px")};
  }
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 63px;
  color: var(--gray-900);
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 12px;
`;

export const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
`;

export const Block = styled.div`
  border-radius: 8px;
  background-color: var(--gray-100);
  padding: 22px 24px 16px 24px;
  transition: height 1s ease;
  color: var(--gray-700);
`;

export const Border = styled.div`
  height: 1px;
  background-color: var(--gray-100);
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default GuideToggle;
