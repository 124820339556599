import { OldH3, OldH4, OldH6 } from "components/Text";
import styled from "styled-components";

export const Container = styled.div`
  padding: 34px 24px 0 24px;
  height: 80vh;
  overflow-y: scroll;
`;

export const Title = styled(OldH3)`
  color: var(--primary-700);
  margin-right: 20px;
  margin-bottom: 50px;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid var(--gray-400) 1px;
`;

export const SubTitle = styled(OldH4)`
  color: var(--primary-700);
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const WaitText = styled(OldH6)`
  color: var(--gray-900);
  margin-top: 10px;
`;
export const Column = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  font-weight: bold;
  width: ${(props) => props.width || "130px"};
  height: 30px;
  color: var(--gray-700);
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: solid var(--gray-500) 2px;
  border-right: solid var(--gray-500) 2px;
`;
export const Value = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  width: ${(props) => props.width || "130px"};
  height: 30px;
  color: var(--gray-500);
  margin-left: 10px;
  margin-right: 10px;
  border-right: solid var(--gray-400) 1px;
`;
