import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ToastState {
  content: string;
  is_open: boolean;
}
const initialState: ToastState = {
  is_open: false,
  content: "",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    openToast(state, action: PayloadAction<string>) {
      state.content = action.payload;
      state.is_open = true;
    },
    closeToast(state) {
      state.content = "";
      state.is_open = false;
    },
  },
});

export const { openToast, closeToast } = toastSlice.actions;
export default toastSlice.reducer;
