import { AxiosResponse } from "axios";
import { BotLogData, PartyInfoResponse } from "containers/Admin";
import { OLD_DJANGO_URL, OLD_NODE_URL } from "globals/setting";
import { AuthAxios, Axios } from "./default";

export const adminGetMerchantPeople = async (merchant_id: number, page: number): Promise<AxiosResponse> => {
  const response = await AuthAxios.get(`${OLD_NODE_URL}/admin/party/matching/merchants/${merchant_id}/${page}`);
  return response;
};

export const adminGetMerchantEmptySpaces = async (merchant_id: number, page: number): Promise<AxiosResponse> => {
  const response = await AuthAxios.get(`${OLD_NODE_URL}/admin/party/matching/spaces/empty/${merchant_id}/${page}`);
  return response;
};

export const adminGetMerchantFilledSpaces = async (merchant_id: number, page: number): Promise<AxiosResponse> => {
  const response = await AuthAxios.get(`${OLD_NODE_URL}/admin/party/matching/spaces/filled/${merchant_id}/${page}`);
  return response;
};

export const adminGetMerchantPeopleCnt = async (merchant_id: number): Promise<AxiosResponse> => {
  const response = await AuthAxios.get(`${OLD_NODE_URL}/admin/party/matching/cnt/people/${merchant_id}`);
  return response;
};

export const adminGetMerchantEmptySpacesCnt = async (merchant_id: number): Promise<AxiosResponse> => {
  const response = await AuthAxios.get(`${OLD_NODE_URL}/admin/party/matching/cnt/spaces/empty/${merchant_id}`);
  return response;
};

export const adminGetMerchantFilledSpacesCnt = async (merchant_id: number): Promise<AxiosResponse> => {
  const response = await AuthAxios.get(`${OLD_NODE_URL}/admin/party/matching/cnt/spaces/filled/${merchant_id}`);
  return response;
};

export const getServiceVariables = async (): Promise<AxiosResponse> => {
  const response = await AuthAxios.get(`${OLD_NODE_URL}/admin/setting/settings`);
  return response;
};

export const getServiceVariable = async (key: string): Promise<AxiosResponse> => {
  const response = await Axios.get(`${OLD_NODE_URL}/admin/setting/settings/${key}`);
  return response;
};

export const getPartyInfo = async (party_id: number): Promise<AxiosResponse<PartyInfoResponse>> => {
  const response = await AuthAxios.get(`${OLD_NODE_URL}/admin/party/info/${party_id}`);
  return response;
};

export const adminSetServiceVariable = async ({
  key,
  value,
  type,
}: {
  key: string;
  value: number | string | boolean;
  type: string;
}): Promise<AxiosResponse> => {
  let converted_value: number | string | boolean = "";
  if (type === "number") {
    converted_value = Number(value);
  } else if (type === "boolean") {
    converted_value = !(value === "false");
  } else {
    converted_value = value;
  }
  const response = await AuthAxios.patch(`${OLD_NODE_URL}/admin/setting/settings/${key}`, {
    value: converted_value,
    type,
  });
  return response;
};

export const adminGetPartyBotLogs = async ({
  party_id,
  is_success,
  page,
}: {
  party_id?: number;
  is_success?: boolean;
  page?: number;
}): Promise<AxiosResponse<PartyInfoResponse>> => {
  const response = await AuthAxios.get(`${OLD_DJANGO_URL}/party/admin/party-bot-logs`, {
    params: {
      party_id,
      is_success,
      page,
    },
  });
  return response;
};

export const adminStartPartyMatching = async (party_id: number): Promise<AxiosResponse<{ data: BotLogData }>> => {
  const response = await AuthAxios.post(`${OLD_DJANGO_URL}/party/admin/parties/${party_id}/matching-start`);
  return response;
};
