/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RootState } from "root.redux";

export const slotSelector = (state: RootState) => state.slot.slot;

export const membershipSelector = (state: RootState) => state.membership;

export const slotsSelector = (state: RootState) => state.myparty.slots;

export const isTryingPaySelector = (state: RootState) => state.auth.is_trying_pay;
export const isLoginSelector = (state: RootState) => state.auth.is_login;

export const userSelector = (state: RootState) => state.user;

export const merchandiseSelector = (state: RootState) => state.merchandise;
