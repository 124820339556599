import React from "react";
import styled from "styled-components";
import { ReactComponent as IconArrowTop } from "./assets/icon_arrow_top.svg";

const GoToTopButton = ({
  goToTop,
  bottom,
  go_to_top_ref,
}: {
  goToTop: () => void;
  bottom: number;
  go_to_top_ref: React.MutableRefObject<HTMLDivElement | null>;
}): JSX.Element => (
  <IconWrapper bottom={bottom}>
    <IconContainer onClick={goToTop} ref={go_to_top_ref}>
      <IconArrowTop />
    </IconContainer>
  </IconWrapper>
);

const IconWrapper = styled.div<{ bottom: number }>`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  bottom: ${(props) => props.bottom}px;
  z-index: 3;
  width: 100%;
  max-width: 640px;
  text-align: right;
  padding-right: 20px;
  transition: bottom 0.3s ease-out;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  box-shadow: var(--shadow-low);
  border-radius: 50%;
`;

export default GoToTopButton;
