import React from "react";
import styled from "styled-components";
import { InputDescriptionType } from "./interface";
import { ReactComponent as IconCheck } from "./assets/icon_check.svg";
import { Caption1 } from "components/Text";

interface DescriptionProps {
  theme?: InputDescriptionType;
  children: string;
  is_have_check?: boolean;
}

const description_theme: { [key in InputDescriptionType]: { color: string } } = {
  DEFAULT: {
    color: "var(--gray-400)",
  },
  SUCCESS: {
    color: "var(--primary-600)",
  },
  ERROR: {
    color: "var(--red-400)",
  },
};

const Description = ({ theme = "DEFAULT", children, is_have_check }: DescriptionProps): JSX.Element => {
  const color = description_theme[theme].color;

  return (
    <MessageWrapper>
      {is_have_check && (
        <IconWrapper>
          <IconCheck title="icon check" fill={color} />
        </IconWrapper>
      )}
      <Caption1 color={color}>{children}</Caption1>
    </MessageWrapper>
  );
};

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  width: 14px;
  height: 14px;
  margin-right: 2px;
`;

export default Description;
