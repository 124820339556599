import React, { lazy } from "react";

const OldDivider = lazy(() => import("components/Divider").then(({ OldDivider }) => ({ default: OldDivider })));
const Tab = lazy(() => import("./SlotStyle").then(({ Tab }) => ({ default: Tab })));
const TextColumn = lazy(() => import("./SlotStyle").then(({ TextColumn }) => ({ default: TextColumn })));
const MainText = lazy(() => import("./SlotStyle").then(({ MainText }) => ({ default: MainText })));
const SubText = lazy(() => import("./SlotStyle").then(({ SubText }) => ({ default: SubText })));
const Arrow = lazy(() => import("./SlotStyle").then(({ Arrow }) => ({ default: Arrow })));
const MerchantIcon = lazy(() => import("./SlotStyle").then(({ MerchantIcon }) => ({ default: MerchantIcon })));

const AddPartyTab = ({ goMembershipChange }: { goMembershipChange: (merchant_id: number) => void }): JSX.Element => (
  <>
    <Tab onClick={goMembershipChange}>
      <MerchantIcon />
      <TextColumn>
        <MainText>파티 추가</MainText>
        <SubText>새로운 파티를 추가해보세요</SubText>
      </TextColumn>
      <Arrow />
    </Tab>
    <OldDivider margin_top="0" margin_bottom="0" height="1px" />
  </>
);

export default AddPartyTab;
