import React, { lazy, Suspense, useEffect } from "react";
import qs from "qs";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { BlurLoading } from "components/Loading";
import { useRootState } from "globals/utils";
import { MANAGEMERCHANT_CHANGE_SPS_SAGA } from "containers/ManageMerchant";
import { OLDHOSTPARTY_SET_PARTY_SAGA } from "./saga";
import { LAFTEL_MERCHANT_ID_HARD, NETFLIX_MERCHANT_ID_HARD, WATCHA_MERCHANT_ID_HARD } from "globals/variables";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const ChangeSps = lazy(() => import("components/ManageMerchant").then(({ ChangeSps }) => ({ default: ChangeSps })));

const ChangeSpsContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation<{ party_id: number }>();
  const history = useHistory();
  const party_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).party_id,
  );
  const party = useRootState((state) => state.oldhostparty.selected_party.party);
  const is_loading = useRootState((state) => state.oldhostparty.selected_party.is_loading);

  const selectPartySagaDispatch = () =>
    dispatch({
      type: OLDHOSTPARTY_SET_PARTY_SAGA,
      payload: { party_id, goBack: () => history.push("/myparty/old") },
    });

  const previous_route = `/myparty/old/party?party_id=${party_id}`;

  const goService = () => {
    window.open(party?.item_service_url);
  };
  const changeSps = (sps: { key?: string; value?: string; pin?: string }) => {
    if (!party || !sps.key || !sps.value) {
      alert("잘못된 접근입니다. 새로고침을 해주세요.");
      return;
    }
    const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phone_regex = /^\d{3}\d{3,4}\d{4}$/;
    if (party.item_id === NETFLIX_MERCHANT_ID_HARD && !email_regex.test(sps.key) && !phone_regex.test(sps.key)) {
      alert("넷플릭스 ID는 이메일 혹은 휴대폰번호만 사용 가능합니다.");
      return;
    }
    if (party.item_id === LAFTEL_MERCHANT_ID_HARD && !email_regex.test(sps.key)) {
      alert("라프텔 ID는 이메일만 사용 가능합니다.");
      return;
    }
    if (party.item_id === WATCHA_MERCHANT_ID_HARD && !email_regex.test(sps.key)) {
      alert("왓챠 ID는 이메일만 사용 가능합니다.");
      return;
    }
    const payload = {
      party_id,
      sps: {
        sps_key: sps.key,
        sps_value: sps.value,
        sps_pin: sps.pin,
      },
      goCheckSps: () => {
        window.location.href = `/myparty/old/party?party_id=${party_id}`;
      },
    };
    dispatch({ type: MANAGEMERCHANT_CHANGE_SPS_SAGA, payload });
  };

  useEffect(() => {
    selectPartySagaDispatch();
    return;
  }, []);

  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation
        back_path={previous_route}
        title={!is_loading ? `(구)${party?.merchant_name} ID/PW 변경` : "ID/PW 변경"}
        is_loading={is_loading}
      />
      {!is_loading && party && (
        <ChangeSps
          merchant_id={Number(party.item_id)}
          merchant_name={party.merchant_name}
          goService={goService}
          changeSps={changeSps}
        />
      )}
    </Suspense>
  );
};

export default ChangeSpsContainer;
