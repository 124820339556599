import { deleteParty, getRefundInfo, getParty } from "apis";
import { closeModal } from "containers/Modal";
import { logError, StatusCodes } from "globals/utils";
import { call, put, takeLatest } from "redux-saga/effects";
import { setRefundLoading, setHostLoading, setHostSlot, setRefundInfo, setFullLoading } from "./slice";
import { setTryingPay, cleanupTryingPay } from "globals/auth";
import { MYPARTY_SET_SLOTS_SAGA } from "containers/MyParty";
import { Amplitude } from "apis/amplitude";

function* setRefundInfoSaga(action: {
  type: string;
  payload: { goBack: () => void; merchant_id: number; goMyParty: () => void };
}) {
  yield put(setRefundLoading(true));
  try {
    const response = yield call(getRefundInfo, action.payload.merchant_id);
    const { data } = response.data;
    yield put(setRefundInfo(data));
  } catch (e) {
    logError(e);
    if (e.response) {
      alert(`파티 해체 과정에서 문제가 생겼습니다.\n${e.response.data?.msg}`);
      action.payload.goMyParty();
    } else {
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
      action.payload.goBack();
    }
  } finally {
    yield put(setRefundLoading(false));
  }
}
export const PARTYDELETE_SET_REFUND_INFO_SAGA = "partydelete/setRefundInfoSaga";

function* setHostSlotSaga(action: { type: string; payload: { party_id: number; goBack: () => void } }) {
  yield put(setHostLoading(true));
  try {
    const { party_id } = action.payload;
    const response = yield call(getParty, party_id);
    const { data } = response.data;
    if (!data.host_yn) {
      yield put(setHostLoading(false));
      return;
    }
    yield put(setHostSlot(data));
  } catch (e) {
    logError(e);
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.NOT_FOUND_404:
          alert("가입되어 있는 파티가 아닙니다.");
          break;
        default:
          alert(`에러가 발생했습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.\n${e.response.data?.msg}`);
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    action.payload.goBack();
  } finally {
    yield put(setHostLoading(false));
  }
}
export const PARTYDELETE_SET_HOST_SLOT_SAGA = "partydelete/setHostSlotSaga";

function* deletePartySaga(action: {
  type: string;
  payload: { party_id: number; merchant_name: string; goBack: () => void };
}) {
  yield put(closeModal());
  yield put(setTryingPay());
  yield put(setFullLoading(true));
  try {
    const { party_id, merchant_name } = action.payload;
    Amplitude.tryDeleteParty({ merchant_name });
    const response = yield call(deleteParty, party_id);
    if (response.status === StatusCodes.NO_CONTENT_204) {
      alert("파티해체가 완료되었습니다");
      yield put({ type: MYPARTY_SET_SLOTS_SAGA });
      window.location.href = "/myparty";
    }
  } catch (e) {
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.BAD_REQUEST_400:
          alert("파티원은 이용권 구매 후 1달 뒤에 해지 가능합니다.");
          break;
        case StatusCodes.NOT_FOUND_404:
          alert("해당 파티를 찾을 수 없습니다.");
          break;
        case StatusCodes.INTERNAL_SERVER_ERROR_500:
          alert("멤버십을 결제해야 이용할 수 있습니다.");
          break;
        default:
          alert(`파티해체 과정에서 문제가 생겼습니다.\n${e.response.data?.msg}`);
      }
    } else alert("현재 서버가 원활하지 않습니다. 나중에 다시 시도해주세요.");
    action.payload.goBack();
  } finally {
    yield put(cleanupTryingPay());
    yield put(setFullLoading(false));
  }
}
export const PARTYDELETE_DELETE_PARTY_SAGA = "partydelete/deletePartySaga";

function* partydeleteSaga(): Generator {
  yield takeLatest(PARTYDELETE_SET_REFUND_INFO_SAGA, setRefundInfoSaga);
  yield takeLatest(PARTYDELETE_SET_HOST_SLOT_SAGA, setHostSlotSaga);
  yield takeLatest(PARTYDELETE_DELETE_PARTY_SAGA, deletePartySaga);
}

export default partydeleteSaga;
