import styled from "styled-components";
import { H3, Body3 } from "components/Text";

export const GuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to top, var(--gray-050), rgba(248, 249, 253, 0));
  width: auto;
`;

export const GuideMainTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const GuideMainText = styled(H3)`
  text-align: center;
  z-index: 1;
  color: var(--gray-700);
`;

export const Underline = styled.div<{ width: number }>`
  top: 0;
  left: -2px;
  position: absolute;
  width: ${(props) => props.width}px;
  height: 15px;
  margin: 14px 0 0;
  transform: rotate(-1deg);
  background-color: var(--primary-300);
`;

export const GuideSubText = styled(Body3)`
  color: var(-gray-700);
  text-align: center;
`;

export const CardContainer = styled.div`
  width: 100%;
  height: 312px;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow-x: scroll;
  cursor: grab;
  white-space: nowrap;
  overflow-anchor: none;
  ::-webkit-scrollbar {
    display: none;
    width: none;
  }
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;
