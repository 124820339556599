import React from "react";
import Logo from "../Logo";
import styled from "styled-components";
import { OldDivider } from "components/Divider";
import { TabNavIcon, TabNavData } from "containers/TopNavigation";
import { Body2 } from "components/Text";

const TabNavigation = ({ goHome, tab_list }: { goHome: () => void; tab_list: TabNavData[] }): JSX.Element => (
  <>
    <Container>
      <MainRow>
        <div onClick={goHome}>
          <Logo />
        </div>
        <TabNavIcon />
      </MainRow>
      <TabRow>
        {tab_list.map((tab) => (
          <Tab key={`${tab.name}_tab`} is_selected={tab.is_selected} onClick={tab.onClick}>
            {tab.name}
          </Tab>
        ))}
      </TabRow>
    </Container>
    <OldDivider margin_top="0px" margin_bottom="0px" height="1px" />
  </>
);

const Container = styled.header`
  display: flex;
  padding: 14px 20px 14px 20px;
  flex-direction: column;
  width: calc(100% - 40px);
  max-width: 640px;
  height: 68px;
`;

const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  height: 24px;
`;

const TabRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Tab = styled(Body2)<{ is_selected?: boolean }>`
  margin-right: 28px;
  font-weight: ${(props) => (props.is_selected ? "bold" : "normal")};
  color: ${(props) => (props.is_selected ? "var(--gray-800)" : "var(--gray-600)")};
  cursor: pointer;
  &:hover {
    font-weight: bold;
    color: var(--gray-900);
  }
`;

export default TabNavigation;
