import React, { lazy, Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { BlurLoading } from "components/Loading";
import { TabNavigation } from "containers/TopNavigation";
import {
  BlackGradient,
  CtaButtonContainer,
  CtaButtonFloater,
  CtaSection,
  SectionLocationGuide,
  WhiteGradient,
} from "components/Home";
import ButtonLegacy from "components/Button";

const LicenseFooter = lazy(() => import("components/Footer").then(({ LicenseFooter }) => ({ default: LicenseFooter })));
const Section1 = lazy(() => import("components/Home").then(({ Section1 }) => ({ default: Section1 })));
const Section2 = lazy(() => import("components/Home").then(({ Section2 }) => ({ default: Section2 })));
const Section3 = lazy(() => import("components/Home").then(({ Section3 }) => ({ default: Section3 })));
const Section4 = lazy(() => import("components/Home").then(({ Section4 }) => ({ default: Section4 })));
const Section5 = lazy(() => import("components/Home").then(({ Section5 }) => ({ default: Section5 })));
const Section6 = lazy(() => import("components/Home").then(({ Section6 }) => ({ default: Section6 })));
const Section7 = lazy(() => import("components/Home").then(({ Section7 }) => ({ default: Section7 })));
const Section8 = lazy(() => import("components/Home").then(({ Section8 }) => ({ default: Section8 })));
const GoToTopButton = lazy(() => import("components/Home").then(({ GoToTopButton }) => ({ default: GoToTopButton })));

const HomeContainer = (): JSX.Element => {
  const history = useHistory();
  const goMatching = () => history.push("/matching");

  const SECTION1_BUTTON_END_LOCATION = 434;
  const SECTION8_HEIGHT = 598;
  const CTA_FLOATING_TRIGGER_LOCATION = 375;
  const CTA_CONTAINER_THICK_HEIGHT = 60;

  const goToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const [button_bottom, setButtonBottom] = useState<number>(98);
  const license_ref = useRef<HTMLDivElement | null>(null);
  const handleButton = useCallback(([entry]) => {
    const { current } = license_ref;
    if (!!current && entry.isIntersecting) setButtonBottom(36);
    else setButtonBottom(98);
  }, []);

  useEffect(() => {
    let observer: IntersectionObserver;

    if (license_ref.current) {
      observer = new IntersectionObserver(handleButton, { threshold: 0 });
      observer.observe(license_ref.current);
    }

    return () => observer && observer.disconnect();
  }, [handleButton]);

  const section2_ref = useRef<HTMLDivElement>(null);
  const section8_ref = useRef<HTMLDivElement>(null);
  const cta_ref = useRef<HTMLDivElement>(null);
  const white_background_ref = useRef<HTMLDivElement>(null);
  const black_background_ref = useRef<HTMLDivElement>(null);
  const go_to_top_ref = useRef<HTMLDivElement>(null);

  const [floating_space_height, setFloatingSpaceHeight] = useState<number>(4063 + SECTION8_HEIGHT);
  const [floating_space_top, setFloatingSpaceTop] = useState<number>(SECTION1_BUTTON_END_LOCATION);

  const setFloatingSpacePosition = useCallback(() => {
    const element8 = section8_ref.current;
    setFloatingSpaceTop(SECTION1_BUTTON_END_LOCATION);
    setFloatingSpaceHeight((element8?.offsetTop || 0) - SECTION1_BUTTON_END_LOCATION + SECTION8_HEIGHT);
  }, []);

  const ctaScrollEvent = useCallback(() => {
    const element8 = section8_ref.current;
    const element_style = cta_ref.current?.style;
    const black_background_style = black_background_ref.current?.style;
    const white_background_style = white_background_ref.current?.style;
    const go_to_top_style = go_to_top_ref.current?.style;

    if (element_style && white_background_style && black_background_style && go_to_top_style) {
      if (window.scrollY > CTA_FLOATING_TRIGGER_LOCATION) {
        white_background_style.opacity = "1";
        element_style.transform = "translate(0, 0)";
        element_style.opacity = "1";
        go_to_top_style.opacity = "1";
      } else {
        white_background_style.opacity = "0";
        element_style.transform = "translate(0, 100px)";
        element_style.opacity = "0";
        go_to_top_style.opacity = "0";
      }

      if (window.scrollY > (element8?.offsetTop || 0) + CTA_CONTAINER_THICK_HEIGHT - window.innerHeight) {
        white_background_style.opacity = "0";
        black_background_style.opacity = "1";
      } else if (black_background_style) {
        white_background_style.opacity = "1";
        black_background_style.opacity = "0";
      }
    }
  }, []);

  useEffect(() => {
    const section_9_offset_top = section8_ref.current?.offsetTop;
    if (section_9_offset_top && section_9_offset_top > 0) {
      setFloatingSpacePosition();
    }
    window.addEventListener("load", setFloatingSpacePosition);
    window.addEventListener("scroll", ctaScrollEvent);
    return () => {
      window.removeEventListener("load", setFloatingSpacePosition);
      window.removeEventListener("scroll", ctaScrollEvent);
    };
  }, []);

  return (
    <>
      <TabNavigation />
      <Suspense fallback={<BlurLoading />}>
        <Section1 goMatching={goMatching} />
        <SectionLocationGuide ref={section2_ref}></SectionLocationGuide>
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <SectionLocationGuide ref={section8_ref}></SectionLocationGuide>
        <Section8 />
        <SectionLocationGuide ref={license_ref}></SectionLocationGuide>
        <LicenseFooter />
        <GoToTopButton goToTop={goToTop} bottom={button_bottom} go_to_top_ref={go_to_top_ref} />
        <CtaSection top={floating_space_top} height={floating_space_height}>
          <CtaButtonContainer ref={cta_ref}>
            <BlackGradient ref={black_background_ref} />
            <WhiteGradient ref={white_background_ref} />
            <CtaButtonFloater>
              <ButtonLegacy height={50} onClick={goMatching} font_weight="bold" padding_top="0px">
                서비스 시작하기
              </ButtonLegacy>
            </CtaButtonFloater>
          </CtaButtonContainer>
        </CtaSection>
      </Suspense>
    </>
  );
};

export default HomeContainer;
