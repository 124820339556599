import axios, { AxiosRequestConfig } from "axios";
import { getAccessToken, removeAuthToken } from "globals/auth/token";
import * as Sentry from "@sentry/react";
import { logError } from "globals/utils";

const axios_common_default: AxiosRequestConfig = {
  headers: {
    common: {
      "Content-Type": "application/json",
    },
  },
  withCredentials: true,
};

const Axios = axios.create(axios_common_default);
const AuthAxios = axios.create(axios_common_default);

AuthAxios.interceptors.request.use(
  async (config) => {
    const access_token = await getAccessToken();
    if (access_token && parseJwt(access_token).type === "refresh") {
      removeAuthToken();
      goLoginWithCurrentUrl();
      throw axios.Cancel;
    }
    if (access_token) {
      config.headers.Authorization = `jwt ${access_token}`;
    } else {
      throw axios.Cancel;
    }
    return config;
  },
  (error) => {
    alert("로그인 과정에서 에러가 발생했습니다. 다시 로그인해주세요.");
    removeAuthToken();
    goLoginWithCurrentUrl();
    logError(error);
    return Promise.reject(error);
  },
);

Axios.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (!error.response) {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  },
);

const goLoginWithCurrentUrl = (): void => {
  const redirect_url = encodeURIComponent(`${window.location.pathname}${window.location.search}`);
  window.location.href = `/login/select?redirect_url=${redirect_url}`;
};

const parseJwt = (token: string): { type: string } => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
};

export { Axios, AuthAxios, goLoginWithCurrentUrl };
