import React from "react";
import ProgressBar from "components/ProgressBar";
import { useRootState } from "globals/utils";

const ProgressBarContainer = (): JSX.Element | null => {
  const percent = useRootState((state) => state.progressbar.percent);
  const is_open = useRootState((state) => state.progressbar.is_open);
  return is_open ? <ProgressBar percent={percent} /> : null;
};

export default ProgressBarContainer;
