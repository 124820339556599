import React, { useState, useEffect } from "react";
import { PageNavigation, TabNavigation, DesktopTabNavigation } from "components/TopNavigation";
import { NavigationType, TabNavData } from "./interfaces";
import { useHistory, useLocation } from "react-router";
import { isTryingPaySelector, useRootState } from "globals/utils";
import { DesktopTabNavData } from ".";

const TopNavigation = (): JSX.Element => {
  const [is_mobile, setMobile] = useState<boolean>(true);
  useEffect(() => {
    setMobile(window.innerWidth < 1120);
    return;
  }, []);
  const current_navigation = useRootState((state) => state.navigation.current_navigation);

  switch (current_navigation) {
    case NavigationType.TAB:
      return is_mobile ? <TabNavigationContainer /> : <DesktopTabNavigationContainer />;
    case NavigationType.PAGE:
      return <PageNavigationContainer />;
    default:
      return is_mobile ? <TabNavigationContainer /> : <DesktopTabNavigationContainer />;
  }
};

const PageNavigationContainer = (): JSX.Element => {
  const history = useHistory();

  const back_path = useRootState((state) => state?.navigation?.page_navigation?.back_path);
  const close_path = useRootState((state) => state?.navigation?.page_navigation?.close_path);
  const title = useRootState((state) => state?.navigation?.page_navigation?.title);
  const is_loading = useRootState((state) => state?.navigation?.page_navigation?.is_loading);
  const is_have_divider = useRootState((state) => state?.navigation?.page_navigation?.is_have_divider);
  const is_trying_pay = useRootState(isTryingPaySelector);

  const onArrowClick = () => {
    if (!back_path) return null;
    if (typeof back_path === "function") back_path();
    else if (back_path === "back") history.goBack();
    else history.push(back_path);
  };
  const onXClick = () => {
    if (!close_path) return null;
    if (typeof close_path === "function") close_path();
    else if (close_path === "back") history.goBack();
    else history.push(close_path);
  };
  return (
    <PageNavigation
      title={title}
      onArrowClick={!!back_path && onArrowClick}
      onXClick={!!close_path && onXClick}
      is_loading={is_loading || is_trying_pay}
      is_have_divider={is_have_divider}
    />
  );
};

const TabNavigationContainer = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const is_home = location.pathname === "/";
  const is_myparty = location.pathname.includes("myparty");
  const is_guide = location.pathname.includes("guide");
  const is_matching = location.pathname.includes("matching");

  const tab_list: TabNavData[] = [
    {
      name: "홈",
      onClick: () => history.push("/"),
      is_selected: is_home,
    },
    {
      name: "파티매칭",
      onClick: () => history.push("/matching"),
      is_selected: is_matching,
    },
    {
      name: "MY파티",
      onClick: () => history.push("/myparty"),
      is_selected: is_myparty,
    },
    {
      name: "가이드",
      onClick: () => history.push("/guide"),
      is_selected: is_guide,
    },
  ];
  const goHome = () => history.push("/");

  return <TabNavigation goHome={goHome} tab_list={tab_list} />;
};

const DesktopTabNavigationContainer = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const is_home = location.pathname === "/";
  const is_myparty = location.pathname.includes("myparty");
  const is_guide = location.pathname.includes("guide");
  const is_matching = location.pathname.includes("matching");

  const tab_list: DesktopTabNavData[] = [
    {
      name: "홈",
      onClick: () => history.push("/"),
      is_selected: is_home,
      margin_right: 22,
    },
    {
      name: "파티매칭",
      onClick: () => history.push("/matching"),
      is_selected: is_matching,
      margin_right: 16,
    },
    {
      name: "MY파티",
      onClick: () => history.push("/myparty"),
      is_selected: is_myparty,
      margin_right: 17,
    },
    {
      name: "가이드",
      onClick: () => history.push("/guide"),
      is_selected: is_guide,
    },
  ];
  const goHome = () => history.push("/");

  return <DesktopTabNavigation goHome={goHome} tab_list={tab_list} is_fixed={is_home} />;
};

export default TopNavigation;
