import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { BlurLoading } from "components/Loading";
import { membershipSelector, useRootState, userSelector } from "globals/utils";
import { AUTH_LOG_OUT_SAGA } from "globals/auth";
import { MEMBERSHIP_SET_MEMBERSHIP_SAGA } from "globals/membership";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const MyPage = lazy(() => import("components/MyPage"));

const MyPageContainer = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation<{ from: string }>();
  const dispatch = useDispatch();

  const previous_route = location.state && location.state.from ? location.state.from : "/";
  const membership = useRootState(membershipSelector).membership_data;
  const user = useRootState(userSelector).user_data;
  const is_user_loading = useRootState(userSelector).is_loading;
  const is_membership_loading = useRootState(membershipSelector).is_loading;

  const setMembershipSagaDispatch = () => dispatch({ type: MEMBERSHIP_SET_MEMBERSHIP_SAGA });
  const logOutDispatch = () => dispatch({ type: AUTH_LOG_OUT_SAGA });

  const goMypage = () => history.push("/mypage");
  const goManagePay = () => history.push("/mypage/managebilling");
  const goManageReward = () => history.push("/mypage/manageaccount");
  const goQnA = () => window.open("https://www.notion.so/pickleplus/5da7343e431245af85c471de3602427f");
  const goChannelTalk = () => window.ChannelIO("show");
  const goNotice = () =>
    window.open("https://www.notion.so/pickleplus/3ef654356c1742aa84180dbb71995ec0?v=0776c08e78264696ad23524f7fd3389b");
  const goOldPickle = () => (window.location.href = "https://old.pickle.plus");

  const goSuggest = () => window.open("https://forms.gle/LyKounwmmKEeHYoTA");

  useEffect(() => {
    setMembershipSagaDispatch();
    return;
  }, []);

  const is_loading = is_membership_loading || is_user_loading;

  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation back_path={previous_route} title="마이페이지" is_loading={is_loading} />
      {!is_loading && user && (
        <MyPage
          membership={membership}
          user={user}
          logOut={logOutDispatch}
          goMyPage={goMypage}
          goManagePay={goManagePay}
          goManageReward={goManageReward}
          goQnA={goQnA}
          goChannelTalk={goChannelTalk}
          goNotice={goNotice}
          goOldPickle={goOldPickle}
          goSuggest={goSuggest}
        />
      )}
    </Suspense>
  );
};

export default MyPageContainer;
