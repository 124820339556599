import jwt from "jsonwebtoken";
import moment from "moment";
import { refreshAccessToken, goLoginWithCurrentUrl } from "apis";

export const isTokenExpRemain = (token: string): boolean => {
  const payload = jwt.decode(token, { json: true });
  if (!payload) {
    throw new Error("token is invalid");
  }
  if (Number(payload.exp) < moment().utc().add({ minute: 3 }).unix()) {
    return false;
  }
  return true;
};

export const getAccessToken = async (): Promise<string | null> => {
  const refresh_token = getRefreshToken();
  if (!refresh_token) return null;
  if (!isTokenExpRemain(refresh_token)) {
    alert("세션이 만료되었습니다. 다시 로그인해주세요.");
    removeAuthToken();
    goLoginWithCurrentUrl();
    return null;
  }
  let access_token = window.sessionStorage.getItem("access_token");
  if (!access_token || !isTokenExpRemain(access_token)) {
    access_token = await refreshAccessToken(refresh_token);
    window.sessionStorage.setItem("access_token", access_token);
  }
  return access_token;
};

export const getRefreshToken = (): string | null => {
  const session_refresh_token = window.sessionStorage.getItem("refresh_token");
  if (session_refresh_token) {
    return session_refresh_token;
  }
  const local_refresh_token = window.localStorage.getItem("refresh_token");
  if (local_refresh_token) {
    window.sessionStorage.setItem("refresh_token", local_refresh_token);
    return local_refresh_token;
  }
  removeAuthToken();
  return null;
};

export const removeAuthToken = (): void => {
  window.sessionStorage.removeItem("access_token");
  window.sessionStorage.removeItem("refresh_token");
  window.localStorage.removeItem("refresh_token");
  window.localStorage.removeItem("kakao_access_token");
  window.localStorage.removeItem("kakao_refresh_token");
  window.localStorage.removeItem("old_access_token");
};
