import { createBilling, CreateBillingReqData, registerMatching } from "apis";
import { Amplitude } from "apis/amplitude";
import { logError, removeSelectedInfo, StatusCodes } from "globals/utils";
import { call, put, takeLatest } from "redux-saga/effects";
import { setLoading } from "./slice";

function* registerMatchingAndCreateBillingSaga(action: {
  type: string;
  payload: {
    cardInfo: CreateBillingReqData;
    merchant_id: number;
    merchant_name?: string;
    goBack: () => void;
    goSuccess: () => void;
  };
}) {
  yield put(setLoading(true));
  const { cardInfo, merchant_id, merchant_name, goBack, goSuccess } = action.payload;
  try {
    yield call(createBilling, cardInfo);
    yield put({
      type: REGISTERMEMBER_REGISTER_MATCHING_SAGA,
      payload: {
        merchant_id,
        merchant_name,
        goBack,
        goSuccess,
      },
    });
  } catch (e) {
    if (e.response) {
      if (e.response.status === StatusCodes.INTERNAL_SERVER_ERROR_500) {
        alert(e.response.data?.msg);
      } else {
        alert(`카드 등록 과정에서 문제가 발생했습니다.\n${e.response.data?.msg}`);
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
    goBack();
  } finally {
    yield put(setLoading(false));
  }
}
export const REGISTERMEMBER_REGISTER_MATCHING_AND_CREATE_BILLING_SAGA =
  "registerMember/registerMatchingAndCreateBillingSaga";

function* registerMatchingSaga(action: {
  type: string;
  payload: { merchant_id: number; merchant_name?: string; goBack: () => null; goSuccess: () => null };
}) {
  yield put(setLoading(true));
  const { merchant_id, merchant_name, goBack, goSuccess } = action.payload;
  try {
    const response = yield call(registerMatching, merchant_id);
    if (response.status === StatusCodes.OK_200) {
      Amplitude.registerMemberMatching({ merchant_name });
      removeSelectedInfo();
    }
  } catch (e) {
    logError(e);
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.NOT_FOUND_404:
          alert(e.response.data?.msg);
          break;
        case StatusCodes.CONFLICT_409:
          alert("사용 가능한 OTT 개수를 초과했습니다.");
          break;
        case StatusCodes.NOT_ACCEPTABLE_406:
          alert("미성년자는 피클플러스 멤버십을 이용하실 수 없습니다! 양해 부탁드립니다.");
          break;
        default:
          alert(
            "에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)",
          );
          break;
      }
    }
    goBack();
    return;
  } finally {
    yield put(setLoading(false));
  }
  alert("파티매칭이 시작되었습니다.");
  goSuccess();
}
export const REGISTERMEMBER_REGISTER_MATCHING_SAGA = "registerMember/registerMatchingSaga";

function* registerMemberSaga(): Generator {
  yield takeLatest(REGISTERMEMBER_REGISTER_MATCHING_AND_CREATE_BILLING_SAGA, registerMatchingAndCreateBillingSaga);
  yield takeLatest(REGISTERMEMBER_REGISTER_MATCHING_SAGA, registerMatchingSaga);
}

export default registerMemberSaga;
