import React from "react";
import styled, { css } from "styled-components";
import Space from "components/Space";
import { OldH4, OldSub } from "components/Text";

interface PairProps {
  title: string;
  content: string;
  is_last?: boolean;
}

const Pair = ({ title, content, is_last }: PairProps): JSX.Element => (
  <Row is_last={is_last}>
    <div>
      <OldSub color={is_last ? "var(--gray-700)" : "var(--gray-500)"}>{title}</OldSub>
      {is_last && <Space height={2} />}
    </div>
    {is_last ? <OldH4>{content}</OldH4> : <OldSub color="var(--gray-700)">{content}</OldSub>}
  </Row>
);

const Row = styled.div<{ is_last?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.is_last &&
    css`
      align-items: flex-end;
    `}
`;

export default Pair;
