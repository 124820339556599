export type ButtonTheme =
  | "primary-fill"
  | "primary-weak"
  | "primary-text"
  | "gray-fill"
  | "gray-text"
  | "blue-fill"
  | "blue-text";

export interface ThemeProps {
  background_color: string;
  color: string;
  disabled_background_color: string;
  disabled_color: string;
  hovered_background_color: string;
  hovered_color: string;
}

export const button_theme: { [key in ButtonTheme]: ThemeProps } = {
  "primary-fill": {
    background_color: "var(--primary-500)",
    color: "var(--white)",
    disabled_background_color: "var(--gray-100)",
    disabled_color: "var(--gray-400)",
    hovered_background_color: "var(--primary-600)",
    hovered_color: "var(--white)",
  },
  "primary-weak": {
    background_color: "var(--primary-100)",
    color: "var(--primary-600)",
    disabled_background_color: "var(--gray-100)",
    disabled_color: "var(--gray-400)",
    hovered_background_color: "var(--primary-200)",
    hovered_color: "var(--primary-600)",
  },
  "primary-text": {
    background_color: "transparent",
    color: "var(--primary-500)",
    disabled_background_color: "transparent",
    disabled_color: "var(--gray-400)",
    hovered_background_color: "var(--gray-050)",
    hovered_color: "var(--primary-500)",
  },
  "gray-fill": {
    background_color: "var(--gray-100)",
    color: "var(--gray-600)",
    disabled_background_color: "var(--gray-050)",
    disabled_color: "var(--gray-400)",
    hovered_background_color: "var(--gray-200)",
    hovered_color: "var(--gray-600)",
  },
  "gray-text": {
    background_color: "transparent",
    color: "var(--gray-600)",
    disabled_background_color: "transparent",
    disabled_color: "var(--gray-400)",
    hovered_background_color: "var(--gray-050)",
    hovered_color: "var(--gray-600)",
  },
  "blue-fill": {
    background_color: "var(--blue-500)",
    color: "var(--white)",
    disabled_background_color: "var(--gray-100)",
    disabled_color: "var(--gray-400)",
    hovered_background_color: "var(--blue-600)",
    hovered_color: "var(--white)",
  },
  "blue-text": {
    background_color: "transparent",
    color: "var(--blue-500)",
    disabled_background_color: "transparent",
    disabled_color: "var(--gray-400)",
    hovered_background_color: "var(--gray-050)",
    hovered_color: "var(--blue-500)",
  },
};
