const purchaseMembership = ({
  merchandise_id,
  merchandise_name,
  value,
}: {
  merchandise_id: number;
  merchandise_name: string;
  value: number;
}): void => {
  window.fbq("track", "Purchase", {
    content_ids: [`${merchandise_id}`],
    content_name: merchandise_name,
    content_type: "product",
    currency: "KRW",
    value,
    contents: [
      {
        id: `${merchandise_id}`,
        quantity: 1,
      },
    ],
  });
};

const createParty = ({
  merchandise_id,
  merchandise_name,
  merchant_name,
  value,
}: {
  merchandise_id: number;
  merchandise_name: string;
  merchant_name: string;
  value: number;
}): void => {
  window.fbq("track", "Purchase", {
    content_ids: [`${merchandise_id}`],
    content_name: `${merchandise_name} ${merchant_name} 파티장`,
    content_type: "product",
    currency: "KRW",
    value,
    contents: [
      {
        id: `${merchandise_id}`,
        quantity: 1,
      },
    ],
  });
};

const signUp = (): void => {
  window.fbq("track", "CompleteRegistration", {
    content_name: "회원가입",
  });
};

const enterMatching = (): void => {
  window.fbq("track", "ViewContent", {
    content_name: "파티매칭 페이지 진입",
  });
};

export const PixelEvent = {
  purchaseMembership,
  createParty,
  signUp,
  enterMatching,
};
