import React from "react";
import styled from "styled-components";
import { Caption1, Subtitle2 } from "components/Text";
import {
  IconAccountInfoVertical,
  IconAddCard,
  IconCreateAccount,
  IconNotifyTalk,
  IconOtt,
  IconPremium,
  IconReceive,
  IconWithdraw,
} from "assets/icons";

interface CardProps {
  order: JSX.Element;
  main_text: JSX.Element;
  sub_text: JSX.Element;
  icon: JSX.Element;
  line_length: number;
  width: number;
}

const Order = styled(Caption1)`
  color: var(--gray-700);
  font-weight: bold;
`;

const SubText = styled(Caption1)`
  color: var(--gray-700);
`;

export const host_cards: CardProps[] = [
  {
    order: <Order>01</Order>,
    main_text: (
      <Subtitle2>
        보고싶은 OTT
        <br />
        서비스 계정 생성
      </Subtitle2>
    ),
    sub_text: (
      <SubText>
        OTT 서비스에서 직접
        <br />
        공유할 계정을 만들어요.
      </SubText>
    ),
    icon: <IconCreateAccount />,
    line_length: 18,
    width: 172,
  },
  {
    order: <Order>02</Order>,
    main_text: (
      <Subtitle2>
        프리미엄
        <br />
        요금제 구독
      </Subtitle2>
    ),
    sub_text: (
      <SubText>
        OTT 서비스의 프리미엄
        <br />
        요금제를 구독해요.
      </SubText>
    ),
    icon: <IconPremium />,
    line_length: 24,
    width: 172,
  },
  {
    order: <Order>03</Order>,
    main_text: (
      <Subtitle2>
        구독한 서비스의
        <br />
        계정 정보 등록
      </Subtitle2>
    ),
    sub_text: (
      <SubText>
        해당 계정의 아이디와
        <br />
        패스워드를 입력해요.
      </SubText>
    ),
    icon: <IconAccountInfoVertical />,
    line_length: 24,
    width: 172,
  },
  {
    order: <Order>04</Order>,
    main_text: (
      <Subtitle2>
        정산 받을 계좌
        <br />
        정보 등록
      </Subtitle2>
    ),
    sub_text: (
      <SubText>
        OTT 구독료에 대해 정산
        <br />
        받을 계좌를 등록해요.
      </SubText>
    ),
    icon: <IconReceive />,
    line_length: 24,
    width: 172,
  },
  {
    order: <Order>05</Order>,
    main_text: (
      <Subtitle2>
        이용료 결제할
        <br />
        카드 등록
      </Subtitle2>
    ),
    sub_text: (
      <SubText>
        피클플러스 이용료를
        <br />
        결제할 카드를 등록해요.
      </SubText>
    ),
    icon: <IconAddCard />,
    line_length: 24,
    width: 172,
  },
  {
    order: <Order>06</Order>,
    main_text: (
      <Subtitle2>
        파티 생성
        <br />
        완료!
      </Subtitle2>
    ),
    sub_text: (
      <SubText>
        OTT 구독료 전액을 매달
        <br />
        정산 받을 수 있어요.
      </SubText>
    ),
    icon: <IconOtt />,
    line_length: 24,
    width: 172,
  },
];

export const member_cards: CardProps[] = [
  {
    order: <Order>01</Order>,
    main_text: (
      <Subtitle2>
        보고싶은 OTT
        <br />
        서비스 선택
      </Subtitle2>
    ),
    sub_text: (
      <SubText>
        넷플릭스, 왓챠, 웨이브,
        <br />
        라프텔 중에서 선택해요.
      </SubText>
    ),
    icon: <IconOtt />,
    line_length: 18,
    width: 172,
  },
  {
    order: <Order>02</Order>,
    main_text: (
      <Subtitle2>
        이용료 결제할
        <br />
        카드 등록
      </Subtitle2>
    ),
    sub_text: (
      <SubText>
        OTT 서비스 이용료를
        <br />
        결제할 카드를 등록해요.
      </SubText>
    ),
    icon: <IconAddCard />,
    line_length: 24,
    width: 172,
  },
  {
    order: <Order>03</Order>,
    main_text: (
      <Subtitle2>
        파티 매칭
        <br />
        완료!
      </Subtitle2>
    ),
    sub_text: (
      <SubText>
        파티 매칭이 완료되면
        <br />
        알림으로 알려드려요.
      </SubText>
    ),
    icon: <IconNotifyTalk />,
    line_length: 24,
    width: 172,
  },
  {
    order: <Order>04</Order>,
    main_text: (
      <Subtitle2>
        공유 받은 계정에
        <br />내 프로필 추가
      </Subtitle2>
    ),
    sub_text: (
      <SubText>
        공유 받은 계정애서 내<br />
        프로필을 추가해요.
      </SubText>
    ),
    icon: <IconCreateAccount />,
    line_length: 24,
    width: 172,
  },
  {
    order: <Order>05</Order>,
    main_text: (
      <Subtitle2>
        매 달<br />
        자동 정산
      </Subtitle2>
    ),
    sub_text: (
      <SubText>
        첫 이용료 결제일에 맞춰
        <br />매 달 자동 결제돼요.
      </SubText>
    ),
    icon: <IconWithdraw />,
    line_length: 24,
    width: 172,
  },
];
