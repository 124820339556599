import React, { useEffect } from "react";
import { Redirect, Switch, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import qs from "qs";
import MyParty from "./MyParty";
import PartyDelete from "../PartyDelete";
import MyPartyInfo from "./MyPartyInfo";
import { membershipSelector, RestrictRoute, slotsSelector, useRootState, userSelector } from "globals/utils";
import { BlurLoading } from "components/Loading";
import MyPartyEdit from "./MyPartyEdit";
import Waiting from "./Waiting";
import WaitingCancel from "./WaitingCancel";
import PartyLeave from "../PartyLeave";
import SetProfile from "./SetProfile";
import OldHostParty from "containers/OldHostParty";
import { MYPARTY_SET_SLOTS_SAGA } from "./saga";
import { MEMBERSHIP_SET_MEMBERSHIP_SAGA } from "globals/membership";
import { Route } from "globals/utils";
import SendMessage from "containers/SendMessage";

const MyPartyRoute = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const merchant_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_id,
  );
  const party_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).party_id,
  );

  const user = useRootState(userSelector).user_data;

  const is_slots_loading = useRootState(slotsSelector).is_loading;
  const is_membership_loading = useRootState(membershipSelector).is_loading;
  const setMembershipSagaDispatch = () => dispatch({ type: MEMBERSHIP_SET_MEMBERSHIP_SAGA });
  const setSlotsSagaDispatch = () => dispatch({ type: MYPARTY_SET_SLOTS_SAGA });
  const is_slot_loading = useRootState((state) => state.slot.is_loading);
  const slot = useRootState((state) => state.slot.slot);

  useEffect(() => {
    setMembershipSagaDispatch();
    setSlotsSagaDispatch();
    return;
  }, []);

  useEffect(() => {
    if (!is_slot_loading && location.pathname === "/myparty/info" && !slot?.my_profile_name) {
      history.replace(`/myparty/profile${location.search}`);
    }
    return;
  }, [is_slot_loading]);

  const is_loading = is_membership_loading || is_slots_loading;

  return is_loading ? (
    <BlurLoading />
  ) : (
    <Switch>
      <Route exact path="/myparty" component={MyParty} />
      <RestrictRoute is_pass={!!merchant_id} redirect_url="/myparty" path="/myparty/profile" component={SetProfile} />
      <RestrictRoute is_pass={!!merchant_id} redirect_url="/myparty" path="/myparty/info" component={MyPartyInfo} />
      <RestrictRoute is_pass={!!merchant_id} redirect_url="/myparty" path="/myparty/message" component={SendMessage} />
      <RestrictRoute
        is_pass={!!merchant_id || !!party_id}
        redirect_url="/myparty"
        path="/myparty/delete"
        component={PartyDelete}
      />
      <RestrictRoute is_pass={!!merchant_id} redirect_url="/myparty" path="/myparty/change" component={MyPartyEdit} />
      <RestrictRoute is_pass={!!merchant_id} redirect_url="/myparty" path="/myparty/waiting" component={Waiting} />
      <RestrictRoute is_pass={!!merchant_id} redirect_url="/myparty" path="/myparty/cancel" component={WaitingCancel} />
      <RestrictRoute
        is_pass={!!merchant_id && !!party_id}
        redirect_url="/myparty"
        path="/myparty/leave"
        component={PartyLeave}
      />
      <RestrictRoute is_pass={!!user?.is_special} redirect_url="/" path="/myparty/old" component={OldHostParty} />
      <Redirect to="/myparty" />
    </Switch>
  );
};

export default MyPartyRoute;
