import { OldH6, OldSmall2, OldSub } from "components/Text";
import styled from "styled-components";

export const Title = styled(OldH6)`
  font-weight: normal;
`;

export const SubTitle = styled(OldSub)`
  color: var(--gray-700);
`;

export const Primary = styled.span`
  color: var(--primary-700);
`;

export const Container = styled.div`
  padding: 0px 24px 20px 24px;
`;

export const PromotionSmall = styled(OldSmall2)`
  font-weight: normal;
  color: var(--gray-500);
`;
