import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PartyDeleteState, HostSlotData, RefundData } from "./interface";

const initialState: PartyDeleteState = {
  is_refund_loading: true,
  is_host_loading: true,
  is_full_loading: false,
};

const partydeleteSlice = createSlice({
  name: "partydelete",
  initialState,
  reducers: {
    setRefundLoading(state, action: PayloadAction<boolean>) {
      state.is_refund_loading = action.payload;
    },
    setHostLoading(state, action: PayloadAction<boolean>) {
      state.is_host_loading = action.payload;
    },
    setHostSlot(state, action: PayloadAction<HostSlotData>) {
      state.host_slot = action.payload;
    },
    setRefundInfo(state, action: PayloadAction<RefundData>) {
      state.refund_info = action.payload;
    },
    cleanupPartyDelete(state) {
      state.is_refund_loading = true;
      state.is_host_loading = true;
      state.host_slot = undefined;
      state.refund_info = undefined;
      state.is_full_loading = false;
    },
    setFullLoading(state, action: PayloadAction<boolean>) {
      state.is_full_loading = action.payload;
    },
  },
});

export const {
  setRefundLoading,
  setHostLoading,
  setHostSlot,
  setRefundInfo,
  cleanupPartyDelete,
  setFullLoading,
} = partydeleteSlice.actions;
export default partydeleteSlice.reducer;
