import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProgressBarState {
  is_open: boolean;
  percent: number;
}

const initialState: ProgressBarState = {
  is_open: false,
  percent: 0,
};

const progressbarSlice = createSlice({
  name: "progressbar",
  initialState,
  reducers: {
    setProgressBar(state, action: PayloadAction<number>) {
      state.is_open = true;
      state.percent = action.payload;
    },
    cleanupProgressBar(state) {
      state.is_open = false;
      state.percent = 0;
    },
  },
});

export const { setProgressBar, cleanupProgressBar } = progressbarSlice.actions;
export default progressbarSlice.reducer;
