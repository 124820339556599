import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OldHostPartyState, PartyData } from "./interface";

const initialState: OldHostPartyState = {
  is_loading: true,
  selected_party: {
    is_matching_loading: true,
    is_matching: false,
    is_loading: true,
  },
};

const oldhostpartySlice = createSlice({
  name: "oldhostparty",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.is_loading = action.payload;
    },
    setParties(state, action: PayloadAction<PartyData[]>) {
      state.parties = action.payload;
    },
    selectParty(state, action: PayloadAction<PartyData>) {
      state.selected_party.party = action.payload;
    },
    cleanupOldHostParty(state) {
      state.is_loading = true;
      state.parties = undefined;
      state.selected_party = { is_matching_loading: true, is_matching: false, party: undefined, is_loading: true };
    },
    cleanupSelectedParty(state) {
      state.selected_party = { is_matching_loading: true, is_matching: false, party: undefined, is_loading: true };
    },
    setPartyMatching(state, action: PayloadAction<boolean>) {
      state.selected_party.is_matching = action.payload;
    },
    setMatchingLoading(state, action: PayloadAction<boolean>) {
      state.selected_party.is_matching_loading = action.payload;
    },
    setSelectedPartyLoading(state, action: PayloadAction<boolean>) {
      state.selected_party.is_loading = action.payload;
    },
  },
});

export const {
  setLoading,
  setParties,
  selectParty,
  cleanupOldHostParty,
  cleanupSelectedParty,
  setPartyMatching,
  setMatchingLoading,
  setSelectedPartyLoading,
} = oldhostpartySlice.actions;
export default oldhostpartySlice.reducer;
