import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Redirect } from "react-router";
import qs from "qs";
import { cleanupOldHostParty } from "./slice";
import Parties from "./Parties";
import CheckParty from "./CheckParty";
import { BlurLoading } from "components/Loading";
import ChangeSps from "./ChangeSps";
import { RestrictRoute, useRootState, Route } from "globals/utils";
import { OLDHOSTPARTY_SET_PARTIES_SAGA } from "./saga";

const OldHostPartyRoute = (): JSX.Element => {
  const dispatch = useDispatch();
  const is_loading = useRootState((state) => state.oldhostparty.is_loading);

  const party_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).party_id,
  );

  const setPartiesSagaDispatch = () => dispatch({ type: OLDHOSTPARTY_SET_PARTIES_SAGA });
  const cleanupOldHostPartyDispatch = () => dispatch(cleanupOldHostParty());

  useEffect(() => {
    setPartiesSagaDispatch();
    return () => {
      cleanupOldHostPartyDispatch();
    };
  }, []);

  return is_loading ? (
    <BlurLoading />
  ) : (
    <Switch>
      <Route exact path="/myparty/old" component={Parties} />
      <RestrictRoute
        is_pass={!!party_id}
        redirect_url="/myparty/old"
        path="/myparty/old/party"
        component={CheckParty}
      />
      <RestrictRoute
        is_pass={!!party_id}
        redirect_url="/myparty/old"
        path="/myparty/old/change"
        component={ChangeSps}
      />
      <Redirect to="/myparty/old" />
    </Switch>
  );
};

export default OldHostPartyRoute;
