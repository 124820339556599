import styled from "styled-components";
import icon_blue_arrow from "./assets/icon_diagonal_arrow_blue.png";
import { OldH5 } from "components/Text";

export const Container = styled.div`
  padding: 64px 24px 0 24px;
`;

export const Title = styled(OldH5)`
  color: var(--gray-900);
`;
export const LinkRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;
export const LinkText = styled.div`
  margin-top: 4px;
  color: var(--blue-500);
`;
export const LinkArrow = styled.img.attrs({
  src: icon_blue_arrow,
  alt: "icon_link_arrow",
})`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`;

export const Margin = styled.div`
  height: 32px;
`;

export const TermRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 63px;
  padding-top: 21px;
  padding-bottom: 17px;
  margin-top: 64px;
  margin-bottom: 25px;
  cursor: pointer;
`;

export const TermCheck = styled.input.attrs({
  type: "image",
  alt: "icon_check",
})`
  width: 24px;
  height: 24px;
`;

export const TermText = styled.div`
  color: var(--gray-900);
  margin-left: 12px;
  padding-top: 1px;
`;
