export type ButtonSize = "xLarge" | "large" | "medium" | "small";

export interface SizeProps {
  padding: string;
  font_size: number;
  line_height: number;
}

export const button_size: { [key in ButtonSize]: SizeProps } = {
  xLarge: {
    padding: "21.5px 16px 20.5px",
    font_size: 16,
    line_height: 22,
  },
  large: {
    padding: "15.5px 16px 14.5px",
    font_size: 16,
    line_height: 22,
  },
  medium: {
    padding: "12px 16px 11px",
    font_size: 16,
    line_height: 22,
  },
  small: {
    padding: "6.5px 8px 5.5px",
    font_size: 14,
    line_height: 20,
  },
};
