import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModalType, openCommonModal, slotSelector, useRootState } from "globals/utils";
import qs from "qs";
import { BlurLoading } from "components/Loading";
import { Waiting } from "components/MyParty";
import { PageNavigation } from "containers/TopNavigation";
import { SELECTED_MERCHANT, SELECTED_USER_TYPE } from "globals/variables";
import { Subtitle1, Body3 } from "components/Text";
import { MYPARTY_CANCEL_MATCHING_SAGA } from "./saga";
import { PARTYDELETE_SET_REFUND_INFO_SAGA } from "containers/PartyDelete";
import { SLOT_SET_SLOT_WAITING_MERCHANT_SAGA } from "containers/Slot";

const WaitingContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const merchant_name = useRootState(slotSelector)?.merchant_name;
  const is_slot_loading = useRootState((state) => state.slot.is_loading);
  const is_refund_loading = useRootState((state) => state.partydelete.is_refund_loading);
  const is_refund = useRootState((state) => state.partydelete.refund_info?.is_refund);
  const merchant_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_id,
  );

  const goMyParty = () => history.push("/myparty");
  const setSlotWaitingMerchantSagaDispatch = () =>
    dispatch({ type: SLOT_SET_SLOT_WAITING_MERCHANT_SAGA, payload: { merchant_id, goMyParty } });
  const cancelMatchingSagaDispatch = () => {
    openCommonModal({
      title: (
        <Subtitle1>
          곧 OTT를 저렴하게 보실 수 있어요!
          <br />
          그래도 파티 매칭을 취소하시겠어요?
        </Subtitle1>
      ),
      content: (
        <Body3 color="var(--gray-600)">
          파티 매칭을 취소하고 다시 신청하실 경우,
          <br />
          대기 우선 순위가 뒤로 밀리니 신중히 생각해주세요
        </Body3>
      ),
      is_dimmer: true,
      is_dimmer_close: true,
      type: ModalType.ENLARGED,
      buttons: [
        { text: "아니요", is_close_button: true, theme: "gray-weak" },
        {
          text: "취소할래요",
          is_close_button: true,
          onClick: () => {
            if (is_refund) history.push(`/myparty/cancel?merchant_id=${merchant_id}`);
            else
              dispatch({
                type: MYPARTY_CANCEL_MATCHING_SAGA,
                payload: {
                  goMyParty,
                  merchant_id,
                },
              });
          },
        },
      ],
    });
  };
  const setRefundInfoSagaDispatch = () =>
    dispatch({ type: PARTYDELETE_SET_REFUND_INFO_SAGA, payload: { goBack: goMyParty, merchant_id, goMyParty } });
  const goPartyCreate = () => {
    sessionStorage.setItem(SELECTED_USER_TYPE, "host");
    sessionStorage.setItem(SELECTED_MERCHANT, merchant_id.toString());
    history.push("/register/host/init");
  };

  useEffect(() => {
    if (!merchant_id) {
      history.push("/myparty");
    }
    setRefundInfoSagaDispatch();
    setSlotWaitingMerchantSagaDispatch();
    return;
  }, []);

  const is_loading = is_slot_loading || is_refund_loading;
  return (
    <>
      <PageNavigation
        back_path="/myparty"
        title={is_loading ? "빠른매칭" : `${merchant_name} 파티 빠른매칭`}
        is_loading={is_loading}
      />
      {!is_loading && merchant_name ? (
        <Waiting
          merchant_name={merchant_name}
          cancelSlot={cancelMatchingSagaDispatch}
          goCreateParty={goPartyCreate}
          is_refund={is_refund}
        />
      ) : (
        <BlurLoading />
      )}
    </>
  );
};

export default WaitingContainer;
