import React, { useState } from "react";
import InputWithLabel from "components/Input/InputWithLabel";
import { OldDivider } from "components/Divider";
import { MyPartyInfoProps } from "./interface";
import {
  Container,
  MerchantColumn,
  InputContainer,
  ServiceTab,
  DangerTab,
  ProfileBox,
  MerchantBox,
  MerchantIconImg,
  ProfileList,
  ProfileSetContainer,
  ProfileImgFrame,
  IconHostBadgeFrame,
  ProfileImg,
  ProfileName,
  GrayLabel,
  BlueLabel,
  ProfileDifferentInfo,
  ProfileImgLicense,
  ChangeMyProfile,
} from "./MyPartyInfoStyle";
import { ReactComponent as IconBlueCheck } from "./assets/icon_blue_check.svg";
import { ReactComponent as IconHostBadge } from "./assets/icon_host_badge.svg";
import { ReactComponent as IconProfileChangeArrow } from "./assets/icon_profile_change_arrow.svg";
import { ReactComponent as IconBlueArrow } from "./assets/icon_blue_arrow.svg";
import icon_person from "./assets/icon_person.png";
import img_empty_pickle from "./assets/img_empty_pickle.png";
import { ButtonPropsLegacy } from "components/Button";
import Space from "components/Space";
import BlueBox from "components/BlueBox";
import { WATCHA_MERCHANT_ID_HARD, LAFTEL_MERCHANT_ID_HARD } from "globals/variables";
import ProfileUsingInfoBox from "./ProfileUsingInfoBox";
import GuideToggle from "components/GuideToggle";
import { OldSub } from "components/Text";

const MyPartyInfo = ({
  slot,
  openToast,
  closeToast,
  goMerchantService,
  goChannelTalk,
  goEdit,
  goSendMessage,
  changeProfileName,
  profiles,
  goDelete,
  goLeave,
}: MyPartyInfoProps): JSX.Element => {
  const copyText = (copytext: string) => {
    const tmp = document.createElement("textarea");
    tmp.value = copytext;
    document.body.appendChild(tmp);
    tmp.select();
    document.execCommand("copy");
    document.body.removeChild(tmp);
    openToast("클립보드에 복사 되었습니다");
    setTimeout(() => {
      closeToast();
    }, 1000);
  };

  const id_button_props: ButtonPropsLegacy = {
    theme: "primary-weak",
    width: 100,
    height: 40,
    children: "복사",
    onClick: () => copyText(slot.sps_key || ""),
  };
  const pw_button_props: ButtonPropsLegacy = {
    theme: "primary-weak",
    width: 100,
    height: 40,
    children: "복사",
    onClick: () => copyText(slot.sps_value || ""),
  };
  const pin_button_props: ButtonPropsLegacy = {
    theme: "primary-weak",
    width: 100,
    height: 40,
    children: "복사",
    onClick: () => copyText(slot.sps_pin || ""),
  };

  const [is_guide_open, setIsGuideOpen] = useState<boolean>(false);

  return slot ? (
    <Container>
      <Space height={40} />
      <MerchantColumn>
        <ProfileBox>
          <MerchantBox>
            <MerchantIconImg src={slot.img_url} alt="OTT 아이콘" />
            <OldSub>{slot.merchant_name} 프리미엄 파티</OldSub>
          </MerchantBox>
          <Space height={20} />
          <ProfileList>
            {profiles.map((profile, index) => (
              <ProfileSet
                src={slot.merchant_profile_image_url || ""}
                label={profile.label}
                profile_name={profile.profile_name || ""}
                is_badge={index === 0}
                key={`profile_${index}`}
              />
            ))}
          </ProfileList>
          <Space height={12} />
          <ProfileDifferentInfo>*프로필 이미지와 순서는 실제 화면과 다를 수 있습니다</ProfileDifferentInfo>
          <Space height={11} />
          <ProfileImgLicense>{slot.merchant_profile_image_license}</ProfileImgLicense>
          <Space height={4} />
          <OldDivider background_color="var(--gray-200)" height="1px" margin_bottom="0" margin_top="0" />
          <ChangeMyProfile onClick={changeProfileName}>
            <OldSub>내 프로필 닉네임 변경</OldSub>
            <IconProfileChangeArrow />
          </ChangeMyProfile>
        </ProfileBox>
        <Space height={16} />
        {slot.host_yn ? (
          <BlueBox icon="price">
            매월 {slot.pay_day}일에 {slot.merchant_name}에서 결제하고 온 금액
            <br />
            {(slot.reward || -1).toLocaleString()}원을 등록한 계좌로 이체해드려요
          </BlueBox>
        ) : (
          <BlueBox icon="info">
            &apos;{slot.my_profile_name}&apos; 닉네임을 사용하지 않으면 타인이
            <br />
            혼동해 내 프로필을 이용·삭제할 수도 있어요
          </BlueBox>
        )}
        {!slot.host_yn && (
          <>
            <Space height={16} />
            <GuideToggle
              title="어떤 프로필을 사용해야하나요?"
              is_open={is_guide_open}
              height={
                slot.merchant_id === WATCHA_MERCHANT_ID_HARD || slot.merchant_id === LAFTEL_MERCHANT_ID_HARD ? 199 : 223
              }
              desktop_height={
                slot.merchant_id === WATCHA_MERCHANT_ID_HARD || slot.merchant_id === LAFTEL_MERCHANT_ID_HARD ? 127 : 151
              }
              icon_src={icon_person}
              onClick={() => setIsGuideOpen(!is_guide_open)}
            >
              <ProfileUsingInfoBox
                merchant_id={slot.merchant_id || 0}
                merchant_name={slot.merchant_name || ""}
                profile_name={slot.my_profile_name || ""}
              />
            </GuideToggle>
            <Space height={12} />
          </>
        )}
      </MerchantColumn>
      <Space height={16} />
      <InputContainer>
        <InputWithLabel
          value={slot.sps_key}
          is_complete={true}
          label="아이디"
          is_have_button={true}
          button_props={id_button_props}
          readonly
        />
        <Space height={20} />
        <InputWithLabel
          value={slot.sps_value}
          is_complete
          label="비밀번호"
          is_have_button
          button_props={pw_button_props}
          readonly
        />
        {slot.sps_pin && (
          <>
            <Space height={32} />
            <InputWithLabel
              value={slot.sps_pin}
              is_complete
              label="성인 비밀번호(4자리)"
              is_have_button
              button_props={pin_button_props}
              readonly
            />
          </>
        )}
      </InputContainer>
      <Space height={16} />
      <ServiceTab is_blue onClick={() => goMerchantService()} margin_top={slot.host_yn ? 0 : 12}>
        {slot.merchant_name}로 바로가기
        <IconBlueArrow width="24px" height="26px" />
      </ServiceTab>
      <OldDivider />
      {slot.host_yn && <ServiceTab onClick={goEdit}>{slot.merchant_name} ID/PW를 바꿨어요</ServiceTab>}
      <ServiceTab onClick={goSendMessage}>
        {slot.host_yn ? "파티원들에게 연락하고 싶어요" : "파티장에게 연락하고 싶어요"}
      </ServiceTab>
      <ServiceTab onClick={goChannelTalk}>이용에 문제가 있으신가요?</ServiceTab>
      <DangerTab onClick={slot.host_yn ? goDelete : goLeave}>
        {slot.host_yn ? "파티 해체하기" : "파티 탈퇴하기"}
      </DangerTab>
    </Container>
  ) : (
    <div />
  );
};

export default MyPartyInfo;

const ProfileSet = ({
  src,
  profile_name,
  label,
  is_badge,
}: {
  src: string;
  profile_name: string;
  label?: "파티장" | "파티원" | "납부완료" | "내 프로필";
  is_badge?: boolean;
}) => (
  <ProfileSetContainer>
    <ProfileImgFrame>
      {is_badge && (
        <IconHostBadgeFrame>
          <IconHostBadge />
        </IconHostBadgeFrame>
      )}
      {label ? <ProfileImg src={src} /> : <ProfileImg src={img_empty_pickle} />}
    </ProfileImgFrame>
    <Space height={4} />
    <ProfileName>{profile_name}</ProfileName>
    <Space height={4} />
    {label === "파티장" && <GrayLabel>{label}</GrayLabel>}
    {label === "파티원" && <GrayLabel>{label}</GrayLabel>}
    {label === "납부완료" && (
      <BlueLabel is_icon_included>
        <IconBlueCheck width={12} height={12} />
        <div>{label}</div>
      </BlueLabel>
    )}
    {label === "내 프로필" && <BlueLabel>{label}</BlueLabel>}
  </ProfileSetContainer>
);
