import React, { useState, useCallback, useEffect } from "react";
import {
  Container,
  Title,
  LinkArrow,
  LinkRow,
  LinkText,
  TermCheck,
  Margin,
  TermRow,
  TermText,
} from "./MyPartyEditStyle";
import { WAVVE_MERCHANT_ID_HARD } from "globals/variables";
import icon_checked from "./assets/icon_checked.png";
import icon_unchecked from "./assets/icon_unchecked.png";
import InputWithLabel from "../Input/InputWithLabel";
import ButtonLegacy from "../Button";
import { Banner } from "components/Contents";
import icon_info from "./assets/icon_info.png";
import Space from "components/Space";

interface MyPartyEditProps {
  merchant_id?: number;
  merchant_name?: string;
  goService: () => void;
  changeSps: (sps: { key?: string; value?: string; pin?: string }) => void;
}

const MyPartyEdit = ({ merchant_id, merchant_name, goService, changeSps }: MyPartyEditProps): JSX.Element => {
  const [is_enable, setEnable] = useState<boolean>(false);
  const [sps_key, setSpsKey] = useState<{ is_complete: boolean; value: string }>({
    is_complete: false,
    value: "",
  });
  const [sps_value, setSpsValue] = useState<{ is_complete: boolean; value: string }>({
    is_complete: false,
    value: "",
  });
  const [sps_pin, setSpsPin] = useState<{ is_complete: boolean; value: string }>({
    is_complete: false,
    value: "",
  });
  const [is_agree, setAgree] = useState<boolean>(false);

  const onChangeKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    const new_key = { ...sps_key };
    new_key.value = e.currentTarget.value;
    if (new_key.value.length > 0) new_key.is_complete = true;
    else new_key.is_complete = false;
    setSpsKey({ ...new_key });
  };
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const new_value = { ...sps_value };
    new_value.value = e.currentTarget.value;
    if (new_value.value.length > 0) new_value.is_complete = true;
    else new_value.is_complete = false;
    setSpsValue({ ...new_value });
  };

  const onChangePin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const new_pin = { ...sps_pin };
    new_pin.value = e.currentTarget.value;
    if (new_pin.value.length === 4) new_pin.is_complete = true;
    else new_pin.is_complete = false;
    setSpsPin({ ...new_pin });
  };

  const checkButtonEnable = useCallback(
    (key, value, is_check) => {
      if (!key.is_complete || !value.is_complete || !is_check) {
        setEnable(false);
        return false;
      }
      setEnable(true);
    },
    [sps_key, sps_value, is_agree],
  );
  const checkButtonEnableHavePin = useCallback(
    (key, value, pin, is_check) => {
      if (!key.is_complete || !value.is_complete || !pin.is_complete || !is_check) {
        setEnable(false);
        return false;
      }
      setEnable(true);
    },
    [sps_key, sps_value, sps_pin, is_agree],
  );

  useEffect(() => {
    {
      merchant_id === WAVVE_MERCHANT_ID_HARD
        ? checkButtonEnableHavePin(sps_key, sps_value, sps_pin, is_agree)
        : checkButtonEnable(sps_key, sps_value, is_agree);
    }
    return;
  }, [sps_key, sps_value, sps_pin, is_agree]);

  return (
    <Container>
      <Title>
        {merchant_name} 프리미엄 파티 <br />
        ID/PW 변경
      </Title>
      <LinkRow onClick={goService}>
        <LinkText>{merchant_name} 갔다오기</LinkText>
        <LinkArrow />
      </LinkRow>
      <Space height={20} />
      <Banner img_url={icon_info}>
        보안상 SNS 등 외부 계정은
        <br />
        사용하지 말아주세요
      </Banner>
      <Space height={44} />
      <InputWithLabel
        label="아이디"
        placeholder="아이디를 입력해주세요"
        is_complete={sps_key.is_complete}
        value={sps_key.value}
        onChange={onChangeKey}
      />
      <Margin />
      <InputWithLabel
        label="비밀번호"
        placeholder="비밀번호를 입력해주세요"
        type="password"
        is_complete={sps_value.is_complete}
        value={sps_value.value}
        onChange={onChangeValue}
      />
      {merchant_id === WAVVE_MERCHANT_ID_HARD && (
        <>
          <Margin />
          <InputWithLabel
            label="성인 비밀번호 (4자리)"
            placeholder="성인 비밀번호를 입력해주세요"
            is_complete={sps_pin.is_complete}
            value={sps_pin.value}
            onChange={onChangePin}
            maxLength={4}
          />
        </>
      )}
      <TermRow onClick={() => setAgree(!is_agree)}>
        <TermCheck src={is_agree ? icon_checked : icon_unchecked} />
        <TermText>공유 가능한 안전한 비밀번호에요</TermText>
      </TermRow>
      <ButtonLegacy
        disabled={!is_enable}
        onClick={() => is_enable && changeSps({ key: sps_key.value, value: sps_value.value, pin: sps_pin.value })}
      >
        변경하기
      </ButtonLegacy>
    </Container>
  );
};

export default MyPartyEdit;
