import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { closeModal, ModalState, openModal } from "containers/Modal";
import { ModalType } from "components/Modal";
import { useRootState } from "globals/utils";
import { FullLoading } from "components/Loading";
import { MYPAGE_SET_BANK_ACCOUNT_SAGA, MYPAGE_DELETE_BANK_ACCOUNT_SAGA } from "./saga";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const ManageAccount = lazy(() => import("components/MyPage").then(({ ManageAccount }) => ({ default: ManageAccount })));

const ManageAccountContainer = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bank_account = useRootState((state) => state.mypage.manage_account.bank_account);

  const closeModalDispatch = () => dispatch(closeModal());
  const openModalDispatch = () => dispatch(openModal(delete_modal));
  const setBankAccountSagaDispatch = () => dispatch({ type: MYPAGE_SET_BANK_ACCOUNT_SAGA });
  const deleteBankAccountSagaDispatch = () => dispatch({ type: MYPAGE_DELETE_BANK_ACCOUNT_SAGA });
  const goAddBankAccount = () => {
    history.push("/mypage/addaccount/init");
  };
  const delete_modal: ModalState = {
    title: "등록된 계좌를 삭제하시나요?",
    content: "삭제하게 되면 리워드를 받을 수 없어요",
    is_dimmer: true,
    type: ModalType.NORMAL,
    buttons: [
      {
        onClick: closeModalDispatch,
        text: "아니요",
        theme: "gray-ghost",
      },
      {
        onClick: () => {
          deleteBankAccountSagaDispatch();
          closeModalDispatch();
        },
        text: "네",
        theme: "primary-fill",
      },
    ],
  };
  useEffect(() => {
    setBankAccountSagaDispatch();
    return;
  }, []);
  return (
    <Suspense fallback={<FullLoading />}>
      <PageNavigation back_path="/mypage" title="정산계좌 관리" />
      <ManageAccount
        bank_account={bank_account}
        openDeleteModal={openModalDispatch}
        goAddBankAccount={goAddBankAccount}
      />
    </Suspense>
  );
};

export default ManageAccountContainer;
