export interface NavigationState {
  current_navigation: number;
  is_loading?: boolean;
  page_navigation?: PageNavData;
}

export enum NavigationType {
  TAB,
  PAGE,
}

export interface PageNavData {
  back_path?: string | (() => void);
  close_path?: string | (() => void);
  title?: string;
  is_loading?: boolean;
  is_have_divider?: boolean;
}

export interface TabNavData {
  name: string;
  onClick: () => void;
  is_selected: boolean;
}

export interface DesktopTabNavData extends TabNavData {
  margin_right?: number;
}
