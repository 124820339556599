import { BlurLoading } from "components/Loading";
import * as Sentry from "@sentry/react";
import React from "react";
import { Store } from "index";

const ERROR_KEY = "chunk_failed";
const ERROR_NAME = "ChunkLoadError";

interface Item {
  value: string;
  expiry: number;
}

const captureException = (error: Error): void => {
  Sentry.withScope((scope) => {
    const object = Store.getState();
    scope.setContext("Redux Auth Data", { auth: JSON.stringify(object.auth) });
    scope.setContext("Redux Matching Data", { matching: JSON.stringify(object.matching) });
    scope.setContext("Redux Membership Data", { membership: JSON.stringify(object.membership) });
    scope.setContext("Redux Register Data", { register: JSON.stringify(object.register) });
    scope.setContext("Redux Register Member Data", { registerMember: JSON.stringify(object.registerMember) });
    scope.setContext("Redux Register Host Data", { registerhost: JSON.stringify(object.registerhost) });
    scope.setContext("Redux Slot Data", { slot: JSON.stringify(object.slot) });
    scope.setContext("Redux User Data", { user: JSON.stringify(object.user) });
    Sentry.captureException(error);
  });
};

const ErrorFallback = ({ error }: { error: Error }): JSX.Element => {
  if (error?.name && error.name === ERROR_NAME) {
    if (!getWithExpiry(ERROR_KEY)) {
      setWithExpiry(ERROR_KEY, "true", 10000);
      window.location.reload();
    }
  }
  captureException(error);
  return <BlurLoading />;
};

export default ErrorFallback;

const setWithExpiry = (key: string, value: string, ttl: number) => {
  const item: Item = {
    value: value,
    expiry: new Date().getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getWithExpiry = (key: string): string | null => {
  const itemString = localStorage.getItem(key);
  if (!itemString) return null;
  const item = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiry;

  if (isExpired) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};
