import React from "react";
import { IMP, IMP_CODE } from "globals/setting";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PageNavigation } from "containers/TopNavigation";
import { Certification } from "components/SetPassword";
import { SETPASSWORD_CHECK_USER_SAGA } from "./saga";

const CertificationContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const goPasswordInput = () => {
    history.push("/password/input");
  };

  const checkUserSagaDispatch = (payload: { imp_uid: string; goPasswordInput: () => void }) =>
    dispatch({ type: SETPASSWORD_CHECK_USER_SAGA, payload: payload });

  const openCertificate = () => {
    IMP.init(IMP_CODE || "");
    IMP.certification({}, callback);
  };
  const callback = async (response_imp: { success: boolean; imp_uid: string }) => {
    if (!response_imp.success) {
      setTimeout(() => alert("본인인증에 실패했습니다."), 100);
      return;
    }
    checkUserSagaDispatch({ imp_uid: response_imp.imp_uid, goPasswordInput });
  };

  return (
    <>
      <PageNavigation back_path="/login/phone" title="비밀번호 찾기" />
      <Certification openCertificate={openCertificate} />
    </>
  );
};

export default CertificationContainer;
