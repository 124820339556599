import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MyPartyState } from "./interface";
import { SlotData } from "globals/interfaces";

const initialState: MyPartyState = {
  is_loading: true,
  is_max: true,
  slots: {
    is_loading: true,
  },
  only_matching: {
    is_only_matching: false,
    is_loading: true,
  },
  set_profile: {
    my_profile_name: "",
    is_loading: true,
  },
};

const mypartySlice = createSlice({
  name: "myparty",
  initialState,
  reducers: {
    setSlotsLoading(state, action: PayloadAction<boolean>) {
      state.slots.is_loading = action.payload;
    },
    setSlots(state, action: PayloadAction<SlotData[]>) {
      state.slots.slots_data = action.payload;
    },
    setIsMax(state, action: PayloadAction<boolean>) {
      state.is_max = action.payload;
    },
    setOnlyMatching(state, action: PayloadAction<boolean>) {
      state.only_matching.is_only_matching = action.payload;
    },
    setOnlyMatchingMerchandiseAmount(state, action: PayloadAction<number>) {
      state.only_matching.merchandise_amount = action.payload;
    },
    setOnlyMatchingSavedAmount(state, action: PayloadAction<number>) {
      state.only_matching.saved_amount = action.payload;
    },
    setOnlyMatchingLoading(state, action: PayloadAction<boolean>) {
      state.only_matching.is_loading = action.payload;
    },
    setMyProfileName(state, action: PayloadAction<string>) {
      state.set_profile.my_profile_name = action.payload;
    },
    setProfileLoading(state, action: PayloadAction<boolean>) {
      state.set_profile.is_loading = action.payload;
    },
  },
});

export const {
  setSlotsLoading,
  setSlots,
  setIsMax,
  setOnlyMatching,
  setOnlyMatchingMerchandiseAmount,
  setOnlyMatchingSavedAmount,
  setOnlyMatchingLoading,
  setMyProfileName,
  setProfileLoading,
} = mypartySlice.actions;
export default mypartySlice.reducer;
