import React from "react";
import styled, { css } from "styled-components";
import { ButtonTheme, ThemeProps, button_theme } from "./theme";
import { ButtonSize, SizeProps, button_size } from "./size";

const Button = ({
  theme = "primary-fill",
  size = "medium",
  children,
  is_disabled,
  width,
  data_test_id,
  onClick,
}: {
  theme?: ButtonTheme;
  size?: ButtonSize;
  children: React.ReactNode;
  is_disabled?: boolean;
  width?: string;
  data_test_id?: string;
  onClick?: () => void;
}): JSX.Element => {
  const theme_props = theme ? theme : "primary-fill";
  const size_props = size ? size : "large";
  return (
    <ButtonContainer
      onClick={onClick}
      theme_props={button_theme[theme_props]}
      is_disabled={is_disabled}
      size_props={button_size[size_props]}
      width={width}
      data-testid={data_test_id}
    >
      {children}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div<{
  theme_props: ThemeProps;
  size_props: SizeProps;
  is_disabled?: boolean;
  width?: string;
}>`
  width: ${({ width }) => width || "100%"};
  padding: ${(props) => props.size_props.padding};
  background-color: ${(props) => props.theme_props.background_color};
  color: ${(props) => props.theme_props.color};
  border-radius: 8px;
  font-size: ${(props) => props.size_props.font_size}px;
  line-height: ${(props) => props.size_props.line_height}px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${(props) => props.theme_props.hovered_background_color};
    color: ${(props) => props.theme_props.hovered_color};
  }

  ${(props) =>
    props.is_disabled &&
    css`
      background-color: ${props.theme_props.disabled_background_color};
      color: ${props.theme_props.disabled_color};
      cursor: default;
      &:hover {
        background-color: ${props.theme_props.disabled_background_color};
        color: ${props.theme_props.disabled_color};
      }
    `}
`;

export default Button;
