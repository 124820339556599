import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegisterHostState, MerchantData, BankData } from "./interface";

const initialState: RegisterHostState = {
  is_loading: true,
  sps: {
    is_done: false,
    sps_key: "",
    sps_value: "",
  },
  account: {
    is_done: false,
  },
  is_modal_open: false,
  is_billing_done: false,
  is_difference_loading: true,
};

const registerhostSlice = createSlice({
  name: "registerhost",
  initialState,
  reducers: {
    setMerchant(state, action: PayloadAction<MerchantData>) {
      state.merchant = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.is_loading = action.payload;
    },
    setSpsKey(state, action: PayloadAction<string>) {
      state.sps.sps_key = action.payload;
    },
    setSpsValue(state, action: PayloadAction<string>) {
      state.sps.sps_value = action.payload;
    },
    setSpsPin(state, action: PayloadAction<string>) {
      state.sps.sps_pin = action.payload;
    },
    setSpsDone(state, action: PayloadAction<boolean>) {
      state.sps.is_done = action.payload;
    },
    setAccountBank(state, action: PayloadAction<BankData>) {
      state.account.bank = action.payload;
    },
    setAccountNum(state, action: PayloadAction<string>) {
      state.account.num = action.payload;
    },
    setAccountDone(state, action: PayloadAction<boolean>) {
      state.account.is_done = action.payload;
    },
    cleanupRegisterHost(state) {
      state.sps = {
        is_done: false,
        sps_key: "",
        sps_value: "",
        sps_pin: undefined,
      };
      state.account = {
        is_done: false,
        bank: undefined,
        num: undefined,
      };
      state.is_modal_open = false;
      state.is_billing_done = false;
    },
    setModal(state, action: PayloadAction<boolean>) {
      state.is_modal_open = action.payload;
    },
    setBillingDone(state, action: PayloadAction<boolean>) {
      state.is_billing_done = action.payload;
    },
    setDifferenceAmount(state, action: PayloadAction<{ remain: number; diff: number }>) {
      state.remaining_amount = action.payload.remain;
      state.difference_amount = action.payload.diff;
    },
    setDifferenceLoading(state, action: PayloadAction<boolean>) {
      state.is_difference_loading = action.payload;
    },
  },
});

export const {
  setMerchant,
  setLoading,
  setSpsKey,
  setSpsPin,
  setSpsValue,
  setSpsDone,
  cleanupRegisterHost,
  setAccountBank,
  setAccountDone,
  setAccountNum,
  setModal,
  setBillingDone,
  setDifferenceAmount,
  setDifferenceLoading,
} = registerhostSlice.actions;
export default registerhostSlice.reducer;
