import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MerchantData } from ".";
import { MatchingState, UsingMerchantData } from "./interface";

const initialState: MatchingState = {
  matching_count: 0,
  is_set_merchant_loading: true,
  is_set_using_merchant_loading: true,
  is_merchandises_loading: true,
  is_count_loading: true,
};

const matchingSlice = createSlice({
  name: "matching",
  initialState,
  reducers: {
    setMatchingCount(state, action: PayloadAction<number>) {
      state.matching_count = action.payload;
    },
    setCountLoading(state, action: PayloadAction<boolean>) {
      state.is_count_loading = action.payload;
    },
    setMerchants(state, action: PayloadAction<MerchantData[]>) {
      state.merchants = action.payload;
    },
    setUsingMerchants(state, action: PayloadAction<UsingMerchantData[]>) {
      state.using_merchants = action.payload;
    },
    setMerchantLoading(state, action: PayloadAction<boolean>) {
      state.is_set_merchant_loading = action.payload;
    },
    setUsingMerchantLoading(state, action: PayloadAction<boolean>) {
      state.is_set_using_merchant_loading = action.payload;
    },
  },
});

export const {
  setMatchingCount,
  setMerchants,
  setUsingMerchants,
  setCountLoading,
  setMerchantLoading,
  setUsingMerchantLoading,
} = matchingSlice.actions;
export default matchingSlice.reducer;
