import React from "react";
import styled from "styled-components";

interface AdBannerProps {
  img_src: string;
  alt: string;
  data_test_id?: string;
  onClick: () => void;
}

const AdBanner = ({ img_src, alt, onClick, data_test_id }: AdBannerProps): JSX.Element => (
  <Container>
    <Img src={img_src} alt={alt} data-testid={data_test_id} onClick={onClick} />
  </Container>
);

const Container = styled.div`
  display: flex;
  padding: 0px 24px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  min-width: 327px;
  max-width: 400px;
  cursor: pointer;
  @media only screen and (min-width: 540px) {
    width: 400px;
  }
`;

export default AdBanner;
