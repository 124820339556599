import ButtonLegacy from "components/Button";
import { InputWithLabel } from "components/Input";
import Space from "components/Space";
import React from "react";
import styled from "styled-components";
import { OldH6, OldSub, OldSmall2 } from "components/Text";

interface SetProfileProps {
  merchant_name: string;
  profile_img_src: string;
  profile_img_license: string;
  profiles: ProfileSet[];
  is_host: boolean;
  my_profile_name: string;
  is_enable: boolean;
  myProfileOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitProfile: () => void;
}

export interface ProfileSet {
  profile_name: string;
  label: "파티장" | "파티원";
}

const SetProfile = ({
  merchant_name,
  profile_img_src,
  profile_img_license,
  profiles,
  is_host,
  my_profile_name,
  is_enable,
  myProfileOnChange,
  submitProfile,
}: SetProfileProps): JSX.Element => (
  <Container>
    <OldH6>
      {merchant_name}에서 사용할
      <br />
      프로필 닉네임을 알려주세요
    </OldH6>
    <Space height={16} />
    <GreenBox>
      <Bold>
        {merchant_name}에서도 반드시 같은 닉네임으로
        <br />
        프로필을 만들어 사용해주세요
      </Bold>
    </GreenBox>
    <Space height={16} />
    <ProfileListBox>
      {!is_host &&
        profiles.map((profile, index) => (
          <ProfileSet
            profile_name={profile.profile_name}
            label={profile.label}
            profile_img_src={profile_img_src}
            key={`profile_${index}_${profile.profile_name}`}
          />
        ))}
      <ProfileSet profile_name={my_profile_name || "?"} label="내 프로필" profile_img_src={profile_img_src} />
      <ProfileImgLicense>{profile_img_license}</ProfileImgLicense>
    </ProfileListBox>
    <Space height={32} />
    <form onSubmit={(e) => e.preventDefault()} onKeyDown={(e) => e.key === "Enter" && is_enable && submitProfile()}>
      <InputWithLabel
        label="최대 6자리까지 입력 가능"
        is_complete={false}
        placeholder="프로필 닉네임을 알려주세요"
        value={my_profile_name}
        onChange={myProfileOnChange}
        is_have_button
      />
    </form>
    <Space height={40} />
    <ButtonLegacy onClick={is_enable ? submitProfile : undefined} disabled={!is_enable}>
      완료
    </ButtonLegacy>
  </Container>
);

const Container = styled.div`
  padding: 40px 24px 32px 24px;
  width: 100%;
`;

export const GreenBox = styled.div`
  padding: 10px 9px 6px 9px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  color: var(--primary-700);
  background-color: var(--primary-100);
`;

export const Bold = styled(OldSub)`
  font-weight: bold;
`;

const ProfileListBox = styled.div`
  padding: 40px 11px 29px 11px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  color: var(--primary-700);
  background-color: var(--black);
`;

interface ProfileSetProps {
  profile_name: string;
  label: "파티장" | "파티원" | "내 프로필";
  profile_img_src: string;
}

const ProfileSet = ({ profile_name, label, profile_img_src }: ProfileSetProps) => (
  <ProfileSetContainer>
    <ProfileImg src={profile_img_src} alt="OTT 프로필 이미지" />
    <Space height={8} />
    <ProfileName>{profile_name}</ProfileName>
    <Space height={4} />
    <LabelBox>{label}</LabelBox>
  </ProfileSetContainer>
);

const ProfileSetContainer = styled.div`
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 70px;
  height: 116px;

  &:last-child {
    margin-right: 0;
  }
`;

const ProfileImg = styled.img`
  border-radius: 8px;
  width: 70px;
  height: 70px;
`;

const ProfileName = styled(OldSmall2)`
  color: var(--white);
`;

const LabelBox = styled(OldSmall2)`
  padding: 0 8px 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  border-radius: 9px;
  color: var(--gray-300);
  background-color: var(--gray-700);
  font-weight: normal;
  text-align: center;
`;

const ProfileImgLicense = styled.div`
  position: absolute;
  padding-right: 4px;
  bottom: 4px;
  width: 100%;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  text-align: right;
  color: var(--gray-300);
`;

export default SetProfile;
