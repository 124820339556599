import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserState, UserData } from "./interface";

const initialState: UserState = {
  is_loading: true,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.is_loading = action.payload;
    },
    setUser(state, action: PayloadAction<UserData>) {
      state.user_data = action.payload;
    },
  },
});

export const { setLoading, setUser } = userSlice.actions;
export default userSlice.reducer;
