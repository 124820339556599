import { call, put, takeLatest } from "redux-saga/effects";
import { changeSps, setMerchantProfileName, sendMsg } from "apis";
import { logError, StatusCodes } from "globals/utils";

function* changeSpsSaga(action: {
  type: string;
  payload: {
    party_id: number;
    sps: { sps_key: string; sps_value: string; sps_pin?: string };
    goCheckSps: () => void;
  };
}) {
  try {
    const response = yield call(changeSps, { party_id: action.payload.party_id, sps: action.payload.sps });
    if (response.status === StatusCodes.OK_200) {
      alert("ID/PW 변경이 완료되었어요");
      action.payload.goCheckSps();
    }
  } catch (e) {
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.FORBIDDEN_403:
          alert("파티장이 아닙니다.");
          break;
        default:
          alert(`에러가 발생했습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.\n${e.response.data?.msg}`);
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
  }
}
export const MANAGEMERCHANT_CHANGE_SPS_SAGA = "managemerchant/changeSpsSaga";

function* sendMsgSaga(action: {
  type: string;
  payload: {
    msg: string;
    is_host: boolean;
    party_id: number;
    host_id: string;
    members_id: string[];
    include_phone: boolean;
    goNext?: () => null;
  };
}) {
  const { msg, party_id, host_id, is_host, members_id, include_phone, goNext } = action.payload;
  try {
    const send_targets: string[] = is_host ? members_id : [host_id];
    for (const member_id of send_targets) {
      yield call(sendMsg, {
        party_id,
        member_id,
        msg,
        include_phone,
      });
    }
    alert("문자 전송이 완료되었어요");
  } catch (e) {
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.FORBIDDEN_403:
          alert("파티 구성원 권한이 필요합니다.");
          break;
        case StatusCodes.NOT_FOUND_404:
          alert("해당 파티의 구성원이 아닙니다.");
          break;
        case StatusCodes.BAD_REQUEST_400:
          alert("메시지의 발신자와 수신자가 동일합니다.");
          break;
        case StatusCodes.TOO_MANY_REQUESTS_429:
          alert(`${e.response.data?.msg}`);
          break;
        default:
          alert(`메시지 발송을 실패했습니다\n${e.response.data?.msg}`);
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  }
  if (goNext) goNext();
}
export const MANAGEMERCHANT_SEND_MSG_SAGA = "managemerchant/sendMsgSaga";

function* setMerchantProfileNameSaga(action: {
  type: string;
  payload: {
    profile_name: string;
    closeProfileModal: () => void;
    party_id: number;
    my_member_id: string;
    merchant_name: string;
  };
}) {
  try {
    const response = yield call(
      setMerchantProfileName,
      action.payload.party_id,
      action.payload.my_member_id,
      action.payload.profile_name,
    );
    if (response.status === StatusCodes.OK_200) {
      alert(
        `내 프로필 이름 변경이 완료되었어요\n${action.payload.merchant_name} 프로필도 똑같은 이름으로 변경해주세요!`,
      );
      yield put({ type: "managemerchant/getMembersProfileSaga" });
      action.payload.closeProfileModal();
      window.location.reload();
    }
  } catch (e) {
    logError(e);
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.NOT_FOUND_404:
          alert("가입되어 있는 파티가 아닙니다.");
          break;
        case StatusCodes.NOT_ACCEPTABLE_406:
          alert("프로필 이름은 6자 미만으로 해주세요");
          break;
        case StatusCodes.CONFLICT_409:
          alert("똑같은 프로필 닉네임이 존재합니다.\n다른 프로필 닉네임으로 설정해주세요.");
          break;
        default:
          alert("프로필 이름 변경에 실패했어요");
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
  }
}
export const MANAGEMERCHANT_SET_MERCHANT_PROFILE_NAME_SAGA = "managemerchant/setMerchantProfileNameSaga";

function* managemerchantSaga(): Generator {
  yield takeLatest(MANAGEMERCHANT_CHANGE_SPS_SAGA, changeSpsSaga);
  yield takeLatest(MANAGEMERCHANT_SEND_MSG_SAGA, sendMsgSaga);
  yield takeLatest(MANAGEMERCHANT_SET_MERCHANT_PROFILE_NAME_SAGA, setMerchantProfileNameSaga);
}

export default managemerchantSaga;
