import { call, put, takeLatest } from "redux-saga/effects";
import { getBilling } from "apis";
import { logError } from "globals/utils";
import { setBillingLoading, setBilling } from "./slice";

function* setBillingSaga() {
  yield put(setBillingLoading(true));
  try {
    const response = yield call(getBilling);
    const { data } = response.data;
    if (data && data.length > 0) {
      yield put(setBilling({ billing_id: data[0].billing_id, card_name: data[0].name, card_num: data[0].num }));
    }
  } catch (e) {
    alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  }
  yield put(setBillingLoading(false));
}
export const REGISTER_SET_BILLING_SAGA = "register/setBillingSaga";

function* registerSaga(): Generator {
  yield takeLatest(REGISTER_SET_BILLING_SAGA, setBillingSaga);
}

export default registerSaga;
