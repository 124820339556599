import { BlurLoading } from "components/Loading";
import React, { lazy, Suspense } from "react";

const Space = lazy(() => import("components/Space"));
const Button = lazy(() => import("components/Button"));
const Container = lazy(() => import("./NonePartyStyle").then(({ Container }) => ({ default: Container })));
const OldH6 = lazy(() => import("components/Text").then(({ OldH6 }) => ({ default: OldH6 })));
const SubTitle = lazy(() => import("./NonePartyStyle").then(({ SubTitle }) => ({ default: SubTitle })));
const Letter = lazy(() => import("./NonePartyStyle").then(({ Letter }) => ({ default: Letter })));

const NotLogin = ({ openLoginModal }: { openLoginModal: () => void }): JSX.Element => (
  <Suspense fallback={<BlurLoading />}>
    <Container>
      <OldH6>
        파티에 가입하고
        <br />
        편리하게 계정을 공유해보세요!
      </OldH6>
      <Space height={4} />
      <SubTitle>로그인하고 파티에 가입해보세요!</SubTitle>
      <Space height={61} />
      <Letter />
      <Space height={101} />
      <Button onClick={openLoginModal} font_size={20} font_weight="bold">
        로그인/회원가입
      </Button>
    </Container>
  </Suspense>
);

export default NotLogin;
