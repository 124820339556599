import Space from "components/Space";
import React from "react";
import styled from "styled-components";
import { H2, Body2 } from "components/Text";
import { useScrollFadeIn } from "globals/utils";
import { img_iphone } from "./assets";

const Section3 = (): JSX.Element => (
  <Container>
    <Space height={60} />
    <MainText {...useScrollFadeIn(1, 0.3)}>
      같이 볼 사람 찾을 필요없이
      <br />
      원하는 OTT에 자동 매칭!
    </MainText>
    <Space height={16} />
    <SubText {...useScrollFadeIn(1, 0.3)}>
      OTT 서비스 계정을 함께 공유하는 &apos;파티&apos;를 만들어
      <br />
      같이 볼 &apos;파티원&apos;을 자동으로 매칭해줘요.
    </SubText>
    <Space height={11} />
    <ImageWrapper {...useScrollFadeIn(1, 0)}>
      <Image src={img_iphone} />
    </ImageWrapper>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow: hidden;
  align-items: center;
`;

const MainText = styled(H2)`
  text-align: center;
`;

const SubText = styled(Body2)`
  text-align: center;
  color: var(--gray-700);
`;

const Image = styled.img`
  height: auto;
  width: 435px;
`;

const ImageWrapper = styled.div`
  height: 469.8px;
  width: 435px;
`;

export default Section3;
