import React, { useState, useEffect } from "react";
import { openLoginModal, useRootState } from "globals/utils";
import { useHistory } from "react-router-dom";
import { TopNavLoginButton, DesktopTopNavLoginButton } from "components/Login";

const LoginButton = (): JSX.Element => {
  const history = useHistory();
  const is_modal_open = useRootState((state) => state.modal.is_open);
  const [is_mobile, setMobile] = useState<boolean>(true);
  useEffect(() => {
    setMobile(window.innerWidth < 1120);
    return;
  }, []);
  return is_mobile ? (
    <TopNavLoginButton
      onClick={
        !is_modal_open
          ? () => {
              openLoginModal({ history });
            }
          : undefined
      }
    />
  ) : (
    <DesktopTopNavLoginButton
      onClick={
        !is_modal_open
          ? () => {
              openLoginModal({ history });
            }
          : undefined
      }
    />
  );
};

export default LoginButton;
