import React from "react";
import { HostSlotData } from "containers/PartyDelete";
import Space from "components/Space";
import { Container, ExplainText, Ul, Li } from "./Refund";
import { Pair } from "components/Contents";
import { OldH5 } from "components/Text";

const HostRefund = ({ host_slot }: { host_slot: HostSlotData }): JSX.Element => (
  <>
    <Container>
      <OldH5>{host_slot.merchant_name} 구독료 이체 예정액</OldH5>
      <Space height={4} />
      <ExplainText>
        {host_slot.merchant_name} 구독료를 파티장으로 이용한 <br /> 기간만큼 계산해서 이체해드려요
      </ExplainText>
      <Space height={20} />
      <Pair title={`${host_slot.merchant_name} 프리미엄 구독료`} content={`${host_slot.amount.toLocaleString()}원`} />
      <Space height={8} />
      <Pair title="파티장으로 이용한 기간" content={`${host_slot.confirmed_days}일`} />
      <Space height={20} />
      <Pair is_last title="이체 예정 금액" content={`${host_slot.confirmed_amount.toLocaleString()}원`} />
    </Container>
    <Ul>
      <Li>
        이체 예정액은 파티해체 신청 후 <br /> 다음 영업일에 이체돼요
      </Li>
    </Ul>
  </>
);

export default HostRefund;
