import Logo from "components/Logo";
import React from "react";
import styled from "styled-components";
import { DesktopTabNavData, TabNavIcon } from "containers/TopNavigation";
import { DesktopSubtitle1 } from "components/Text";
import { OldDivider } from "components/Divider";

const DesktopTabNavigation = ({
  goHome,
  tab_list,
  is_fixed,
}: {
  goHome: () => void;
  tab_list: DesktopTabNavData[];
  is_fixed: boolean;
}): JSX.Element => (
  <Container is_fixed={is_fixed}>
    <NavWrapper>
      <div onClick={goHome}>
        <Logo />
      </div>
      <TabRow>
        {tab_list.map((tab) => (
          <Tab
            key={`${tab.name}_tab`}
            is_selected={tab.is_selected}
            onClick={tab.onClick}
            margin_right={tab.margin_right}
          >
            {tab.name}
          </Tab>
        ))}
      </TabRow>
      <TabNavIcon />
    </NavWrapper>
    {!is_fixed && <OldDivider margin_top="0px" margin_bottom="0px" height="1px" />}
  </Container>
);

const Container = styled.div<{ is_fixed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: ${(props) => (props.is_fixed ? "fixed" : "static")};
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  z-index: 5;
`;

const NavWrapper = styled.div`
  display: flex;
  width: 1080px;
  height: 60px;
  justify-content: space-between;
  align-items: center;
`;

const TabRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Tab = styled(DesktopSubtitle1)<{ is_selected?: boolean; margin_right?: number }>`
  padding: 12px;
  margin-right: ${(props) => props.margin_right}px;
  font-weight: ${(props) => (props.is_selected ? "bold" : "normal")};
  color: ${(props) => (props.is_selected ? "var(--gray-900)" : "var(--gray-700)")};
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    background-color: var(--gray-100);
  }
`;

export default DesktopTabNavigation;
