import { call, put, select, takeLatest } from "redux-saga/effects";
import { getMatchingPeopleCount, getMerchants, getParties, getSlots } from "apis";
import { RootState } from "root.redux";
import { logError } from "globals/utils";
import {
  NETFLIX_MERCHANT_ID_HARD,
  WATCHA_MERCHANT_ID_HARD,
  WAVVE_MERCHANT_ID_HARD,
  LAFTEL_MERCHANT_ID_HARD,
} from "globals/variables";
import { getServiceVariable } from "apis/admin";
import {
  setMatchingCount,
  setCountLoading,
  setMerchants,
  setUsingMerchants,
  setMerchantLoading,
  setUsingMerchantLoading,
} from "./slice";
import { UsingMerchantData } from "./interface";

function* setMatchingCountSaga() {
  yield put(setCountLoading(true));
  try {
    const response = yield call(getMatchingPeopleCount);
    const { data } = response.data;
    yield put(setMatchingCount(data.count));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setCountLoading(false));
  }
}
export const MATCHING_SET_MATCHING_COUNT_SAGA = "matching/setMatchingCountSaga";

function* setMerchantsSaga() {
  try {
    yield put(setMerchantLoading(true));
    yield put(setUsingMerchantLoading(true));
    const response = yield call(getMerchants);
    const { data } = response.data;
    const response_netflix = yield call(getServiceVariable, "is_host_hooking_netflix");
    const response_wavve = yield call(getServiceVariable, "is_host_hooking_wavve");
    const response_watcha = yield call(getServiceVariable, "is_host_hooking_watcha");
    const response_laftel = yield call(getServiceVariable, "is_host_hooking_laftel");
    const hooking_info: { [key: number]: boolean } = {
      [NETFLIX_MERCHANT_ID_HARD]: !!response_netflix.data?.data?.value,
      [WAVVE_MERCHANT_ID_HARD]: !!response_wavve.data?.data?.value,
      [WATCHA_MERCHANT_ID_HARD]: !!response_watcha.data?.data?.value,
      [LAFTEL_MERCHANT_ID_HARD]: !!response_laftel.data?.data?.value,
    };
    if (data && data.length > 0) {
      for (const merchant of data) {
        merchant.is_host_hooking = hooking_info[merchant.merchant_id];
      }
    }
    yield put(setMerchants(data));
  } catch (e) {
    if (e.response)
      alert(`에러가 발생했습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.\n${e.response.data?.msg}`);
    else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  } finally {
    yield put(setMerchantLoading(false));
  }
  try {
    const is_login = yield select((state: RootState) => state.auth.is_login);
    if (!is_login) {
      return;
    }
    const using_merchants: UsingMerchantData[] = [];

    const response = yield call(getSlots);
    const { data } = response.data;
    if (data && data.length > 0)
      for (const slot of data) {
        using_merchants.push({
          merchant_id: slot.merchant_id,
          merchant_name: slot.merchant_name,
          amount: slot.merchant_amount,
          img_url: slot.img_url,
          host_yn: slot.host_yn,
        });
      }

    const party_response = yield call(getParties);
    const party_data = party_response.data.data;
    if (party_data && party_data.length > 0) {
      for (const party of party_data) {
        const using_merchant: UsingMerchantData = {
          merchant_id: party.merchant_id,
          merchant_name: party.merchant_name,
          amount: party.amount,
          img_url: party.merchant_profile_image_url,
          is_special: party.is_special,
          host_yn: party.host_yn,
        };
        if (using_merchant.is_special && using_merchant.host_yn) {
          using_merchants.push(using_merchant);
        }
      }
    }
    if (using_merchants.length > 0) yield put(setUsingMerchants(using_merchants));
  } catch (e) {
    alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  } finally {
    yield put(setUsingMerchantLoading(false));
  }
}
export const MATCHING_SET_MERCHANTS_SAGA = "matching/setMerchantsSaga";

function* matchingSaga(): Generator {
  yield takeLatest(MATCHING_SET_MATCHING_COUNT_SAGA, setMatchingCountSaga);
  yield takeLatest(MATCHING_SET_MERCHANTS_SAGA, setMerchantsSaga);
}

export default matchingSaga;
