import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { LOGIN_GET_KAKAO_ACCESS_TOKEN_SAGA } from "./saga";

const KakaoRedirect = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const getState = (): { redirect_url?: string } => {
    const state = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).state;
    if (state) return JSON.parse(decodeURIComponent(state.toString()));
    return {};
  };

  const goRedirectUrl = () => {
    const state = getState();
    const redirect_url = decodeURIComponent(state.redirect_url || "/");
    history.push(redirect_url);
  };
  const goSignUp = () => {
    const state = getState();
    const redirect_url = encodeURIComponent(state.redirect_url || "/");
    history.push(`/signup?redirect_url=${redirect_url}`);
  };
  const getKakaoAccessTokenSagaDispatch = (code: string | null) =>
    dispatch({ type: LOGIN_GET_KAKAO_ACCESS_TOKEN_SAGA, payload: { code, goRedirectUrl, goSignUp } });

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    const code = query.code?.toString();
    if (!code) {
      alert("카카오 로그인 과정에서 문제가 생겼습니다");
      goRedirectUrl();
      return;
    }
    getKakaoAccessTokenSagaDispatch(code);

    return;
  }, []);
  return <></>;
};

export default KakaoRedirect;
