export const img_alim_talk = "https://asset.pickle.plus/images/img_alim_talk.webp";
export const img_iphone = "https://asset.pickle.plus/images/img_iphone.webp";
export const img_partners = "https://asset.pickle.plus/images/img_partners.webp";
export const img_content1 = "https://asset.pickle.plus/images/img_content1.webp";
export const img_content2 = "https://asset.pickle.plus/images/img_content2.webp";
export const img_content3 = "https://asset.pickle.plus/images/img_content3.webp";
export const img_content4 = "https://asset.pickle.plus/images/img_content4.webp";
export const img_content5 = "https://asset.pickle.plus/images/img_content5.webp";
export const img_content6 = "https://asset.pickle.plus/images/img_content6.webp";
export const img_content7 = "https://asset.pickle.plus/images/img_content7.webp";
export const img_content8 = "https://asset.pickle.plus/images/img_content8.webp";
