import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MyPageState, BillingData, BankAccountData, BankData } from "./interface";
import banks from "globals/banks";

const initialState: MyPageState = {
  manage_billing: {
    is_loading: true,
    is_add_card_loading: false,
  },
  manage_account: {
    is_loading: true,
  },
  add_account: {
    bank_list: banks,
  },
};

const mypageSlice = createSlice({
  name: "mypage",
  initialState,
  reducers: {
    setBilling(state, action: PayloadAction<BillingData>) {
      state.manage_billing.billing = action.payload;
    },
    setBankAccount(state, action: PayloadAction<BankAccountData>) {
      state.manage_account.bank_account = action.payload;
    },
    selectBank(state, action: PayloadAction<BankData>) {
      state.add_account.selected_bank = action.payload;
    },
    setAddCardLoading(state, action: PayloadAction<boolean>) {
      state.manage_billing.is_add_card_loading = action.payload;
    },
    setAccountLoading(state, action: PayloadAction<boolean>) {
      state.manage_account.is_loading = action.payload;
    },
    setManageBillingLoading(state, action: PayloadAction<boolean>) {
      state.manage_billing.is_loading = action.payload;
    },
  },
});

export const {
  setBilling,
  setBankAccount,
  selectBank,
  setAddCardLoading,
  setAccountLoading,
  setManageBillingLoading,
} = mypageSlice.actions;
export default mypageSlice.reducer;
