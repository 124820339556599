import { useRef, useEffect, useCallback, MutableRefObject } from "react";

const useScrollFadeIn = (
  duration = 1,
  delay = 0,
): { ref: MutableRefObject<HTMLDivElement | null>; style: { opacity: number; transform?: string } } => {
  const element = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback(
    ([entry]) => {
      const { current } = element;
      if (!!current && entry.isIntersecting) {
        current.style.transitionProperty = "all";
        current.style.transitionDuration = `${duration}s`;
        current.style.transitionTimingFunction = "cubic-bezier(0, 0, 0.2, 1)";
        current.style.transitionDelay = `${delay}s`;
        current.style.opacity = "1";
        current.style.transform = "translate3d(0, 0, 0)";
      }
    },
    [delay, duration],
  );

  useEffect(() => {
    let observer: IntersectionObserver;

    if (element.current) {
      observer = new IntersectionObserver(handleScroll, { threshold: 0.7 });
      observer.observe(element.current);
    }

    return () => observer && observer.disconnect();
  }, [handleScroll]);

  return {
    ref: element,
    style: {
      opacity: 0,
      transform: "translate3d(0, 15%, 0)",
    },
  };
};

export default useScrollFadeIn;
