import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  getBilling,
  createBilling,
  deleteBilling,
  getBankAccount,
  createBankAccount,
  deleteBankAccount,
  CreateBillingReqData,
} from "apis";
import banks from "globals/banks";
import { openModal } from "containers/Modal";
import { RootState } from "root.redux";
import { logError, StatusCodes } from "globals/utils";
import { setBilling, setBankAccount, setAddCardLoading, setAccountLoading, setManageBillingLoading } from "./slice";
import { ModalProps } from "components/Modal";

function* setBillingSaga() {
  yield put(setManageBillingLoading(true));
  try {
    const response = yield call(getBilling);
    const { data } = response.data;
    const main_card = data[0];
    if (main_card)
      yield put(setBilling({ billing_id: main_card.billing_id, card_name: main_card.name, card_num: main_card.num }));
  } catch (e) {
    logError(e);
  }
  yield put(setManageBillingLoading(false));
}
export const MYPAGE_SET_BILLING_SAGA = "mypage/setBillingSaga";

function* createBillingSaga(action: { type: string; payload: CreateBillingReqData; goAddCard: () => void }) {
  yield put(setAddCardLoading(true));
  try {
    const response = yield call(createBilling, action.payload);
    if (response.status === StatusCodes.OK_200) {
      const { data } = response.data;
      yield put(setBilling({ billing_id: data.billing_id, card_name: data.name, card_num: data.num }));
    }
    action.goAddCard();
  } catch (e) {
    if (e.response) {
      if (e.response.status === StatusCodes.INTERNAL_SERVER_ERROR_500) {
        alert(e.response.data?.msg);
      } else {
        alert(`카드 등록 과정에서 문제가 발생했습니다.\n${e.response.data?.msg}`);
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  }

  yield put(setAddCardLoading(false));
}
export const MYPAGE_CREATE_BILLING_SAGA = "mypage/createBillingSaga";

function* deleteBillingSaga(action: { type: string; payload: { cant_delete_modal: ModalProps } }) {
  yield put(setManageBillingLoading(true));
  const membership = yield select((state: RootState) => state.membership.membership_data);
  if (membership) {
    yield put(openModal(action.payload.cant_delete_modal));
    yield put(setManageBillingLoading(false));
    return;
  }
  try {
    const response = yield call(deleteBilling);
    if (response.status === StatusCodes.OK_200) {
      alert("카드 삭제가 완료되었어요!");
      window.location.reload();
    }
  } catch (e) {
    if (e.response) {
      if (e.response.status === StatusCodes.CONFLICT_409) {
        alert("멤버십 사용 중에는 카드를 삭제할 수 없습니다.");
      } else {
        alert(`카드 삭제 과정에서 문제가 발생했습니다.\n${e.response.data?.msg}`);
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  }
  yield put(setManageBillingLoading(false));
}
export const MYPAGE_DELETE_BILLING_SAGA = "mypage/deleteBillingSaga";

function* setBankAccountSaga() {
  yield put(setAccountLoading(true));
  try {
    const response = yield call(getBankAccount);
    if (response.status === StatusCodes.OK_200) {
      const { account_bank, account_num } = response.data.data;
      const bank = banks.find((bank) => bank.bank_code === account_bank);
      if (bank)
        yield put(
          setBankAccount({
            name: bank.name,
            bank_code: bank.bank_code,
            num: account_num,
            img_url: bank.img_src,
          }),
        );
    }
  } catch (e) {
    logError(e);
    if (e.response) {
      if (e.response?.status !== StatusCodes.NOT_FOUND_404) {
        alert(`계좌 등록과정에서 문제가 생겼습니다.\n${e.response.data?.msg}`);
      }
    } else {
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    }
  }
  yield put(setAccountLoading(false));
}
export const MYPAGE_SET_BANK_ACCOUNT_SAGA = "mypage/setBankAccountSaga";

function* createBankAccountSaga(action: {
  type: string;
  payload: { account_num: string; account_bank: string; goManageAccount: () => void };
}) {
  try {
    const account_name: string = yield select((state: RootState) => state.user.user_data?.real_name);
    const { account_bank, account_num } = action.payload;
    const response = yield call(createBankAccount, { account_bank, account_num, account_name });
    if (response.status === StatusCodes.CREATED_201) {
      alert("계좌 등록을 완료했습니다.");
      action.payload.goManageAccount();
    }
  } catch (e) {
    if (e.response) {
      alert(`계좌 등록과정에서 문제가 생겼습니다.\n${e.response.data?.msg}`);
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  }
}
export const MYPAGE_CREATE_BANK_ACCOUNT_SAGA = "mypage/createBankAccountSaga";

function* deleteBankAccountSaga() {
  try {
    const response = yield call(deleteBankAccount);
    if (response.status === StatusCodes.NO_CONTENT_204) {
      alert("정상적으로 계좌가 삭제되었습니다.");
      window.location.reload();
    }
  } catch (e) {
    if (e.response) {
      if (e.response.data.code === "USR0300") alert("OTT계정을 등록한 동안에는 계좌를 삭제할 수 없습니다.");
      else alert(`계좌 삭제과정에서 문제가 생겼습니다.\n${e.response.data?.msg}`);
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  }
}
export const MYPAGE_DELETE_BANK_ACCOUNT_SAGA = "mypage/deleteBankAccountSaga";

function* mypageSaga(): Generator {
  yield takeLatest(MYPAGE_SET_BILLING_SAGA, setBillingSaga);
  yield takeLatest(MYPAGE_CREATE_BILLING_SAGA, createBillingSaga);
  yield takeLatest(MYPAGE_DELETE_BILLING_SAGA, deleteBillingSaga);
  yield takeLatest(MYPAGE_SET_BANK_ACCOUNT_SAGA, setBankAccountSaga);
  yield takeLatest(MYPAGE_CREATE_BANK_ACCOUNT_SAGA, createBankAccountSaga);
  yield takeLatest(MYPAGE_DELETE_BANK_ACCOUNT_SAGA, deleteBankAccountSaga);
}

export default mypageSaga;
