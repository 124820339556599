import React, { Suspense, useEffect } from "react";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { BlurLoading } from "components/Loading";
import { AuthRoute } from "globals/utils";
import { RegisterMember } from "containers/RegisterMember";
import { SELECTED_MERCHANT, SELECTED_USER_TYPE } from "globals/variables";

const RegisterMemberRoute = (): JSX.Element => {
  const history = useHistory();
  const selected_merchant_id = sessionStorage.getItem(SELECTED_MERCHANT);
  const selected_user_type = sessionStorage.getItem(SELECTED_USER_TYPE);

  useEffect(() => {
    if (selected_user_type !== "member" || !selected_merchant_id) {
      history.replace("/matching");
      return;
    }
  }, []);

  return (
    <Suspense fallback={<BlurLoading />}>
      <Switch>
        <AuthRoute path="/register/member/init" component={RegisterMember} />
        <Redirect to="/register/member/init" />
      </Switch>
    </Suspense>
  );
};

export default RegisterMemberRoute;
