import React, { lazy, Suspense } from "react";
import { SlotData } from "globals/interfaces";
import AddPartyTab from "./AddPartyTab";
import { Container, Title, SubTitle, Primary, PromotionSmall } from "./MyPartyListStyle";
import { CircularLoading } from "components/Loading";

const Slots = lazy(() => import("./Slots"));
const Space = lazy(() => import("components/Space"));

interface MyPartyListProps {
  merchandise_name?: string;
  slots: SlotData[];
  goMembershipChange: () => void;
  goWaiting: (merchant_id: number) => void;
  goPartyInfo: (merchant_id: number) => void;
  saved_amount?: number;
  merchandise_amount?: number;
  slot_cnt?: number;
  is_max: boolean;
  is_only_matching: boolean;
}
const MyPartyList = ({
  merchandise_name,
  slots,
  goMembershipChange,
  goWaiting,
  goPartyInfo,
  saved_amount,
  merchandise_amount,
  slot_cnt,
  is_max,
  is_only_matching,
}: MyPartyListProps): JSX.Element => (
  <Suspense fallback={<CircularLoading />}>
    <Container>
      <Title>
        {is_only_matching && "매칭완료시 "}
        <Primary>{slot_cnt}개</Primary> OTT를 최고화질로
        <br />월 <Primary>{merchandise_amount?.toLocaleString()}원</Primary>에 즐{is_only_matching ? "길수" : "기고"}
        있어요
      </Title>
      <Space height={8} />
      {!!saved_amount && (
        <SubTitle>
          혼자 쓸 때보다 월 <strong>{saved_amount.toLocaleString()}원</strong>씩 절약{is_only_matching ? "돼요" : "중"}
        </SubTitle>
      )}
      {merchandise_name?.includes("*") && (
        <>
          <Space height={8} />
          <PromotionSmall>(구)피클플러스 고객 전용 특가 적용 중</PromotionSmall>
        </>
      )}
      <Space height={32} />
      <Slots slots={slots} goWaiting={goWaiting} goPartyInfo={goPartyInfo} />
      {!is_max && <AddPartyTab goMembershipChange={goMembershipChange} />}
    </Container>
  </Suspense>
);

export default MyPartyList;
