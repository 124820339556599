import styled from "styled-components";

export enum OldInputState {
  READY,
  COMPLETE,
}

const OldInput = styled.input<{
  width?: string | number;
  state: number;
  textalign?: string;
  height?: string;
}>`
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  caret-color: var(--primary-500);
  -moz-appearance: textfield;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  height: ${(props) => (props.height ? props.height : "44px")};
  width: ${(props) => props.width || "40px"};
  border-radius: 0;
  border-bottom: ${(props) =>
    props.state === OldInputState.COMPLETE ? "solid 1px var(--gray-700)" : "solid 1px var(--gray-300)"};
  font-family: SpoqaHanSansNeo;
  font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  text-align: ${(props) => props.textalign || "left"};
  color: var(--gray-900);

  ::placeholder {
    color: var(--gray-500);
  }

  :focus {
    border-bottom: solid 1px var(--primary-500);
  }
`;

export default OldInput;
