import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { PageNavigation } from "containers/TopNavigation";
import { PasswordInput } from "components/SetPassword";
import { useRootState } from "globals/utils";
import { SETPASSWORD_RESET_PASSWORD_SAGA } from "./saga";

const PasswordInputContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const imp_uid = useRootState((state) => state.setpassword.imp_uid);

  const goMain = () => {
    history.push("/");
  };
  const setPasswordSagaDispatch = (new_password: string) => {
    dispatch({ type: SETPASSWORD_RESET_PASSWORD_SAGA, payload: { imp_uid, new_password, goMain } });
  };

  return (
    <>
      <PageNavigation back_path="/password/certification" title="비밀번호 변경" />
      <PasswordInput resetPassword={setPasswordSagaDispatch} />
    </>
  );
};

export default PasswordInputContainer;
