import React from "react";
import styled from "styled-components";

const Tooltip = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <div>
    <TooltipBody>
      {children}
      <TooltipArrow />
    </TooltipBody>
  </div>
);

const TooltipBody = styled.div`
  position: relative;
  width: fit-content;
  padding: 9px 16px;
  border-radius: 20px;
  background-color: var(--white);
  border: solid 1px var(--gray-200);
  box-shadow: 0 6px 8px 0 rgba(57, 60, 59, 0.12);
`;

const TooltipArrow = styled.div`
  position: absolute;
  width: 10.5px;
  height: 10.5px;
  top: calc(100% - 6px);
  left: 50%;
  margin-left: -7.5px;
  background-color: var(--white);
  transform: rotate(45deg);
  overflow: hidden;
  border-radius: 3.5px;
`;

export default Tooltip;
