import React, { lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import qs from "qs";
import { ModalType } from "components/Modal";
import { ModalState, openModal, closeModal } from "containers/Modal";
import button_kakao_img from "./assets/button_kakao.png";
import { BlurLoading } from "components/Loading";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const SelectLogin = lazy(() => import("components/Login/SelectLogin"));

const SelectLoginContainer = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const openModalDispatch = (modal_state: ModalState) => dispatch(openModal(modal_state));
  const closeModalDispatch = () => dispatch(closeModal());
  const getRedirectUrl = () => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return encodeURIComponent(query.redirect_url ? query.redirect_url.toString() : "/myparty");
  };
  const goPhoneLogin = () => {
    history.push(`/login/phone?redirect_url=${getRedirectUrl()}`);
  };
  const goKakaoLogin = () => {
    window.Kakao.Auth.authorize({
      redirectUri: `${window.location.origin}/login/kakao`,
      state: JSON.stringify({ redirect_url: getRedirectUrl() }),
    });
  };
  const openSignupModal = () => {
    openModalDispatch({
      title: "피클플러스 회원가입",
      is_dimmer: true,
      is_dimmer_close: true,
      type: ModalType.STACKED,
      buttons: [
        {
          onClick: () => {
            goKakaoLogin();
            closeModalDispatch();
          },
          text: "",
          image_src: button_kakao_img,
        },
        {
          onClick: closeModalDispatch,
          text: "취소",
          theme: "gray-ghost",
        },
      ],
    });
  };
  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation back_path="/" title="로그인" />
      <SelectLogin goPhoneLogin={goPhoneLogin} goKakaoLogin={goKakaoLogin} openSignupModal={openSignupModal} />
    </Suspense>
  );
};

export default SelectLoginContainer;
