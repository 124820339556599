const purchaseMembership = ({
  merchandise_name,
  merchandise_id,
  price,
  value,
  phone,
}: {
  value: number;
  merchandise_id: number;
  price: number;
  merchandise_name: string;
  phone?: string;
}): void => {
  const transaction_id = `${phone}${Date.now()}`;
  window.gtag("event", "purchase", {
    transaction_id,
    affiliation: "피클플러스 웹",
    value,
    currency: "KRW",
    items: [
      {
        id: `${merchandise_id}`,
        name: merchandise_name,
        brand: "피클플러스",
        price,
        quantity: 1,
        category: "피클플러스 멤버십",
      },
    ],
  });
};

const createParty = ({
  merchandise_name,
  merchandise_id,
  merchant_name,
  value,
  phone,
}: {
  merchandise_name: string;
  merchandise_id: number;
  merchant_name: string;
  value: number;
  phone?: string;
}): void => {
  const transaction_id = `${phone}${Date.now()}`;
  window.gtag("event", "purchase", {
    transaction_id,
    affiliation: "피클플러스 웹",
    value,
    currency: "KRW",
    items: [
      {
        id: `${merchandise_id}`,
        name: `${merchandise_name} ${merchant_name} 파티장`,
        brand: "피클플러스",
        price: value,
        quantity: 1,
        category: "피클플러스 멤버십",
      },
    ],
  });
};

export const GaEvent = {
  purchaseMembership,
  createParty,
};
