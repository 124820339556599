import React, { lazy, Suspense } from "react";
import qs from "qs";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { IMP, IMP_CODE } from "globals/setting";
import { BlurLoading } from "components/Loading";
import { SIGNUP_CHECK_DUPLICATE_AND_SIGN_UP_SAGA } from "./saga";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const SignUp = lazy(() => import("components/SignUp"));

const SignUpContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const redirect_url = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).redirect_url;

  const checkDuplicateAndSignUpSagaDispatch = (payload: { imp_uid: string; goRedirectUrl: () => void }) =>
    dispatch({ type: SIGNUP_CHECK_DUPLICATE_AND_SIGN_UP_SAGA, payload });
  const goRedirectUrl = () => {
    if (redirect_url) history.push(decodeURIComponent(redirect_url.toString()));
    else history.push("/matching");
  };
  const openCertificate = () => {
    IMP.init(IMP_CODE || "");
    IMP.certification({}, callback);
  };
  const callback = async (response_imp: { success: boolean; imp_uid: string }) => {
    if (!response_imp.success) {
      setTimeout(() => alert("본인인증에 실패했습니다."), 300);
      return;
    }
    checkDuplicateAndSignUpSagaDispatch({ imp_uid: response_imp.imp_uid, goRedirectUrl });
  };

  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation close_path={redirect_url ? decodeURIComponent(redirect_url.toString()) : "/"} title="본인 인증" />
      <SignUp openCertificate={openCertificate} />
    </Suspense>
  );
};

export default SignUpContainer;
