import React from "react";
import { RefundData } from "containers/PartyDelete";
import styled from "styled-components";
import icon_info from "./assets/icon_info.png";
import Space from "components/Space";
import { OldH4, OldH5, OldSub } from "components/Text";

export interface RefundProps {
  refund_info: RefundData;
  is_matching?: boolean;
}

const Refund = ({ refund_info, is_matching }: RefundProps): JSX.Element => {
  const {
    difference_amount,
    remain_days,
    target_amount,
    target_slot_cnt,
    current_amount,
    current_slot_cnt,
  } = refund_info;
  return (
    <>
      <Container>
        <OldH5>{is_matching ? "매칭취소" : "파티해체"} 환불 예정액</OldH5>
        <Space height={4} />
        <ExplainText>
          {is_matching && (
            <>
              자동으로 재매칭 중이던 매칭을 취소해요.
              <br />
            </>
          )}
          {target_slot_cnt === 0 ? (
            <>
              OTT {current_slot_cnt}개 이용권 가격에서 남은 일자만큼을 <br />
              계산한 값이 환불돼요
            </>
          ) : (
            <>
              OTT {current_slot_cnt}개 이용권과 OTT {target_slot_cnt}개 이용권의 차액에서 <br />
              남은 일자만큼을 계산한 값이 환불돼요
            </>
          )}
        </ExplainText>
        <Space height={20} />
        <div>
          <Row>
            <SubText>OTT {current_slot_cnt}개 이용권 가격</SubText>
            <SubText is_value>{current_amount.toLocaleString()}원</SubText>
          </Row>
          <Space height={8} />
          {target_slot_cnt !== 0 && (
            <>
              <Row>
                <SubText>OTT {target_slot_cnt}개 이용권 가격</SubText>
                <SubText is_value>{target_amount.toLocaleString()}원</SubText>
              </Row>
              <Space height={8} />
            </>
          )}
          <Row>
            <SubText>남은 일자</SubText>
            <SubText is_value>{remain_days}일</SubText>
          </Row>
          <Space height={20} />
          <LastRow>
            <LastText>환불 예정 금액</LastText>
            <OldH4>{(difference_amount * -1).toLocaleString()}원</OldH4>
          </LastRow>
        </div>
      </Container>
      <Ul>
        <Li>
          카드결제 부분취소가 진행돼요 <br />
          카드사에 따라 1~5 영업일이 걸려요
        </Li>
      </Ul>
    </>
  );
};

export const Container = styled.div`
  padding: 64px 24px 40px 24px;
`;

export const ExplainText = styled.div`
  color: var(--gray-700);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SubText = styled(OldSub)<{ is_value?: boolean }>`
  color: ${(props) => (props.is_value ? "var(--gray-700)" : "var(--gray-500)")};
`;

export const LastText = styled(OldSub)`
  color: var(--gray-700);
  padding-bottom: 2px;
`;

export const LastRow = styled(Row)`
  align-items: flex-end;
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 24px 24px 17px 24px;
  margin: 0;
`;

export const Li = styled.li`
  background: url(${icon_info}) no-repeat left center;
  background-size: 24px;
  color: var(--gray-500);
  padding-left: 36px;
`;

export default Refund;
