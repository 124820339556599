import styled from "styled-components";

interface TextProps {
  font_weight?: string;
  color?: string;
}

export const H1 = styled.h1<TextProps>`
  display: block;
  font-size: 30px;
  line-height: 42px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "bold"};
  color: ${(props) => props.color};
`;

export const H2 = styled.h2<TextProps>`
  display: block;
  font-size: 26px;
  line-height: 34px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "bold"};
  color: ${(props) => props.color};
`;

export const H3 = styled.h3<TextProps>`
  display: block;
  font-size: 22px;
  line-height: 30px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "bold"};
  color: ${(props) => props.color};
  letter-spacing: -0.03em;
`;

export const Subtitle1 = styled.h4<TextProps>`
  display: block;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "bold"};
  color: ${(props) => props.color};
`;

export const Subtitle2 = styled.h5<TextProps>`
  display: block;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "bold"};
  color: ${(props) => props.color};
`;

export const Subtitle3 = styled.h6<TextProps>`
  display: block;
  font-size: 13px;
  line-height: 18px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "bold"};
  color: ${(props) => props.color};
`;

export const Body1 = styled.div<TextProps>`
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "normal"};
  color: ${(props) => props.color};
`;

export const Body2 = styled.div<TextProps>`
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "normal"};
  color: ${(props) => props.color};
`;

export const Body3 = styled.div<TextProps>`
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "normal"};
  color: ${(props) => props.color};
`;

export const Caption1 = styled.small<TextProps>`
  display: block;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  padding: 0;
  font-weight: ${(props) => props.font_weight || "normal"};
  color: ${(props) => props.color};
`;

export const Caption2 = styled.small<TextProps>`
  display: block;
  font-size: 11px;
  line-height: 15px;
  margin: 0;
  padding: 0%;
  font-weight: ${(props) => props.font_weight || "normal"};
  color: ${(props) => props.color};
`;

export const OldH1 = styled.h1<TextProps>`
  display: block;
  font-weight: ${(props) => props.font_weight || "bold"};
  font-size: 48px;
  line-height: 60px;
  margin: 0;
  color: ${(props) => props.color};
`;

export const OldH2 = styled.h2<TextProps>`
  display: block;
  font-weight: ${(props) => props.font_weight || "bold"};
  font-size: 36px;
  line-height: 48px;
  margin: 0;
  color: ${(props) => props.color};
`;

export const OldH3 = styled.h3<TextProps>`
  display: block;
  font-weight: ${(props) => props.font_weight || "bold"};
  font-size: 32px;
  line-height: 44px;
  margin: 0;
  color: ${(props) => props.color};
`;

export const OldH4 = styled.h4<TextProps>`
  display: block;
  font-weight: ${(props) => props.font_weight || "bold"};
  font-size: 28px;
  line-height: 36px;
  margin: 0;
  color: ${(props) => props.color};
`;

export const OldH5 = styled.h5<TextProps>`
  display: block;
  font-weight: ${(props) => props.font_weight || "bold"};
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  color: ${(props) => props.color};
`;

export const OldH6 = styled.h6<TextProps>`
  display: block;
  font-weight: ${(props) => props.font_weight || "bold"};
  font-size: 20px;
  line-height: 28px;
  margin: 0;
  color: ${(props) => props.color};
`;

export const OldBody1 = styled.div<TextProps>`
  display: block;
  font-weight: ${(props) => props.font_weight || "normal"};
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.color};
`;

export const OldSub = styled.sub<TextProps>`
  display: block;
  font-weight: ${(props) => props.font_weight || "normal"};
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: ${(props) => props.color};
`;

export const OldSmall1 = styled.small<TextProps>`
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: ${(props) => props.font_weight || "bold"};
  margin: 0;
  padding: 0;
  color: ${(props) => props.color};
`;

export const OldSmall2 = styled.small<TextProps>`
  display: block;
  font-weight: ${(props) => props.font_weight || "bold"};
  font-size: 11px;
  line-height: 16px;
  margin: 0;
  padding: 0;
  color: ${(props) => props.color};
`;
