import { AxiosResponse } from "axios";
import { OLD_DJANGO_URL } from "globals/setting";
import { Axios } from "./default";

export const getMerchants = async (): Promise<AxiosResponse> => {
  const response = await Axios({
    url: `${OLD_DJANGO_URL}/party/merchants`,
    method: "GET",
  });
  return response;
};

export const getMerchant = async (merchant_id: number): Promise<AxiosResponse> => {
  const response = await Axios({
    url: `${OLD_DJANGO_URL}/party/merchants/${merchant_id}`,
    method: "GET",
  });
  return response;
};
