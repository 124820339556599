import React from "react";
import styled from "styled-components";
import { Body2 } from "components/Text";
import { EmojiLink } from "assets/emojis";
import Space from "components/Space";
import ButtonLegacy from "components/Button";
import { ReactComponent as ImgPeopleLink } from "./assets/img_people_link.svg";
import { ICON_WAVVE, ICON_NETFLIX, ICON_WATCHA, ICON_LAFTEL } from "globals/variables";

const Section1 = ({ goMatching }: { goMatching: () => void }): JSX.Element => {
  const merchant_imgs = [ICON_NETFLIX, ICON_WATCHA, ICON_WAVVE, ICON_LAFTEL];

  return (
    <Container>
      <Space height={24} />
      <MerchantRow>
        {merchant_imgs.map((merchant_img) => (
          <MerchantCircle key={`${merchant_img}`}>
            <img src={merchant_img} width="22.5px" />
          </MerchantCircle>
        ))}
      </MerchantRow>
      <Space height={16} />
      <Title>
        커피 한 잔 값으로
        <br />
        OTT를 더 저렴하게
      </Title>
      <Space height={20} />
      <Explain>넷플릭스, 왓챠, 웨이브, 라프텔…</Explain>
      <Explain>매달 나가는 구독료 부담스럽다면?</Explain>
      <Explain>
        <EmojiWrapper>
          <EmojiLink size={14} />
        </EmojiWrapper>
        <strong>안전한 계정 공유</strong>로 더 저렴하게!
      </Explain>
      <Space height={30} />
      <ButtonLegacy
        width={231}
        height={50}
        font_weight="bold"
        padding_top="0px"
        onClick={goMatching}
        data-testid="go_matching_button"
      >
        서비스 시작하기
      </ButtonLegacy>
      <Space height={15} />
      <ImgPeopleLink title="img people link" />
    </Container>
  );
};

const Title = styled.div`
  font-family: HGGGothic;
  font-size: 35px;
  line-height: 46px;
  text-align: center;
`;

const MerchantRow = styled.div`
  display: flex;
  width: 183px;
  justify-content: space-between;
`;
const MerchantCircle = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--gray-100);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, rgba(244, 255, 252, 0), #eefdfa, #ebfcf8);
  align-items: center;
  overflow-x: hidden;
`;

const EmojiWrapper = styled.div`
  position: absolute;
  top: 2px;
  left: -17px;
`;

const Explain = styled(Body2)`
  position: relative;
  line-height: 23px;
  color: var(--gray-700);
`;

export default Section1;
