import React, { useEffect, useState } from "react";
import { PageNavigation } from "containers/TopNavigation";
import { HostRefund, DeleteButtons, Refund } from "components/PartyDelete";
import { membershipSelector, useRootState } from "globals/utils";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { FullLoading } from "components/Loading";
import { closeModal, ModalState, openModal } from "containers/Modal";
import styled from "styled-components";
import { ModalType } from "components/Modal";
import { cleanupPartyDelete } from "./slice";
import {
  PARTYDELETE_SET_REFUND_INFO_SAGA,
  PARTYDELETE_SET_HOST_SLOT_SAGA,
  PARTYDELETE_DELETE_PARTY_SAGA,
} from "./saga";

const PartyDelete = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const party_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).party_id,
  );
  const merchant_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_id,
  );
  const host_slot = useRootState((state) => state.partydelete.host_slot);
  const is_refund_loading = useRootState((state) => state.partydelete.is_refund_loading);
  const is_host_loading = useRootState((state) => state.partydelete.is_host_loading);
  const refund_info = useRootState((state) => state.partydelete.refund_info);
  const is_full_loading = useRootState((state) => state.partydelete.is_full_loading);
  const is_promotion = useRootState(membershipSelector).membership_data?.is_promotion;

  const [current_page, setCurrentPage] = useState<number>(1);
  const [max_page, setMaxPage] = useState<number>(1);

  const is_last = current_page === max_page;

  const goBack = () => history.push(`/myparty/info?merchant_id=${merchant_id}`);
  const goMyParty = () => history.push("/myparty");
  const setRefundInfoSagaDispatch = () =>
    dispatch({ type: PARTYDELETE_SET_REFUND_INFO_SAGA, payload: { goBack, merchant_id, goMyParty } });
  const setHostSlotSagaDispatch = () =>
    dispatch({ type: PARTYDELETE_SET_HOST_SLOT_SAGA, payload: { party_id, goBack: goMyParty } });
  const openModalDispatch = (modal_state: ModalState) => dispatch(openModal(modal_state));
  const closeModalDispatch = () => dispatch(closeModal());
  const cleanupPartyDeleteDispatch = () => dispatch(cleanupPartyDelete());
  const deletePartySagaDispatch = () => {
    if (!is_promotion) {
      dispatch({
        type: PARTYDELETE_DELETE_PARTY_SAGA,
        payload: { party_id, merchant_name: host_slot?.merchant_name, goBack },
      });
      return;
    }
    if (confirm("더이상 (구)피클플러스 특가를 적용받지 못하시게 됩니다. 진행하시겠습니까?")) {
      dispatch({
        type: PARTYDELETE_DELETE_PARTY_SAGA,
        payload: { party_id, goBack },
      });
    } else {
      closeModalDispatch();
      goBack();
    }
  };

  useEffect(() => {
    setRefundInfoSagaDispatch();
    setHostSlotSagaDispatch();
    return () => {
      cleanupPartyDeleteDispatch();
    };
  }, []);

  useEffect(() => {
    if (!is_host_loading && host_slot) setMaxPage(2);
  });

  useEffect(() => {
    if (!is_refund_loading && !refund_info?.is_refund) {
      alert("환불 대상이 아닙니다");
      goMyParty();
      return;
    }
  });

  const openConfirmModal = () => {
    openModalDispatch({
      title: "정말 파티해체 하시겠어요?",
      content: <ModalContent>파티해체는 즉시 진행되기 때문에 취소하실 수 없어요</ModalContent>,
      type: ModalType.ENLARGED,
      is_dimmer: true,
      is_dimmer_close: true,
      buttons: [
        {
          onClick: closeModalDispatch,
          text: "아니요",
          theme: "gray-ghost",
        },
        {
          onClick: () => deletePartySagaDispatch(),
          text: "예",
          theme: "red-fill",
        },
      ],
    });
  };

  return (
    <>
      <PageNavigation
        title="파티해체"
        back_path={() => (current_page === 1 ? goBack() : setCurrentPage(current_page - 1))}
        is_loading={is_host_loading || is_refund_loading || is_full_loading}
      />
      {!is_host_loading && !is_refund_loading && refund_info && !is_full_loading ? (
        <>
          {current_page === 1 && host_slot ? (
            <HostRefund host_slot={host_slot} />
          ) : (
            <Refund refund_info={refund_info} />
          )}
          <DeleteButtons
            is_last={is_last}
            goBack={goBack}
            goNext={() => (current_page === max_page ? openConfirmModal() : setCurrentPage(current_page + 1))}
          />
        </>
      ) : (
        <FullLoading />
      )}
    </>
  );
};

const ModalContent = styled.div`
  color: var(--gray-700);
`;

export default PartyDelete;
