import React from "react";
import styled from "styled-components";
import { IconArrowLeft, IconX } from "assets/icons";
import { OldDivider } from "components/Divider";
import { Body1 } from "components/Text";

interface PageNavigationProps {
  onArrowClick?: (() => void) | false;
  onXClick?: (() => void) | false;
  title?: string;
  is_loading?: boolean;
  is_have_divider?: boolean;
}

const PageNavigation = ({
  onArrowClick,
  onXClick,
  title,
  is_loading,
  is_have_divider = true,
}: PageNavigationProps): JSX.Element => (
  <>
    <NavContainer>
      {!is_loading && !!onArrowClick && (
        <LeftIconWrapper onClick={onArrowClick}>
          <IconArrowLeft />
        </LeftIconWrapper>
      )}
      {!!title && <Body1 color="var(--gray-800)">{title}</Body1>}
      {!is_loading && !!onXClick && (
        <RightIconWrapper onClick={onXClick}>
          <IconX />
        </RightIconWrapper>
      )}
    </NavContainer>
    {is_have_divider && <OldDivider margin_top="0px" margin_bottom="0px" height="1px" />}
  </>
);

const NavContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 640px;
  height: 72px;
  position: relative;
  justify-content: center;
`;

const LeftIconWrapper = styled.div`
  position: absolute;
  left: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
const RightIconWrapper = styled.div`
  position: absolute;
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export default PageNavigation;
