import { BlurLoading } from "components/Loading";
import React, { lazy, Suspense } from "react";

const Space = lazy(() => import("components/Space"));
const Button = lazy(() => import("components/Button"));
const Container = lazy(() => import("./NonePartyStyle").then(({ Container }) => ({ default: Container })));
const OldH6 = lazy(() => import("components/Text").then(({ OldH6 }) => ({ default: OldH6 })));
const SubTitle = lazy(() => import("./NonePartyStyle").then(({ SubTitle }) => ({ default: SubTitle })));
const Letter = lazy(() => import("./NonePartyStyle").then(({ Letter }) => ({ default: Letter })));

const NoneParty = ({ real_name, goMatching }: { real_name?: string; goMatching: () => void }): JSX.Element => (
  <Suspense fallback={<BlurLoading />}>
    <Container>
      <OldH6>
        {real_name}님,
        <br />
        아직 가입한 파티가 없어요
      </OldH6>
      <Space height={4} />
      <SubTitle>지금 바로 파티에 가입해보세요!</SubTitle>
      <Space height={61} />
      <Letter />
      <Space height={101} />
      <Button onClick={goMatching} font_size={20} font_weight="bold">
        빠른매칭 시작하기
      </Button>
    </Container>
  </Suspense>
);

export default NoneParty;
