import React, { useEffect, useState } from "react";
import Admin from "components/Admin";
import { MatchingPersonData, MatchingPartySpaceData } from "components/Admin/Admin";
import {
  adminGetMerchantPeople,
  adminGetMerchantEmptySpaces,
  adminGetMerchantFilledSpaces,
  adminGetMerchantPeopleCnt,
  adminGetMerchantEmptySpacesCnt,
  adminGetMerchantFilledSpacesCnt,
  getPartyInfo,
} from "apis/admin";
import { StatusCodes } from "globals/utils";
import { PartyInfoResponse } from "./interface";

const AdminContainer = (): JSX.Element => {
  const [merchant_id, setMerchantId] = useState<number>(1);
  const [people_cnt, setPeopleCnt] = useState<number>(1);
  const [empty_space_cnt, setEmptySpaceCnt] = useState<number>(1);
  const [filled_space_cnt, setFilledSpaceCnt] = useState<number>(1);
  const [people_page, setPeoplePage] = useState<number>(1);
  const [empty_space_page, setEmptySpacePage] = useState<number>(1);
  const [filled_space_page, setFilledSpacePage] = useState<number>(1);
  const [matching_people, setMatchingPeople] = useState<MatchingPersonData[]>();
  const [empty_party_spaces, setEmptyMatchingPartySpaces] = useState<MatchingPartySpaceData[]>();
  const [filled_party_spaces, setFilledMatchingPartySpaces] = useState<MatchingPartySpaceData[]>();
  const [party_info, setPartyInfo] = useState<PartyInfoResponse>();
  const [party_id, setPartyId] = useState<number>(0);
  const getMerchantPeople = async (merchant_id: number) => {
    const response = await adminGetMerchantPeople(merchant_id, people_page);
    if (response.status === StatusCodes.OK_200) {
      setMatchingPeople(response.data.data);
    }
  };
  const getMerchantEmptySpaces = async (merchant_id: number) => {
    const response = await adminGetMerchantEmptySpaces(merchant_id, empty_space_page);
    if (response.status === StatusCodes.OK_200) {
      setEmptyMatchingPartySpaces(response.data.data);
    }
  };
  const getMerchantFilledSpaces = async (merchant_id: number) => {
    const response = await adminGetMerchantFilledSpaces(merchant_id, filled_space_page);
    if (response.status === StatusCodes.OK_200) {
      setFilledMatchingPartySpaces(response.data.data);
    }
  };
  const getMerchantPeopleCnt = async (merchant_id: number) => {
    const response = await adminGetMerchantPeopleCnt(merchant_id);
    if (response.status === StatusCodes.OK_200) {
      const cnt = parseInt(response.data.data);
      setPeoplePage(1);
      setPeopleCnt(cnt);
    }
  };
  const getMerchantEmptySpacesCnt = async (merchant_id: number) => {
    const response = await adminGetMerchantEmptySpacesCnt(merchant_id);
    if (response.status === StatusCodes.OK_200) {
      const cnt = parseInt(response.data.data);
      setEmptySpacePage(1);
      setEmptySpaceCnt(cnt);
    }
  };
  const getMerchantFilledSpacesCnt = async (merchant_id: number) => {
    const response = await adminGetMerchantFilledSpacesCnt(merchant_id);
    if (response.status === StatusCodes.OK_200) {
      const cnt = parseInt(response.data.data);
      setFilledSpacePage(1);
      setFilledSpaceCnt(cnt);
    }
  };
  const handlePartyInfoClick = async () => {
    try {
      const response = await getPartyInfo(party_id);
      if (response.status === StatusCodes.OK_200) {
        setPartyInfo(response.data);
      }
    } catch (e) {
      if (e.response?.status === StatusCodes.NOT_FOUND_404) {
        alert("존재하지 않는 party_id 입니다.");
      } else alert("오류 발생!! 문의 바래요~");
    }
  };
  const onChange = (event: React.FormEvent) => {
    const target = event.target as HTMLSelectElement;
    const merchant_id = parseInt(target.value);
    setMerchantId(merchant_id);
  };
  const onPartyIdChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setPartyId(parseInt(value));
  };
  const onPeoplePageChange = (event: React.FormEvent) => {
    const target = event.target as HTMLSelectElement;
    const page = parseInt(target.value);
    setPeoplePage(page);
  };
  const onEmptySpacePageChange = (event: React.FormEvent) => {
    const target = event.target as HTMLSelectElement;
    const page = parseInt(target.value);
    setEmptySpacePage(page);
  };
  const onFilledSpacePageChange = (event: React.FormEvent) => {
    const target = event.target as HTMLSelectElement;
    const page = parseInt(target.value);
    setFilledSpacePage(page);
  };
  useEffect(() => {
    setMerchantId(1);
  }, []);
  useEffect(() => {
    getMerchantPeopleCnt(merchant_id);
    getMerchantEmptySpacesCnt(merchant_id);
    getMerchantFilledSpacesCnt(merchant_id);
  }, [merchant_id]);
  useEffect(() => {
    getMerchantPeople(merchant_id);
    getMerchantEmptySpaces(merchant_id);
    getMerchantFilledSpaces(merchant_id);
  }, [merchant_id, people_page]);
  useEffect(() => {
    getMerchantPeople(merchant_id);
    getMerchantEmptySpaces(merchant_id);
    getMerchantFilledSpaces(merchant_id);
  }, [merchant_id, empty_space_page]);
  useEffect(() => {
    getMerchantPeople(merchant_id);
    getMerchantEmptySpaces(merchant_id);
    getMerchantFilledSpaces(merchant_id);
  }, [merchant_id, filled_space_page]);

  return (
    <Admin
      merchant_id={merchant_id}
      people_page={people_page}
      empty_space_page={empty_space_page}
      filled_space_page={filled_space_page}
      matching_people={matching_people}
      empty_party_spaces={empty_party_spaces}
      filled_party_spaces={filled_party_spaces}
      people_cnt={people_cnt}
      empty_space_cnt={empty_space_cnt}
      filled_space_cnt={filled_space_cnt}
      onChange={onChange}
      onPeoplePageChange={onPeoplePageChange}
      onEmptySpacePageChange={onEmptySpacePageChange}
      onFilledSpacePageChange={onFilledSpacePageChange}
      onPartyIdChange={onPartyIdChange}
      onPartyIdClick={handlePartyInfoClick}
      party_info={party_info}
    />
  );
};

export default AdminContainer;
