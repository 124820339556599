import { call, put, takeLatest, select } from "redux-saga/effects";
import { getMembership, getSavedAmount } from "apis";
import { isLoginSelector, logError, StatusCodes } from "globals/utils";
import { setLoading, setMembership, setSavedAmount, setAmountLoading } from "globals/membership";
import { AUTH_SET_USER_SAGA } from "globals/auth";

function* setMembershipSaga() {
  yield put(setLoading(true));
  yield put({ type: AUTH_SET_USER_SAGA });
  const is_login = yield select(isLoginSelector);
  if (!is_login) {
    yield put(setLoading(false));
    return;
  }
  try {
    const response = yield call(getMembership);
    const {
      merchandise_id,
      merchandise_name,
      merchandise_price,
      is_promotion,
      slot_cnt,
      period_end_date,
    } = response.data.data;
    yield put(
      setMembership({
        merchandise_id,
        merchandise_name,
        merchandise_price,
        is_promotion,
        slot_cnt,
        pay_day: period_end_date.split("-")[2],
        period_end_date,
      }),
    );
  } catch (e) {
    if (e.response) {
      logError(e.response);
      const { status } = e.response;
      switch (status) {
        case StatusCodes.NOT_FOUND_404:
          break;
        case StatusCodes.UNAUTHORIZED_401:
          break;
        default:
          alert(`에러가 발생했습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.\n${e.response.data?.msg}`);
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
  }
  yield put(setLoading(false));
}
export const MEMBERSHIP_SET_MEMBERSHIP_SAGA = "membership/setMembershipSaga";

function* setSavedAmountSaga() {
  yield put(setAmountLoading(true));
  try {
    const response = yield call(getSavedAmount);
    const { data } = response.data;
    yield put(setSavedAmount(data));
  } catch (e) {
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.PAYMENT_REQUIRED_402:
          break;
        case StatusCodes.INTERNAL_SERVER_ERROR_500:
          alert(`에러가 발생했습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.\n${e.response.data?.msg}`);
          break;
        default:
          alert(
            "에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)",
          );
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  } finally {
    yield put(setAmountLoading(false));
  }
}
export const MEMBERSHIP_SET_SAVED_AMOUNT_SAGA = "membership/setSavedAmountSaga";

function* membershipSaga(): Generator {
  yield takeLatest(MEMBERSHIP_SET_MEMBERSHIP_SAGA, setMembershipSaga);
  yield takeLatest(MEMBERSHIP_SET_SAVED_AMOUNT_SAGA, setSavedAmountSaga);
}

export default membershipSaga;
