import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { cancelMatching, getSlots, setMerchantProfileName } from "apis";
import { RootState } from "root.redux";
import { logError, StatusCodes } from "globals/utils";
import { MAX_SLOT_COUNT_HARD } from "globals/variables";
import { SlotData, SlotStatus } from "globals/interfaces";
import { MerchandiseData, setLoading } from "globals/merchandise";
import { closeModal } from "containers/Modal";
import {
  setSlotsLoading,
  setSlots,
  setIsMax,
  setOnlyMatching,
  setOnlyMatchingMerchandiseAmount,
  setOnlyMatchingSavedAmount,
  setOnlyMatchingLoading,
  setProfileLoading,
} from "./slice";
import { setTryingPay, cleanupTryingPay } from "globals/auth";
import { SLOT_SET_SLOT_SAGA } from "containers/Slot";
import { Amplitude } from "apis/amplitude";

function* cancelMatchingSaga(action: { type: string; payload: { goMyParty: () => void; merchant_id: number } }) {
  yield put(setTryingPay());
  yield put(closeModal());
  try {
    const matching_data = yield select((state: RootState) => state.myparty.slots.slots_data);
    if (matching_data.length > 0) {
      const { person_id, merchant_name } = matching_data.find(
        (matching: { merchant_id: number }) => matching.merchant_id === action.payload.merchant_id,
      );
      const response = yield call(cancelMatching, person_id);
      if (response.status === StatusCodes.OK_200) {
        alert("매칭이 취소되었습니다");
        window.location.href = "/myparty";
        matching_data;
        Amplitude.cancelMemberMatching({ merchant_name });
      }
    }
  } catch (e) {
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.NOT_FOUND_404:
          alert("이미 계정발급이 완료되었습니다.");
          window.location.href = "/myparty";
          break;
      }
    } else {
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
      logError(e);
      action.payload.goMyParty();
    }
  } finally {
    yield put(cleanupTryingPay());
  }
}
export const MYPARTY_CANCEL_MATCHING_SAGA = "myparty/cancelMatchingSaga";

function* setSlotsSaga() {
  yield put(setProfileLoading(true));
  try {
    const is_login = yield select((state: RootState) => state.auth.is_login);
    if (!is_login) {
      yield put(setSlotsLoading(false));
      return;
    }
    const response = yield call(getSlots);
    const { data } = response.data;
    if (data && data.length > 0) {
      yield put(setSlots(data));
      yield put(setIsMax(data.length === MAX_SLOT_COUNT_HARD));
      let matching_count = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].status === SlotStatus.MEMBER_WAIT) matching_count += 1;
      }
      yield put(setOnlyMatching(matching_count === data.length));
    }
  } catch (e) {
    logError(e);
  } finally {
    yield put(setSlotsLoading(false));
  }
}
export const MYPARTY_SET_SLOTS_SAGA = "myparty/setSlotsSaga";

function* setOnlyMatchingMerchandiseAmountSaga(action: { type: string; payload: { slot_cnt: number } }) {
  yield put(setOnlyMatchingLoading(true));
  try {
    const is_only_matching = yield select((state: RootState) => state.myparty.only_matching.is_only_matching);
    if (!is_only_matching) {
      yield put(setOnlyMatchingLoading(false));
      return;
    }
    let merchandises = yield select((state: RootState) => state.merchandise.merchandises);
    if (!merchandises) {
      while (true) {
        yield take(setLoading);
        const is_merchandises_loading = yield select((state: RootState) => state.merchandise.is_loading);
        if (!is_merchandises_loading) break;
      }
      merchandises = yield select((state: RootState) => state.merchandise.merchandises);
    }
    const merchandise_amount = merchandises.find(
      (merchandise: MerchandiseData) => merchandise.slot_cnt === action.payload.slot_cnt,
    ).amount;

    yield put(setOnlyMatchingMerchandiseAmount(merchandise_amount));

    let sum = 0;
    const slots = yield select((state: RootState) => state.myparty.slots.slots_data);
    slots.forEach((slot: SlotData) => (sum += slot.merchant_amount));
    yield put(setOnlyMatchingSavedAmount(sum - merchandise_amount));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setOnlyMatchingLoading(false));
  }
}
export const MYPARTY_SET_ONLY_MATCHING_MERCHANDISE_AMOUNT_SAGA = "myparty/setOnlyMatchingMerchandiseAmountSaga";

function* setProfileNameSaga(action: {
  type: string;
  payload: {
    profile_name: string;
    closeProfileModal?: () => void;
    goPartyInfo?: () => void;
    party_id: number;
    my_member_id: string;
    merchant_id: number;
  };
}) {
  try {
    const response = yield call(
      setMerchantProfileName,
      action.payload.party_id,
      action.payload.my_member_id,
      action.payload.profile_name,
    );
    if (response.status === StatusCodes.OK_200) {
      yield put({
        type: SLOT_SET_SLOT_SAGA,
        payload: { merchant_id: action.payload.merchant_id, goPartyInfo: action.payload.goPartyInfo },
      });
      if (action.payload.closeProfileModal) {
        action.payload.closeProfileModal();
        window.location.reload();
      }
    }
  } catch (e) {
    logError(e);
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.NOT_FOUND_404:
          alert("가입되어 있는 파티가 아닙니다.");
          break;
        case StatusCodes.NOT_ACCEPTABLE_406:
          alert("프로필 이름은 6자 미만으로 해주세요");
          break;
        case StatusCodes.CONFLICT_409:
          alert("똑같은 프로필 닉네임이 존재합니다.\n다른 프로필 닉네임으로 설정해주세요.");
          break;
        default:
          alert("프로필 이름 변경에 실패했어요");
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
  }
}
export const MYPARTY_SET_PROFILE_NAME_SAGA = "myparty/setProfileNameSaga";

function* mypartySaga(): Generator {
  yield takeLatest(MYPARTY_CANCEL_MATCHING_SAGA, cancelMatchingSaga);
  yield takeLatest(MYPARTY_SET_SLOTS_SAGA, setSlotsSaga);
  yield takeLatest(MYPARTY_SET_ONLY_MATCHING_MERCHANDISE_AMOUNT_SAGA, setOnlyMatchingMerchandiseAmountSaga);
  yield takeLatest(MYPARTY_SET_PROFILE_NAME_SAGA, setProfileNameSaga);
}

export default mypartySaga;
