import { call, put, takeLatest } from "redux-saga/effects";
import {
  getParties,
  setMerchantProfileName,
  sendMsg,
  deleteParty,
  getPartyMatchingSpaces,
  setPartyMatchingOn,
  getParty,
} from "apis";
import { PartyData } from "./interface";
import { logError, StatusCodes } from "globals/utils";
import {
  setLoading,
  setParties,
  selectParty,
  setPartyMatching,
  setMatchingLoading,
  setSelectedPartyLoading,
} from "./slice";

interface PartyResData {
  is_special: boolean;
  party_id: number;
  merchant_id: number;
  merchant_name: string;
  img_url: string;
  item_class_id: number;
  item_class_name: string;
  item_name: string;
  sps_key: string;
  sps_value: string;
  sps_pin?: string;
  my_member_id: string;
  my_profile_name: string;
  host: { profile_name: string };
  members: { profile_name: string; member_id: string }[];
  reward: number;
  next_pay_dt: string;
  host_yn: boolean;
}

const service_url = [
  "https://www.netflix.com",
  "https://www.wavve.com",
  "https://www.watcha.com",
  "https://laftel.net",
];
function* setPartiesSaga() {
  yield put(setLoading(true));
  try {
    const response = yield call(getParties);
    const { data } = response.data;
    const payload: PartyData[] = [];
    if (data.length > 0) {
      data.map((party: PartyResData) => {
        const members_id: string[] = [];
        const is_special_and_host = party.is_special && party.host_yn;
        if (is_special_and_host) {
          const profile_names = ["매칭중...", "매칭중...", "매칭중...", "매칭중..."];
          profile_names[0] = party.host.profile_name;
          party.members.map((member: { profile_name: string; member_id: string }, index) => {
            profile_names[index + 1] = member.profile_name;
            members_id.push(member.member_id);
          });
          payload.push({
            party_id: party.party_id,
            item_id: party.merchant_id,
            merchant_name: party.merchant_name,
            img_url: party.img_url,
            item_service_url: service_url[party.merchant_id - 1],
            sps_key: party.sps_key,
            sps_value: party.sps_value,
            sps_pin: party.sps_pin,
            my_member_id: party.my_member_id,
            members_id,
            my_profile_name: party.my_profile_name,
            profile_names,
            reward: party.reward,
            pay_day: Number(party.next_pay_dt.split("-")[2]),
          });
        }
      });
    }
    yield put(setParties(payload));
  } catch (e) {
    logError(e);
  }
  yield put(setLoading(false));
}
export const OLDHOSTPARTY_SET_PARTIES_SAGA = "oldhostparty/setPartiesSaga";

function* setItemProfileNameSaga(action: {
  type: string;
  payload: {
    profile_name: string;
    closeProfileModal: () => void;
    party_id: number;
    my_member_id: string;
    item_short_name: string;
  };
}) {
  try {
    const response = yield call(
      setMerchantProfileName,
      action.payload.party_id,
      action.payload.my_member_id,
      action.payload.profile_name,
    );
    if (response.status === StatusCodes.OK_200) {
      alert(
        `내 프로필 이름 변경이 완료되었어요\n${action.payload.item_short_name} 프로필도 똑같은 이름으로 변경해주세요!`,
      );
      action.payload.closeProfileModal();
      window.location.reload();
    }
  } catch (e) {
    logError(e);
    if (e.response) {
      const { code } = e.response.data;
      switch (code) {
        case "PRT1340":
          alert("똑같은 프로필 닉네임이 존재합니다.\n다른 프로필 닉네임으로 설정해주세요.");
          break;
        case "PRT1341":
          alert("프로필 이름은 6자 미만으로 해주세요");
          break;
        default:
          alert("프로필 이름 변경에 실패했어요");
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
  }
}
export const OLDHOSTPARTY_SET_ITEM_PROFILE_NAME_SAGA = "oldhostparty/setItemProfileNameSaga";

function* sendMsgSaga(action: { type: string; payload: { msg: string; party_id: number; members_id: string[] } }) {
  try {
    const { party_id, msg, members_id } = action.payload;
    let total_cnt = 0;
    for (let i = 0; i < members_id.length; i++) {
      const response = yield call(sendMsg, {
        party_id,
        member_id: members_id[i],
        msg,
        include_phone: false,
      });
      if (response.status === StatusCodes.OK_200) {
        total_cnt += 1;
      }
    }
    alert(`메시지를 ${total_cnt}건 발송했습니다.`);
  } catch (e) {
    if (e.response) {
      alert(`메시지 발송을 실패했습니다\n${e.response.data?.msg}`);
    }
    logError(e);
  }
}
export const OLDHOSTPARTY_SEND_MSG_SAGA = "oldhostparty/sendMsgSaga";

function* deletePartySaga(action: { type: string; payload: { party_id: number; item_short_name: string } }) {
  try {
    if (confirm("정말 파티를 해체하시겠습니까?")) {
      const response = yield call(deleteParty, action.payload.party_id);
      if (response.status === StatusCodes.NO_CONTENT_204) {
        alert(
          `파티를 해체했습니다.\n계정보안과 재결제를 막기위해 ${action.payload.item_short_name} PW를 변경해주세요.`,
        );
        window.location.href = "/myparty/old";
      }
    }
  } catch (e) {
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.NOT_FOUND_404:
          alert("가입되어 있는 파티가 아닙니다.");
          break;
        case StatusCodes.FORBIDDEN_403:
          alert("해당 파티에 대한 권한이 없습니다.");
          break;
        default:
          alert(`파티 해체과정에서 문제가 생겼습니다.\n${e.response.data?.msg}`);
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
  }
}
export const OLDHOSTPARTY_DELETE_PARTY_SAGA = "oldhostparty/deletePartySaga";

function* setPartyMatchingSaga(action: { type: string; payload: { party_id: number } }) {
  yield put(setMatchingLoading(true));
  try {
    const response = yield call(getPartyMatchingSpaces, action.payload.party_id);
    const { data } = response.data;
    if (data.length > 0) yield put(setPartyMatching(true));
    else yield put(setPartyMatching(false));
  } catch (e) {
    logError(e);
  }
  yield put(setMatchingLoading(false));
}
export const OLDHOSTPARTY_SET_PARTY_MATCHING_SAGA = "oldhostparty/setPartyMatchingSaga";

function* setMatchingOnSaga(action: { type: string; payload: { party_id: number } }) {
  try {
    const response = yield call(setPartyMatchingOn, action.payload.party_id);
    if (response.status === StatusCodes.OK_200) yield put(setPartyMatching(true));
  } catch (e) {
    if (e.response) {
      const { status, data } = e.response;
      switch (status) {
        case StatusCodes.FORBIDDEN_403:
          if (data.code === "PRT3013") {
            alert(
              "공유한 계정에 문제가 있어 파티원 매칭이 중지되었습니다.\n문제 해결이 확인되면 자동으로 매칭 활성화가 되니 기다려주세요.",
            );
          } else {
            alert("파티장이 아닙니다.");
          }
          break;
        case StatusCodes.BAD_REQUEST_400:
          alert(e.response.data?.msg);
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
  }
}
export const OLDHOSTPARTY_SET_MATCHING_ON_SAGA = "oldhostparty/setMatchingOnSaga";

function* setPartySaga(action: { type: string; payload: { party_id: number; goBack: () => void } }) {
  yield put(setSelectedPartyLoading(true));
  try {
    const response = yield call(getParty, action.payload.party_id);
    const { data } = response.data;
    if (data) {
      const members_id: string[] = [];
      const is_special_and_host = data.is_special && data.host_yn;
      if (is_special_and_host) {
        const profile_names = ["매칭중...", "매칭중...", "매칭중...", "매칭중..."];
        profile_names[0] = data.host.profile_name;
        data.members.map((member: { profile_name: string; member_id: string }, index: number) => {
          profile_names[index + 1] = member.profile_name;
          members_id.push(member.member_id);
        });
        yield put(
          selectParty({
            party_id: data.party_id,
            item_id: data.merchant_id,
            merchant_name: data.merchant_name,
            img_url: data.img_url,
            item_service_url: service_url[data.merchant_id - 1],
            sps_key: data.sps_key,
            sps_value: data.sps_value,
            sps_pin: data.sps_pin,
            my_member_id: data.my_member_id,
            members_id,
            my_profile_name: data.my_profile_name,
            profile_names,
            reward: data.reward,
            pay_day: Number(data.next_pay_dt.split("-")[2]),
          }),
        );
      }
    }
  } catch (e) {
    logError(e);
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.NOT_FOUND_404:
          alert("가입되어 있는 파티가 아닙니다.");
          break;
        default:
          alert(`에러가 발생했습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.\n${e.response.data?.msg}`);
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    action.payload.goBack();
  }
  yield put(setSelectedPartyLoading(false));
}
export const OLDHOSTPARTY_SET_PARTY_SAGA = "oldhostparty/setPartySaga";

function* oldhostpartySaga(): Generator {
  yield takeLatest(OLDHOSTPARTY_SET_PARTIES_SAGA, setPartiesSaga);
  yield takeLatest(OLDHOSTPARTY_SET_ITEM_PROFILE_NAME_SAGA, setItemProfileNameSaga);
  yield takeLatest(OLDHOSTPARTY_SEND_MSG_SAGA, sendMsgSaga);
  yield takeLatest(OLDHOSTPARTY_DELETE_PARTY_SAGA, deletePartySaga);
  yield takeLatest(OLDHOSTPARTY_SET_PARTY_MATCHING_SAGA, setPartyMatchingSaga);
  yield takeLatest(OLDHOSTPARTY_SET_MATCHING_ON_SAGA, setMatchingOnSaga);
  yield takeLatest(OLDHOSTPARTY_SET_PARTY_SAGA, setPartySaga);
}

export default oldhostpartySaga;
