import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import circularProgressMotion from "./assets/progressLoading.json";

const CircularLoading = (): JSX.Element => (
  <Container>
    <Lottie
      width="36px"
      height="auto"
      isClickToPauseDisabled={false}
      options={{
        animationData: circularProgressMotion,
        loop: true,
        autoplay: true,
      }}
    />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default CircularLoading;
