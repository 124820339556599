import React from "react";
import { closeModal, ModalState, openModal } from "containers/Modal";
import { KAKAO_JS_KEY } from "globals/setting";
import { ModalType } from "components/Modal";
import { ReactComponent as KakaoButton } from "./assets/button_kakao.svg";
import { Store } from "index";
import { RouterHistory } from "@sentry/react/dist/reactrouter";
import styled from "styled-components";
import Space from "components/Space";
import { OldSmall2 } from "components/Text";

//TODO: 정적파일정리 & 이런 공용함수들 어떻게 정리할지 논의
const openLoginModal = ({ history }: { history: RouterHistory }): void => {
  const dispatch = Store.dispatch;
  const openModalDispatch = (modal_state: ModalState) => dispatch(openModal(modal_state));
  const closeModalDispatch = () => dispatch(closeModal());
  const redirect_url = window.location.pathname + window.location.search;

  const goKakaoLogin = () => {
    if (!window.Kakao) {
      const head = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.src = "https://developers.kakao.com/sdk/js/kakao.min.js";
      head.appendChild(script);

      script.onload = () => {
        !!KAKAO_JS_KEY && window.Kakao.init(KAKAO_JS_KEY);
        window.Kakao.Auth.authorize({
          redirectUri: `${window.location.origin}/login/kakao`,
          state: JSON.stringify({
            redirect_url: encodeURIComponent(redirect_url || "/"),
          }),
        });
      };
      return;
    }

    if (!window.Kakao.isInitialized() && KAKAO_JS_KEY) window.Kakao.init(KAKAO_JS_KEY);
    window.Kakao.Auth.authorize({
      redirectUri: `${window.location.origin}/login/kakao`,
      state: JSON.stringify({
        redirect_url: encodeURIComponent(redirect_url || "/"),
      }),
    });
  };
  const goSelectLogin = () => {
    history.push(`/login/select?redirect_url=${encodeURIComponent(redirect_url || "/")}`);
    closeModalDispatch();
  };
  openModalDispatch({
    title: "로그인/회원가입",
    type: ModalType.LOGIN,
    is_dimmer: true,
    is_dimmer_close: true,
    element: (
      <>
        <Space height={32} />
        <KakaoButtonWrpper onClick={goKakaoLogin}>
          <KakaoButton title="kakao button" />
        </KakaoButtonWrpper>
        <Space height={18} />
        <LinkText data-testid="phone_login" onClick={goSelectLogin}>
          전화번호로 가입했었나요?
        </LinkText>
        <Space height={6} />
      </>
    ),
  });
  return;
};

const KakaoButtonWrpper = styled.div`
  width: 280px;
  height: 48px;
  cursor: pointer;
`;

const LinkText = styled(OldSmall2)`
  text-decoration-line: underline;
  color: var(--gray-600);
  cursor: pointer;
  font-weight: normal;
  text-align: center;
`;

export default openLoginModal;
