import React from "react";
import styled from "styled-components";
import { IconProps } from "./interface";
import { ReactComponent as XLine } from "./src/icon_x_line.svg";

export const IconX = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <XLine title="icon math x line" />
  </IconWrapper>
);
const IconWrapper = styled.div<IconProps>`
  width: ${(props) => props.size || 24}px;
  height: ${(props) => props.size || 24}px;
`;
