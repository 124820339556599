import React from "react";
import styled from "styled-components";
import { IconProps } from "./interface";
import { ReactComponent as ArrowLeft } from "./src/icon_arrow_left.svg";

export const IconArrowLeft = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <ArrowLeft title="icon arrow left" />
  </IconWrapper>
);
const IconWrapper = styled.div<IconProps>`
  width: ${(props) => props.size || 24}px;
  height: ${(props) => props.size || 24}px;
`;
