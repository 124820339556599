import React from "react";
import { H2, Body2 } from "components/Text";
import styled from "styled-components";
import Space from "components/Space";
import { ReactComponent as IconCheck } from "./assets/icon_check.svg";
import { useScrollFadeIn } from "globals/utils";
import { img_partners } from "./assets";

const Section6 = (): JSX.Element => (
  <Container>
    <Space height={60} />
    <Text {...useScrollFadeIn(1, 0.3)}>
      피클플러스를
      <br />
      신뢰할 수 있는 이유!
    </Text>
    <Space height={20} />
    <CheckBox {...useScrollFadeIn(1, 0.5)}>
      <IconCheck title="icon_check" />
      <InfoText>
        8만명 이상의 고객이 피클플러스를
        <br />
        통해 OTT를 이용하고 있어요.
      </InfoText>
    </CheckBox>
    <Space height={16} />
    <CheckBox {...useScrollFadeIn(1, 0.7)}>
      <IconCheck title="icon_check" />
      <InfoText>
        훌륭한 파트너들과 투자, 제휴,
        <br />
        지원 관계를 맺고 있어요.
      </InfoText>
    </CheckBox>
    <Space height={40} />
    <Image src={img_partners} />
    <Space height={70} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow: hidden;
  align-items: center;
`;

const Text = styled(H2)`
  text-align: center;
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--gray-050);
  border-radius: 20px;
  height: 82px;
  width: 311px;
  padding: 21px 0 21px 30px;
`;

const InfoText = styled(Body2)`
  color: var(--gray-700);
  margin-left: 20px;
`;

const Image = styled.img`
  height: auto;
  width: 303px;
`;

export default Section6;
