import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MembershipState, MembershipData, SavedAmountData } from "./interface";

const initialState: MembershipState = {
  is_loading: true,
  is_amount_loading: true,
};

const membershipSlice = createSlice({
  name: "membership",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.is_loading = action.payload;
    },
    setMembership(state, action: PayloadAction<MembershipData>) {
      state.membership_data = action.payload;
    },
    setSavedAmount(state, action: PayloadAction<SavedAmountData>) {
      state.saved_amount_data = action.payload;
    },
    setAmountLoading(state, action: PayloadAction<boolean>) {
      state.is_amount_loading = action.payload;
    },
  },
});

export const { setMembership, setLoading, setSavedAmount, setAmountLoading } = membershipSlice.actions;
export default membershipSlice.reducer;
