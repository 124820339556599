import { getMerchandises } from "apis";
import { logError } from "globals/utils";
import { call, put, takeLatest } from "redux-saga/effects";
import { MerchandiseData } from "./interface";
import { setLoading, setMerchandises } from "globals/merchandise";

function* setMerchandisesSaga() {
  yield put(setLoading(true));
  try {
    const response = yield call(getMerchandises);
    if (response.status !== 200) throw new Error("상품정보 불러오기 실패");
    const { data } = response.data;
    const payload: MerchandiseData[] = data;
    payload[payload.length - 1].is_max = true;
    yield put(setMerchandises(payload));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoading(false));
  }
}
export const MERCHANDISE_SET_MERCHANDISES_SAGA = "merchandise/setMerchandisesSaga";

function* merchandiseSaga(): Generator {
  yield takeLatest(MERCHANDISE_SET_MERCHANDISES_SAGA, setMerchandisesSaga);
}

export default merchandiseSaga;
