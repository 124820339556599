import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PageNavigation } from "containers/TopNavigation";
import qs from "qs";
import Space from "components/Space";
import styled from "styled-components";
import { Body2, Caption1, H3 } from "components/Text";
import InputWrap from "components/Input/InputWrap";
import Tooltip from "components/Tooltip";
import { EmojiLink } from "assets/emojis";
import { IconCheckCircleFillPrimary, IconCheckCircleLineGray } from "assets/icons";
import { ButtonGroupCTA } from "components/Button";
import { useRootState } from "globals/utils";
import { InputState } from "components/Input";
import { MANAGEMERCHANT_SEND_MSG_SAGA } from "containers/ManageMerchant";
import { useHistory } from "react-router-dom";
import { cleanupSlot, SLOT_SET_SLOT_SAGA } from "containers/Slot";
import { BlurLoading } from "components/Loading";

const SendMessage = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [message, setMessage] = useState<string>("");
  const [input_state, setInputState] = useState<InputState>("DEFAULT");
  const [is_include_phone, setIncludePhone] = useState<boolean>(false);

  const merchant_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_id,
  );
  const slot = useRootState((state) => state.slot.slot);
  const is_slot_loading = useRootState((state) => state.slot.is_loading);
  const input_description = { text: "욕설, 비방, 명예훼손 등의 언행은 지양해주세요" };
  const max_length = 600;
  const disabled_condition = message.length === 0 || message.length > max_length;

  const goMyParty = () => history.push("/myparty");
  const goMyPartyInfo = () => history.push(`/myparty/info?merchant_id=${merchant_id}`);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.currentTarget;
      setMessage(value);

      if (value.length > max_length) setInputState("ERROR");
      else if (value.length > 0) setInputState("ACTIVE");
      else setInputState("DEFAULT");
    },
    [message, input_state],
  );
  const sendMsgSagaDispatch = ({
    msg,
    is_host,
    include_phone,
  }: {
    msg: string;
    is_host: boolean;
    include_phone: boolean;
  }) => {
    const host_id = slot?.host?.member_id;
    const party_id = slot?.party_id;
    const members_id: string[] = [];
    slot?.members?.map((member) => members_id.push(member.member_id));
    dispatch({
      type: MANAGEMERCHANT_SEND_MSG_SAGA,
      payload: {
        msg,
        is_host,
        party_id,
        host_id,
        members_id,
        include_phone,
        goNext: goMyPartyInfo,
      },
    });
  };
  const sendMessage = () => {
    sendMsgSagaDispatch({
      msg: message,
      is_host: Boolean(slot?.host_yn),
      include_phone: is_include_phone,
    });
    setMessage("");
  };
  const setSlotSagaDispatch = (merchant_id: number) =>
    dispatch({ type: SLOT_SET_SLOT_SAGA, payload: { merchant_id, goMyParty } });
  const cleanupSlotDispatch = () => dispatch(cleanupSlot());
  useEffect(() => {
    setSlotSagaDispatch(merchant_id);
    return () => {
      cleanupSlotDispatch();
    };
  }, []);
  return (
    <>
      <PageNavigation close_path={`/myparty/info?merchant_id=${merchant_id}`} is_have_divider={false} />
      {!is_slot_loading ? (
        <Container>
          <Space height={12} />
          <H3>
            {slot?.host_yn ? "파티원" : "파티장"}에게 보내고 싶은 문자를 <br /> 빠르게 전달해드릴게요
          </H3>
          <Space height={10} />
          <Caption1 color="var(--gray-600)">같은 파티인만큼 따뜻한 매너를 보여주세요 :)</Caption1>
          <Space height={40} />
          <InputWrap
            state={input_state}
            placeholder="문자 내용을 입력해주세요 (최대 600자)"
            description={input_description}
            maxLength={max_length}
            onChange={onChange}
          >
            {message}
          </InputWrap>
          <Space height={231} />
          <CenterWrapper>
            <Tooltip>
              <Row>
                <EmojiLink size={20} />
                <TooltipText font_weight="bold">전화번호 포함하여 보낼 시, 답장 받을 수 있어요</TooltipText>
              </Row>
            </Tooltip>
            <Space height={12} />
            <Row onClick={() => setIncludePhone(!is_include_phone)}>
              {is_include_phone ? <IconCheckCircleFillPrimary /> : <IconCheckCircleLineGray />}
              <CheckText>전화번호를 포함해서 보낼게요</CheckText>
            </Row>
            <Space height={8} />
            <ButtonGroupCTA
              size="large"
              main_button={{
                text: "입력 완료",
                onClick: () => (!disabled_condition ? sendMessage() : null),
                is_disabled: disabled_condition,
              }}
            />
          </CenterWrapper>
        </Container>
      ) : (
        <BlurLoading />
      )}
    </>
  );
};

const Container = styled.div`
  padding: 0 24px;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  :nth-child(3) {
    cursor: pointer;
  }
`;

const TooltipText = styled(Caption1)`
  margin-left: 4px;
  font-weight: bold;
`;
const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: calc(50% - 145px);
`;
const CheckText = styled(Body2)`
  margin-left: 10px;
  color: var(--gray-700);
`;

export default SendMessage;
