import { ThemeLegacy } from "components/Button";
export enum ModalType {
  NORMAL,
  SIMPLE,
  ENLARGED,
  STACKED,
  LOGIN,
}

export interface ModalButtonProps {
  theme?: ThemeLegacy;
  image_src?: string;
  text: string;
  onClick?: () => void;
  is_close_button?: boolean;
  disabled?: boolean;
}

export interface ModalProps {
  z_index?: number;
  is_dimmer?: boolean;
  title?: string | JSX.Element;
  content?: string | JSX.Element;
  element?: JSX.Element;
  type?: ModalType;
  buttons?: ModalButtonProps[];
  is_dimmer_close?: boolean;
  closeModal?: () => void;
}
