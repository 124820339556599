import styled from "styled-components";

interface TextProps {
  font_weight?: string;
  color?: string;
}

export const DesktopD1 = styled.h1<TextProps>`
  display: block;
  font-size: 46px;
  line-height: 60px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "bold"};
  color: ${(props) => props.color};
`;

export const DesktopH1 = styled.h1<TextProps>`
  display: block;
  font-size: 32px;
  line-height: 42px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "bold"};
  color: ${(props) => props.color};
`;

export const DesktopH2 = styled.h2<TextProps>`
  display: block;
  font-size: 26px;
  line-height: 34px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "bold"};
  color: ${(props) => props.color};
`;

export const DesktopSubtitle1 = styled.div<TextProps>`
  display: block;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "bold"};
  color: ${(props) => props.color};
`;

export const DesktopSubtitle2 = styled.div<TextProps>`
  display: block;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "bold"};
  color: ${(props) => props.color};
`;

export const DesktopBody1 = styled.div<TextProps>`
  display: block;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "normal"};
  color: ${(props) => props.color};
`;

export const DesktopBody2 = styled.div<TextProps>`
  display: block;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "normal"};
  color: ${(props) => props.color};
`;

export const DesktopBody3 = styled.div<TextProps>`
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  font-weight: ${(props) => props.font_weight || "normal"};
  color: ${(props) => props.color};
`;
