import React from "react";
import styled from "styled-components";
import { DesktopSubtitle2, OldSub } from "components/Text";

const TopNavLoginButton = ({ onClick }: { onClick?: () => void }): JSX.Element => (
  <LoginText data-testid="login_text" onClick={onClick}>
    로그인/회원가입
  </LoginText>
);

export const DesktopTopNavLoginButton = ({ onClick }: { onClick?: () => void }): JSX.Element => (
  <Row>
    <Tab data-testid="login_text" onClick={onClick}>
      로그인
    </Tab>
    <Line />
    <Tab onClick={onClick}>회원가입</Tab>
  </Row>
);

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Line = styled.div`
  width: 1px;
  height: 24px;
  background-color: #c4c4c4;
  margin: 0 8px;
`;

const LoginText = styled(OldSub)`
  color: var(--gray-600);
  font-weight: bold;
  cursor: pointer;
`;

const Tab = styled(DesktopSubtitle2)`
  padding: 12px;
  color: var(--gray-600);
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    background-color: var(--gray-100);
  }
`;

export default TopNavLoginButton;
