import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setProgressBar, cleanupProgressBar } from "containers/ProgressBar";
import { useRootState } from "globals/utils";
import { BlurLoading } from "components/Loading";
import { MYPAGE_CREATE_BANK_ACCOUNT_SAGA } from "./saga";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const AddAccountInput = lazy(() =>
  import("components/MyPage").then(({ AddAccountInput }) => ({ default: AddAccountInput })),
);
const AddAccountInputContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selected_bank = useRootState((state) => state.mypage.add_account.selected_bank);

  const setProgressBarDisptach = (percent: number) => dispatch(setProgressBar(percent));
  const cleanupProgressBarDispatch = () => dispatch(cleanupProgressBar());
  const createBankAccountSagaDispatch = (payload: {
    account_num: string;
    account_bank: string;
    goManageAccount: () => void;
  }) => dispatch({ type: MYPAGE_CREATE_BANK_ACCOUNT_SAGA, payload });
  const addAccount = ({ account_num, account_bank }: { account_num: string; account_bank: string }) => {
    createBankAccountSagaDispatch({
      account_num,
      account_bank,
      goManageAccount: () => {
        history.push("/mypage/manageaccount");
      },
    });
  };
  useEffect(() => {
    setProgressBarDisptach(100);
    return () => {
      cleanupProgressBarDispatch();
    };
  }, []);
  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation back_path="/mypage/addaccount/init" title="계좌 등록하기" is_loading={!selected_bank} />
      {!!selected_bank && <AddAccountInput selected_bank={selected_bank} addAccount={addAccount} />}
    </Suspense>
  );
};

export default AddAccountInputContainer;
