import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MerchandiseData, MerchandiseState } from "./interface";

const initialState: MerchandiseState = {
  is_loading: true,
};

const merchandiseSlice = createSlice({
  name: "merchandise",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.is_loading = action.payload;
    },
    setMerchandises(state, action: PayloadAction<MerchandiseData[]>) {
      state.merchandises = action.payload;
    },
  },
});

export const { setMerchandises, setLoading } = merchandiseSlice.actions;
export default merchandiseSlice.reducer;
