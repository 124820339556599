import React from "react";
import Toast from "components/Toast";
import { useRootState } from "globals/utils";

const ToastContainer = (): JSX.Element | null => {
  const content = useRootState((state) => state.toast.content);
  const is_open = useRootState((state) => state.toast.is_open);
  return is_open ? <Toast content={content} z_index={40} is_active={is_open} /> : null;
};

export default ToastContainer;
