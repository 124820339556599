import { OldSub } from "components/Text";
import React from "react";
import styled from "styled-components";

import { ReactComponent as IconDollarBlue } from "./assets/icon_dollar_blue.svg";
import { ReactComponent as IconInfoBlue } from "./assets/icon_info_blue.svg";

const BlueBox = ({ icon, children }: { icon: "price" | "info"; children: React.ReactNode }): JSX.Element => (
  <BlueBoxContainer is_tight={icon === "info"}>
    {icon === "price" && (
      <IconWrapper>
        <IconDollarBlue title="icon_dollar_blue" />
      </IconWrapper>
    )}
    {icon === "info" && (
      <IconWrapper>
        <IconInfoBlue title="icon_info_blue" />
      </IconWrapper>
    )}
    <InfoText>{children}</InfoText>
  </BlueBoxContainer>
);

const BlueBoxContainer = styled.div<{ is_tight: boolean }>`
  display: flex;
  justify-content: center;
  padding: ${({ is_tight }) => (is_tight ? "13px 7px 9px 40px" : "13px 12px 9px 48px")};
  position: relative;
  border-radius: 8px;
  background-color: var(--blue-100);
`;
const InfoText = styled(OldSub)`
  color: var(--blue-700);
  text-align: center;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 19px;
  left: 12px;
`;

export default BlueBox;
