import React from "react";
import styled from "styled-components";
import { ReactComponent as NoticeRed } from "./src/icon_notice_red.svg";
import { ReactComponent as NoticeGray } from "./src/icon_notice_gray.svg";
import { ReactComponent as NoticePrimary } from "./src/icon_notice_primary.svg";
import { ReactComponent as Info } from "./src/icon_info.svg";
import { ReactComponent as Delete } from "./src/icon_delete.svg";
import { IconProps } from "./interface";

export const IconNoticeRed = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <NoticeRed title="icon notice red" />
  </IconWrapper>
);
export const IconNoticeGray = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <NoticeGray title="icon notice gray" />
  </IconWrapper>
);
export const IconNoticePrimary = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <NoticePrimary title="icon notice primary" />
  </IconWrapper>
);
export const IconInfo = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <Info title="icon info" />
  </IconWrapper>
);
export const IconDelete = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <Delete title="icon delete" />
  </IconWrapper>
);

const IconWrapper = styled.div<IconProps>`
  width: ${(props) => props.size || 24}px;
  height: ${(props) => props.size || 24}px;
`;
