import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { Store } from "./root.redux";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { SENTRY_DSN } from "globals/setting";

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Integrations.InboundFilters({
      ignoreErrors: [
        /Non-Error promise rejection captured with value: Object Not Found Matching Id:7/i,
        /\(evaluating 'Object\.prototype\.hasOwnProperty\.call(d\._iq,a)'\)$/i,
        /memcovercolor/i,
        /\(evaluating 'document\.getElementsByTagName('video')\[0\]\.webkitExitFullScreen'\)$/,
        /missing \) after argument list/i,
        /sess/i,
        /^window\.naverAppDic\.checkDomStatus/i,
        /\(evaluating 'document\.querySelector\('meta\[name="twitter:description"\]'\)\.content'\)$/i,
        /^Failed to read the 'sessionStorage' property from 'Window'/i,
        /can't access dead object/i,
        /Cannot resolve iframe with unknown index "0"$/i,
        /Cannot read property 'checkDomStatus' of undefined/i,
        /The operation is insecure/i,
        /_avast_submit/i,
      ],
      denyUrls: [
        /(.*)\.mouseflow\.com/,
        /(.*)\.channel\.io/,
        /(.*)\.googletagmanager\.com/,
        /(.*)\.google-analytics\.com/,
        /^chrome-extension:\//i,
        /^safari-extension:\//i,
      ],
    }),
  ],
  environment: process.env.NODE_ENV || "development",
  release: `${process.env.REACT_APP_RELEASE}`,
});

ReactDOM.render(
  <Provider store={Store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root"),
);

export { Store };

reportWebVitals(console.info);
