import { AxiosResponse } from "axios";
import { OLD_DJANGO_URL } from "globals/setting";
import { AuthAxios, Axios } from "./default";

export const getMerchandises = async (): Promise<AxiosResponse> => {
  const response = await Axios({
    url: `${OLD_DJANGO_URL}/membership/merchandises`,
    method: "GET",
  });
  return response;
};

export const getDifferenceAmount = async (merchandise_id: number): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/membership/merchandises/${merchandise_id}/compare`,
    method: "GET",
  });
  return response;
};
