import React from "react";
import { ButtonPropsLegacy, ThemePropsLegacy } from "./interfaceLegacy";
import styled, { css } from "styled-components";

const theme_color: { [key: string]: ThemePropsLegacy } = {
  "primary-fill": {
    background_color: "--primary-500",
    color: "--white",
    hover: "--primary-600",
  },
  "gray-fill": {
    background_color: "--gray-700",
    color: "--white",
    hover: "--gray-800",
  },
  "red-fill": {
    background_color: "--red-500",
    color: "--white",
    hover: "--red-600",
  },
  "primary-weak": {
    background_color: "--primary-100",
    color: "--primary-700",
    hover: "--primary-200",
  },
  "gray-weak": {
    background_color: "--gray-100",
    color: "--gray-900",
    hover: "--gray-200",
  },
  "red-weak": {
    background_color: "--red-100",
    color: "--red-500",
    hover: "--red-200",
  },
  "primary-ghost": {
    background_color: "--white",
    color: "--primary-700",
    hover: "--primary-100",
  },
  "gray-ghost": {
    background_color: "--white",
    color: "--gray-900",
    hover: "--gray-100",
  },
  "red-ghost": {
    background_color: "--white",
    color: "--red-500",
    hover: "--red-100",
  },
  "primary-outline": {
    background_color: "--white",
    color: "--primary-500",
    hover: "--primary-100",
  },
};

const ButtonLegacy = (props: ButtonPropsLegacy): JSX.Element => {
  const button_theme = props.theme ? props.theme : "primary-fill";
  const { background_color, color, hover } = theme_color[button_theme];
  return (
    <OriginButton background_color={background_color} color={color} hover={hover} {...props}>
      {props.children}
    </OriginButton>
  );
};
const OriginButton = styled.div<ButtonPropsLegacy>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height || 52}px;
  width: 100%;
  border: ${(props) => (props.theme === "primary-outline" ? "solid 1px var(--primary-500)" : 0)};
  border-radius: ${(props) => props.radius || 8}px;
  cursor: pointer;
  background-color: var(${(props) => props.background_color || "--primary-500"});
  color: var(${(props) => props.color || "--white"});
  padding-top: ${(props) => props.padding_top || "4px"};
  font-weight: ${(props) => props.font_weight || "normal"};
  font-size: ${(props) => props.font_size || 16}px;
  line-height: ${(props) => props.line_height || 24}px;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  &:hover {
    background-color: var(${(props) => props.hover || "--primary-600"});
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: var(--gray-100);
      color: var(--gray-300);
      &:hover {
        background-color: var(--gray-100);
      }
    `}
`;

export default ButtonLegacy;
