interface MediaMinSize {
  [key: string]: number;
}

export const media_min_size: MediaMinSize = {
  desktop: 640,
};

const customMediaQuery = (minWidth: number): string => `@media (min-width: ${minWidth}px)`;

const media = {
  custom: customMediaQuery,
  desktop: customMediaQuery(media_min_size.desktop),
};

export default media;
