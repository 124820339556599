import React from "react";
import styled from "styled-components";
import InputWithLabel from "components/Input/InputWithLabel";
import { OldDivider } from "components/Divider";
import { ButtonPropsLegacy } from "components/Button";
import img_member_pickle from "./assets/img_member_pickle.png";
import img_empty_pickle from "./assets/img_empty_pickle.png";
import img_matching_pickle from "./assets/img_matching_pickle.png";
import icon_info from "./assets/icon_info.png";
import { ModalState } from "containers/Modal";
import { OldSub, OldSmall2 } from "components/Text";

interface ProfilesProps {
  openChangeProfileNameModal: () => void;
  my_profile_name: string;
  profile_names: string[];
  item_short_name: string;
  reward: number;
  pay_day: number;
  is_matching: boolean;
  openMatchingModal: (content: ModalState) => void;
  matching_modal: ModalState;
  member_cnt: number;
}

const PartyProfiles = ({
  openChangeProfileNameModal,
  my_profile_name,
  profile_names,
  item_short_name,
  reward,
  pay_day,
  is_matching,
  openMatchingModal,
  member_cnt,
  matching_modal,
}: ProfilesProps): JSX.Element => {
  const profile_button_props: ButtonPropsLegacy = {
    theme: "gray-weak",
    width: 100,
    height: 40,
    children: "변경",
    onClick: () => openChangeProfileNameModal(),
  };

  return (
    <>
      <InputContainer>
        <InputWithLabel
          button_props={profile_button_props}
          value={my_profile_name}
          label="내 프로필 이름"
          is_complete
          is_have_button
          readonly
        />
      </InputContainer>
      <ProfileContainer>
        <ProfileLabel>{item_short_name} 프로필 구성</ProfileLabel>
        <ProfileRow>
          {profile_names.map((element, index) => (
            <PickleColumn key={`${element}_${index}_profile`}>
              <PickleIcon
                src={element !== "매칭중..." ? img_member_pickle : is_matching ? img_matching_pickle : img_empty_pickle}
              />
              <PickleName>
                {element === "매칭중..." && !is_matching ? "(빈자리)" : element || "(닉네임 미정)"}
              </PickleName>
            </PickleColumn>
          ))}
        </ProfileRow>
        <OldDivider margin_top="0px" margin_bottom="0px" height="1px" background_color="var(--gray-700)" />
      </ProfileContainer>
      <Tab>
        <InfoIcon />
        <PriceInfoText>
          {is_matching ? (
            `매월 ${pay_day}일에 ${(reward * 0.75).toLocaleString()}원이 이체돼요`
          ) : (
            <>
              {member_cnt}명에게 공유중이므로
              <br />
              매월 {pay_day}일에 {((reward * member_cnt) / 4).toLocaleString()}원 이체됩니다
            </>
          )}
        </PriceInfoText>
        {!is_matching && (
          <MatchingOnButton onClick={() => openMatchingModal(matching_modal)}>
            <ButtonText>매칭 활성화</ButtonText>
          </MatchingOnButton>
        )}
      </Tab>
    </>
  );
};

const InputContainer = styled.div`
  padding: 0 24px 32px 24px;
`;

const ProfileContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;
const ProfileLabel = styled(OldSub)`
  color: var(--gray-700);
`;

const ProfileRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 116px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const PickleColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PickleIcon = styled.img`
  width: 64px;
  height: 64px;
`;

const PickleName = styled(OldSub)`
  color: var(--gray-900);
  margin-top: 8px;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  height: 81px;
  padding: 0 24px 0 24px;
  margin-bottom: 33px;
  align-items: center;
`;

const InfoIcon = styled.img.attrs({
  src: icon_info,
  alt: "icon_info",
})`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const MatchingOnButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 88px;
  height: 28px;
  top: 26px;
  right: 24px;
  background-color: var(--gray-100);
  border-radius: 4px;
  cursor: pointer;
`;

const ButtonText = styled(OldSmall2)`
  color: var(--gray-900);
  padding-top: 2px;
`;

const PriceInfoText = styled(OldSub)`
  color: var(--gray-400);
`;

export default PartyProfiles;
