import { media } from "globals/style";
import styled from "styled-components";

const Divider = styled.div`
  width: 100vw;
  height: 20px;
  background-color: var(--gray-050);
  ${media.desktop} {
    display: none;
  }
`;

export const OldDivider = styled.div<{
  margin_top?: string;
  margin_bottom?: string;
  height?: string;
  background_color?: string;
  width?: string;
}>`
  width: ${({ width }) => width || "100%"};
  height: ${(props) => props.height || "12px"};
  background-color: ${(props) => props.background_color || "var(--gray-100)"};
  margin-top: ${(props) => props.margin_top || "12px"};
  margin-bottom: ${(props) => props.margin_bottom || "12px"};
  border-radius: 8px;
`;

export default Divider;
