import { RestrictRoute, useRootState, Route } from "globals/utils";
import React from "react";
import { Redirect, Switch } from "react-router-dom";
import Certification from "./Certification";
import PasswordInput from "./PasswordInput";

const SetPasswordRoute = (): JSX.Element => {
  const imp_uid = useRootState((state) => state.setpassword.imp_uid);

  return (
    <Switch>
      <Route path="/password/certification" component={Certification} />
      <RestrictRoute
        is_pass={!!imp_uid}
        redirect_url="/password/certification"
        path="/password/input"
        component={PasswordInput}
      />
      <Redirect to="/password/certification" />
    </Switch>
  );
};

export default SetPasswordRoute;
