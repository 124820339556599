import Modal from "components/Modal";
import { useRootState } from "globals/utils";
import React, { Suspense } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "./slice";

const ModalContainer = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const modal = useRootState((state) => state.modal);
  const is_open = useRootState((state) => state.modal.is_open);
  const closeModalDispatch = () => dispatch(closeModal());
  return is_open ? (
    <Suspense fallback={<></>}>
      <Modal {...modal} closeModal={closeModalDispatch} />
    </Suspense>
  ) : null;
};

export default ModalContainer;
