import React, { lazy, Suspense } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { BlurLoading } from "components/Loading";
import { useRootState } from "globals/utils";
import { OldH5 } from "components/Text";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const PartyTab = lazy(() => import("components/OldHostParty").then(({ PartyTab }) => ({ default: PartyTab })));
const Space = lazy(() => import("components/Space"));

const Parties = (): JSX.Element => {
  const history = useHistory();
  const parties = useRootState((state) => state.oldhostparty.parties);
  const is_loading = useRootState((state) => state.oldhostparty.is_loading);
  const goCheckParty = (party_id: number) => {
    history.push(`/myparty/old/party?party_id=${party_id}`);
  };
  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation back_path="/myparty" title="파티 관리 (파티장)" is_loading={is_loading} />
      {!is_loading && parties && (
        <>
          <Title>
            (구)피클플러스
            <br />
            파티장이셨나요?
          </Title>
          <SubTitle>파티장으로 생성했던 파티를 관리할 수 있어요.</SubTitle>
          {parties.map((party) => (
            <PartyTab
              key={`${party.party_id}_tab`}
              party_id={party.party_id}
              item_img_url={party.img_url}
              item_short_name={party.merchant_name}
              goCheckParty={goCheckParty}
            />
          ))}
          <Space height={32} />
        </>
      )}
    </Suspense>
  );
};

const Title = styled(OldH5)`
  color: var(--gray-900);
  padding: 64px 24px 4px 24px;
`;
const SubTitle = styled.div`
  color: var(--gray-700);
  padding: 0 24px 20px 24px;
`;

export default Parties;
