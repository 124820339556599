import React from "react";
import styled, { keyframes } from "styled-components";
import {
  img_content1,
  img_content2,
  img_content3,
  img_content4,
  img_content5,
  img_content6,
  img_content7,
  img_content8,
} from "../assets";

const contentList = [
  img_content8,
  img_content1,
  img_content2,
  img_content3,
  img_content7,
  img_content4,
  img_content5,
  img_content6,
  img_content8,
  img_content1,
  img_content2,
];

const ContentsSlide = (): JSX.Element => (
  <Container>
    <ContentWrapper>
      {contentList.map((content, index) => (
        <ContentImg key={index} src={content} />
      ))}
    </ContentWrapper>
  </Container>
);

const Container = styled.div`
  width: 100%;
  padding: 21px 0;
  overflow: hidden;
  position: relative;
`;

const Scroll = keyframes`
  0% { transform: translateX(-230px); }
	100% { transform: translateX(-2470px); }
`;

const ContentWrapper = styled.div`
  display: flex;
  animation: ${Scroll} 25s linear infinite;
`;

const ContentImg = styled.img`
  width: 260px;
  height: 146px;
  margin: 0 10px;
`;

export default ContentsSlide;
