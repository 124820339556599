import { OldSub } from "components/Text";
import React from "react";
import styled from "styled-components";
import icon_arrow from "./assets/icon_arrow.png";
import icon_host from "./assets/icon_host.png";

interface TabProps {
  party_id: number;
  item_img_url: string;
  item_short_name: string;
  goCheckParty: (party_id: number) => void;
}
const PartyTab = ({ party_id, item_img_url, item_short_name, goCheckParty }: TabProps): JSX.Element => (
  <Tab onClick={() => goCheckParty(party_id)}>
    <Circle>
      <ItemIcon src={item_img_url} />
      <HostIcon />
    </Circle>
    <TextColumn>
      <MainText>(구){item_short_name} 파티</MainText>
      <SubText>파티장으로 이용 중</SubText>
    </TextColumn>
    <Arrow />
  </Tab>
);

export const Tab = styled.div`
  display: flex;
  flex-direction: row;
  padding: 22px 24px 17px 20px;
  height: 87px;
  position: relative;
  cursor: pointer;
  align-items: center;
`;

export const Circle = styled.div`
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--gray-100);
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ItemIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const MainText = styled.div`
  font-weight: bold;
  color: var(--gray-900);
`;

export const SubText = styled(OldSub)`
  margin-top: 4px;
  color: var(--gray-500);
`;

export const Arrow = styled.img.attrs({
  src: icon_arrow,
  alt: "icon_arrow",
})`
  position: absolute;
  right: 20px;
  width: 24px;
  height: 24px;
`;

export const HostIcon = styled.img.attrs({
  src: icon_host,
  alt: "icon_host",
})`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  right: 0;
`;

export default PartyTab;
