import React from "react";
import styled from "styled-components";
import { WAVVE_MERCHANT_ID_HARD, WATCHA_MERCHANT_ID_HARD, LAFTEL_MERCHANT_ID_HARD } from "globals/variables";
import { List } from "components/Contents";
import { ChangeColor } from "components/Text";

interface ProfileUsingInfoBoxProps {
  merchant_id: number;
  merchant_name: string;
  profile_name: string;
}
const ProfileUsingInfoBox = ({ merchant_id, merchant_name, profile_name }: ProfileUsingInfoBoxProps): JSX.Element =>
  merchant_id === WATCHA_MERCHANT_ID_HARD || merchant_id === LAFTEL_MERCHANT_ID_HARD ? (
    <GrayBox>
      <List
        list_space={16}
        list={[
          <ChangeColor color="var(--gray-700)" key="list_0">
            {merchant_name} 로그인 후 <strong>&apos;{profile_name}&apos;</strong> 닉네임의 프로필을 생성해주세요
          </ChangeColor>,
          <ChangeColor color="var(--gray-700)" key="list_1">
            프로필 수가 많아 추가가 불가능 하다면 &apos;빈자리&apos; 라고 되어 있는 프로필을 삭제하거나 현재 파티
            구성원들 중 <strong>아무도 사용하지 않고 있는 프로필</strong>을 삭제해주세요
          </ChangeColor>,
        ]}
      />
    </GrayBox>
  ) : merchant_id === WAVVE_MERCHANT_ID_HARD ? (
    <GrayBox>
      <List
        list_space={16}
        list={[
          <ChangeColor color="var(--gray-700)" key="list_0">
            {merchant_name} 로그인 후 <strong>&apos;빈자리&apos;</strong>라고 되어있는 프로필의 닉네임을{" "}
            <strong>&apos;{profile_name}&apos;</strong>로 변경해 사용해 주세요 (&apos;빈자리&apos; → &apos;
            {profile_name}
            &apos;)
          </ChangeColor>,
          <ChangeColor color="var(--gray-700)" key="list_1">
            &apos;빈자리&apos;라고 되어 있는 프로필이 없다면 현재 파티 구성원들 중{" "}
            <strong>아무도 사용하지 않고 있는 프로필</strong>을 &apos;{profile_name}&apos;로 변경 후 사용해주세요
          </ChangeColor>,
        ]}
      />
    </GrayBox>
  ) : (
    <GrayBox>
      <List
        list_space={16}
        list={[
          <ChangeColor color="var(--gray-700)" key="list_0">
            <strong>&apos;빈자리&apos;</strong> 라고 되어있는 프로필을 삭제 후{" "}
            <strong>&apos;{profile_name}&apos;</strong> 닉네임의 프로필을 생성해주세요 (프로필이 잠겨있어도 삭제가
            가능해요)
          </ChangeColor>,
          <ChangeColor color="var(--gray-700)" key="list_1">
            &apos;빈자리&apos;라고 되어 있는 프로필이 없다면 현재 파티 구성원들 중{" "}
            <strong>아무도 사용하지 않고 있는 프로필</strong>을 삭제해주세요
          </ChangeColor>,
        ]}
      />
    </GrayBox>
  );

const GrayBox = styled.div`
  padding: 22px 24px 1px 24px;
  width: 100%;
  background-color: var(--gray-100);
  border-radius: 8px;
`;

export default ProfileUsingInfoBox;
