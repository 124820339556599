import { AxiosResponse } from "axios";
import { OLD_DJANGO_URL, OLD_NODE_URL } from "globals/setting";
import { Axios, AuthAxios } from "./default";
import { ResponseData } from "./interface";

interface MatchingCountResData {
  count: number;
}

export const getMatchingPeopleCount = async (): Promise<AxiosResponse<ResponseData<MatchingCountResData>>> => {
  const response = await Axios({
    url: `${OLD_NODE_URL}/matching/people/complete-cnt`,
    method: "GET",
  });
  return response;
};

export const cancelMatching = async (person_id: number): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/party/matching/people/${person_id}`,
    method: "DELETE",
  });
  return response;
};
