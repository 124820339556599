import axios, { AxiosResponse } from "axios";
import qs from "qs";
import { KAKAO_CLIENT_SECRET, KAKAO_REST_KEY } from "globals/setting";

export const getKakaoTokenByCode = (code: string): Promise<AxiosResponse> => {
  const redirect_uri = `${window.location.origin}${location.pathname}`;
  return axios.post(
    "https://kauth.kakao.com/oauth/token",
    qs.stringify({
      grant_type: "authorization_code",
      client_id: KAKAO_REST_KEY,
      redirect_uri,
      code,
      client_secret: KAKAO_CLIENT_SECRET,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    },
  );
};
