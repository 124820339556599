import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MerchantData, PartyLeaveState, RefundData } from "./interface";

const initialState: PartyLeaveState = {
  is_full_loading: false,
  is_profile_delete_done: false,
  is_refund_done: false,
  is_refund_loading: true,
  is_merchant_loading: true,
};

const partyLeaveSlice = createSlice({
  name: "partyleave",
  initialState,
  reducers: {
    setRefundLoading(state, action: PayloadAction<boolean>) {
      state.is_refund_loading = action.payload;
    },
    setFullLoading(state, action: PayloadAction<boolean>) {
      state.is_full_loading = action.payload;
    },
    setRefundConfirm(state, action: PayloadAction<boolean>) {
      state.is_refund_done = action.payload;
    },
    setProfileDeleteConfirm(state, action: PayloadAction<boolean>) {
      state.is_profile_delete_done = action.payload;
    },
    setRefundInfo(state, action: PayloadAction<RefundData>) {
      state.refund_info = action.payload;
    },
    setMerchantInfo(state, action: PayloadAction<MerchantData>) {
      state.merchant_info = action.payload;
    },
    setMerchantLoading(state, action: PayloadAction<boolean>) {
      state.is_merchant_loading = action.payload;
    },
    cleanUpPartyLeave(state) {
      state.is_refund_done = false;
      state.is_profile_delete_done = false;
      state.is_full_loading = false;
      state.is_refund_loading = true;
      state.is_merchant_loading = true;
    },
  },
});

export const {
  setRefundLoading,
  setFullLoading,
  setRefundConfirm,
  setProfileDeleteConfirm,
  setRefundInfo,
  setMerchantInfo,
  setMerchantLoading,
  cleanUpPartyLeave,
} = partyLeaveSlice.actions;
export default partyLeaveSlice.reducer;
