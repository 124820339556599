import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setProgressBar, cleanupProgressBar } from "containers/ProgressBar";
import { BankData } from "./interface";
import { selectBank } from "./slice";
import { useRootState } from "globals/utils";
import { BlurLoading } from "components/Loading";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const Bank = lazy(() => import("components/MyPage").then(({ Bank }) => ({ default: Bank })));
const Title = lazy(() => import("components/MyPage").then(({ Title }) => ({ default: Title })));

const AddAccountInit = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bank_list = useRootState((state) => state.mypage.add_account.bank_list);

  const setProgressBarDisptach = (percent: number) => dispatch(setProgressBar(percent));
  const cleanupProgressBarDispatch = () => dispatch(cleanupProgressBar());
  const goAddAccountInput = (selected_bank: BankData) => {
    selectBankDispatch(selected_bank);
    history.push("/mypage/addaccount/input");
  };
  const selectBankDispatch = (bank: BankData) => dispatch(selectBank(bank));
  useEffect(() => {
    setProgressBarDisptach(50);
    return () => {
      cleanupProgressBarDispatch();
    };
  }, []);
  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation back_path="/mypage/manageaccount" title="계좌 등록하기" />
      <Title>
        매월 어떤 은행으로 <br /> 이체할까요?
      </Title>
      {bank_list.map((bank) => (
        <Bank bank={bank} key={`${bank.bank_code}_tab`} selectBank={goAddAccountInput} />
      ))}
    </Suspense>
  );
};

export default AddAccountInit;
