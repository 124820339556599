import React from "react";
import OldInput from "./OldInput";
import styled from "styled-components";

interface PasswordInputProps {
  width: number;
}

const PasswordInput = styled(OldInput).attrs({
  inputMode: "numeric",
  autoComplete: "on",
  type: "password",
})<PasswordInputProps>`
  width: ${(props) => props.width}px;
  text-align: center;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: var(--gray-700);
  border-radius: 50%;
  margin-left: 8px;
`;

export const PasswordDots = (cnt: number): JSX.Element[] =>
  [...Array(cnt)].map((e, index) => <Dot key={`password_dot_${index}`} />);

export default PasswordInput;
