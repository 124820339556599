import React from "react";
import { LoginButton } from "../Login";
import { MyPageIcon } from "components/TopNavigation";
import { isLoginSelector, useRootState } from "globals/utils";
import { useHistory, useLocation } from "react-router-dom";

const TabNavIcon = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const is_login = useRootState(isLoginSelector);

  const goMyPage = () => {
    history.push({ pathname: "/mypage", state: { from: location.pathname } });
  };
  return is_login ? <MyPageIcon onClick={goMyPage} /> : <LoginButton />;
};

export default TabNavIcon;
