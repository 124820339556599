import { AuthAxios, Axios } from "./default";
import { OLD_DJANGO_URL, OLD_NODE_URL, OLD_REAL_DJANGO_URL, OLD_REAL_NODE_URL } from "globals/setting";
import { AxiosResponse } from "axios";

export const checkDuplicate = (imp_uid: string): Promise<AxiosResponse> =>
  Axios.get(`${OLD_DJANGO_URL}/user/signup/${imp_uid}`);

interface SignUpReqData {
  imp_uid: string;
  info: {
    password?: string;
    oauth_service_name: string | null;
    oauth_access_token: string | null;
  };
}
export const signUp = (data: SignUpReqData): Promise<AxiosResponse> | null => {
  const { imp_uid, info } = data;
  if (info.password)
    return Axios.post(`${OLD_DJANGO_URL}/user/signup/${imp_uid}`, {
      password: info.password,
      device: "asdf",
      remember_me: false,
      marketing_check: false,
    });
  else if (info.oauth_service_name && info.oauth_access_token)
    return Axios.post(`${OLD_NODE_URL}/user/signup`, {
      signup_service: info.oauth_service_name,
      signup_key: {
        access_token: info.oauth_access_token,
        imp_uid: imp_uid,
      },
      device: "asdf",
      remember_me: false,
      marketing_check: false,
    });
  return null;
};

export interface loginByPhoneReqData {
  phone: string;
  password: string;
  is_remember: boolean;
}

export const loginByPhone = ({ phone, password, is_remember }: loginByPhoneReqData): Promise<AxiosResponse> =>
  Axios.post(`${OLD_DJANGO_URL}/user/login/token`, {
    phone,
    password,
    device: "asdf",
    remember_me: is_remember,
  });

export const oldLoginByPhone = ({ phone, password }: loginByPhoneReqData): Promise<AxiosResponse> =>
  Axios.post(
    `${OLD_REAL_DJANGO_URL}/user/login/token`,
    {
      phone,
      password,
      device: "asdf",
      remember_me: false,
    },
    {
      withCredentials: false,
    },
  );

export const refreshAccessToken = async (refresh_token: string): Promise<string> => {
  const response = await Axios.post(`${OLD_DJANGO_URL}/user/login/token/refresh`, {
    refresh_token,
  });
  const { access_token } = response.data.data;
  return access_token;
};

interface ResetPasswordReqData {
  imp_uid: string;
  new_password: string;
}
export const resetPassword = async ({ imp_uid, new_password }: ResetPasswordReqData): Promise<AxiosResponse> => {
  const response = await Axios.post(`${OLD_DJANGO_URL}/user/reset/password`, {
    imp_uid: imp_uid,
    new_password: new_password,
    device: "asdf",
    remember_me: true,
  });
  return response;
};

export const loginByKakao = async (kakao_access_token: string): Promise<AxiosResponse> => {
  const data = {
    access_token: kakao_access_token,
    remember_me: true,
    device: "asdf",
  };
  return await Axios.post(`${OLD_NODE_URL}/auth/oauth/kakao/token`, data);
};

export const oldLoginByKakao = async (kakao_access_token: string): Promise<AxiosResponse> => {
  const data = {
    access_token: kakao_access_token,
    remember_me: true,
    device: "asdf",
  };
  return await Axios.post(`${OLD_REAL_NODE_URL}/auth/oauth/kakao/token`, data, { withCredentials: false });
};

export const syncKakao = async (kakao_access_token: string): Promise<AxiosResponse> => {
  const response = await AuthAxios.post(`${OLD_NODE_URL}/auth/oauth/kakao/sync`, { access_token: kakao_access_token });
  return response;
};

export const checkUnderMaintenance = (): Promise<AxiosResponse> => Axios.get(`${OLD_NODE_URL}/maintenance`);
