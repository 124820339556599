import React, { RefObject } from "react";
import styled from "styled-components";
import DefaultInput from "./DefaultInput";
import Space from "components/Space";
import { IconDelete } from "assets/icons/Basic";
import { InputDescriptionType, InputState } from "./interface";
import Description from "./Description";

interface InputProps {
  value?: string;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  autoComplete?: "on" | "off";
  pattern?: string;
  input_ref?: RefObject<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  is_delete_available?: boolean;
  onDeleteClick?: () => void;
  description?: { text: string; theme?: InputDescriptionType; is_have_check?: boolean };
  data_test_id?: string;
  state: InputState;
}

const Input = (props: InputProps): JSX.Element => (
  <Container>
    <InputAndIconWrapper>
      <StyledInput
        value={props.value || ""}
        placeholder={props.placeholder}
        type={props.type}
        autoComplete={props.autoComplete}
        maxLength={props.maxLength}
        ref={props.input_ref}
        pattern={props.pattern}
        onChange={props.onChange}
        readOnly={props.state === "DISABLED"}
        data-testid={props.data_test_id || ""}
        state={props.state || "DEFAULT"}
        width="100%"
        disabled={props.state === "DISABLED"}
      />
      {props.value && props.is_delete_available && props.onDeleteClick && (
        <IconRemoveWrapper>
          <IconDelete size={20} />
        </IconRemoveWrapper>
      )}
    </InputAndIconWrapper>
    {!!props.description && (
      <>
        <Space height={8} />
        <Description theme={props.description.theme} is_have_check={props.description.is_have_check}>
          {props.description.text}
        </Description>
      </>
    )}
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputAndIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
`;

const IconRemoveWrapper = styled.div`
  position: absolute;
  margin-top: 8px;
  right: 0px;
  cursor: pointer;
`;

const StyledInput = styled(DefaultInput)`
  width: 100%;
`;

export default Input;
