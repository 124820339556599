import React from "react";
import styled from "styled-components";
import motion_loading from "./assets/motion_matching_loading.json";
import Lottie from "react-lottie";
import Space from "components/Space";
import { OldDivider } from "components/Divider";
import img_bullet from "./assets/img_bullet.png";
import { OldH6, OldSub } from "components/Text";

const Waiting = ({
  merchant_name,
  cancelSlot,
  goCreateParty,
  is_refund,
}: {
  merchant_name: string;
  cancelSlot: () => void;
  goCreateParty: () => void;
  is_refund?: boolean;
}): JSX.Element => (
  <Container>
    <Title>
      {merchant_name} 프리미엄
      <br />
      파티 {is_refund && "재"}매칭 중
    </Title>
    <Space height={4} />
    <SubTitle>
      대기자가 많아 조금 기다려야해요.
      <br />
      매칭이 완료되면 카톡으로 알려드립니다!
    </SubTitle>
    <Lottie
      width="auto"
      height="160px"
      isClickToPauseDisabled={false}
      options={{ animationData: motion_loading, loop: true, autoplay: true }}
    />
    <CancelText onClick={cancelSlot}>매칭 신청 취소</CancelText>
    <Space height={12} />
    {!is_refund && (
      <>
        <OldDivider height="8px" />
        <Space height={8} />
        <HostRecommendText>대기 없이 바로 시작하고 싶다면?</HostRecommendText>
        <Space height={16} />
        <BenefitBox>
          <Ul>
            <Li>
              <Bold>파티장</Bold>은 대기시간 없이 <Bold>즉시 시작</Bold> 가능
            </Li>
            <Space height={8} />
            <Li>
              <Bold>파티장</Bold>은 찜한 목록, 남긴 평가, 추천 알고리즘
              <br />
              등을 본인계정에 소장, 유지 가능
            </Li>
          </Ul>
        </BenefitBox>
        <Space height={24} />
        <HostStartButton onClick={goCreateParty}>파티장으로 빠른 시작</HostStartButton>
      </>
    )}
  </Container>
);

const Container = styled.div`
  padding: 32px 20px 32px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(OldH6)`
  color: var(--gray-900);
  text-align: center;
`;

const SubTitle = styled(OldSub)`
  color: var(--gray-700);
  text-align: center;
`;

const CancelText = styled.div`
  text-decoration: underline;
  color: var(--gray-700);
  &:hover {
    cursor: pointer;
  }
`;

const HostRecommendText = styled.div`
  font-weight: bold;
  color: var(--gray-700);
`;

const BenefitBox = styled.div`
  width: 100%;
  padding: 32px 24px 28px 34px;
  border-radius: 12px;
  background-color: var(--primary-100);
`;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Li = styled.li`
  background: url(${img_bullet}) no-repeat left top;
  background-size: 2px;
  background-position-y: 8px;
  padding-left: 8px;
  font-size: 14px;
  color: var(--primary-700);
  text-align: left;
`;

const Bold = styled.span`
  font-weight: bold;
  color: var(--primary-700);
`;

const HostStartButton = styled.div`
  background-color: var(--primary-500);
  width: 100%;
  text-align: center;
  color: var(--white);
  padding: 16px 16px 12px 16px;
  border-radius: 8px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;

export default Waiting;
