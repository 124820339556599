import ButtonLegacy from "components/Button";
import { OldInput, OldInputState } from "components/Input";
import { OldSub } from "components/Text";
import React, { useState } from "react";
import styled from "styled-components";

const AdminVariable = ({
  variable,
  setServiceVariable,
}: {
  variable: { key: string; value: string; type: string; remark: string };
  setServiceVariable: ({ key, value, type }: { key: string; value: number | string | boolean; type: string }) => void;
}): JSX.Element => {
  const [new_value, setNewValue] = useState<string | undefined>("");
  const { key, value, type, remark } = variable;
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.currentTarget.value);
  };

  return (
    <AdminVariableStyle>
      <ColumnAndValue title={"키"} value={key} is_read_only={true} width={400} />
      <ColumnAndValue title={`이전값 (${type})`} value={value} is_read_only={true} width={200} />
      <ColumnAndValue
        title={`바꿀값 (${type})`}
        value={new_value}
        is_read_only={false}
        width={200}
        onChange={onChange}
      />
      <ColumnAndValue title={"설명"} value={remark} is_read_only={true} width={300} />
      <ButtonLegacy onClick={() => new_value && setServiceVariable({ key, value: new_value, type })} width={60}>
        적용
      </ButtonLegacy>
    </AdminVariableStyle>
  );
};

const ColumnAndValue = ({
  title,
  value,
  is_read_only,
  width,
  onChange,
}: {
  title: string;
  value?: string;
  is_read_only: boolean;
  width: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <ColumnStyle width={width}>
    <OldSub>{title}</OldSub>
    <OldInput width={"100%"} state={OldInputState.COMPLETE} value={value} readOnly={is_read_only} onChange={onChange} />
  </ColumnStyle>
);

const AdminVariableStyle = styled.div`
  display: flex;
  padding: 0 40px 0 40px;
  justify-content: space-between;
  width: 100vw;
`;

const ColumnStyle = styled.div<{ width: number }>`
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  width: ${(props) => props.width}px;
`;

export default AdminVariable;
