import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";

import { loginSaga } from "containers/Login";
import { navigation_reducer } from "containers/TopNavigation";
import { register_reducer, registerSaga } from "containers/MembershipRegister";
import { auth_reducer, authSaga } from "globals/auth";
import { modal_reducer } from "containers/Modal";
import { managemerchantSaga } from "containers/ManageMerchant";
import { toast_reducer } from "containers/Toast";
import { mypage_reducer, mypageSaga } from "containers/MyPage";
import { signUpSaga } from "containers/SignUp";
import { progressbar_reducer } from "containers/ProgressBar";
import { slot_reducer, slotSaga } from "containers/Slot";
import { setpassword_reducer, setpasswordSaga } from "containers/SetPassword";
import { oldhostparty_reducer, oldhostpartySaga } from "containers/OldHostParty";
import { adminSaga, admin_reducer } from "containers/Admin";
import { user_reducer } from "globals/user";
import { membershipSaga, membership_reducer } from "globals/membership";
import { matchingSaga, matching_reducer } from "containers/Matching";
import { mypartySaga, myparty_reducer } from "containers/MyParty";
import { registerhostSaga, registerhost_reducer } from "containers/RegisterHost";
import { registerMemberSaga, registerMember_reducer } from "containers/RegisterMember";
import { merchandise_reducer, merchandiseSaga } from "globals/merchandise";
import { partydelete_reducer, partydeleteSaga } from "containers/PartyDelete";
import { partyleaveSaga, partyleave_reducer } from "containers/PartyLeave";

const RootReducer = combineReducers({
  auth: auth_reducer,
  modal: modal_reducer,
  navigation: navigation_reducer,
  register: register_reducer,
  membership: membership_reducer,
  toast: toast_reducer,
  mypage: mypage_reducer,
  progressbar: progressbar_reducer,
  slot: slot_reducer,
  setpassword: setpassword_reducer,
  oldhostparty: oldhostparty_reducer,
  admin: admin_reducer,
  user: user_reducer,
  matching: matching_reducer,
  myparty: myparty_reducer,
  registerhost: registerhost_reducer,
  registerMember: registerMember_reducer,
  merchandise: merchandise_reducer,
  partydelete: partydelete_reducer,
  partyleave: partyleave_reducer,
});

function* rootSaga() {
  yield all([
    authSaga(),
    registerSaga(),
    membershipSaga(),
    loginSaga(),
    mypageSaga(),
    signUpSaga(),
    managemerchantSaga(),
    slotSaga(),
    setpasswordSaga(),
    oldhostpartySaga(),
    adminSaga(),
    matchingSaga(),
    mypartySaga(),
    registerhostSaga(),
    registerMemberSaga(),
    merchandiseSaga(),
    partydeleteSaga(),
    partyleaveSaga(),
  ]);
}

const sagaMiddleware = createSagaMiddleware();

export type RootState = ReturnType<typeof RootReducer>;

const Store = configureStore({
  reducer: RootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export { Store, RootReducer };
