import { AxiosResponse } from "axios";
import { OLD_NODE_URL, OLD_DJANGO_URL } from "globals/setting";
import { AuthAxios } from "./default";

export const deleteParty = async (party_id: number): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/party/parties/${party_id}`,
    method: "DELETE",
  });
  return response;
};

export const getPartyMatchingSpaces = async (party_id: number): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_NODE_URL}/matching/spaces?party_id=${party_id}`,
    method: "GET",
  });
  return response;
};

export const setPartyMatchingOn = async (party_id: number): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_NODE_URL}/matching/spaces`,
    method: "POST",
    data: { party_id },
  });
  return response;
};

export const getParties = async (): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/party/parties`,
    method: "GET",
  });
  return response;
};

export const getParty = async (party_id: number): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/party/parties/${party_id}`,
    method: "GET",
  });
  return response;
};

export const getRefundInfo = async (merchant_id: number): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/party/delete-preview?merchant_id=${merchant_id}`,
    method: "GET",
  });
  return response;
};
