import { OldH5, OldSub, OldSmall2 } from "components/Text";
import styled from "styled-components";
import icon_arrow from "./assets/icon_diagonal_arrow.png";
import icon_info from "./assets/icon_info_gray.png";

export const Container = styled.div`
  padding: 0 0 32px;
`;

export const MerchantColumn = styled.div`
  padding: 0 24px 0;
`;

export const MerchantWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background-color: var(--gray-100);
`;
export const MerchantIcon = styled.img`
  width: 44px;
  height: 44px;
`;
export const MerchantText = styled(OldH5)`
  margin-top: 32px;
  margin-bottom: 4px;
  color: var(--gray-900);
`;

export const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--gray-700);
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;
`;

export const Space = styled.div`
  height: 32px;
`;

export const ServiceTab = styled.div<{ margin_top?: number; is_blue?: boolean }>`
  position: relative;
  padding: 0 24px 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 63px;
  color: ${({ is_blue }) => (is_blue ? "var(--blue-500)" : "var(--gray-900)")};
  cursor: pointer;
`;

export const DangerTab = styled.div`
  position: relative;
  padding: 22px 24px 17px 24px;
  color: var(--red-500);
  cursor: pointer;
`;

export const ArrowIcon = styled.img.attrs({
  src: icon_arrow,
  alt: "icon_arrow",
})`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 20px;
`;
export const ProfileLabel = styled(OldSub)`
  color: var(--gray-700);
  padding-left: 24px;
`;

export const ProfileRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 116px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
`;

export const Line = styled.div`
  margin: 0 24px 0 24px;
  height: 1px;
  background-color: var(--gray-700);
`;

export const InfoIcon = styled.img.attrs({
  src: icon_info,
  alt: "icon_info",
})`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

export const ProfileBox = styled.div`
  display: flex;
  padding-top: 28px;
  flex-direction: column;
  width: 100%;
  height: 271px;
  background-color: var(--gray-100);
  border-radius: 12px;
`;

export const MerchantBox = styled.div`
  display: flex;
  padding-left: 24px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 20px;
  color: var(--gray-500);
`;

export const MerchantIconImg = styled.img`
  margin-right: 4px;
  width: 16px;
  height: 16px;
`;

export const ProfileList = styled.div`
  display: flex;
  padding: 0 15px 0 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const ProfileSetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 74px;
  height: 98px;
`;

export const ProfileImg = styled.img`
  border-radius: 8px;
  width: 56px;
  height: 56px;
`;

export const ProfileName = styled(OldSmall2)`
  font-weight: normal;
  color: var(--gray-900);
`;

export const GrayLabel = styled(OldSmall2)`
  padding: 0 8px 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  border-radius: 9px;
  color: var(--gray-500);
  background-color: var(--gray-200);
  font-weight: normal;
  text-align: center;
`;

export const BlueLabel = styled(OldSmall2)<{ is_icon_included?: boolean }>`
  padding: ${({ is_icon_included }) => (is_icon_included ? "0px 4px 0px 4px" : "0px 8px 0px 8px")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${({ is_icon_included }) => (is_icon_included ? "55px" : "auto")};
  height: 18px;
  border-radius: 9px;
  color: var(--blue-600);
  background-color: var(--blue-100);
  font-weight: normal;
  text-align: center;
`;

export const ProfileImgFrame = styled.div`
  position: relative;
  border-radius: 8px;
  width: 56px;
  height: 56px;
`;

export const IconHostBadgeFrame = styled.div`
  position: absolute;
  width: 12px;
  height: 17px;
  bottom: 0;
  right: 0;
`;

export const ProfileDifferentInfo = styled(OldSmall2)`
  width: 100%;
  text-align: center;
  font-weight: normal;
  color: var(--gray-400);
`;

export const ProfileImgLicense = styled.div`
  padding-right: 4px;
  width: 100%;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  text-align: right;
  color: var(--gray-300);
`;

export const ChangeMyProfile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  width: 100%;
  height: 48px;
  color: var(--gray-600);
  cursor: pointer;
`;

export const CaptionSmall = styled(OldSmall2)`
  font-weight: normal;
  color: var(--gray-900);
`;
