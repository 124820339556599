import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { slotSelector, useRootState } from "globals/utils";
import { BlurLoading } from "components/Loading";
import { MANAGEMERCHANT_CHANGE_SPS_SAGA } from "containers/ManageMerchant";
import { SLOT_SET_SLOT_SAGA } from "containers/Slot";
import { LAFTEL_MERCHANT_ID_HARD, NETFLIX_MERCHANT_ID_HARD, WATCHA_MERCHANT_ID_HARD } from "globals/variables";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const MyPartyEdit = lazy(() => import("components/MyParty").then(({ MyPartyEdit }) => ({ default: MyPartyEdit })));

const MyPartyEditContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const merchant_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_id,
  );
  const slot = useRootState(slotSelector);
  const is_loading = useRootState((state) => state.slot.is_loading);
  const previous_route = `/myparty/info?merchant_id=${merchant_id}`;
  const goMyParty = () => history.push("/myparty");
  const setSlotSagaDispatch = (merchant_id: number) =>
    dispatch({ type: SLOT_SET_SLOT_SAGA, payload: { merchant_id, goMyParty } });

  const goService = () => {
    window.open(slot?.merchant_service_url);
  };

  useEffect(() => {
    setSlotSagaDispatch(merchant_id);
  }, []);

  const changeSps = (sps: { key?: string; value?: string; pin?: string }) => {
    if (!sps.key || !sps.value) {
      alert("잘못된 접근입니다. 새로고침을 해주세요.");
      return;
    }
    const email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phone_regex = /^\d{3}\d{3,4}\d{4}$/;
    if (merchant_id === NETFLIX_MERCHANT_ID_HARD && !email_regex.test(sps.key) && !phone_regex.test(sps.key)) {
      alert("넷플릭스 ID는 이메일 혹은 휴대폰번호만 사용 가능합니다.");
      return;
    }
    if (merchant_id === LAFTEL_MERCHANT_ID_HARD && !email_regex.test(sps.key)) {
      alert("라프텔 ID는 이메일만 사용 가능합니다.");
      return;
    }
    if (merchant_id === WATCHA_MERCHANT_ID_HARD && !email_regex.test(sps.key)) {
      alert("왓챠 ID는 이메일만 사용 가능합니다.");
      return;
    }
    const payload = {
      party_id: slot?.party_id,
      sps: {
        sps_key: sps.key,
        sps_value: sps.value,
        sps_pin: sps.pin,
      },
      goCheckSps: () => {
        window.location.href = `/myparty/info?merchant_id=${merchant_id}`;
      },
    };
    dispatch({ type: MANAGEMERCHANT_CHANGE_SPS_SAGA, payload });
  };

  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation
        back_path={previous_route}
        title={is_loading || !slot ? "ID/PW 변경" : `${slot.merchant_name} ID/PW 변경`}
        is_loading={is_loading}
      />
      {!is_loading && slot ? (
        <MyPartyEdit
          merchant_id={slot.merchant_id}
          merchant_name={slot.merchant_name}
          goService={goService}
          changeSps={changeSps}
        />
      ) : (
        <BlurLoading />
      )}
    </Suspense>
  );
};

export default MyPartyEditContainer;
