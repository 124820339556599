import axios, { AxiosResponse } from "axios";
import { OLD_DJANGO_URL, OLD_REAL_DJANGO_URL } from "globals/setting";
import { AuthAxios } from "./default";

export const getUser = async (): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/user/information`,
    method: "GET",
  });
  return response;
};

export const oldGetUserParty = async (access_token: string | unknown): Promise<AxiosResponse> => {
  const response = await axios.get(`${OLD_REAL_DJANGO_URL}/party/parties`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `jwt ${access_token}`,
    },
    withCredentials: false,
  });
  return response;
};
