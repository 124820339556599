import { OldH5 } from "components/Text";
import React, { useState, useEffect, useRef } from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import pageLoadingMotion from "./assets/pageLoading.json";

const FullLoading = (): JSX.Element => {
  const [count, setCount] = useState<number>(0);
  const [text, setText] = useState<string>("잠시만 기다려주세요");
  const text_list = ["잠시만 기다려주세요.", "거의 다 돼가요."];

  type IntervalFuntion = () => unknown | void;
  const useInterval = (callback: IntervalFuntion, delay: number) => {
    const savedCallback = useRef<IntervalFuntion | null>(null);
    useEffect(() => {
      savedCallback.current = callback;
    });
    useEffect(() => {
      const tick = () => {
        if (savedCallback.current) savedCallback.current();
      };
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  };
  const textSwitch = () => {
    const index = count % 2;
    setText(text_list[index]);
  };

  useInterval(() => {
    textSwitch();
    setCount(count + 1);
  }, 3000);

  return (
    <Container>
      <OldH5>{text}</OldH5>
      <Space />
      <Lottie
        width="auto"
        height="120px"
        isClickToPauseDisabled={false}
        options={{
          animationData: pageLoadingMotion,
          loop: true,
          autoplay: true,
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  align-items: center;
`;

const Space = styled.div`
  height: 64px;
`;

export default FullLoading;
