import React from "react";
import styled from "styled-components";
import { ReactComponent as AccountInfoH } from "./src/icon_account_info_h.svg";
import { ReactComponent as AccountInfoV } from "./src/icon_account_info_v.svg";
import { ReactComponent as AddCard } from "./src/icon_add_card.svg";
import { ReactComponent as CreateAccount } from "./src/icon_create_account.svg";
import { ReactComponent as NotifyTalk } from "./src/icon_notify_talk.svg";
import { ReactComponent as Ott } from "./src/icon_ott.svg";
import { ReactComponent as Premium } from "./src/icon_premium.svg";
import { ReactComponent as Receive } from "./src/icon_receive.svg";
import { ReactComponent as Withdraw } from "./src/icon_withdraw.svg";
import { IconProps } from "./interface";

export const IconAccountInfoHorizontal = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <AccountInfoH title="icon account info horizontal" />
  </IconWrapper>
);
export const IconAccountInfoVertical = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <AccountInfoV title="icon account info vertical" />
  </IconWrapper>
);
export const IconAddCard = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <AddCard title="icon add card" />
  </IconWrapper>
);
export const IconCreateAccount = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <CreateAccount title="icon create account" />
  </IconWrapper>
);
export const IconNotifyTalk = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <NotifyTalk title="icon notify talk" />
  </IconWrapper>
);
export const IconOtt = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <Ott title="icon ott" />
  </IconWrapper>
);
export const IconPremium = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <Premium title="icon premium" />
  </IconWrapper>
);
export const IconReceive = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <Receive title="icon receive" />
  </IconWrapper>
);
export const IconWithdraw = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <Withdraw title="icon withdraw" />
  </IconWrapper>
);

const IconWrapper = styled.div<IconProps>`
  width: ${(props) => props.size || 40}px;
  height: ${(props) => props.size || 40}px;
`;
