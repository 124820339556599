import { KAKAO_MOMENTS } from "globals/setting";

const InitKakaoPixel = () => {
  if (window.kakaoPixel) return window.kakaoPixel(KAKAO_MOMENTS);
};

const pageView = (): void => {
  const KakaoPixel = InitKakaoPixel();
  KakaoPixel && KakaoPixel.pageView("pageView");
};

const signUp = (): void => {
  const KakaoPixel = InitKakaoPixel();
  KakaoPixel && KakaoPixel.completeRegistration("signUp");
};

const purchaseMembership = (merchandise_name: string): void => {
  const KakaoPixel = InitKakaoPixel();
  KakaoPixel && KakaoPixel.purchase(merchandise_name);
};

const createParty = ({
  merchandise_name,
  merchant_name,
}: {
  merchandise_name: string;
  merchant_name: string;
}): void => {
  const KakaoPixel = InitKakaoPixel();
  KakaoPixel && KakaoPixel.purchase(`${merchandise_name} ${merchant_name} 파티장`);
};

export const KakaoMoments = {
  pageView,
  signUp,
  purchaseMembership,
  createParty,
};
