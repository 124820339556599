import React from "react";
import CardGuide from "components/CardGuide";
import { host_cards, member_cards } from "./CardElements";

const Cards = ({ type }: { type: "host" | "member" }): JSX.Element => {
  if (type === "host") {
    return (
      <>
        {host_cards.map((card, index) => (
          <CardGuide
            key={`card_${index}`}
            line_length={card.line_length}
            order={card.order}
            main_text={card.main_text}
            sub_text={card.sub_text}
            icon={card.icon}
            is_first={index === 0}
            is_last={index === host_cards.length - 1}
          />
        ))}
      </>
    );
  } else
    return (
      <>
        {member_cards.map((card, index) => (
          <CardGuide
            key={`card_${index}`}
            line_length={card.line_length}
            order={card.order}
            main_text={card.main_text}
            sub_text={card.sub_text}
            icon={card.icon}
            is_first={index === 0}
            is_last={index === member_cards.length - 1}
          />
        ))}
      </>
    );
};

export default Cards;
