import React from "react";
import { RouteProps, useHistory } from "react-router";
import { Route } from "globals/utils";

interface RestrictRouteProps extends RouteProps {
  redirect_url: string;
  is_pass: boolean;
}

const RestrictRoute = (props: RestrictRouteProps): JSX.Element | null => {
  const history = useHistory();
  if (!props.is_pass) {
    history.replace(props.redirect_url);
    return null;
  }
  return (
    <Route {...props} path={props.path} component={props.component}>
      {props.children}
    </Route>
  );
};

export default RestrictRoute;
