import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SlotState } from "./interface";
import { SlotData } from "globals/interfaces";

const initialState: SlotState = {
  is_loading: true,
};

const slotSlice = createSlice({
  name: "slot",
  initialState,
  reducers: {
    cleanupSlot(state) {
      state.slot = undefined;
      state.is_loading = true;
    },
    setSlotLoading(state, action: PayloadAction<boolean>) {
      state.is_loading = action.payload;
    },
    setSlot(state, action: PayloadAction<SlotData>) {
      state.slot = action.payload;
    },
  },
});

export const { cleanupSlot, setSlot, setSlotLoading } = slotSlice.actions;
export default slotSlice.reducer;
