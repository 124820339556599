import AdminBotLogs from "./AdminBotLogs";
import { BlurLoading } from "components/Loading";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Switch } from "react-router-dom";
import { RootState } from "root.redux";
import styled from "styled-components";
import AdminHome from "./AdminHome";
import AdminVariableList from "./AdminVariableList";
import { OldH5 } from "components/Text";
import { ADMIN_CHECK_USER_ADMIN_SAGA } from "./saga";
import { Route } from "globals/utils";

const AdminRoute = (): JSX.Element => {
  const is_loading = useSelector((state: RootState) => state.admin.is_loading);
  const dispatch = useDispatch();
  const checkUserAdminSagaDispatch = () => dispatch({ type: ADMIN_CHECK_USER_ADMIN_SAGA });
  useEffect(() => {
    checkUserAdminSagaDispatch();
    return;
  }, []);
  return is_loading ? (
    <BlurLoading />
  ) : (
    <AdminStyle>
      <AdminNavigator>
        <Link to="/admin/home">
          <OldH5>매칭</OldH5>
        </Link>
        <Link to="/admin/variables">
          <OldH5>변수관리</OldH5>
        </Link>
        <Link to="/admin/bot">
          <OldH5>봇 검증</OldH5>
        </Link>
      </AdminNavigator>
      <Switch>
        <Route path="/admin/home" component={AdminHome} desktop_component={AdminHome} />
        <Route path="/admin/variables" component={AdminVariableList} desktop_component={AdminVariableList} />
        <Route path="/admin/bot" component={AdminBotLogs} desktop_component={AdminBotLogs} />
        <Redirect to="/" />
      </Switch>
    </AdminStyle>
  );
};

const AdminStyle = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  width: 100vw;
`;

const AdminNavigator = styled.nav`
  display: flex;
  padding-left: 100px;
  margin: 20px;
  width: 400px;
  flex-direction: row;
  justify-content: space-between;
`;

export default AdminRoute;
