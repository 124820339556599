import {
  createBankAccount,
  createBilling,
  CreateBillingReqData,
  GaEvent,
  getDifferenceAmount,
  getMerchant,
  getSlots,
  KakaoMoments,
  PixelEvent,
  registerSps,
  registerSpsReqData,
} from "apis";
import { logError, removeSelectedInfo, StatusCodes } from "globals/utils";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "root.redux";
import { setMerchant, setDifferenceAmount, setDifferenceLoading, setLoading } from "./slice";
import { setTryingPay, cleanupTryingPay } from "globals/auth";
import { Amplitude } from "apis/amplitude";

function* setMerchantSaga(action: { type: string; payload: { merchant_id: number } }) {
  yield put(setLoading(true));
  try {
    const response = yield call(getMerchant, action.payload.merchant_id);
    const { data } = response.data;
    yield put(setMerchant(data));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoading(false));
  }
}
export const REGISTERHOST_SET_MERCHANT_SAGA = "registerhost/setMerchantSaga";

function* createBankAccountSaga(action: {
  type: string;
  payload: { account_num: string; account_bank: string; goInit: () => void };
}) {
  try {
    const account_name: string = yield select((state: RootState) => state.user.user_data?.real_name);
    const { account_bank, account_num, goInit } = action.payload;
    const response = yield call(createBankAccount, { account_bank, account_num, account_name });
    if (response.status === StatusCodes.CREATED_201) {
      alert("계좌 등록을 완료했습니다.");
      goInit();
    }
  } catch (e) {
    if (e.response) {
      alert(`계좌 등록과정에서 문제가 생겼습니다.\n${e.response.data?.msg}`);
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  }
}
export const REGISTERHOST_CREATE_BANK_ACCOUNT_SAGA = "registerhost/createBankAccountSaga";

function* createBillingSaga(action: { type: string; payload: { cardInfo: CreateBillingReqData; goInit: () => void } }) {
  yield put(setTryingPay());
  try {
    const response = yield call(createBilling, action.payload.cardInfo);
    if (response.status === StatusCodes.OK_200) {
      alert("결제 카드 등록이 완료되었습니다.");
      action.payload.goInit();
    }
  } catch (e) {
    if (e.response) {
      if (e.response.status === StatusCodes.INTERNAL_SERVER_ERROR_500) {
        alert(e.response.data?.msg);
      } else {
        alert(`카드 등록 과정에서 문제가 발생했습니다.\n${e.response.data?.msg}`);
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  }
  yield put(cleanupTryingPay());
}
export const REGISTERHOST_CREATE_BILLING_SAGA = "registerhost/createBillingSaga";

function* createPartySaga(action: { type: string; payload: { sps_data: registerSpsReqData; goBack: () => void } }) {
  try {
    const response = yield call(registerSps, action.payload.sps_data);
    if (response.status === StatusCodes.OK_200) {
      removeSelectedInfo();
      alert("파티장으로 등록이 완료됐습니다");
      window.location.href = "/myparty";
      const { paid_amount, merchandise_id, merchandise_name, merchant_name } = response.data.data;
      const phone = yield select((state: RootState) => state.user.user_data?.phone);
      KakaoMoments.createParty({ merchandise_name, merchant_name });
      GaEvent.createParty({
        merchandise_name,
        merchandise_id,
        merchant_name,
        value: paid_amount,
        phone,
      });
      PixelEvent.createParty({
        merchandise_name,
        merchandise_id,
        merchant_name,
        value: paid_amount,
      });
      Amplitude.createParty({
        merchandise_name,
        merchandise_id,
        merchant_name,
        paid_amount,
      });
    }
  } catch (e) {
    if (e.response) {
      const { status } = e.response;
      const {
        data: { code, msg },
      } = e.response;
      switch (status) {
        case StatusCodes.PAYMENT_REQUIRED_402:
          alert("멤버십이 결제되어 있지 않습니다.");
          break;
        case StatusCodes.NOT_ACCEPTABLE_406:
          alert("미성년자는 피클플러스 멤버십을 이용하실 수 없습니다! 양해 부탁드립니다.");
          break;
        case StatusCodes.CONFLICT_409:
          alert("이미 해당 OTT를 사용 중 입니다.");
          break;
        case StatusCodes.LENGTH_REQUIRED_411:
          alert("더 이상 파티를 등록하실 수 없습니다.");
          break;
        case StatusCodes.BAD_REQUEST_400:
          if (code === "PRT0016") {
            alert("웨이브의 경우, 성인 인증 비밀번호가 필수로 필요합니다.");
          } else if (code === "PAY0010") {
            alert("카드의 잔액이 부족합니다.");
          } else if (code === "PAY0009") {
            alert(`결제 과정에 문제가 생겼습니다.\n${msg}`);
          } else if (code === "PAY0005" || code === "PAY0007") {
            alert(`카드 등록 과정에 문제가 생겼습니다.\n${msg}`);
          } else {
            alert(msg);
          }
          break;
        default:
          alert("잘못된 접근입니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.");
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    action.payload.goBack();
    logError(e);
  }
}
export const REGISTERHOST_CREATE_PARTY_SAGA = "registerhost/createPartySaga";

function* setDifferenceAmountSaga() {
  yield put(setDifferenceLoading(true));
  try {
    const merchandises = yield select((state: RootState) => state.merchandise.merchandises);
    const slot_response = yield call(getSlots);
    const slots_data = slot_response.data.data;
    const using_merchant_cnt = slots_data.length;
    if (using_merchant_cnt === 0) return;
    const upgrade_merchandise = merchandises.find(
      (merchandise: { slot_cnt: number; amount: number; merchandise_id: number }) =>
        merchandise.slot_cnt === using_merchant_cnt + 1,
    );
    const response = yield call(getDifferenceAmount, upgrade_merchandise.merchandise_id);
    const { data } = response.data;
    yield put(setDifferenceAmount({ remain: data.difference_amount, diff: data.adding_amount }));
  } catch (e) {
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.PAYMENT_REQUIRED_402:
          alert("피클플러스 멤버십 결제액이 미납되어 있습니다.\n미납액 결제완료 후 다시 시도해주세요.");
          break;
        case StatusCodes.NOT_FOUND_404:
          alert("가격을 비교하려는 OTT 대상이 없습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.");
          break;
        default:
          alert(`에러가 발생했습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.\n${e.response.data?.msg}`);
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    logError(e);
  } finally {
    yield put(setDifferenceLoading(false));
  }
}
export const REGISTERHOST_SET_DIFFERENCE_AMOUNT_SAGA = "registerhost/setDifferenceAmountSaga";

function* registerhostSaga(): Generator {
  yield takeLatest(REGISTERHOST_SET_MERCHANT_SAGA, setMerchantSaga);
  yield takeLatest(REGISTERHOST_CREATE_BANK_ACCOUNT_SAGA, createBankAccountSaga);
  yield takeLatest(REGISTERHOST_CREATE_BILLING_SAGA, createBillingSaga);
  yield takeLatest(REGISTERHOST_CREATE_PARTY_SAGA, createPartySaga);
  yield takeLatest(REGISTERHOST_SET_DIFFERENCE_AMOUNT_SAGA, setDifferenceAmountSaga);
}

export default registerhostSaga;
