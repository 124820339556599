import React from "./index";
import ButtonLegacy from "components/Button";
import { LoginModalProps } from "./interface";
import styled from "styled-components";
import button_kakao from "./assets/button_kakao.png";
import { OldH6 } from "components/Text";

const LoginModal = ({ z_index, closeLoginModal, logIn }: LoginModalProps): JSX.Element => {
  const modal_z_index = z_index || 30;
  return (
    <ModalContainer z_index={modal_z_index}>
      <Modal z_index={modal_z_index}>
        <Title>피클플러스 시작하기</Title>
        <OAuthButton onClick={logIn} />
        <ButtonLegacy theme="gray-ghost" height={40} onClick={closeLoginModal}>
          전화번호로 가입했었나요?
        </ButtonLegacy>
      </Modal>
      <Dimmer onClick={closeLoginModal} z_index={modal_z_index} />
    </ModalContainer>
  );
};

const ModalContainer = styled.div<{ z_index: number }>`
  position: fixed;
  z-index: ${(props) => props.z_index};
`;

const Modal = styled.div<{ z_index: number }>`
  position: fixed;
  width: 328px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding: 24px 24px 20px 24px;
  background-color: var(--white);
  z-index: ${(props) => props.z_index + 1};
`;

const Title = styled(OldH6)`
  color: var(--gray-900);
`;

const OAuthButton = styled.img.attrs({
  src: button_kakao,
  alt: "button_kakao",
})`
  width: 280px;
  height: 40px;
  cursor: pointer;
  margin-top: 32px;
`;

const Dimmer = styled.div<{ z_index: number }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--black);
  opacity: 0.8;
  z-index: ${(props) => props.z_index};
`;

export default LoginModal;
