import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Redirect, useHistory } from "react-router-dom";
import GlobalStyle from "globals/style";
import TopNavigation from "containers/TopNavigation";
import Modal from "containers/Modal";
import Toast from "containers/Toast";
import { useDispatch } from "react-redux";
import ProgressBar from "containers/ProgressBar";
import ScrollToTop from "containers/ScrollToTop";
import { KakaoMoments } from "apis";
import { AuthRoute, ErrorFallback, RestrictRoute, useRootState } from "globals/utils";
import { Amplitude } from "apis/amplitude";
import { BlurLoading } from "components/Loading";
import { ErrorBoundary } from "@sentry/react";
import { AUTH_INITIALIZE_AUTH_SAGA } from "globals/auth";
import { Route } from "globals/utils";
import { HomeDesktop } from "containers/Home";

const Home = lazy(() => import("containers/Home"));
const Guide = lazy(() => import("containers/Guide"));
const Login = lazy(() => import("containers/Login"));
const MyParty = lazy(() => import("containers/MyParty"));
const SignUp = lazy(() => import("containers/SignUp"));
const RegisterRoute = lazy(() => import("containers/RegisterRoute"));
const MyPageRoute = lazy(() => import("containers/MyPage"));
const SetPassword = lazy(() => import("containers/SetPassword"));
const Admin = lazy(() => import("containers/Admin"));
const Matching = lazy(() => import("containers/Matching"));

const App = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const initializeAuthSagaDispatch = () => dispatch({ type: AUTH_INITIALIZE_AUTH_SAGA });
  const is_initialized = useRootState((state) => state.auth.is_initialized);
  const is_login = useRootState((state) => state.auth.is_login);

  useEffect(() => {
    Amplitude.initClient();
    initializeAuthSagaDispatch();
    const listen = history.listen;
    const unlisten = listen(() => {
      KakaoMoments.pageView();
      Amplitude.pagePath();
    });

    return unlisten;
  }, []);

  return is_initialized ? (
    <ErrorBoundary fallback={ErrorFallback}>
      <GlobalStyle />
      <TopNavigation />
      <ProgressBar />
      <Modal />
      <Toast />
      <ScrollToTop>
        <Suspense fallback={<BlurLoading />}>
          <Switch>
            <Route exact path="/" component={Home} desktop_component={HomeDesktop} />
            <Route path="/guide" component={Guide} />
            <RestrictRoute is_pass={!is_login} redirect_url="/" path="/login" component={Login} />
            <Route path="/myparty" component={MyParty} />
            <RestrictRoute is_pass={!is_login} redirect_url="/" path="/signup" component={SignUp} />
            <AuthRoute path="/register" component={RegisterRoute} />
            <AuthRoute path="/mypage" component={MyPageRoute} />
            <Route path="/password" component={SetPassword} />
            <Route path="/admin" component={Admin} />
            <Route path="/matching" component={Matching} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </ScrollToTop>
      <Toast />
    </ErrorBoundary>
  ) : (
    <div />
  );
};

export default App;
