import React from "react";
import styled from "styled-components";
import Space from "components/Space";
import { Color } from "globals/interfaces";
import { OldBody1 } from "components/Text";

interface BannerProps {
  img_url?: string;
  children: React.ReactNode;
  background_color?: Color;
  color?: Color;
}

const Banner = ({ img_url, children, background_color, color }: BannerProps): JSX.Element => (
  <Container background_color={background_color}>
    {!!img_url && (
      <>
        <Icon src={img_url} />
        <Space height={16} />
      </>
    )}
    <OldBody1 color={color} font_weight="bold">
      {children}
    </OldBody1>
  </Container>
);

const Container = styled.div<{ background_color?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 36px 28px 36px;
  background-color: ${(props) => props.background_color || "var(--gray-100)"};
  border-radius: 12px;
  text-align: center;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export default Banner;
