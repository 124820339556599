import { ModalType } from "components/Modal";
import { ModalState, openModal, closeModal } from "containers/Modal";
import { Store } from "index";

const openCommonModal = (modal_state: ModalState): void => {
  const dispatch = Store.dispatch;
  const openModalDispatch = (modal_state_param: ModalState) => dispatch(openModal(modal_state_param));
  const closeModalDispatch = () => dispatch(closeModal());
  const clone_modal_state: ModalState = { ...modal_state };
  clone_modal_state.buttons?.map((button) => {
    if (button.is_close_button) {
      const pastOnClick = button.onClick?.bind({});
      button.onClick = () => {
        if (pastOnClick !== undefined && typeof pastOnClick === "function") {
          pastOnClick();
        }
        closeModalDispatch();
      };
    }
  });
  openModalDispatch(clone_modal_state);
  return;
};

export { ModalType };

export default openCommonModal;
