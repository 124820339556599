import Space from "components/Space";
import React from "react";
import styled from "styled-components";
import { H2 } from "components/Text";
import { ReactComponent as ImgDiscount } from "./assets/img_discount.svg";
import { useScrollFadeIn } from "globals/utils";

const Section2 = (): JSX.Element => (
  <Container>
    <Space height={60} />
    <Text {...useScrollFadeIn(1, 0.3)}>피클플러스에서는</Text>
    <TextContainer {...useScrollFadeIn(1, 0.3)}>
      <Text>OTT 1개를&nbsp;</Text>
      <TextWrapper>
        <Text>4900원</Text>
        <Underline />
      </TextWrapper>
      <Text>에!</Text>
    </TextContainer>
    <Space height={20} />
    <div {...useScrollFadeIn(1, 0)}>
      <ImgDiscount />
    </div>
    <Space height={60} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--gray-050);
  align-items: center;
`;

const Text = styled(H2)`
  text-align: center;
  z-index: 1;
`;

const TextContainer = styled.div`
  display: flex;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Underline = styled.div`
  top: 0;
  left: -2px;
  position: absolute;
  width: 88px;
  height: 15px;
  margin: 18px 0 0;
  transform: rotate(-2deg);
  background-color: var(--primary-300);
`;

export default Section2;
