export enum StatusCodes {
  OK_200 = 200,
  CREATED_201 = 201,
  NO_CONTENT_204 = 204,
  BAD_REQUEST_400 = 400,
  UNAUTHORIZED_401 = 401,
  PAYMENT_REQUIRED_402 = 402,
  FORBIDDEN_403 = 403,
  NOT_FOUND_404 = 404,
  METHOD_NOT_ALLOWED_405 = 405,
  NOT_ACCEPTABLE_406 = 406,
  CONFLICT_409 = 409,
  LENGTH_REQUIRED_411 = 411,
  TOO_MANY_REQUESTS_429 = 429,
  INTERNAL_SERVER_ERROR_500 = 500,
  BAD_GATEWAY_502 = 502,
  SERVICE_UNAVAILABLE_503 = 503,
}
