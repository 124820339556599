import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as IconMypage } from "./assets/icon_mypage.svg";

const MyPageIcon = ({ onClick }: { onClick: () => void }): JSX.Element => {
  const [is_mobile, setMobile] = useState<boolean>(true);
  const [is_mouse_on, setMouseOn] = useState<boolean>(false);
  useEffect(() => {
    setMobile(window.innerWidth < 1120);
    return;
  }, []);
  return (
    <IconWrapper onClick={onClick}>
      <IconMypage
        title="icon mypage"
        fill={!is_mobile && is_mouse_on ? "var(--primary-500)" : "var(--gray-600)"}
        onMouseOver={() => setMouseOn(true)}
        onMouseOut={() => setMouseOn(false)}
      />
    </IconWrapper>
  );
};

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export default MyPageIcon;
