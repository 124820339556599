import React from "react";
import ButtonLegacy, { ThemeLegacy } from "components/Button";
import { ModalType } from "./interface";

export const ModalButton = ({
  children,
  width,
  height,
  theme,
  image_src,
  onClick,
  disabled,
}: {
  width: number;
  height: number;
  children: React.ReactNode;
  theme?: ThemeLegacy;
  image_src?: string;
  type?: ModalType;
  onClick?: () => void;
  disabled?: boolean;
}): JSX.Element =>
  image_src ? (
    <img
      width={width}
      height={height}
      src={image_src}
      alt={"modal_img_button"}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    />
  ) : (
    <ButtonLegacy width={width} height={height} onClick={onClick} theme={theme} disabled={disabled}>
      {children}
    </ButtonLegacy>
  );
