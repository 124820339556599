import React, { useEffect, useState } from "react";
import AdminBotLogDetail from "components/AdminBot";
import { useDispatch } from "react-redux";
import { useRootState } from "globals/utils";
import styled from "styled-components";
import { BotLogData } from "./interface";
import { OldH4, OldBody1, OldH5 } from "components/Text";
import ButtonLegacy from "components/Button";
import Space from "components/Space";
import { setSelectedBotLog } from "./slice";
import { InputWithLabel } from "components/Input";
import { ADMIN_SET_BOT_LOGS_SAGA, ADMIN_START_PARTY_MATCHING_SAGA } from "./saga";

const AdminBotLogsContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const [party_id, setPartyId] = useState<number | undefined>(undefined);
  const [page, setPage] = useState<number | undefined>(0);
  const [is_detail, setIsDetail] = useState<boolean>(false);
  const bot_logs = useRootState((state) => state.admin.bot_logs);
  const selected_bot_log = useRootState((state) => state.admin.selected_bot_log);
  const setSelectedBotLogDispatch = (bot_log: BotLogData) => dispatch(setSelectedBotLog(bot_log));
  const setBotLogsSagaDispatch = ({
    party_id,
    is_success,
    page,
  }: {
    party_id?: number;
    is_success?: boolean;
    page?: number;
  }) => dispatch({ type: ADMIN_SET_BOT_LOGS_SAGA, payload: { party_id, is_success, page } });
  const startPartyMatchingSagaDispatch = (party_id: number) =>
    dispatch({ type: ADMIN_START_PARTY_MATCHING_SAGA, payload: { party_id, onSuccess: searchLogs } });

  const onPartyIdChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setPartyId(value ? parseInt(value) : undefined);
  };
  const onPageChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setPage(value ? parseInt(value) : 0);
  };

  const goBack = () => {
    setIsDetail(false);
    searchLogs();
  };
  const confirmLog = (party_id: number) => {
    startPartyMatchingSagaDispatch(party_id);
    setIsDetail(false);
  };
  const searchLogs = () => {
    setBotLogsSagaDispatch({ party_id, page });
  };
  const showDetailLog = (bot_log: BotLogData) => {
    setSelectedBotLogDispatch(bot_log);
    setIsDetail(true);
  };

  useEffect(() => {
    setBotLogsSagaDispatch({});
    return;
  }, []);
  return (
    <Container>
      {is_detail ? (
        <AdminBotLogDetail confirmLog={confirmLog} goBack={goBack} selected_bot_log={selected_bot_log} />
      ) : (
        <>
          <OldH4 color="var(--primary-700)">봇 로그 확인</OldH4>
          <InputGroup>
            <InputWithLabel
              label="파티ID"
              placeholder="Party ID를 입력하세요"
              type="number"
              value={party_id ? `${party_id}` : undefined}
              onChange={onPartyIdChange}
              is_complete
            />
            <InputWithLabel
              label="페이지"
              placeholder="페이지를 입력하세요"
              type="number"
              value={page ? `${page}` : undefined}
              onChange={onPageChange}
              is_complete
            />
            <ButtonLegacy width={60} height={40} onClick={searchLogs}>
              조회
            </ButtonLegacy>
          </InputGroup>
          <Space height={20} />
          <TableContainer>
            <BotLogListTable>
              <colgroup>
                <col width={100} />
                <col width={300} />
                <col width={100} />
                <col width={250} />
                <col width={200} />
                <col width={120} />
                <col width={300} />
                <col width={300} />
                <col width={100} />
                <col width={100} />
              </colgroup>
              <BotLogHeadRow>
                <BotLogItemColumn scope="col">
                  <OldBody1>파티 ID</OldBody1>
                </BotLogItemColumn>
                <BotLogItemColumn scope="col">
                  <OldBody1>검증액션</OldBody1>
                </BotLogItemColumn>
                <BotLogItemColumn scope="col">
                  <OldBody1>성공여부</OldBody1>
                </BotLogItemColumn>
                <BotLogItemColumn scope="col">
                  <OldBody1>OTT 아이디</OldBody1>
                </BotLogItemColumn>
                <BotLogItemColumn scope="col">
                  <OldBody1>OTT 비밀번호</OldBody1>
                </BotLogItemColumn>
                <BotLogItemColumn scope="col">
                  <OldBody1>OTT 핀번호</OldBody1>
                </BotLogItemColumn>
                <BotLogItemColumn scope="col">
                  <OldBody1>실패사유</OldBody1>
                </BotLogItemColumn>
                <BotLogItemColumn scope="col">
                  <OldBody1>로그 생성일</OldBody1>
                </BotLogItemColumn>
                <BotLogItemColumn scope="col">
                  <OldBody1>매칭 여부</OldBody1>
                </BotLogItemColumn>
                <BotLogItemColumn scope="col">
                  <OldBody1>해체 여부</OldBody1>
                </BotLogItemColumn>
              </BotLogHeadRow>
              {bot_logs?.map((bot_log, index) => (
                <BotLogItem key={`bot_log_item_${index}`} {...bot_log} onClick={() => showDetailLog(bot_log)} />
              ))}
            </BotLogListTable>
          </TableContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 24px;
`;
const TableContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 430px);
  overflow-y: auto;
  overflow-x: auto;
`;

const BotLogListTable = styled.table`
  padding: 24px;
  border-collapse: collapse;
`;

interface BotLogItemProps extends BotLogData {
  onClick: () => void;
}

const BotLogItem = (props: BotLogItemProps) => (
  <BotLogItemRow
    is_success={props.is_success}
    is_deleted={!!props.party_delete_dt}
    is_matching={props.is_matching}
    onClick={props.onClick}
  >
    <BotLogItemColumn scope="row">{props.party_id}</BotLogItemColumn>
    <BotLogItemColumn scope="row">{props.action}</BotLogItemColumn>
    <BotLogItemColumn scope="row">{props.is_success ? "성공" : "실패"}</BotLogItemColumn>
    <BotLogItemColumn scope="row">{props.sps_key}</BotLogItemColumn>
    <BotLogItemColumn scope="row">{props.sps_value}</BotLogItemColumn>
    <BotLogItemColumn scope="row">{props.sps_pin}</BotLogItemColumn>
    <BotLogItemColumn scope="row">{props.failed_cause}</BotLogItemColumn>
    <BotLogItemColumn scope="row">{props.create_dt}</BotLogItemColumn>
    <BotLogItemColumn scope="row">
      <OldH5>{props.is_matching ? "O" : "X"}</OldH5>
    </BotLogItemColumn>
    <BotLogItemColumn scope="row">
      <OldH5>{props.party_delete_dt ? "O" : "X"}</OldH5>
    </BotLogItemColumn>
  </BotLogItemRow>
);

const BotLogItemRow = styled.tr<{ is_success?: boolean; is_deleted?: boolean; is_matching?: boolean }>`
  padding: 0;
  margin: 0;
  border: var(--gray-300) 1px solid;
  background-color: ${({ is_success, is_deleted, is_matching }) => {
    if (is_deleted) {
      return "var(--gray-400)";
    } else if (is_success) {
      return "var(--primary-200)";
    } else if (is_matching) {
      return "var(--blue-200)";
    } else if (!is_matching) {
      return "var(--red-200)";
    }
  }};
  :hover {
    background-color: var(--yellow-200);
    cursor: pointer;
  }
`;

const BotLogHeadRow = styled(BotLogItemRow)`
  background-color: var(--gray-100);
  :hover {
    background-color: var(--gray-100);
    cursor: pointer;
  }
`;

const BotLogItemColumn = styled.td.attrs({ height: 30 })`
  padding: 0 5px 0 5px;
  margin: 0;
  border-right: var(--gray-300) 1px solid;
  border-left: var(--gray-300) 1px solid;
  border-bottom: var(--gray-300) 1px solid;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 170px;
`;

export default AdminBotLogsContainer;
