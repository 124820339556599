import { AxiosResponse } from "axios";
import { SlotData } from "globals/interfaces";
import { OLD_NODE_URL, OLD_DJANGO_URL } from "globals/setting";
import { AuthAxios } from "./default";

export const registerMatching = async (merchant_id: number): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_NODE_URL}/matching/people`,
    method: "POST",
    data: {
      merchant_id,
    },
  });
  return response;
};

export const getSlots = async (): Promise<AxiosResponse<SlotData[]>> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/membership/slots`,
    method: "GET",
  });
  return response;
};

export const getSlot = async (merchant_id: number): Promise<AxiosResponse<SlotData>> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/membership/slot?merchant_id=${merchant_id}`,
    method: "GET",
  });
  return response;
};
