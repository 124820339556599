import React from "react";
import styled from "styled-components";
import icon_have_account from "./assets/icon_have_account.png";
import { OldH5 } from "components/Text";

interface ItemProps {
  item_img_url?: string;
  item_name?: string;
}

const PartyItem = ({ item_img_url, item_name }: ItemProps): JSX.Element => (
  <ItemColumn>
    <ItemWrapper>
      <ItemIcon src={item_img_url} />
    </ItemWrapper>
    <ItemText>(구){item_name} 파티</ItemText>
    <SubText>
      파티장으로 이용 중
      <HostIcon />
    </SubText>
  </ItemColumn>
);

const ItemColumn = styled.div`
  padding: 64px 24px 19px 24px;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background-color: var(--gray-100);
`;
const ItemIcon = styled.img`
  width: 44px;
  height: 44px;
`;
const ItemText = styled(OldH5)`
  margin-top: 32px;
  margin-bottom: 4px;
  color: var(--gray-900);
`;

const SubText = styled.div`
  display: flex;
  flex-direction: row;
  color: var(--gray-700);
`;
const HostIcon = styled.img.attrs({
  src: icon_have_account,
  alt: "icon_have_account",
})`
  width: 16px;
  height: 16px;
  padding-top: 2px;
  margin-left: 4px;
`;

export default PartyItem;
