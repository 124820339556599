import React from "react";
import styled from "styled-components";
import Button from "./Button";

interface ButtonSingleCTAProps {
  text: string;
  onClick: () => void;
  data_test_id?: string;
  is_disabled?: boolean;
}

type ButtonGroupCTASize = "medium" | "large";

interface ButtonGroupCTAProps {
  size: ButtonGroupCTASize;
  main_button: ButtonSingleCTAProps;
  sub_button?: ButtonSingleCTAProps;
}

const ButtonGroupCTA = ({ size, main_button, sub_button }: ButtonGroupCTAProps): JSX.Element => (
  <>
    <BottomBlank size={size} />
    <ButtonFixedContainer>
      {sub_button && (
        <>
          <ButtonPositioner flex_grow={93}>
            <Button theme="gray-fill" size={size} data_test_id={sub_button.data_test_id} onClick={sub_button.onClick}>
              {sub_button.text}
            </Button>
          </ButtonPositioner>
          <SpaceRow />
        </>
      )}
      <ButtonPositioner flex_grow={234}>
        <Button
          theme="primary-fill"
          is_disabled={main_button.is_disabled}
          size={size}
          data_test_id={main_button.data_test_id}
          onClick={main_button.onClick}
        >
          {main_button.text}
        </Button>
      </ButtonPositioner>
    </ButtonFixedContainer>
  </>
);

const BottomBlank = styled.div<{ size: ButtonGroupCTASize }>`
  position: relative;
  width: 100%;
  height: ${({ size }) => {
    switch (size) {
      case "medium":
        return "79px";
      case "large":
        return "86px";
      default:
        return "79px";
    }
  }};
`;

const ButtonFixedContainer = styled.div`
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: center;
  padding: 18px 20px 16px 20px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--white) 19.8%);
`;

const ButtonPositioner = styled.div<{ flex_grow: number }>`
  flex-basis: 0;
  flex-grow: ${({ flex_grow }) => flex_grow};
`;

const SpaceRow = styled.div`
  flex-basis: 0;
  flex-grow: 8;
`;

export default ButtonGroupCTA;
