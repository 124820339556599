import React, { lazy, Suspense, useEffect } from "react";
import qs from "qs";
import { isTryingPaySelector, useRootState } from "globals/utils";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BlurLoading, FullLoading } from "components/Loading";
import { closeModal, ModalState, openModal } from "containers/Modal";
import { ModalType } from "components/Modal";
import styled from "styled-components";
import { MYPARTY_CANCEL_MATCHING_SAGA } from "./saga";
import { PARTYDELETE_SET_REFUND_INFO_SAGA } from "containers/PartyDelete";
import { SLOT_SET_SLOT_WAITING_MERCHANT_SAGA } from "containers/Slot";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const DeleteButtons = lazy(() =>
  import("components/PartyDelete").then(({ DeleteButtons }) => ({ default: DeleteButtons })),
);
const Refund = lazy(() => import("components/PartyDelete").then(({ Refund }) => ({ default: Refund })));

const WaitingCancel = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const merchant_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_id,
  );
  const is_refund_loading = useRootState((state) => state.partydelete.is_refund_loading);
  const refund_info = useRootState((state) => state.partydelete.refund_info);
  const is_trying_pay = useRootState(isTryingPaySelector);

  const goBack = () => history.push(`/myparty/waiting?merchant_id=${merchant_id}`);
  const goMyParty = () => history.push("/myparty");
  const setRefundInfoSagaDispatch = () =>
    dispatch({ type: PARTYDELETE_SET_REFUND_INFO_SAGA, payload: { goBack: goMyParty, merchant_id, goMyParty } });
  const openModalDispatch = (modal_state: ModalState) => dispatch(openModal(modal_state));
  const closeModalDispatch = () => dispatch(closeModal());
  const setSlotWaitingMerchantSagaDispatch = (merchant_id: number) =>
    dispatch({ type: SLOT_SET_SLOT_WAITING_MERCHANT_SAGA, payload: { merchant_id, goMyParty } });
  const cancelMatchingSagaDispatch = () =>
    dispatch({
      type: MYPARTY_CANCEL_MATCHING_SAGA,
      payload: {
        goMyParty,
        merchant_id,
      },
    });

  useEffect(() => {
    setRefundInfoSagaDispatch();
    setSlotWaitingMerchantSagaDispatch(merchant_id);

    return;
  }, []);

  useEffect(() => {
    if (!is_refund_loading && !refund_info?.is_refund) {
      goMyParty();
      return;
    }
    return;
  }, [is_refund_loading]);

  const openConfirmModal = () => {
    openModalDispatch({
      title: "정말 매칭취소 하시겠어요?",
      content: <ModalContent> 매칭취소는 즉시 진행되기 때문에 취소하실 수 없어요</ModalContent>,
      type: ModalType.ENLARGED,
      is_dimmer: true,
      is_dimmer_close: true,
      buttons: [
        {
          onClick: closeModalDispatch,
          text: "아니요",
          theme: "gray-ghost",
        },
        {
          onClick: () => cancelMatchingSagaDispatch(),
          text: "예",
          theme: "red-fill",
        },
      ],
    });
  };

  const is_loading = is_refund_loading || is_trying_pay;
  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation
        back_path={`/myparty/waiting?merchant_id=${merchant_id}`}
        title="매칭취소"
        is_loading={is_loading}
      />
      {!is_loading && refund_info ? (
        <>
          <Refund refund_info={refund_info} is_matching={true} />
          <DeleteButtons is_last={true} is_matching={true} goBack={goBack} goNext={openConfirmModal} />
        </>
      ) : (
        <FullLoading />
      )}
    </Suspense>
  );
};

const ModalContent = styled.div`
  color: var(--gray-700);
`;

export default WaitingCancel;
