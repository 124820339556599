import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminState, BotLogData, VariableListData } from "./interface";

const initialState: AdminState = {
  is_loading: true,
  variable_list: [],
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.is_loading = action.payload;
    },
    setVariableList(state, action: PayloadAction<VariableListData[]>) {
      state.variable_list = action.payload;
    },
    setSelectedBotLog(state, action: PayloadAction<BotLogData>) {
      state.selected_bot_log = action.payload;
    },
    setBotLogs(state, action: PayloadAction<BotLogData[]>) {
      state.bot_logs = action.payload;
    },
  },
});

export const { setLoading, setVariableList, setSelectedBotLog, setBotLogs } = adminSlice.actions;

export default adminSlice.reducer;
