import React from "react";
import styled from "styled-components";
import { EmojiSparkles } from "assets/emojis";
import Space from "components/Space";
import { ChangeColor, H1 } from "components/Text";
import { ReactComponent as ImgTextBalloon } from "../assets/img_text_balloon.svg";
import ContentsSlide from "./ContentsSlide";

const Section8 = (): JSX.Element => (
  <Container>
    <Space height={70} />
    <ImgTextBalloon title="text balloon" />
    <Space height={15} />
    <Title>
      더&nbsp;<ChangeColor color="var(--primary-500)">저렴하고</ChangeColor>, 더&nbsp;
      <ChangeColor color="var(--primary-500)">간편하게</ChangeColor>
      <br />
      OTT를 감상해보세요
      <EmojiWrapper>
        <EmojiSparkles />
      </EmojiWrapper>
    </Title>
    <Space height={29} />
    <ContentsSlide />
    <Space height={39} />
    <ButtonWrapper></ButtonWrapper>
    <Space height={80} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--gray-900);
`;

const Title = styled(H1)`
  font-family: HGGGothic;
  text-align: center;
  color: var(--white);
  font-weight: normal;
`;

const ButtonWrapper = styled.div`
  padding: 0 20px;
  width: 100%;
  height: 50px;
`;

const EmojiWrapper = styled.span`
  display: inline-flex;
  vertical-align: middle;
  margin-left: 4px;
`;

export default Section8;
