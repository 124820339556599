import { BlurLoading, FullLoading } from "components/Loading";
import { membershipSelector, useRootState } from "globals/utils";
import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CreateBillingReqData } from "apis";
import { useHistory } from "react-router-dom";
import { SELECTED_MERCHANT } from "globals/variables";
import { setMerchant } from "./slice";
import { REGISTER_SET_BILLING_SAGA } from "containers/MembershipRegister";
import { REGISTERHOST_SET_DIFFERENCE_AMOUNT_SAGA } from "containers/RegisterHost";
import {
  REGISTERMEMBER_REGISTER_MATCHING_AND_CREATE_BILLING_SAGA,
  REGISTERMEMBER_REGISTER_MATCHING_SAGA,
} from "./saga";
import { MEMBERSHIP_SET_MEMBERSHIP_SAGA } from "globals/membership";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const RegisterMember = lazy(() => import("components/RegisterMember"));

const RegisterMemberContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const goSuccess = () => (window.location.href = "/myparty");
  const goBack = () => history.replace("/matching");
  const billing = useRootState((state) => state.register.billing);
  const setBillingSagaDispatch = () => dispatch({ type: REGISTER_SET_BILLING_SAGA });
  const merchants = useRootState((state) => state.matching.merchants);
  const merchandises = useRootState((state) => state.merchandise.merchandises);
  const selected_merchant_id = sessionStorage.getItem(SELECTED_MERCHANT) || "1";
  const selected_merchant = merchants?.find((merchant) => merchant.merchant_id.toString() === selected_merchant_id);
  const using_merchants = useRootState((state) => state.matching.using_merchants);
  const remaining_amount = useRootState((state) => state.registerhost.remaining_amount);
  const difference_amount = useRootState((state) => state.registerhost.difference_amount);
  const is_matching_loading = useRootState((state) => state.registerMember.is_loading);
  const period_end_date = useRootState(membershipSelector).membership_data?.period_end_date;

  const setMerchantDispatch = () => {
    if (selected_merchant) dispatch(setMerchant(selected_merchant));
  };
  const registerMatchingAndCreateBillingSagaDispatch = (cardInfo: CreateBillingReqData) =>
    dispatch({
      type: REGISTERMEMBER_REGISTER_MATCHING_AND_CREATE_BILLING_SAGA,
      payload: {
        cardInfo: cardInfo,
        merchant_id: parseInt(selected_merchant_id),
        merchant_name: selected_merchant?.merchant_name,
        goBack,
        goSuccess,
      },
    });
  const registerMatchingSaga = () =>
    dispatch({
      type: REGISTERMEMBER_REGISTER_MATCHING_SAGA,
      payload: {
        merchant_id: parseInt(selected_merchant_id),
        merchant_name: selected_merchant?.merchant_name,
        goBack,
        goSuccess,
      },
    });
  const setMembershipSagaDispatch = () => dispatch({ type: MEMBERSHIP_SET_MEMBERSHIP_SAGA });

  const is_difference_loading = useRootState((state) => state.registerhost.is_difference_loading);
  const setDifferenceAmountSagaDispatch = () => dispatch({ type: REGISTERHOST_SET_DIFFERENCE_AMOUNT_SAGA });

  useEffect(() => {
    setBillingSagaDispatch();
    setMerchantDispatch();
    setDifferenceAmountSagaDispatch();
    setMembershipSagaDispatch();

    return;
  }, []);

  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation
        title="매칭 시작하기"
        back_path="/matching"
        is_loading={!selected_merchant || !merchandises || is_difference_loading || is_matching_loading}
      />
      {selected_merchant && merchandises && !is_difference_loading && !is_matching_loading ? (
        <RegisterMember
          billing={billing}
          merchandises={merchandises}
          selected_merchant={selected_merchant}
          registerMemberAndCreateBillingDispatch={registerMatchingAndCreateBillingSagaDispatch}
          using_merchants={using_merchants}
          setButtonClick={registerMatchingSaga}
          difference_amount={difference_amount}
          remaining_amount={remaining_amount}
          period_end_date={period_end_date}
        />
      ) : (
        <FullLoading />
      )}
    </Suspense>
  );
};

export default RegisterMemberContainer;
