import { AxiosResponse } from "axios";
import { OLD_DJANGO_URL } from "globals/setting";
import { AuthAxios } from "./default";

export const getMembership = async (): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/membership`,
    method: "GET",
  });
  return response;
};

export const getSavedAmount = async (): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/membership/saved-amount`,
    method: "GET",
  });
  return response;
};
