import React from "react";
import styled from "styled-components";
import { H2, Body2 } from "components/Text";
import Space from "components/Space";
import { useScrollFadeIn } from "globals/utils";

const Explanation = (): JSX.Element => (
  <Container>
    <Space height={60} />
    <MainText {...useScrollFadeIn(1, 0.3)}>어떻게 이용하나요?</MainText>
    <Space height={18} />
    <SubText {...useScrollFadeIn(1, 0.3)}>
      OTT별로 파티장과 파티원 중 선택하여 이용 가능해요.
      <br />
      계정을 공유하는 계정 주인을 &apos;파티장&apos;이라고 하며,
      <br />
      계정을 공유받는 사람을 &apos;파티원&apos;이라고 불러요.
    </SubText>
    <Space height={45} />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);
`;

const MainText = styled(H2)`
  text-align: center;
`;

const SubText = styled(Body2)`
  text-align: center;
  color: var(--gray-700);
`;

export default Explanation;
