import { call, put, takeLatest } from "redux-saga/effects";
import { oldLoginByPhone, loginByKakao, syncKakao, loginByPhone, oldLoginByKakao, loginByPhoneReqData } from "apis";
import { getKakaoTokenByCode } from "apis/kakao";
import { logError, StatusCodes } from "globals/utils";
import { AUTH_LOG_IN_SAGA } from "globals/auth";

function* loginByPhoneSaga(action: {
  type: string;
  payload: { data: loginByPhoneReqData; goRedirectUrl: () => void };
}) {
  try {
    const response = yield call(loginByPhone, action.payload.data);
    try {
      const old_response = yield call(oldLoginByPhone, action.payload.data);
      const old_access_token = old_response.data?.data?.access_token;
      window.localStorage.setItem("old_access_token", old_access_token);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    const { access_token, refresh_token, is_remember } = response.data.data;
    yield put({
      type: AUTH_LOG_IN_SAGA,
      payload: { access_token, refresh_token, is_remember, goRedirectUrl: action.payload.goRedirectUrl },
    });
  } catch (e) {
    logError(e);
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.BAD_REQUEST_400:
          alert("요청 형식이 잘못되었습니다.");
          break;
        case StatusCodes.NOT_FOUND_404:
          alert("휴대폰 번호 또는 비밀번호를 다시 확인해주세요.");
          break;
        case StatusCodes.CONFLICT_409:
          alert("카카오로 연동된 유저입니다.\n카카오 로그인으로 시도해주세요.");
          break;
        default:
          alert(`에러가 발생했습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.\n${e.response.data?.msg}`);
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
  }
}
export const LOGIN_LOGIN_BY_PHONE_SAGA = "login/loginByPhoneSaga";

function* getKakaoAccessTokenSaga(action: {
  type: string;
  payload: { code: string | null; goRedirectUrl: () => void; goSignUp: () => void };
}) {
  try {
    const { code, goRedirectUrl, goSignUp } = action.payload;
    if (code === null) throw new Error("code가 null값입니다");
    const response = yield call(getKakaoTokenByCode, code);
    const { access_token, refresh_token } = response.data;
    window.localStorage.setItem("kakao_refresh_token", refresh_token);
    window.localStorage.setItem("kakao_access_token", access_token);
    yield put({
      type: LOGIN_LOGIN_BY_KAKAO_SAGA,
      payload: { goRedirectUrl, goSignUp },
    });
  } catch (e) {
    logError(e);
    alert("잘못된 접근입니다.");
    location.href = "/";
  }
}
export const LOGIN_GET_KAKAO_ACCESS_TOKEN_SAGA = "login/getKakaoAccessTokenSaga";

function* loginByKakaoSaga(action: { type: string; payload: { goRedirectUrl: () => void; goSignUp: () => void } }) {
  try {
    const kakao_access_token = window.localStorage.getItem("kakao_access_token");
    if (!kakao_access_token) throw new Error("카카오 로그인 되어있지 않음");
    const response = yield call(loginByKakao, kakao_access_token);
    try {
      const old_response = yield call(oldLoginByKakao, kakao_access_token);
      const old_access_token = old_response.data?.data?.access_token;
      window.localStorage.setItem("old_access_token", old_access_token);
      // eslint-disable-next-line no-empty
    } catch (e) {}

    if (!response.data.data) {
      action.payload.goSignUp();
      return;
    }
    const { access_token, refresh_token } = response.data.data;
    yield put({
      type: AUTH_LOG_IN_SAGA,
      payload: { access_token, refresh_token, is_remember: true, goRedirectUrl: action.payload.goRedirectUrl },
    });
  } catch (e) {
    const status = e.response?.status;
    const msg = e.response?.data?.msg;
    switch (status) {
      case StatusCodes.NOT_FOUND_404:
        action.payload.goSignUp();
        break;
      case StatusCodes.BAD_REQUEST_400:
        alert(msg);
        break;
      default:
        alert(`에러가 발생했습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.\n${msg}`);
        break;
    }
    logError(e);
  }
}
export const LOGIN_LOGIN_BY_KAKAO_SAGA = "login/loginByKakaoSaga";

function* kakaoSyncSaga(action: { type: string; payload: { goRedirectUrl: () => void } }) {
  try {
    const kakao_access_token = window.localStorage.getItem("kakao_access_token");
    if (!kakao_access_token) throw new Error("카카오 로그인 되어있지 않음");
    const response_sync = yield call(syncKakao, kakao_access_token);
    if (response_sync.status !== StatusCodes.OK_200) {
      throw new Error("카카오 연동 실패");
    }
    const response = yield call(loginByKakao, kakao_access_token);
    if (response.status === StatusCodes.OK_200) {
      const { access_token, refresh_token } = response.data.data;
      yield put({
        type: AUTH_LOG_IN_SAGA,
        payload: { access_token, refresh_token, is_remember: true, goRedirectUrl: action.payload.goRedirectUrl },
      });
      action.payload.goRedirectUrl();
    }
  } catch (e) {
    alert("연동에 실패했습니다.\n다시 시도해주세요.");
    window.location.href = "/login/select";
    logError(e);
  }
}
export const LOGIN_KAKAO_SYNC_SAGA = "login/kakaoSyncSaga";

function* loginSaga(): Generator {
  yield takeLatest(LOGIN_LOGIN_BY_PHONE_SAGA, loginByPhoneSaga);
  yield takeLatest(LOGIN_GET_KAKAO_ACCESS_TOKEN_SAGA, getKakaoAccessTokenSaga);
  yield takeLatest(LOGIN_LOGIN_BY_KAKAO_SAGA, loginByKakaoSaga);
  yield takeLatest(LOGIN_KAKAO_SYNC_SAGA, kakaoSyncSaga);
}

export default loginSaga;
