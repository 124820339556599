import { AxiosResponse } from "axios";
import { OLD_NODE_URL, OLD_DJANGO_URL } from "globals/setting";
import { AuthAxios } from "./default";

export const getBilling = async (): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_NODE_URL}/billing/billings`,
    method: "GET",
  });
  return response;
};

export interface CreateBillingReqData {
  card_num: string;
  card_month: string;
  card_pw: string;
  card_year: string;
  birth: string;
}

export const createBilling = async (
  data: CreateBillingReqData,
): Promise<AxiosResponse<{ code: string; msg: string }>> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/pay/billing`,
    method: "POST",
    data,
  });
  return response;
};

export const deleteBilling = async (): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_NODE_URL}/billing/billings`,
    method: "DELETE",
  });
  return response;
};
