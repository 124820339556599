import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckCircleLine } from "./src/icon_check_circle_line.svg";
import { ReactComponent as CheckCircleFill } from "./src/icon_check_circle_fill.svg";
import { IconProps } from "./interface";

export const IconCheckCircleLinePrimary = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <CheckCircleLine title="icon check circle line primary" fill="var(--primary-500)" />
  </IconWrapper>
);
export const IconCheckCircleLineGray = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <CheckCircleLine title="icon check circle line gray" fill="var(--gray-400)" />
  </IconWrapper>
);

export const IconCheckCircleFillPrimary = ({ size }: IconProps): JSX.Element => (
  <IconWrapper size={size}>
    <CheckCircleFill title="icon check circle fill primary" fill="var(--primary-500)" />
  </IconWrapper>
);

const IconWrapper = styled.div<IconProps>`
  width: ${(props) => props.size || 24}px;
  height: ${(props) => props.size || 24}px;
`;
