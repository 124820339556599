import React, { useRef } from "react";
import styled from "styled-components";
import { input_theme, InputState, InputDescriptionType } from "./interface";
import Description from "./Description";
import Space from "components/Space";
import { Caption1 } from "components/Text";

interface InputWrapProps {
  children?: string;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  autoComplete?: "on" | "off";
  pattern?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  description?: { text: string; theme?: InputDescriptionType; is_have_check?: boolean };
  data_test_id?: string;
  state: InputState;
}

const InputWrap = (props: InputWrapProps): JSX.Element => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const onChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = 8 + scrollHeight + "px";
    }
    if (props.onChange) props.onChange(e);
  };

  return (
    <Container>
      <TextArea
        placeholder={props.placeholder}
        ref={textareaRef}
        onChange={onChangeValue}
        readOnly={props.state === "DISABLED"}
        data-testid={props.data_test_id || ""}
        state={props.state || "DEFAULT"}
        disabled={props.state === "DISABLED"}
      >
        {props.children}
      </TextArea>
      <Space height={8} />
      <Row>
        {!!props.description && (
          <DescriptionWrapper>
            <Description theme={props.description.theme} is_have_check={props.description.is_have_check}>
              {props.description.text}
            </Description>
          </DescriptionWrapper>
        )}
        {!!props.maxLength && (
          <WordsCount is_error={!!props.children && !!props.maxLength && props.children.length > props.maxLength}>
            {props.children ? props.children.length : 0} / {props.maxLength}
          </WordsCount>
        )}
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextArea = styled.textarea<{ state: InputState; textalign?: string; height?: string }>`
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  caret-color: var(--primary-500);
  -moz-appearance: textfield;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  resize: none;
  overflow-y: visible;

  width: 100%;
  height: ${(props) => props.height || "32px"};

  border-radius: 0;
  border-bottom: ${({ state }) => input_theme[state].border_bottom};
  font-family: SpoqaHanSansNeo;
  font-size: 18px;
  font-weight: ${({ state }) => input_theme[state].font_weight};
  line-height: 24px;
  text-align: ${(props) => props.textalign || "left"};
  color: ${({ state }) => input_theme[state].color};

  :disabled {
    background-color: var(--white);
  }
  ::placeholder {
    color: var(--gray-400);
  }

  :focus {
    border-bottom: ${({ state }) =>
      state === "DISABLED" ? input_theme["DISABLED"].border_bottom : "2px solid var(--primary-500)"};
  }
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

const DescriptionWrapper = styled.div`
  position: absolute;
  left: 0;
`;

const WordsCount = styled(Caption1)<{ is_error?: boolean }>`
  position: absolute;
  right: 0;
  color: ${(props) => (props.is_error ? "var(--red-400)" : "var(--gray-600)")};
`;

export default InputWrap;
