export enum SlotStatus {
  NONE,
  MEMBER_WAIT,
  MEMBER_USE,
  HOST_WAIT,
  HOST_USE,
  HOST_FAIL,
}

export interface SlotData {
  status: SlotStatus;

  merchant_id: number;
  merchant_name: string;
  merchant_service_url: string;
  merchant_amount: number;
  img_url: string;
  host_yn?: boolean;

  person_id?: number;

  party_id?: number;
  member_id?: string;

  sps_key?: string;
  sps_value?: string;
  sps_pin?: string;

  my_profile_name?: string;
  host?: { member_id: string; profile_name: string };
  members?: { member_id: string; profile_name: string }[];

  reward?: number;
  pay_day?: number;

  is_delete_available: boolean;
  delete_available_date?: string;

  merchant_profile_image_url: string;
  merchant_profile_image_license: string;
}
