import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RegisterState, BillingData } from "./interfaces";

const initialState: RegisterState = {
  merchandises: {},
  checkout: {
    is_loading: true,
    is_billing_loading: true,
  },
  addcard: {
    is_loading: false,
  },
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setBillingLoading(state, action: PayloadAction<boolean>) {
      state.checkout.is_billing_loading = action.payload;
    },
    setBilling(state, action: PayloadAction<BillingData>) {
      state.billing = action.payload;
    },
  },
});

export const { setBilling, setBillingLoading } = registerSlice.actions;
export default registerSlice.reducer;
