import React, { lazy, Suspense } from "react";
import styled from "styled-components";
import { useRootState } from "globals/utils";
import { useHistory, useLocation } from "react-router-dom";
import { LevelStatus } from "components/LevelTab";
import { BlurLoading } from "components/Loading";
import GreenBox from "components/GreenBox";
import { OldH6 } from "components/Text";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const Button = lazy(() => import("components/Button"));
const LevelTab = lazy(() => import("components/LevelTab"));
const Space = lazy(() => import("components/Space"));

const RegisterHostInit = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const merchant_name = useRootState((state) => state.registerhost.merchant?.merchant_name);
  const is_sps_done = useRootState((state) => state.registerhost.sps.is_done);
  const is_account_done = useRootState((state) => state.registerhost.account.is_done);
  const is_billing_done = useRootState((state) => state.registerhost.is_billing_done);

  const getSpsStatus = () => {
    if (is_sps_done) return LevelStatus.DONE;
    if (!is_sps_done && !is_account_done && !is_billing_done) return LevelStatus.RECOMMEND;
    return LevelStatus.STANDBY;
  };
  const getAccountStatus = () => {
    if (is_sps_done && is_account_done) return LevelStatus.DONE;
    if (is_sps_done && !is_account_done && !is_billing_done) return LevelStatus.RECOMMEND;
    return LevelStatus.STANDBY;
  };
  const getBillingStatus = () => {
    if (is_sps_done && is_account_done && is_billing_done) return LevelStatus.DONE;
    if (is_sps_done && is_account_done && !is_billing_done) return LevelStatus.RECOMMEND;
    return LevelStatus.STANDBY;
  };
  const goProcessing = () => history.replace("/register/host/processing");

  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation back_path="/matching" title="파티매칭" />
      <Container>
        <Title>
          3가지 단계만 완료하면 <br /> 파티가 생성됩니다
        </Title>
        <Space height={16} />
        <GreenBox>
          파티 생성 후에 <strong>바로 이용 가능</strong>합니다
        </GreenBox>
        <Space height={16} />
        <LevelTab
          title={`1. ${merchant_name} 구독하고오기`}
          content={
            <>
              {merchant_name} 이용권을 구매한 후 <br /> 파티원에게 공유할 계정을 등록해주세요
            </>
          }
          status={getSpsStatus()}
          onClick={() => history.push({ pathname: "/register/host/sps", state: location.pathname })}
          data_test_id="register_host_subscribe_tab"
        />
        <Space height={12} />
        <LevelTab
          title="2. 정산 계좌 등록"
          content={
            <>
              {merchant_name}에서 결제하고 온 금액을 <br /> 돌려받을 계좌를 등록해주세요
            </>
          }
          status={getAccountStatus()}
          onClick={() => history.push({ pathname: "/register/host/account", state: location.pathname })}
          data_test_id="register_host_bank_account_tab"
        />
        <Space height={12} />
        <LevelTab
          title="3. 결제 카드 등록"
          content={
            <>
              피클플러스 서비스 이용료를
              <br />
              결제할 카드를 등록해주세요
            </>
          }
          status={getBillingStatus()}
          onClick={() => history.push({ pathname: "/register/host/billing", state: location.pathname })}
          data_test_id="register_host_card_tab"
        />
        <Space height={24} />
        <Button
          disabled={!(is_sps_done && is_account_done && is_billing_done)}
          onClick={() => is_sps_done && is_account_done && is_billing_done && goProcessing()}
          data-testid="register_confirm_button"
        >
          파티 생성완료
        </Button>
      </Container>
    </Suspense>
  );
};

const Container = styled.div`
  padding: 40px 24px 32px 24px;
`;
const Title = styled(OldH6)`
  color: var(--gray-900);
  font-weight: normal;
`;

export default RegisterHostInit;
