import React from "react";
import styled from "styled-components";
import emoji_crown from "./src/emoji_crown.png";
import emoji_link from "./src/emoji_link.png";
import emoji_money_with_wings from "./src/emoji_money_with_wings.png";
import emoji_party_popper from "./src/emoji_party_popper.png";
import emoji_sparkles from "./src/emoji_sparkles.png";
import emoji_check_mark from "./src/emoji_check_mark.png";
import emoji_check_mark_button from "./src/emoji_check_mark_button.png";
import emoji_gift from "./src/emoji_gift.png";

interface EmojiProps {
  size?: number;
}

export const EmojiCrown = ({ size }: EmojiProps): JSX.Element => (
  <Emoji src={emoji_crown} alt="emoji_crown" size={size} />
);
export const EmojiLink = ({ size }: EmojiProps): JSX.Element => <Emoji src={emoji_link} alt="emoji_link" size={size} />;
export const EmojiMoneyWithWings = ({ size }: EmojiProps): JSX.Element => (
  <Emoji src={emoji_money_with_wings} alt="emoji_money_with_wings" size={size} />
);
export const EmojiPartyPopper = ({ size }: EmojiProps): JSX.Element => (
  <Emoji src={emoji_party_popper} alt="emoji_party_popper" size={size} />
);
export const EmojiSparkles = ({ size }: EmojiProps): JSX.Element => (
  <Emoji src={emoji_sparkles} alt="emoji_sparkles" size={size} />
);
export const EmojiCheckMark = ({ size }: EmojiProps): JSX.Element => (
  <Emoji src={emoji_check_mark} alt="emoji_check_mark" size={size} />
);
export const EmojiCheckMarkButton = ({ size }: EmojiProps): JSX.Element => (
  <Emoji src={emoji_check_mark_button} alt="emoji_check_mark_button" size={size} />
);
export const EmojiGift = ({ size }: EmojiProps): JSX.Element => <Emoji src={emoji_gift} alt="emoji_gift" size={size} />;

const Emoji = styled.img<EmojiProps>`
  width: ${(props) => props.size || 24}px;
  height: ${(props) => props.size || 24}px;
`;
