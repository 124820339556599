import React from "react";
import styled from "styled-components";
import { OldBody1 } from "components/Text";
import { ReactComponent as IconMiniPickle } from "./assets/icon_mini_pickle.svg";
import icon_arrow from "./assets/icon_arrow.png";

const OldPickleHostBox = ({ goOldHostParty }: { goOldHostParty: () => void }): JSX.Element => (
  <Container>
    <Box onClick={goOldHostParty}>
      <IconMiniPickle title="mini pickle icon" />
      <Title>(구)피클플러스 파티</Title>
      <ArrowIcon />
    </Box>
  </Container>
);

const Container = styled.div`
  padding: 16px 24px 0px;
`;
const Box = styled.div`
  display: flex;
  position: relative;
  padding: 21px 20px 17px 24px;
  border-radius: 16px;
  background-color: var(--gray-050);
  cursor: pointer;
`;
const Title = styled(OldBody1)`
  font-weight: bold;
  margin-left: 12px;
  padding-top: 1px;
`;
const ArrowIcon = styled.img.attrs({
  src: icon_arrow,
  alt: "icon_arrow",
})`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 21px;
  right: 20px;
`;

export default OldPickleHostBox;
