import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Lottie from "react-lottie";

const BlurLoading = (): JSX.Element => {
  const z_index = 40;
  const [motion, setMotion] = useState<Record<string, unknown> | null>(null);

  useEffect(() => {
    import("./assets/pageChangeLoading.json").then((motion) => setMotion(motion));
    return;
  }, []);
  return (
    <>
      <ProgressBar z_index={z_index + 1}>
        <Lottie
          width="100%"
          height="11px"
          isClickToPauseDisabled={false}
          options={{
            animationData: motion,
            loop: true,
            autoplay: true,
          }}
        />
      </ProgressBar>
      <Blur z_index={z_index} />
    </>
  );
};

const ProgressBar = styled.div<{ z_index: number }>`
  position: fixed;
  top: -8px;
  left: 0;
  width: 100vw;
  z-index: ${(props) => props.z_index};
`;

const Blur = styled.div<{ z_index: number }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--gray-100);
  opacity: 0.8;
  z-index: ${(props) => props.z_index};
`;

export default BlurLoading;
