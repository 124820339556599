import { OldSub } from "components/Text";
import React from "react";
import styled from "styled-components";
import { ReactComponent as IconArrowGray } from "./assets/icon_arrow_gray.svg";
import { ReactComponent as IconArrowWhite } from "./assets/icon_arrow_white.svg";
import { ReactComponent as IconCheckGreen } from "./assets/icon_check_green.svg";
import { LevelStatus } from "./LevelStatus";

interface LevelTabProps {
  title: string;
  content: JSX.Element;
  status: LevelStatus;
  onClick: () => void;
  data_test_id?: string;
}

const LevelTab = ({ title, content, status, onClick, data_test_id }: LevelTabProps): JSX.Element => (
  <TabContainer>
    <Tab>
      {title}
      <Content>{content}</Content>
    </Tab>
    <TabButton status={status} onClick={() => status !== LevelStatus.STANDBY && onClick()} data-testid={data_test_id}>
      {status === LevelStatus.DONE ? (
        <IconCheckGreen height={"21.4px"} width={"21.4px"} />
      ) : status === LevelStatus.RECOMMEND ? (
        <IconArrowWhite height={"21.4px"} width={"21.4px"} />
      ) : (
        <IconArrowGray height={"21.4px"} width={"21.4px"} />
      )}
    </TabButton>
  </TabContainer>
);

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: var(--gray-100);
  border-radius: 8px;
  width: 100%;
  font-weight: bold;
  color: var(--gray-900);
`;

const Content = styled(OldSub)`
  margin-top: 8px;
  color: var(--gray-900);
`;

const TabButton = styled.div<{ status: LevelStatus }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.status === LevelStatus.RECOMMEND ? "var(--primary-500)" : "var(--gray-100)")};
  min-width: 56px;
  border-radius: 8px;
  justify-self: flex-end;
  margin-left: 4px;
  cursor: ${(props) => props.status !== LevelStatus.STANDBY && "pointer"};
`;

export default LevelTab;
