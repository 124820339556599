import { call, put, takeLatest } from "redux-saga/effects";
import { checkDuplicate, resetPassword } from "apis";
import { logError, StatusCodes } from "globals/utils";
import { setImpUid } from "./slice";
import { logIn } from "globals/auth";

function* checkUserSaga(action: { type: string; payload: { imp_uid: string; goPasswordInput: () => void } }) {
  try {
    const response = yield call(checkDuplicate, action.payload.imp_uid);
    if (response.status === StatusCodes.OK_200) {
      const { duplicate: is_duplicate } = response.data;
      if (is_duplicate) {
        yield put(setImpUid(action.payload.imp_uid));
        action.payload.goPasswordInput();
      }
    }
  } catch (e) {
    logError(e);
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.NOT_ACCEPTABLE_406:
          alert("미성년자는 피클플러스 서비스를 이용하실 수 없습니다! 양해 부탁드립니다.");
          break;
        default:
          alert("회원 정보를 찾는 과정에서 오류가 발생했습니다.\n관리자에게 문의해주세요.");
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
  }
}
export const SETPASSWORD_CHECK_USER_SAGA = "setpassword/checkUserSaga";

function* resetPasswordSaga(action: {
  type: string;
  payload: { imp_uid: string; new_password: string; goMain: () => void };
}) {
  try {
    const response = yield call(resetPassword, action.payload);
    if (response.status === StatusCodes.OK_200) {
      const { access_token, refresh_token } = response.data.data;
      window.sessionStorage.setItem("access_token", access_token);
      window.sessionStorage.setItem("refresh_token", refresh_token);
      window.localStorage.setItem("refresh_token", refresh_token);
      alert("비밀번호 변경이 완료되었습니다");
      yield put(logIn());
      action.payload.goMain();
    }
  } catch (e) {
    logError(e);
    if (e.response.data) {
      const { code } = e.response.data;
      switch (code) {
        case "USR2040":
          alert("전의 비밀번호와 다른 비밀번호로 설정해주세요.");
          break;
        default:
          alert(`에러가 발생했습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.\n${e.response.data?.msg}`);
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
  }
}
export const SETPASSWORD_RESET_PASSWORD_SAGA = "setpassword/resetPasswordSaga";

function* setpasswordSaga(): Generator {
  yield takeLatest(SETPASSWORD_CHECK_USER_SAGA, checkUserSaga);
  yield takeLatest(SETPASSWORD_RESET_PASSWORD_SAGA, resetPasswordSaga);
}

export default setpasswordSaga;
