export type InputState = "DEFAULT" | "ACTIVE" | "HOVER" | "DISABLED" | "ERROR";

interface InputThemeProps {
  border_bottom: string;
  color: string;
  font_weight: "bold" | "normal";
}

export const input_theme: { [key in InputState]: InputThemeProps } = {
  DEFAULT: {
    border_bottom: "1px solid var(--gray-300)",
    color: "var(--gray-400)",
    font_weight: "normal",
  },
  ACTIVE: {
    border_bottom: "2px solid var(--primary-500)",
    color: "var(--gray-700)",
    font_weight: "bold",
  },
  HOVER: {
    border_bottom: "1px solid var(--gray-400)",
    color: "var(--gray-500)",
    font_weight: "normal",
  },
  DISABLED: {
    border_bottom: "1px solid var(--gray-200)",
    color: "var(--gray-300)",
    font_weight: "normal",
  },
  ERROR: {
    border_bottom: "2px solid var(--red-400)",
    color: "var(--gray-700)",
    font_weight: "bold",
  },
};

export type InputDescriptionType = "DEFAULT" | "SUCCESS" | "ERROR";
