import { call, put, takeLatest } from "redux-saga/effects";
import { signUp, checkDuplicate, PixelEvent } from "apis";
import { KakaoMoments } from "apis";
import { logError, StatusCodes } from "globals/utils";
import { logIn, AUTH_SET_USER_SAGA } from "globals/auth";
import { LOGIN_KAKAO_SYNC_SAGA } from "containers/Login";
import { Amplitude } from "apis/amplitude";

function* checkDuplicateAndSignUpSaga(action: {
  type: string;
  payload: { imp_uid: string; goRedirectUrl: () => void };
}) {
  try {
    const response = yield call(checkDuplicate, action.payload.imp_uid);
    const { duplicate: is_duplicate, refresh_token, access_token } = response.data;
    if (is_duplicate) {
      window.sessionStorage.setItem("refresh_token", refresh_token);
      window.sessionStorage.setItem("access_token", access_token);
      window.localStorage.setItem("refresh_token", refresh_token);
      yield put({ type: LOGIN_KAKAO_SYNC_SAGA, payload: { goRedirectUrl: action.payload.goRedirectUrl } });
      return;
    }
    yield put({
      type: SIGNUP_SIGN_UP_SAGA,
      payload: { imp_uid: action.payload.imp_uid, goRedirectUrl: action.payload.goRedirectUrl },
    });
  } catch (e) {
    logError(e);
  }
}
export const SIGNUP_CHECK_DUPLICATE_AND_SIGN_UP_SAGA = "signUp/checkDuplicateAndSignUpSaga";

function* signupSaga(action: { type: string; payload: { imp_uid: string; goRedirectUrl: () => void } }) {
  try {
    const kakao_refresh_token = window.localStorage.getItem("kakao_access_token");
    const { imp_uid } = action.payload;
    const response = yield call(signUp, {
      imp_uid,
      info: {
        oauth_service_name: "kakao",
        oauth_access_token: kakao_refresh_token,
      },
    });
    const { access_token, refresh_token } = response.data.data;
    KakaoMoments.signUp();
    PixelEvent.signUp();
    Amplitude.signUp({ imp_uid });
    window.sessionStorage.setItem("refresh_token", refresh_token);
    window.sessionStorage.setItem("access_token", access_token);
    window.localStorage.setItem("refresh_token", refresh_token);
    yield put(logIn());
    yield put({ type: AUTH_SET_USER_SAGA });
    action.payload.goRedirectUrl();
  } catch (e) {
    if (e.response) {
      const { status } = e.response;
      switch (status) {
        case StatusCodes.NOT_FOUND_404:
          alert("카카오 회원 정보 조회에 실패했습니다.");
          break;
        case StatusCodes.BAD_REQUEST_400:
          alert(e.response.data?.msg);
          break;
        case StatusCodes.NOT_ACCEPTABLE_406:
          alert("미성년자는 피클플러스를 이용하실 수 없습니다! 양해 부탁드립니다.");
          break;
        default:
          alert(`에러가 발생했습니다. 서비스 이용에 문제가 있는 경우 문의·상담을 남겨주세요.\n${e.response.data?.msg}`);
          break;
      }
    } else
      alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
  }
}
export const SIGNUP_SIGN_UP_SAGA = "signUp/signUpSaga";

function* signUpSaga(): Generator {
  yield takeLatest(SIGNUP_CHECK_DUPLICATE_AND_SIGN_UP_SAGA, checkDuplicateAndSignUpSaga);
  yield takeLatest(SIGNUP_SIGN_UP_SAGA, signupSaga);
}

export default signUpSaga;
