import React, { useState } from "react";
import InputWithLabel from "components/Input/InputWithLabel";
import ButtonLegacy from "components/Button";
import styled from "styled-components";
import { OldH5 } from "components/Text";

const PasswordInput = ({ resetPassword }: { resetPassword: (new_password: string) => void }): JSX.Element => {
  const PasswordForm = /^[a-zA-Z0-9`~!@#$%^&*()_=+:;“’/?.>,<}{-]{6,40}$/;
  const [password, setPassword] = useState<{ is_complete: boolean; value: string }>({ is_complete: false, value: "" });

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const new_password = { ...password };
    const value = e.currentTarget.value;
    if (PasswordForm.test(value) && value.search(/[a-z]/gi) !== -1) new_password.is_complete = true;
    else new_password.is_complete = false;
    new_password.value = e.currentTarget.value;
    setPassword({ ...new_password });
  };

  return (
    <Container>
      <Title>
        새로운 비밀번호를 <br />
        등록해주세요
      </Title>
      <InputWithLabel
        label="비밀번호"
        placeholder="영문 포함 6자 이상"
        type="password"
        is_complete={password.is_complete}
        value={password.value}
        onChange={onChangePassword}
      />
      <Margin />
      <ButtonLegacy
        disabled={!password.is_complete}
        onClick={() => password.is_complete && resetPassword(password.value)}
      >
        완료
      </ButtonLegacy>
    </Container>
  );
};

const Container = styled.div`
  padding: 64px 24px 30px 24px;
`;

const Title = styled(OldH5)`
  color: var(--gray-900);
  margin-bottom: 80px;
`;

const Margin = styled.div`
  height: 330px;
`;

export default PasswordInput;
