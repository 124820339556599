import React from "react";

import styled from "styled-components";

const ProgressBar = ({ percent }: { percent: number }): JSX.Element => (
  <Frame>
    <Gauge percent={percent} />
  </Frame>
);
const Frame = styled.div`
  width: 100%;
  max-width: 640px;
  height: 6px;
  background-color: var(--gray-100);
`;

const Gauge = styled.div<{ percent: number }>`
  width: ${(props) => props.percent}%;
  height: 100%;
  background-color: var(--primary-500);
  border-radius: 0 3px 3px 0;
  transition: ease-out 0.3s;
`;

export default ProgressBar;
