import { getAccessToken, removeAuthToken } from "./token";
import { call, put, takeLatest } from "redux-saga/effects";
import { getUser } from "apis";
import { ChannelIoUserProfileData } from "./interface";
import { CHANNEL_IO, PIXEL_ID } from "globals/setting";
import { Amplitude } from "apis/amplitude";
import { logError, removeSelectedInfo } from "globals/utils";
import { logIn, startInitialize, endInitialize, logOut } from "globals/auth";
import { setLoading, setUser } from "globals/user";

function* initializeAuthSaga() {
  yield put(startInitialize());
  try {
    const access_token = yield call(getAccessToken);
    if (access_token) {
      yield put({
        type: AUTH_LOG_IN_SAGA,
        payload: { access_token },
      });
    } else {
      yield put({ type: AUTH_SET_CHANNEL_IO_NOT_USER_SAGA });
    }
  } catch (e) {
    logError(e);
  }
  yield put(endInitialize());
}
export const AUTH_INITIALIZE_AUTH_SAGA = "auth/initializeAuthSaga";

function* logInSaga(action: {
  type: string;
  payload: { access_token?: string; refresh_token?: string; is_remember?: boolean; goRedirectUrl?: () => void };
}) {
  const { access_token, refresh_token, is_remember, goRedirectUrl } = action.payload;
  if (refresh_token) window.sessionStorage.setItem("refresh_token", refresh_token);
  if (is_remember && refresh_token) window.localStorage.setItem("refresh_token", refresh_token);
  if (access_token) window.sessionStorage.setItem("access_token", access_token);
  yield put(logIn());
  yield put({ type: AUTH_SET_USER_SAGA });
  if (goRedirectUrl) goRedirectUrl();
}
export const AUTH_LOG_IN_SAGA = "auth/logInSaga";

function* logOutSaga() {
  yield put(logOut());
  removeAuthToken();
  removeSelectedInfo();
  Amplitude.logOut();
  window.location.href = "/";
}
export const AUTH_LOG_OUT_SAGA = "auth/logOutSaga";

function* setChannelIONotUserSaga() {
  window.ChannelIO("boot", {
    pluginKey: CHANNEL_IO,
    hideChannelButtonOnBoot: false,
    language: "ko",
  });
  window.ChannelIO("hideChannelButton");
  yield put(setLoading(false));
}
export const AUTH_SET_CHANNEL_IO_NOT_USER_SAGA = "auth/setChannelIONotUserSaga";

function* setUserSaga() {
  yield put(setLoading(true));
  const channelIOProfile: ChannelIoUserProfileData = {};
  try {
    const user_response = yield call(getUser);
    if (user_response.status === 200) {
      const {
        is_old_user,
        real_name,
        phone,
        kakao_id,
        is_adult,
        channel_io_member_id,
        is_special,
      } = user_response.data.data;
      channelIOProfile.is_adult = is_adult;
      channelIOProfile.is_kakao_user = Boolean(kakao_id);
      channelIOProfile.is_old_user = is_old_user;
      channelIOProfile.mobileNumber = phone;
      channelIOProfile.name = real_name;
      window.ChannelIO("boot", {
        pluginKey: CHANNEL_IO,
        hideChannelButtonOnBoot: false,
        language: "ko",
        memberId: channel_io_member_id,
        profile: channelIOProfile,
      });
      window.ChannelIO("hideChannelButton");
      if (PIXEL_ID) {
        window.fbq("init", PIXEL_ID, {
          external_id: channel_io_member_id,
        });
      }
      let phone_display: string;
      if (phone) {
        const masked_phone = `${phone.substring(0, 3)}-${phone.substring(3, 5)}**-${phone.substring(7, 9)}**`;
        phone_display = masked_phone;
      } else {
        phone_display = "새 휴대폰 번호에 대한 정보가 없습니다";
      }
      yield put(
        setUser({
          phone: phone_display,
          real_name,
          is_old_user,
          is_special,
        }),
      );
      phone && Amplitude.setUser({ hashed_user_id: channel_io_member_id, phone, real_name });
    }
  } catch (e) {
    if (e.response?.data) logError(e);
  }
  yield put(setLoading(false));
}
export const AUTH_SET_USER_SAGA = "auth/setUserSaga";

function* authSaga(): Generator {
  yield takeLatest(AUTH_INITIALIZE_AUTH_SAGA, initializeAuthSaga);
  yield takeLatest(AUTH_LOG_IN_SAGA, logInSaga);
  yield takeLatest(AUTH_LOG_OUT_SAGA, logOutSaga);
  yield takeLatest(AUTH_SET_CHANNEL_IO_NOT_USER_SAGA, setChannelIONotUserSaga);
  yield takeLatest(AUTH_SET_USER_SAGA, setUserSaga);
}

export default authSaga;
