import { AxiosResponse } from "axios";
import { AuthAxios } from "./default";
import { OLD_DJANGO_URL } from "globals/setting";

export const getBankAccount = async (): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/user/account`,
    method: "GET",
  });
  return response;
};

export const createBankAccount = async (data: {
  account_bank: string;
  account_num: string;
  account_name: string;
}): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/user/account`,
    method: "POST",
    data,
  });
  return response;
};

export const deleteBankAccount = async (): Promise<AxiosResponse> => {
  const response = await AuthAxios({
    url: `${OLD_DJANGO_URL}/user/account`,
    method: "DELETE",
  });
  return response;
};
