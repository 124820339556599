import { BlurLoading } from "components/Loading";
import { AuthRoute, RestrictRoute } from "globals/utils";
import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Redirect, useLocation } from "react-router-dom";
import { cleanUpPartyLeave } from "./slice";

const PartyLeaveInit = lazy(() => import("./PartyLeaveInit"));
const Refund = lazy(() => import("./Refund"));
const ProfileDelete = lazy(() => import("./ProfileDelete"));

const PartyLeaveRoute = (): JSX.Element => {
  const location = useLocation();
  const dispatch = useDispatch();
  const cleanUpPartyLeaveDispatch = () => dispatch(cleanUpPartyLeave());

  useEffect(
    () => () => {
      cleanUpPartyLeaveDispatch();
    },
    [],
  );
  return (
    <Suspense fallback={<BlurLoading />}>
      <Switch>
        <AuthRoute path="/myparty/leave/init" component={PartyLeaveInit} />
        <RestrictRoute
          is_pass={location.state === "/myparty/leave/init"}
          redirect_url="/myparty"
          path="/myparty/leave/refund"
          component={Refund}
        />
        <RestrictRoute
          is_pass={location.state === "/myparty/leave/init"}
          redirect_url="/myparty"
          path="/myparty/leave/profile_delete"
          component={ProfileDelete}
        />
        <Redirect to="/myparty/leave/init" />
      </Switch>
    </Suspense>
  );
};

export default PartyLeaveRoute;
