import styled from "styled-components";
import { input_theme, InputState } from "./interface";

const DefaultInput = styled.input<{
  width?: string | number;
  state: InputState;
  textalign?: string;
  height?: string;
}>`
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  caret-color: var(--primary-500);
  -moz-appearance: textfield;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  height: ${(props) => (props.height ? props.height : "36px")};
  width: ${(props) => props.width || "40px"};
  border-radius: 0;
  border-bottom: ${({ state }) => input_theme[state].border_bottom};
  font-family: SpoqaHanSansNeo;
  font-size: 14px;
  font-weight: ${({ state }) => input_theme[state].font_weight};
  line-height: 20px;
  text-align: ${(props) => props.textalign || "left"};
  color: ${({ state }) => input_theme[state].color};

  :disabled {
    background-color: var(--white);
  }
  ::placeholder {
    color: var(--gray-400);
  }

  :focus {
    border-bottom: ${({ state }) =>
      state === "DISABLED" ? input_theme["DISABLED"].border_bottom : "2px solid var(--primary-500)"};
  }
`;

export default DefaultInput;
