import { PartyInfoResponse } from "containers/Admin";
import React from "react";
import { Container, Row, Title, SubTitle, WaitText, Column, Value, DataRow } from "./AdminStyle";

export interface MatchingPersonData {
  person_id: number;
  space_id: number;
  user_uid: number;
  user: { real_name: string };
  start_dt: Date;
}
export interface MatchingPartySpaceData {
  space_id: number;
  party_id: number;
  host_id: number;
  host_name: string;
  member_id?: string;
  start_dt: Date;
}
const Admin = ({
  merchant_id,
  people_page,
  empty_space_page,
  filled_space_page,
  matching_people,
  empty_party_spaces,
  filled_party_spaces,
  people_cnt,
  empty_space_cnt,
  filled_space_cnt,
  onChange,
  onPeoplePageChange,
  onEmptySpacePageChange,
  onFilledSpacePageChange,
  onPartyIdChange,
  onPartyIdClick,
  party_info,
}: {
  merchant_id: number;
  people_page: number;
  empty_space_page: number;
  filled_space_page: number;
  matching_people?: MatchingPersonData[];
  empty_party_spaces?: MatchingPartySpaceData[];
  filled_party_spaces?: MatchingPartySpaceData[];
  people_cnt: number;
  empty_space_cnt: number;
  filled_space_cnt: number;
  onChange: (event: React.FormEvent) => void;
  onPeoplePageChange: (event: React.FormEvent) => void;
  onEmptySpacePageChange: (event: React.FormEvent) => void;
  onFilledSpacePageChange: (event: React.FormEvent) => void;
  onPartyIdChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onPartyIdClick: () => void;
  party_info?: PartyInfoResponse;
}): JSX.Element => (
  <Container>
    <Row>
      <Title>상품 선택</Title>
      <select style={{ height: "30px" }} value={merchant_id} id="merchant_select" onChange={onChange}>
        <option value="1" key={"netflix_1"}>
          넷플릭스
        </option>
        <option value="2" key={"wavve_2"}>
          웨이브
        </option>
        <option value="3" key={"watcha_3"}>
          왓챠
        </option>
        <option value="4" key={"laftel_4"}>
          라프텔
        </option>
      </select>
    </Row>
    <SubTitle>통계</SubTitle>
    <WaitText>계정 미등록자 대기중 : {people_cnt}명</WaitText>
    <WaitText>계정 매칭공간 대기중 : {empty_space_cnt}개</WaitText>
    <WaitText>계정 매칭공간 매칭완료 : {filled_space_cnt}개</WaitText>
    <SubTitle>계정 미등록자 대기중</SubTitle>
    <select style={{ height: "30px" }} value={people_page} onChange={onPeoplePageChange}>
      {[...Array(Math.floor(people_cnt / 50) + 1)].map((page, index) => (
        <option value={index + 1} key={`page_${index}`}>
          {index + 1}
        </option>
      ))}
    </select>
    <Row>
      <Column>person_id </Column>
      <Column>user_uid </Column>
      <Column width="200px">실명 </Column>
      <Column width="300px">매칭시작시각 </Column>
      <Column>space_id</Column>
    </Row>
    {matching_people?.map((person, index) => (
      <Row key={`person_${person.person_id}_${index}`}>
        <Value>{person.person_id}</Value>
        <Value>{person.user_uid}</Value>
        <Value width="200px">{person.user.real_name}</Value>
        <Value width="300px">{person.start_dt}</Value>
        <Value>{person.space_id}</Value>
      </Row>
    ))}
    <SubTitle>계정 등록자 대기중</SubTitle>
    <select style={{ height: "30px" }} value={empty_space_page} onChange={onEmptySpacePageChange}>
      {[...Array(Math.floor(empty_space_cnt / 50) + 1)].map((page, index) => (
        <option value={index + 1} key={`page_${index}`}>
          {index + 1}
        </option>
      ))}
    </select>
    <Row>
      <Column>space_id</Column> <Column>party_id</Column> <Column>host_user_uid</Column>
      <Column width="300px">매칭시작시각</Column>
    </Row>
    {empty_party_spaces?.map((space, index) => (
      <DataRow key={`space_${space.space_id}_${index}`}>
        <Value>{space.space_id}</Value>
        <Value>{space.party_id}</Value>
        <Value>{space.host_id}</Value>
        <Value width="300px">{space.start_dt}</Value>
      </DataRow>
    ))}
    <SubTitle>계정 등록자 매칭완료</SubTitle>
    <select style={{ height: "30px" }} value={filled_space_page} onChange={onFilledSpacePageChange}>
      {[...Array(Math.floor(filled_space_cnt / 50) + 1)].map((page, index) => (
        <option value={index + 1} key={`page_${index}`}>
          {index + 1}
        </option>
      ))}
    </select>
    <Row>
      <Column>space_id</Column> <Column>party_id</Column> <Column>host_user_uid</Column>
      <Column width="300px">매칭시작시각</Column>
    </Row>
    {filled_party_spaces?.map((space, index) => (
      <DataRow key={`filled_space_${space.space_id}_${index}`}>
        {" "}
        <Value>{space.space_id}</Value>
        <Value>{space.party_id}</Value>
        <Value>{space.host_id}</Value>
        <Value width="350px">{space.start_dt}</Value>
      </DataRow>
    ))}
    <SubTitle>파티 정보 조회</SubTitle>
    <input type="number" placeholder="party_id" onChange={onPartyIdChange} />
    <button onClick={onPartyIdClick}>조회</button>
    <Row>
      <Column>OTT</Column>
      <Column>호스트명</Column>
      <Column>전화번호</Column>
      <Column width="320px">아이디</Column>
      <Column width="200px">비밀번호</Column>
      <Column>성인 인증 비밀번호</Column>
      <Column>해체 여부</Column>
    </Row>
    <DataRow>
      <Value>{party_info?.data.ott_name}</Value>
      <Value>{party_info?.data.real_name}</Value>
      <Value>{party_info?.data.phone}</Value>
      <Value width="320px">{party_info?.data.sps_key}</Value>
      <Value width="200px">{party_info?.data.sps_value}</Value>
      <Value>{party_info?.data?.sps_pin || "없음"}</Value>
      <Value>{party_info?.data.is_deleted}</Value>
    </DataRow>
  </Container>
);

export default Admin;
