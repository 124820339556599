import keb from "./assets/icon-keb.png";
import kb from "./assets/icon-kb@3x.png";
import shinhan from "./assets/icon-shinhan.png";
import nh from "./assets/icon-nh.png";
import woori from "./assets/icon-woori@3x.png";
import citi from "./assets/icon-citi@3x.png";
import post from "./assets/icon-post@3x.png";
import daegu from "./assets/icon-daegu@3x.png";
import hsbc from "./assets/icon-hsbc@3x.png";
import ibk from "./assets/icon-ibk@3x.png";
import k from "./assets/icon-k@3x.png";
import kakao from "./assets/icon-kakao@3x.png";
import samaeul from "./assets/icon-samaeul@3x.png";
import bnk from "./assets/icon-bnk@3x.png";
import suhyup from "./assets/icon-suhyup@3x.png";
import gwangjun from "./assets/icon-g-j@3x.png";
import shinhyup from "./assets/icon-shinhyup@3x.png";
import sc from "./assets/icon-sc@3x.png";
import kdb from "./assets/icon-kdb@3x.png";
import w from "./assets/icon-w@3x.png";
import suchull from "./assets/icon-suchull@3x.png";
import sangho from "./assets/icon-sangho@3x.png";
import acuon from "./assets/icon-acuon@3x.png";
import sbi from "./assets/icon-sbi@3x.png";
import daesin from "./assets/icon-daesin@3x.png";
import welcome from "./assets/icon-welcome@3x.png";

const banks = [
  {
    bank_code: "25",
    name: "KEB하나",
    img_src: keb,
  },
  {
    bank_code: "04",
    name: "KB국민",
    img_src: kb,
  },
  {
    bank_code: "21",
    name: "신한",
    img_src: shinhan,
  },
  {
    bank_code: "10",
    name: "NH농협",
    img_src: nh,
  },
  {
    bank_code: "20",
    name: "우리",
    img_src: woori,
  },
  {
    bank_code: "27",
    name: "씨티",
    img_src: citi,
  },
  {
    bank_code: "71",
    name: "우체국",
    img_src: post,
  },
  {
    bank_code: "31",
    name: "대구",
    img_src: daegu,
  },
  {
    bank_code: "54",
    name: "HSBC",
    img_src: hsbc,
  },
  {
    bank_code: "03",
    name: "IBK기업",
    img_src: ibk,
  },
  {
    bank_code: "89",
    name: "케이뱅크",
    img_src: k,
  },
  {
    bank_code: "90",
    name: "카카오뱅크",
    img_src: kakao,
  },
  {
    bank_code: "45",
    name: "새마을",
    img_src: samaeul,
  },
  {
    bank_code: "32",
    name: "부산",
    img_src: bnk,
  },
  {
    bank_code: "39",
    name: "경남",
    img_src: bnk,
  },
  {
    bank_code: "07",
    name: "수협",
    img_src: suhyup,
  },
  {
    bank_code: "35",
    name: "제주",
    img_src: shinhan,
  },
  {
    bank_code: "34",
    name: "광주",
    img_src: gwangjun,
  },
  {
    bank_code: "37",
    name: "전북",
    img_src: gwangjun,
  },
  {
    bank_code: "48",
    name: "신협",
    img_src: shinhyup,
  },
  {
    bank_code: "23",
    name: "SC제일",
    img_src: sc,
  },
  {
    bank_code: "02",
    name: "KDB산업",
    img_src: kdb,
  },
  {
    bank_code: "05",
    name: "외환",
    img_src: w,
  },
  {
    bank_code: "08",
    name: "수출입",
    img_src: suchull,
  },
  {
    bank_code: "50",
    name: "상호저축",
    img_src: sangho,
  },
  {
    bank_code: "102",
    name: "대신저축",
    img_src: daesin,
  },
  {
    bank_code: "103",
    name: "SBI저축",
    img_src: sbi,
  },
  {
    bank_code: "104",
    name: "애큐온저축",
    img_src: acuon,
  },
  {
    bank_code: "105",
    name: "웰컴저축",
    img_src: welcome,
  },
];

export default banks;
