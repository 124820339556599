import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./interface";

const initialState: AuthState = {
  is_login: false,
  is_initialized: false,
  is_trying_pay: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logIn(state) {
      state.is_login = true;
    },
    startInitialize(state) {
      state.is_initialized = false;
    },
    endInitialize(state) {
      state.is_initialized = true;
    },
    logOut(state) {
      state.is_login = false;
    },
    setTryingPay(state) {
      state.is_trying_pay = true;
    },
    cleanupTryingPay(state) {
      state.is_trying_pay = false;
    },
  },
});

export const { logIn, logOut, startInitialize, endInitialize, setTryingPay, cleanupTryingPay } = authSlice.actions;
export default authSlice.reducer;
