import TopNavigation from "./TopNavigation";
import navigation_reducer, { selectNavigation, setPageNavigation } from "./slice";
import { NavigationType, TabNavData, DesktopTabNavData } from "./interfaces";
import TabNavIcon from "./TabNavIcon";
import TabNavigation from "./TabNavigation";
import PageNavigation from "./PageNavigation";

export {
  navigation_reducer,
  TabNavIcon,
  selectNavigation,
  setPageNavigation,
  NavigationType,
  TabNavigation,
  PageNavigation,
};
export type { TabNavData, DesktopTabNavData };

export default TopNavigation;
