import styled from "styled-components";

export const SectionLocationGuide = styled.div`
  width: 0;
  height: 0;
`;

export const CtaSection = styled.div<{ top: number; height: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 50px;
  width: 100%;
  max-width: 640px;
  height: ${({ height }) => height}px;
  top: ${({ top }) => top}px;
  transition: opacity 0.3s ease-out;
  opacity: 1;
  pointer-events: none;
`;

export const CtaButtonContainer = styled.div`
  position: sticky;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 98px;
  opacity: 0;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out, background-image 0.3s ease-out;
  transform: translate(0, 100px);
  pointer-events: visible;
`;

export const WhiteGradient = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 98px;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1) 61%, rgba(255, 255, 255, 0));
  opacity: 1;
`;

export const BlackGradient = styled.div`
  position: sticky;
  z-index: 3;
  top: 0;
  width: 100%;
  height: 98px;
  background-image: linear-gradient(to top, var(--gray-900), var(--gray-900) 61%, rgba(27, 28, 31, 0));
  transition: opacity 0.1s ease-out;
  opacity: 0;
`;

export const CtaButtonFloater = styled.div`
  position: absolute;
  top: 0;
  z-index: 4;
  padding: 18px 20px 0 20px;
  width: 100%;
  height: 98px;
`;
