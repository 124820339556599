import React from "react";
import styled from "styled-components";
import ButtonLegacy from "components/Button";

const DeleteButtons = ({
  is_last,
  goBack,
  goNext,
  is_matching,
}: {
  is_last: boolean;
  goBack: () => void;
  goNext: () => void;
  is_matching?: boolean;
}): JSX.Element => (
  <ButtonContainer>
    <ButtonLegacy width={159} height={52} theme="gray-weak" onClick={goBack}>
      취소
    </ButtonLegacy>
    <ButtonLegacy width={159} height={52} theme={is_last ? "red-fill" : "gray-fill"} onClick={goNext}>
      {is_matching ? "매칭취소" : is_last ? "파티해체" : "다음"}
    </ButtonLegacy>
  </ButtonContainer>
);

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 24px 32px 24px;
`;

export default DeleteButtons;
