import React, { lazy, Suspense, useEffect, useState } from "react";
import qs from "qs";
import { useDispatch } from "react-redux";
import { useRootState } from "globals/utils";
import { setMyProfileName } from "./slice";
import { useHistory } from "react-router-dom";
import { BlurLoading } from "components/Loading";
import { ProfileSet } from "components/MyParty";
import { MYPARTY_SET_PROFILE_NAME_SAGA } from "./saga";
import { SLOT_SET_SLOT_SAGA } from "containers/Slot";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const SetProfile = lazy(() => import("components/MyParty").then(({ SetProfile }) => ({ default: SetProfile })));

const SetProfileContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const merchant_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_id,
  );

  const slot = useRootState((state) => state.slot.slot);
  const my_member_id = useRootState((state) => state.slot.slot?.member_id);
  const { my_profile_name, is_loading } = useRootState((state) => state.myparty.set_profile);
  const goMyParty = () => history.push("/myparty");
  const setMyProfileNameDispatch = (value: string) => dispatch(setMyProfileName(value));
  const setSlotSagaDispatch = (merchant_id: number) =>
    dispatch({ type: SLOT_SET_SLOT_SAGA, payload: { merchant_id, goMyParty } });
  const setProfileNameSagaDispatch = (profile_name: string) =>
    dispatch({
      type: MYPARTY_SET_PROFILE_NAME_SAGA,
      payload: {
        my_member_id,
        party_id: slot?.party_id,
        profile_name: my_profile_name,
        merchant_id,
        goPartyInfo: () => {
          history.push({
            pathname: "/myparty/info",
            search: `?merchant_id=${merchant_id}`,
            state: {
              is_first: true,
              profile_name,
            },
          });
        },
      },
    });

  const myProfileOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (value.length > 6) {
      return;
    }
    setMyProfileNameDispatch(value);
  };

  const isDuplicatedProfileName = (profile_name: string) => {
    let is_duplicated = false;
    if (profile_name === slot?.host?.profile_name) is_duplicated = true;
    slot?.members?.map((member) => {
      if (member.member_id !== my_member_id && member.profile_name === profile_name) is_duplicated = true;
    });
    return is_duplicated;
  };

  const submitProfile = () => {
    if (isDuplicatedProfileName(my_profile_name)) {
      alert("똑같은 프로필 닉네임이 존재합니다.\n다른 프로필 닉네임으로 설정해주세요.");
      return;
    }
    setProfileNameSagaDispatch(my_profile_name);
  };

  const getProfiles = (): ProfileSet[] => {
    const host_profile: ProfileSet = {
      profile_name: slot?.host && slot?.host?.profile_name ? slot.host.profile_name : "(닉네임 미정)",
      label: "파티장",
    };
    const member_profiles: ProfileSet[] = [];
    if (slot?.members) {
      for (const member of slot.members) {
        if (member.member_id !== my_member_id) {
          member_profiles.push({ profile_name: member.profile_name || "(닉네임 미정)", label: "파티원" });
        }
      }
    }
    return [host_profile, ...member_profiles];
  };

  useEffect(() => {
    setSlotSagaDispatch(merchant_id);
    return () => {
      setMyProfileNameDispatch("");
    };
  }, []);

  const [is_enable, setIsEnable] = useState<boolean>(false);

  useEffect(() => {
    setIsEnable(my_profile_name.length > 0 && my_profile_name.length <= 6);
    return;
  }, [my_profile_name]);

  return is_loading ? (
    <BlurLoading />
  ) : (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation title={`${slot?.merchant_name || ""} 프로필 닉네임 기입`} back_path="/myparty" />
      <SetProfile
        merchant_name={slot?.merchant_name || ""}
        profile_img_src={slot?.merchant_profile_image_url || ""}
        profile_img_license={slot?.merchant_profile_image_license || ""}
        profiles={getProfiles()}
        is_host={!!slot?.host_yn}
        my_profile_name={my_profile_name}
        myProfileOnChange={myProfileOnChange}
        is_enable={!is_loading && is_enable}
        submitProfile={submitProfile}
      />
    </Suspense>
  );
};

export default SetProfileContainer;
