import React, { lazy, Suspense } from "react";
import { useDispatch } from "react-redux";
import { CreateBillingReqData } from "apis/billing";
import { useHistory } from "react-router-dom";
import { FullLoading } from "components/Loading";
import { useRootState } from "globals/utils";
import { MYPAGE_CREATE_BILLING_SAGA } from "./saga";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const AddCard = lazy(() => import("components/AddCard"));
const AddCardTitle = lazy(() => import("components/AddCard").then(({ AddCardTitle }) => ({ default: AddCardTitle })));

const AddCardContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const is_loading = useRootState((state) => state.mypage.manage_billing.is_add_card_loading);
  const goAddCard = () => {
    history.push("/mypage/managebilling");
  };
  const createBillingDispatch = (card: CreateBillingReqData) => {
    const payload = {
      card_num: card.card_num,
      card_year: card.card_year,
      card_month: card.card_month,
      card_pw: card.card_pw,
      birth: card.birth,
    };
    return dispatch({ type: MYPAGE_CREATE_BILLING_SAGA, payload, goAddCard });
  };

  return (
    <Suspense fallback={<FullLoading />}>
      <PageNavigation back_path="/mypage/managebilling" title="결제 카드" is_loading={is_loading} />
      {is_loading ? (
        <FullLoading />
      ) : (
        <>
          <AddCardTitle title="결제 카드 입력" subtitle="결제 카드 정보를 입력해주세요" />
          <AddCard createBilling={createBillingDispatch} button_content="결제카드 등록" />
        </>
      )}
    </Suspense>
  );
};

export default AddCardContainer;
