import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "containers/Modal";
import { ModalProps, ModalType } from "components/Modal";
import { useHistory } from "react-router-dom";
import { FullLoading } from "components/Loading";
import { useRootState } from "globals/utils";
import { MYPAGE_SET_BILLING_SAGA, MYPAGE_DELETE_BILLING_SAGA } from "./saga";
import { MEMBERSHIP_SET_MEMBERSHIP_SAGA } from "globals/membership";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const ManageBilling = lazy(() => import("components/MyPage").then(({ ManageBilling }) => ({ default: ManageBilling })));

const ManageBillingContainer = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const billing = useRootState((state) => state.mypage.manage_billing.billing);
  const is_loading = useRootState((state) => state.mypage.manage_billing.is_loading);

  const setMembershipSagaDispatch = () => dispatch({ type: MEMBERSHIP_SET_MEMBERSHIP_SAGA });
  const setBillingDispatch = () => dispatch({ type: MYPAGE_SET_BILLING_SAGA });
  const deleteBillingDispatch = (cant_delete_modal: ModalProps) =>
    dispatch({ type: MYPAGE_DELETE_BILLING_SAGA, payload: { cant_delete_modal } });
  const closeModalDisptach = () => dispatch(closeModal());
  const goAddCard = () => history.push("/mypage/addbilling");

  const cant_delete_modal: ModalProps = {
    title: (
      <>
        파티 이용 중엔
        <br /> 결제 카드를 삭제할 수 없어요
      </>
    ),
    content: (
      <>
        피클플러스 파티 해지 후 결제 카드
        <br />
        삭제가 가능해요
      </>
    ),
    is_dimmer: true,
    type: ModalType.SIMPLE,
    buttons: [
      {
        onClick: () => {
          closeModalDisptach();
        },
        text: "확인",
        theme: "primary-fill",
      },
    ],
  };
  useEffect(() => {
    setBillingDispatch();
    setMembershipSagaDispatch();
    return;
  }, []);

  return (
    <Suspense fallback={<FullLoading />}>
      <PageNavigation back_path="/mypage" title="결제 관리" />
      {!is_loading && (
        <ManageBilling
          billing={billing}
          openAddCard={goAddCard}
          deleteCard={deleteBillingDispatch}
          cant_delete_modal={cant_delete_modal}
        />
      )}
    </Suspense>
  );
};

export default ManageBillingContainer;
