import styled from "styled-components";
import { OldH6 } from "components/Text";

export const ModalContainer = styled.div<{ z_index: number }>`
  position: fixed;
  z-index: ${(props) => props.z_index};
`;

export const ModalBox = styled.div<{ z_index: number }>`
  position: fixed;
  width: 328px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding: 24px 24px 20px 24px;
  background-color: var(--white);
  z-index: ${(props) => props.z_index + 1};
`;

export const Title = styled(OldH6)`
  color: var(--gray-900);
`;

export const Content = styled.div`
  margin-top: 8px;
`;

export const Dimmer = styled.div<{ z_index: number; is_dimmer: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => (props.is_dimmer ? "var(--black)" : "none")};
  opacity: 0.8;
  z-index: ${(props) => props.z_index};
`;

export const ModalButtonFrame = styled.div<{ is_stacked: boolean }>`
  display: flex;
  height: ${(props) => (props.is_stacked ? "88px" : "40px")};
  flex-direction: ${(props) => (props.is_stacked ? "column" : "row")};
  justify-content: space-between;
  margin-top: 32px;
  width: 280px;
`;
