import styled from "styled-components";
import { icon_diagonal_arrow } from ".";

export const Content = styled.div`
  margin-top: 8px;
  color: var(--gray-900);
`;

export const Tab = styled.div<{ color?: string }>`
  position: relative;
  padding: 20px 20px 17px 24px;
  align-items: center;
  color: ${(props) => props.color || "var(--gray-900)"};
  cursor: pointer;
`;

export const ArrowIcon = styled.img.attrs({
  src: icon_diagonal_arrow,
  alt: "icon_arrow",
})`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 20px;
`;

export const ModalText = styled.div`
  color: var(--gray-900);
`;
export const HighlightText = styled.div`
  color: var(--red-500);
`;
