import { useSelector } from "react-redux";
import { RootState } from "root.redux";

type StateSelector<T> = (state: RootState) => T;
type EqualityFn<T> = (left: T, right: T) => boolean;

function useRootState<T>(selector: StateSelector<T>, equalityFn?: EqualityFn<T>): T {
  return useSelector(selector, equalityFn);
}

export type { StateSelector };
export default useRootState;
