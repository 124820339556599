import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SetPasswordState {
  imp_uid: string;
}

const initialState: SetPasswordState = {
  imp_uid: "",
};

const setpasswordSlice = createSlice({
  name: "setpassword",
  initialState,
  reducers: {
    setImpUid(state, action: PayloadAction<string>) {
      state.imp_uid = action.payload;
    },
  },
});

export const { setImpUid } = setpasswordSlice.actions;
export default setpasswordSlice.reducer;
