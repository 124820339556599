import React, { useRef, useState } from "react";
import styled from "styled-components";
import { Subtitle1 } from "components/Text";
import Space from "components/Space";
import { EmojiCrown, EmojiPartyPopper } from "assets/emojis";
import {
  GuideContainer,
  GuideMainTextWrapper,
  GuideMainText,
  GuideSubText,
  Underline,
  CardContainer,
  CardWrapper,
} from "./style";
import Cards from "./Cards";
import { useScrollFadeIn } from "globals/utils";

const UserGuide = (): JSX.Element => {
  const [selected_type, setSelectedType] = useState<"host" | "member">("host");
  const card_ref = useRef<HTMLDivElement | null>(null);
  const [is_mouse_down, setIsMouseDown] = useState<boolean>(false);
  const [start_x, setStartX] = useState<number>(0);
  const [scroll_left, setScrollLeft] = useState<number>(0);

  const updateCard = (selected_type: "host" | "member"): void => {
    setSelectedType(selected_type);
    card_ref.current && card_ref.current.scroll({ left: 0, behavior: "smooth" });
  };

  const startDragging = (event: React.MouseEvent<HTMLDivElement>): void => {
    setIsMouseDown(true);
    const slider = card_ref.current;
    if (slider) {
      setStartX(event.pageX - slider.offsetLeft);
      setScrollLeft(slider.scrollLeft);
    }
  };

  const stopDragging = (): void => {
    setIsMouseDown(false);
  };

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (!is_mouse_down) return;
    const slider = card_ref.current;
    if (slider) {
      const x = event.pageX - slider.offsetLeft;
      const scroll = x - start_x;
      slider.scrollLeft = scroll_left - scroll;
    }
  };

  return (
    <div {...useScrollFadeIn(1, 0)}>
      <Container>
        <TabContainer>
          <TabWrapper is_selected={selected_type === "host"} onClick={() => updateCard("host")}>
            <Tab is_selected={selected_type === "host"}>파티장 이용</Tab>
          </TabWrapper>
          <TabWrapper is_selected={selected_type === "member"} onClick={() => updateCard("member")}>
            <Tab is_selected={selected_type === "member"}>파티원 이용</Tab>
          </TabWrapper>
        </TabContainer>
      </Container>
      <GuideContainer>
        <Space height={36} />
        <GuideMainText>{selected_type === "host" ? <EmojiCrown /> : <EmojiPartyPopper />}</GuideMainText>
        <GuideMainTextWrapper>
          <GuideMainText>{selected_type === "host" ? "파티 만들기" : "파티 참여하기"}</GuideMainText>
          <Underline width={selected_type === "host" ? 110 : 130} />
        </GuideMainTextWrapper>
        <Space height={18} />
        <GuideSubText>
          {selected_type === "host" ? (
            <>
              프리미엄 이용권 결제가 완료된 자신의 OTT 계정을
              <br />
              공유하여 파티를 생성할 수 있어요.
            </>
          ) : (
            <>
              프리미엄 이용권 결제가 완료된
              <br />
              OTT 계정으로 콘텐츠를 즐길 수 있어요.
            </>
          )}
        </GuideSubText>
        <CardContainer
          ref={card_ref}
          onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => startDragging(e)}
          onMouseUp={stopDragging}
          onMouseLeave={stopDragging}
          onMouseMove={(e: React.MouseEvent<HTMLDivElement>) => onMouseMove(e)}
        >
          <CardWrapper>
            <Cards type={selected_type} />
          </CardWrapper>
        </CardContainer>
        <Space height={30} />
      </GuideContainer>
    </div>
  );
};

const Container = styled.div`
  background-color: var(--white);
`;

const TabContainer = styled.div`
  display: flex;
  border-bottom: 3px solid var(--gray-200);
  justify-content: center;
  position: relative;
`;

const TabWrapper = styled.div<{ is_selected: boolean }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 32.5px;
  width: 93px;
  margin-right: 48px;
  margin-left: 48px;
  margin-bottom: -3px;
  border-bottom: ${(props) => (props.is_selected ? "3px solid var(--gray-700)" : "")};
  &:hover {
    cursor: pointer;
  }
`;

const Tab = styled(Subtitle1)<{ is_selected: boolean }>`
  color: ${(props) => (props.is_selected ? "var(--gray-700)" : "var(--gray-500)")};
  font-weight: ${(props) => (props.is_selected ? "bold" : "normal")};
`;

export default UserGuide;
