import ButtonLegacy from "components/Button";
import { OldDivider } from "components/Divider";
import Space from "components/Space";
import React from "react";
import styled from "styled-components";
import { BotLogData } from "containers/Admin";
import { OldH4 } from "components/Text";

interface AdminBotLogDetailProps {
  goBack: () => void;
  confirmLog: (party_id: number) => void;
  selected_bot_log?: BotLogData;
}

const AdminBotLogDetail = ({ goBack, confirmLog, selected_bot_log }: AdminBotLogDetailProps): JSX.Element => (
  <Container>
    <ButtonRow>
      <ButtonLegacy width={100} height={40} onClick={goBack} theme="gray-weak">
        돌아가기
      </ButtonLegacy>
      {!selected_bot_log?.is_matching && !selected_bot_log?.party_delete_dt && (
        <ButtonLegacy
          width={150}
          height={40}
          onClick={selected_bot_log ? () => confirmLog(selected_bot_log.party_id) : undefined}
          theme="primary-fill"
        >
          확인 후 매칭처리
        </ButtonLegacy>
      )}
    </ButtonRow>
    <Space height={20} />

    <Row>
      <ColumnBox>파티 ID</ColumnBox>
      <Value>{selected_bot_log?.party_id}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>검증액션</ColumnBox>
      <Value>{selected_bot_log?.action}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>성공여부</ColumnBox>
      <Value>{selected_bot_log?.is_success ? "성공" : "실패"}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>OTT 아이디</ColumnBox>
      <Value>{selected_bot_log?.sps_key}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>OTT 비밀번호</ColumnBox>
      <Value>{selected_bot_log?.sps_value}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>OTT 핀번호</ColumnBox>
      <Value>{selected_bot_log?.sps_pin}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>사용한 봇 url</ColumnBox>
      <Value>{selected_bot_log?.request_url}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>사용한 봇 서버 ip</ColumnBox>
      <Value>{selected_bot_log?.request_ip}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>봇이 마지막에 접속한 url</ColumnBox>
      <Value>{selected_bot_log?.last_url}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>실패사유</ColumnBox>
      <Value>{selected_bot_log?.failed_cause}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>로그 생성일</ColumnBox>
      <Value>{selected_bot_log?.create_dt}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>매칭 여부</ColumnBox>
      <Value>{selected_bot_log?.is_matching ? "매칭 중" : "매칭 중지"}</Value>
    </Row>
    <OldDivider height="2px" />
    <Row>
      <ColumnBox>스크린샷</ColumnBox>
      {selected_bot_log?.screenshot_url ? (
        <ScreenshotImg src={selected_bot_log?.screenshot_url} />
      ) : (
        <Value>없음</Value>
      )}
    </Row>
  </Container>
);

const Container = styled.div`
  position: relative;
  padding: 24px;
  height: calc(100vh - 260px);
  overflow-y: auto;
`;
export const SubTitle = styled(OldH4)`
  color: var(--primary-700);
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonRow = styled(Row)`
  width: 300px;
  justify-content: space-between;
`;

const ColumnBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 32px;
  color: var(--white);
  margin: 0px 10px;
  background-color: var(--blue-500);
  border-radius: 8px;
`;

const Value = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  height: 32px;
  color: var(--gray-500);
  margin-left: 10px;
`;

const ScreenshotImg = styled.img`
  width: 800px;
  height: auto;
`;

export default AdminBotLogDetail;
