import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalProps, ModalType } from "components/Modal";

export interface ModalState extends ModalProps {
  is_open?: boolean;
}

const initialState: ModalState = {
  z_index: 40,
  is_dimmer: true,
  is_dimmer_close: true,
  title: "",
  content: "",
  type: ModalType.NORMAL,
  is_open: false,
  buttons: [],
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<ModalProps>) {
      const { payload } = action;
      state.z_index = payload.z_index;
      state.is_dimmer = payload.is_dimmer;
      state.is_dimmer_close = payload.is_dimmer_close;
      state.title = payload.title;
      state.content = payload.content;
      state.element = payload.element;
      state.type = payload.type;
      state.buttons = payload.buttons;
      state.is_open = true;
    },
    closeModal(state) {
      state.is_open = false;
      state.z_index = 40;
      state.is_dimmer = true;
      state.is_dimmer_close = true;
      state.title = "";
      state.content = "";
      state.element = undefined;
      state.type = ModalType.NORMAL;
      state.buttons = [];
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
