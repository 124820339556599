import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { BlurLoading } from "components/Loading";
import {
  isLoginSelector,
  membershipSelector,
  openLoginModal,
  useRootState,
  openCommonModal,
  ModalType,
} from "globals/utils";
import { useHistory } from "react-router-dom";
import { DEFAULT_SELECTED_MERCHANT_ID_HARD, SELECTED_MERCHANT, SELECTED_USER_TYPE } from "globals/variables";
import {
  setLoading,
  setAmountLoading,
  MEMBERSHIP_SET_MEMBERSHIP_SAGA,
  MEMBERSHIP_SET_SAVED_AMOUNT_SAGA,
} from "globals/membership";
import { MATCHING_SET_MATCHING_COUNT_SAGA, MATCHING_SET_MERCHANTS_SAGA } from "./saga";
import { MERCHANDISE_SET_MERCHANDISES_SAGA } from "globals/merchandise";
import { PixelEvent } from "apis";
import Space from "components/Space";
import AdBanner from "components/AdBanner";

const TabNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ TabNavigation }) => ({ default: TabNavigation })),
);
const CurrentMatching = lazy(() =>
  import("components/Matching").then(({ CurrentMatching }) => ({ default: CurrentMatching })),
);
const MatchingStart = lazy(() =>
  import("components/Matching").then(({ MatchingStart }) => ({ default: MatchingStart })),
);
const Question = lazy(() => import("components/Matching").then(({ Question }) => ({ default: Question })));
const OldDivider = lazy(() => import("components/Divider").then(({ OldDivider }) => ({ default: OldDivider })));

const Matching = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const is_login = useRootState(isLoginSelector);
  const is_promotion = useRootState(membershipSelector).membership_data?.is_promotion;

  const selectMerchant = (merchant_id: number) => {
    if (using_merchant_ids && using_merchant_ids?.indexOf(merchant_id) !== -1) return;
    sessionStorage.setItem(SELECTED_MERCHANT, merchant_id.toString());
    setMerchantId(merchant_id.toString());
  };
  const selectUserType = (type: string) => {
    sessionStorage.setItem(SELECTED_USER_TYPE, type);
    setUserType(type);
  };
  const goRegister = (is_host_hooking: boolean, merchant_name?: string) => {
    const goNextStep = () => {
      history.push(`/register/${selected_user_type}`);
    };
    if (!is_login) {
      openLoginModal({ history });
      return;
    }
    if (selected_user_type === "member" && is_host_hooking) {
      openCommonModal({
        title: "앗, 현재 대기인원이 많아요 😱",
        content: (
          <ModalContent>
            {merchant_name} 파티매칭 발급 대기인원이 많아
            <br />
            평소보다 오래 기다려야 해요.
            <br />
            <br />
            파티장으로 시작하면 {merchant_name}를
            <br />
            즉시 이용하실 수 있어요!
          </ModalContent>
        ),
        type: ModalType.ENLARGED,
        is_dimmer: true,
        is_dimmer_close: true,
        buttons: [
          {
            onClick: () => {
              goNextStep();
            },
            text: "기다리기",
            theme: "gray-ghost",
            is_close_button: true,
          },
          {
            onClick: () => {
              sessionStorage.setItem(SELECTED_USER_TYPE, "host");
              history.push("/register/host");
            },
            text: "파티장으로 시작",
            is_close_button: true,
          },
        ],
      });
    } else if (!is_promotion || confirm("더이상 (구)피클플러스 특가를 적용받지 못하시게 됩니다. 진행하시겠습니까?")) {
      goNextStep();
      return;
    }
  };

  const is_membership_loading = useRootState(membershipSelector).is_loading;
  const setMembershipSagaDispatch = () => dispatch({ type: MEMBERSHIP_SET_MEMBERSHIP_SAGA });

  const is_count_loading = useRootState((state) => state.matching.is_count_loading);
  const matching_count = useRootState((state) => state.matching.matching_count);
  const setMatchingCountSagaDispatch = () => dispatch({ type: MATCHING_SET_MATCHING_COUNT_SAGA });

  const is_merchant_loading = useRootState((state) => state.matching.is_set_merchant_loading);
  const merchants = useRootState((state) => state.matching.merchants);
  const setMerchantsSagaDispatch = () => dispatch({ type: MATCHING_SET_MERCHANTS_SAGA });

  const merchandises = useRootState((state) => state.merchandise.merchandises);
  const is_merchandises_loading = useRootState((state) => state.merchandise.is_loading);
  const setMerchandisesSagaDispatch = () => dispatch({ type: MERCHANDISE_SET_MERCHANDISES_SAGA });

  const is_using_merchant_loading = useRootState((state) => state.matching.is_set_using_merchant_loading);
  const using_merchants = useRootState((state) => state.matching.using_merchants);
  const setSavedAmountLoadingSagaDispatch = () => {
    dispatch(setLoading(false));
    dispatch(setAmountLoading(false));
  };

  useEffect(() => {
    setMembershipSagaDispatch();
    setMatchingCountSagaDispatch();
    setMerchantsSagaDispatch();
    setMerchandisesSagaDispatch();

    window.ChannelIO("showChannelButton");
    PixelEvent.enterMatching();

    if (is_login) setSavedAmountSagaDispatch();
    if (!is_login) setSavedAmountLoadingSagaDispatch();
    const user_type = sessionStorage.getItem(SELECTED_USER_TYPE);
    if (!user_type) sessionStorage.setItem(SELECTED_USER_TYPE, "host");
    else setUserType(user_type);

    const selected_merchant = sessionStorage.getItem(SELECTED_MERCHANT);
    if (!selected_merchant) sessionStorage.setItem(SELECTED_MERCHANT, DEFAULT_SELECTED_MERCHANT_ID_HARD.toString());
    else setMerchantId(selected_merchant);

    return () => {
      window.ChannelIO("hideChannelButton");
    };
  }, []);

  const [selected_merchant_id, setMerchantId] = useState<string>(
    sessionStorage.getItem(SELECTED_MERCHANT) || DEFAULT_SELECTED_MERCHANT_ID_HARD.toString(),
  );
  const [selected_user_type, setUserType] = useState<string>(sessionStorage.getItem(SELECTED_USER_TYPE) || "host");
  const using_merchant_ids = using_merchants?.map((using_merchant) => using_merchant.merchant_id);
  const merchant_ids = merchants?.map((merchant) => merchant.merchant_id);

  const setDefaultMerchantId = () => {
    if (!using_merchant_ids?.includes(Number(selected_merchant_id))) return;

    if (using_merchant_ids.length === 0) {
      sessionStorage.setItem(SELECTED_MERCHANT, DEFAULT_SELECTED_MERCHANT_ID_HARD.toString());
      setMerchantId(DEFAULT_SELECTED_MERCHANT_ID_HARD.toString());
      return;
    }
    const not_using_merchant_ids = merchant_ids?.filter((merchant) => using_merchant_ids?.indexOf(merchant) === -1);
    const result = not_using_merchant_ids ? not_using_merchant_ids[0] : undefined;
    if (result) {
      setMerchantId(result.toString());
      sessionStorage.setItem(SELECTED_MERCHANT, result.toString());
    }
  };

  useEffect(() => {
    if (is_login && !is_merchant_loading && !is_using_merchant_loading) setDefaultMerchantId();
    return;
  }, [is_merchant_loading, is_using_merchant_loading]);

  const setSavedAmountSagaDispatch = () => dispatch({ type: MEMBERSHIP_SET_SAVED_AMOUNT_SAGA });
  const saved_amount = useRootState(membershipSelector).saved_amount_data?.saved_amount;
  const is_amount_loading = useRootState(membershipSelector).is_amount_loading;

  const is_loading =
    is_membership_loading ||
    is_count_loading ||
    is_merchant_loading ||
    is_merchandises_loading ||
    is_using_merchant_loading ||
    is_amount_loading;

  return (
    <Suspense fallback={<BlurLoading />}>
      <TabNavigation />
      {is_loading || !merchants || !merchandises ? (
        <BlurLoading />
      ) : (
        <>
          <Container data-testid="matching_main_container">
            <CurrentMatching matching_count={matching_count} />
            <MatchingStart
              merchants={merchants}
              selectMerchant={selectMerchant}
              selected_merchant_id={selected_merchant_id}
              merchandises={merchandises}
              selected_user_type={selected_user_type}
              selectUserType={selectUserType}
              goRegister={goRegister}
              using_merchants={using_merchants}
              saved_amount={saved_amount}
            />
            <OldDivider margin_top="26px" margin_bottom="0px" height="8px" />
          </Container>
          <Space height={30} />
          <AdBanner
            img_src={"https://asset.pickle.plus/images/img_disney_tving_reserve.webp"}
            alt="디즈니 플러스, 티빙 사전예약 배너"
            onClick={() => window.open("https://forms.gle/ug1qrWG4RqbHcJpNA")}
          />
          <Space height={30} />
          <Question />
        </>
      )}
    </Suspense>
  );
};

const ModalContent = styled.div`
  color: var(--gray-700);
`;

const Container = styled.div`
  padding: 16px 24px 0 24px;
`;

export default Matching;
