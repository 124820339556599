import { call, put, takeLatest } from "redux-saga/effects";
import { getSlot } from "apis";
import { SlotStatus } from "globals/interfaces";
import { logError } from "globals/utils";
import { setProfileLoading } from "containers/MyParty";
import { setSlotLoading, setSlot } from "./slice";

function* setSlotSaga(action: {
  type: string;
  payload: { merchant_id: number; goMyParty?: () => void; goPartyInfo?: () => void };
}) {
  yield put(setSlotLoading(true));
  yield put(setProfileLoading(true));
  try {
    const response = yield call(getSlot, action.payload.merchant_id);
    const { data } = response.data;
    if (data) {
      if (!(data.status === SlotStatus.HOST_USE || data.status === SlotStatus.MEMBER_USE)) {
        action.payload.goMyParty ? action.payload.goMyParty() : (window.location.href = "/");
        return;
      }
    }
    yield put(setSlot(data));
    if (action.payload.goPartyInfo) {
      action.payload.goPartyInfo();
    }
  } catch (e) {
    logError(e);
    action.payload.goMyParty ? action.payload.goMyParty() : (window.location.href = "/");
  } finally {
    yield put(setSlotLoading(false));
    yield put(setProfileLoading(false));
  }
}
export const SLOT_SET_SLOT_SAGA = "slot/setSlotSaga";

function* setSlotWaitingMerchantSaga(action: {
  type: string;
  payload: { merchant_id: number; goMyParty: () => void };
}) {
  try {
    yield put(setSlotLoading(true));

    const response = yield call(getSlot, action.payload.merchant_id);
    const { data } = response.data;
    if (!data || !data.person_id) {
      alert("이미 계정발급이 완료되었습니다.");
      window.location.href = "/myparty";
      return;
    }
    const {
      merchant_id,
      merchant_name,
      merchant_service_url,
      merchant_amount,
      img_url,
      person_id,
      is_delete_available,
      merchant_profile_image_license,
      merchant_profile_image_url,
    } = data;
    const status = SlotStatus.MEMBER_WAIT;
    yield put(
      setSlot({
        merchant_id,
        merchant_name,
        merchant_service_url,
        merchant_amount,
        img_url,
        status,
        person_id,
        is_delete_available,
        merchant_profile_image_license,
        merchant_profile_image_url,
      }),
    );
  } catch (e) {
    alert("에러가 발생했습니다. 새로고침을 시도해주세요.\n(서비스 이용에 문제가 생긴 경우 문의·상담을 남겨주세요.)");
    action.payload.goMyParty();
  } finally {
    yield put(setSlotLoading(false));
  }
}
export const SLOT_SET_SLOT_WAITING_MERCHANT_SAGA = "slot/setSlotWaitingMerchantSaga";

function* slotSaga(): Generator {
  yield takeLatest(SLOT_SET_SLOT_SAGA, setSlotSaga);
  yield takeLatest(SLOT_SET_SLOT_WAITING_MERCHANT_SAGA, setSlotWaitingMerchantSaga);
}

export default slotSaga;
