import React, { useEffect } from "react";
import AdminVariable from "components/AdminVariable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "root.redux";
import { ADMIN_GET_SERVICE_VARIABLE_LIST_SAGA, ADMIN_SET_SERVICE_VARIABLE_SAGA } from "./saga";

const AdminVariableList = (): JSX.Element => {
  const dispatch = useDispatch();
  const getServiceVariableListSagaDispatch = () => dispatch({ type: ADMIN_GET_SERVICE_VARIABLE_LIST_SAGA });
  const setServiceVariableSagaDispatch = ({
    key,
    value,
    type,
  }: {
    key: string;
    value: number | string | boolean;
    type: string;
  }) =>
    dispatch({
      type: ADMIN_SET_SERVICE_VARIABLE_SAGA,
      payload: {
        key,
        value,
        type,
      },
    });
  const variable_list = useSelector((state: RootState) => state.admin.variable_list);
  useEffect(() => {
    getServiceVariableListSagaDispatch();
    return;
  }, []);
  return (
    <>
      {variable_list?.map((variable) => (
        <AdminVariable
          variable={variable}
          key={`admin_variable_${variable.key}`}
          setServiceVariable={setServiceVariableSagaDispatch}
        />
      ))}
    </>
  );
};

export default AdminVariableList;
