import React from "react";
import Divider from "components/Divider";
import Explanation from "./Explanation";
import UserGuide from "./UserGuide";

const Section7 = (): JSX.Element => (
  <>
    <Divider />
    <Explanation />
    <UserGuide />
  </>
);

export default Section7;
