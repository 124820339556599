import React, { useState, useEffect, lazy } from "react";
import qs from "qs";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { openToast, closeToast } from "containers/Toast";
import { cleanupSlot } from "containers/Slot";
import { OldInputState } from "components/Input";
import Modal, { ModalType } from "components/Modal";
import { openCommonModal, useRootState } from "globals/utils";
import { ModalState } from "containers/Modal";
import { BlurLoading } from "components/Loading";
import { ProfileUsingInfoBox } from "components/MyParty";
//TODO: GreenBox, Bold은 글로벌 컴포넌트로, CaptionSmall은 Input과 같이 관리되도록 수정해야함
import { GreenBox, Bold } from "components/MyParty/SetProfile";
import { CaptionSmall } from "components/MyParty/MyPartyInfoStyle";
import Space from "components/Space";
import { MANAGEMERCHANT_SET_MERCHANT_PROFILE_NAME_SAGA } from "containers/ManageMerchant";
import { SLOT_SET_SLOT_SAGA } from "containers/Slot";

const OldInput = lazy(() => import("components/Input").then(({ OldInput }) => ({ default: OldInput })));
const MyPartyInfo = lazy(() => import("components/MyParty").then(({ MyPartyInfo }) => ({ default: MyPartyInfo })));
const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);

const MyPartyInfoContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{ is_first?: boolean; profile_name?: string }>();
  const merchant_id = Number(
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }).merchant_id,
  );

  const slot = useRootState((state) => state.slot.slot);
  const is_slot_loading = useRootState((state) => state.slot.is_loading);
  const is_set_profile_loading = useRootState((state) => state.myparty.set_profile.is_loading);
  const goMyParty = () => history.push("/myparty");
  const goSendMessage = () => history.push(`/myparty/message?merchant_id=${merchant_id}`);
  const setSlotSagaDispatch = (merchant_id: number) =>
    dispatch({ type: SLOT_SET_SLOT_SAGA, payload: { merchant_id, goMyParty } });
  const host_profile_name = slot?.host?.profile_name;
  const members = slot?.members;
  const openToastDisptach = (content: string) => dispatch(openToast(content));
  const closeToastDispatch = () => dispatch(closeToast());
  const cleanupSlotDispatch = () => dispatch(cleanupSlot());

  const setMerchantProfileNameSagaDispatch = (profile_name: string) =>
    dispatch({
      type: MANAGEMERCHANT_SET_MERCHANT_PROFILE_NAME_SAGA,
      payload: {
        profile_name,
        closeProfileModal,
        party_id: slot?.party_id,
        my_member_id: slot?.member_id,
        merchant_name: slot?.merchant_name,
      },
    });

  const goMerchantService = () => {
    window.open(slot?.merchant_service_url);
  };
  const goChannelTalk = () => {
    window.ChannelIO("show");
  };
  const goDelete = () => {
    history.push(`/myparty/delete?merchant_id=${slot?.merchant_id}&party_id=${slot?.party_id}`);
  };
  const goLeave = () => {
    if (!slot?.is_delete_available) {
      alert("파티원은 해당 파티 최초 가입 한달 후에 파티탈퇴가 가능합니다.");
      return;
    }
    history.push(`/myparty/leave/init?merchant_id=${slot?.merchant_id}&party_id=${slot?.party_id}`);
  };

  const goEdit = () => {
    history.push(`/myparty/change?merchant_id=${merchant_id}`);
  };

  const [is_profile_modal_open, setProfileModal] = useState<boolean>(false);
  const closeProfileModal = () => {
    setProfileName("");
    setProfileModal(false);
  };
  const openProfileModal = () => setProfileModal(true);
  const [profile_name, setProfileName] = useState<string>("");
  const profile_modal_props: ModalState = {
    is_dimmer: true,
    is_dimmer_close: true,
    title: "내 프로필 닉네임 변경",
    type: ModalType.SIMPLE,
    element: (
      <>
        <Space height={8} />
        <GreenBox>
          <Bold>
            {slot?.merchant_name}에서도 반드시 같은 닉네임으로
            <br />
            프로필을 변경해주세요
          </Bold>
        </GreenBox>
        <OldInput
          width={"280px"}
          state={OldInputState.READY}
          placeholder="변경할 프로필 닉네임"
          maxLength={6}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setProfileName(e.currentTarget.value)}
        />
        <Space height={8} />
        <CaptionSmall>최대 6자리까지 입력 가능</CaptionSmall>
      </>
    ),
    buttons: [
      {
        text: "변경 완료",
        theme: "primary-fill",
        onClick: () =>
          profile_name.length <= 6 && profile_name.length !== 0 && setMerchantProfileNameSagaDispatch(profile_name),
        disabled: profile_name.length > 6 || profile_name.length === 0,
      },
    ],
  };

  const [profiles, setProfiles] = useState<
    {
      label?: "파티장" | "파티원" | "납부완료" | "내 프로필";
      profile_name: string;
    }[]
  >([
    { profile_name: "매칭중..." },
    { profile_name: "매칭중..." },
    { profile_name: "매칭중..." },
    { profile_name: "매칭중..." },
  ]);

  useEffect(() => {
    if (!slot?.host_yn && location.state) {
      const { is_first, profile_name } = location.state;
      if (is_first) {
        openCommonModal({
          title: "어떤 프로필을 사용해야하나요?",
          content: (
            <ProfileUsingInfoBox
              merchant_id={merchant_id}
              merchant_name={slot?.merchant_name || ""}
              profile_name={profile_name || ""}
            />
          ),
          type: ModalType.SIMPLE,
          is_dimmer: true,
          is_dimmer_close: false,
          buttons: [
            {
              onClick: () => {
                history.replace({ ...location, state: { is_first: false } });
              },
              text: "확인",
              is_close_button: true,
            },
          ],
        });
      }
    }
    setSlotSagaDispatch(merchant_id);
    return () => {
      cleanupSlotDispatch();
    };
  }, []);

  useEffect(() => {
    if (!is_slot_loading) {
      const tmp_profiles = [...profiles];
      let index = 0;
      if (slot?.host_yn) {
        tmp_profiles[index++] = { profile_name: host_profile_name || "(닉네임 미정)", label: "파티장" };
        if (members) {
          for (const member of members) {
            if (member.member_id !== slot?.member_id)
              tmp_profiles[index++] = { profile_name: member.profile_name || "(닉네임 미정)", label: "납부완료" };
          }
        }
      } else if (slot?.host_yn === false) {
        tmp_profiles[index++] = { profile_name: host_profile_name || "(닉네임 미정)", label: "파티장" };
        tmp_profiles[index++] = { profile_name: slot?.my_profile_name || "(닉네임 미정)", label: "내 프로필" };
        if (members) {
          for (const member of members) {
            if (member.member_id !== slot?.member_id)
              tmp_profiles[index++] = { profile_name: member.profile_name || "(닉네임 미정)", label: "파티원" };
          }
        }
      }
      setProfiles([...tmp_profiles]);
      return;
    }
  }, [is_slot_loading]);

  const is_loading = is_slot_loading || is_set_profile_loading;
  return (
    <>
      <PageNavigation
        back_path="/myparty"
        title={is_loading || !slot ? "프리미엄 파티" : `${slot.merchant_name} 프리미엄 파티`}
        is_loading={is_loading}
      />
      {slot && !is_loading ? (
        <>
          {is_profile_modal_open && <Modal {...profile_modal_props} closeModal={closeProfileModal} />}
          <MyPartyInfo
            slot={slot}
            openToast={openToastDisptach}
            closeToast={closeToastDispatch}
            goMerchantService={goMerchantService}
            goChannelTalk={goChannelTalk}
            goEdit={goEdit}
            goDelete={goDelete}
            goLeave={goLeave}
            goSendMessage={goSendMessage}
            changeProfileName={() => openProfileModal()}
            profiles={profiles}
          />
        </>
      ) : (
        <BlurLoading />
      )}
    </>
  );
};

export default MyPartyInfoContainer;
