import React, { useEffect, lazy, Suspense } from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RestrictRoute, useRootState, Route } from "globals/utils";
import RegisterHostInit from "./RegisterHostInit";
import { BlurLoading } from "components/Loading";
import { SELECTED_MERCHANT } from "globals/variables";
import { cleanupRegisterHost } from "./slice";
import { REGISTERHOST_SET_MERCHANT_SAGA } from "./saga";

const SetSps = lazy(() => import("./SetSps"));
const SetAccount = lazy(() => import("./SetAccount"));
const SetBilling = lazy(() => import("./SetBilling"));
const Processing = lazy(() => import("./Processing"));

const RegisterHostRoute = (): JSX.Element => {
  const location = useLocation();
  const dispatch = useDispatch();
  const selected_merchant_id = sessionStorage.getItem(SELECTED_MERCHANT);

  const setMerchantSagaDispatch = (merchant_id: number) =>
    dispatch({ type: REGISTERHOST_SET_MERCHANT_SAGA, payload: { merchant_id } });
  const cleanupRegisterHostDispatch = () => dispatch(cleanupRegisterHost());

  const is_sps_done = useRootState((state) => state.registerhost.sps.is_done);
  const is_account_done = useRootState((state) => state.registerhost.account.is_done);
  const is_billing_done = useRootState((state) => state.registerhost.is_billing_done);
  const merchant = useRootState((state) => state.registerhost.merchant);

  useEffect(() => {
    if (selected_merchant_id) setMerchantSagaDispatch(parseInt(selected_merchant_id));
    return () => {
      cleanupRegisterHostDispatch();
    };
  }, []);

  return (
    <Suspense fallback={<BlurLoading />}>
      <Switch>
        <Route path="/register/host/init" component={RegisterHostInit} />
        <RestrictRoute
          is_pass={location.state === "/register/host/init"}
          redirect_url="/register/host/init"
          path="/register/host/sps"
          component={SetSps}
        />
        <RestrictRoute
          is_pass={location.state === "/register/host/init" && is_sps_done}
          redirect_url="/register/host/init"
          path="/register/host/account"
          component={SetAccount}
        />
        <RestrictRoute
          is_pass={location.state === "/register/host/init" && is_sps_done && is_account_done}
          redirect_url="/register/host/init"
          path="/register/host/billing"
          component={SetBilling}
        />
        <RestrictRoute
          is_pass={is_sps_done && is_account_done && is_billing_done && !!merchant}
          redirect_url="/matching"
          path="/register/host/processing"
          component={Processing}
        />
        <Redirect to="/register/host/init" />
      </Switch>
    </Suspense>
  );
};

export default RegisterHostRoute;
