import React from "react";
import ButtonLegacy from "components/Button";
import styled from "styled-components";
import img_password from "./assets/img_password.png";
import { OldH5 } from "components/Text";

const Certification = ({ openCertificate }: { openCertificate: () => void }): JSX.Element => (
  <Container>
    <Title>
      비밀번호 재설정을 위한 <br />
      본인인증이 필요합니다
    </Title>
    <ImgPassword />
    <ButtonLegacy data-testid="password_certificate_button" onClick={() => openCertificate()}>
      본인 인증하기
    </ButtonLegacy>
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 24px 30px 24px;
`;

const Title = styled(OldH5)`
  align-self: flex-start;
  color: var(--gray-900);
`;

const ImgPassword = styled.img.attrs({
  src: img_password,
  alt: "img_password",
})`
  width: 225px;
  height: 240px;
  margin-top: 80px;
  margin-bottom: 140px;
`;

export default Certification;
