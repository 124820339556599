import React, { RefObject } from "react";
import styled from "styled-components";
import OldInput, { OldInputState } from "./OldInput";
import ButtonLegacy, { ButtonPropsLegacy } from "components/Button";

interface InputWithLabelProps {
  value?: string;
  placeholder?: string;
  type?: string;
  label: string;
  is_complete: boolean;
  autoComplete?: "on" | "off";
  maxLength?: number;
  pattern?: string;
  inputMode?: "text" | "none" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined;
  input_ref?: RefObject<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  is_have_button?: boolean;
  button_props?: ButtonPropsLegacy;
  readonly?: boolean;
  data_test_id?: string;
}

const InputWithLabel = (props: InputWithLabelProps): JSX.Element => (
  <InputBox>
    {props.is_have_button && (
      <ButtonWrapper>
        <ButtonLegacy {...props.button_props}>{props.button_props?.children}</ButtonLegacy>
      </ButtonWrapper>
    )}
    <DefaultInput
      state={props.is_complete ? OldInputState.COMPLETE : OldInputState.READY}
      value={props.value || ""}
      placeholder={props.placeholder}
      type={props.type}
      autoComplete={props.autoComplete}
      maxLength={props.maxLength}
      ref={props.input_ref}
      pattern={props.pattern}
      inputMode={props.inputMode}
      onChange={props.onChange}
      readOnly={props.readonly}
      data-testid={props.data_test_id || ""}
    />
    <LabelText state={props.is_complete ? OldInputState.COMPLETE : OldInputState.READY}>{props.label}</LabelText>
  </InputBox>
);

const ButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 3px;
`;

const DefaultInput = styled(OldInput)`
  width: 100%;
`;

export const GenderInput = styled(OldInput).attrs({
  autoComplete: "on",
  placeholder: "9",
  pattern: "^[1-4]{1}$",
  inputMode: "numeric",
})`
  width: 16px;
  text-align: center;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  position: relative;
`;

export const LabelText = styled.label<{ state: number }>`
  margin: 0;
  vertical-align: baseline;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.state === OldInputState.COMPLETE ? "var(--gray-900)" : "var(--gray-700)")};
  ${DefaultInput}:focus + & {
    color: var(--primary-500);
  }
`;

export default InputWithLabel;
