import React, { lazy, Suspense, useEffect } from "react";
import {
  isLoginSelector,
  membershipSelector,
  slotsSelector,
  useRootState,
  userSelector,
  openLoginModal,
} from "globals/utils";
import { useDispatch } from "react-redux";
import { BlurLoading } from "components/Loading";
import { useHistory } from "react-router-dom";
import Space from "components/Space";
import { setAmountLoading, MEMBERSHIP_SET_SAVED_AMOUNT_SAGA } from "globals/membership";
import { MYPARTY_SET_ONLY_MATCHING_MERCHANDISE_AMOUNT_SAGA } from "./saga";
import { MERCHANDISE_SET_MERCHANDISES_SAGA } from "globals/merchandise";
import AdBanner from "components/AdBanner";

const NoneParty = lazy(() => import("components/MyParty").then(({ NoneParty }) => ({ default: NoneParty })));
const NotLogin = lazy(() => import("components/MyParty").then(({ NotLogin }) => ({ default: NotLogin })));
const MyPartyList = lazy(() => import("components/MyParty").then(({ MyPartyList }) => ({ default: MyPartyList })));
const OldPickleHostBox = lazy(() =>
  import("components/MyParty").then(({ OldPickleHostBox }) => ({ default: OldPickleHostBox })),
);
const TabNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ TabNavigation }) => ({ default: TabNavigation })),
);

const MyParty = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const is_login = useRootState(isLoginSelector);
  const real_name = useRootState(userSelector).user_data?.real_name;
  const slots = useRootState(slotsSelector).slots_data;
  const is_max = useRootState((state) => state.myparty.is_max);
  const merchandise_amount = useRootState(membershipSelector).saved_amount_data?.merchandise_amount;
  const goMatching = () => {
    history.push("/matching");
  };
  const goMembershipChange = () => history.push("/matching");
  const goWaiting = (merchant_id: number) => history.push(`/myparty/waiting?merchant_id=${merchant_id}`);
  const goPartyInfo = (merchant_id: number) => history.push(`/myparty/info?merchant_id=${merchant_id}`);

  const is_user_loading = useRootState(userSelector).is_loading;
  const is_special_user = useRootState(userSelector).user_data?.is_special;
  const goOldHostParty = () => history.push("/myparty/old");

  const is_only_matching = useRootState((state) => state.myparty.only_matching.is_only_matching);
  const is_matching_loading = useRootState((state) => state.myparty.only_matching.is_loading);
  const only_matching_merchandise_amount = useRootState((state) => state.myparty.only_matching.merchandise_amount);
  const only_matching_saved_amount = useRootState((state) => state.myparty.only_matching.saved_amount);
  const setMerchandisesSagaDispatch = () => dispatch({ type: MERCHANDISE_SET_MERCHANDISES_SAGA });
  const setOnlyMatchingMerchandiseAmountSagaDispatch = () =>
    dispatch({ type: MYPARTY_SET_ONLY_MATCHING_MERCHANDISE_AMOUNT_SAGA, payload: { slot_cnt: slots?.length } });

  const membership = useRootState(membershipSelector).membership_data;
  const is_saved_amount_loading = useRootState(membershipSelector).is_amount_loading;
  const saved_amount = useRootState(membershipSelector).saved_amount_data?.saved_amount;
  const setSavedAmountSagaDispatch = () => dispatch({ type: MEMBERSHIP_SET_SAVED_AMOUNT_SAGA });
  const setAmountLoadingDispatch = () => dispatch(setAmountLoading(false));

  useEffect(() => {
    window.ChannelIO("showChannelButton");
    return () => {
      window.ChannelIO("hideChannelButton");
    };
  });

  useEffect(() => {
    if (is_only_matching) setMerchandisesSagaDispatch();
    setOnlyMatchingMerchandiseAmountSagaDispatch();
    if (membership) setSavedAmountSagaDispatch();
    else setAmountLoadingDispatch();
    return;
  }, []);

  const is_loading = is_matching_loading || is_saved_amount_loading;
  return (
    <Suspense fallback={<BlurLoading />}>
      <TabNavigation />
      {!is_user_loading && is_special_user ? (
        <OldPickleHostBox goOldHostParty={goOldHostParty} />
      ) : (
        <Space height={16} />
      )}
      <Space height={24} />
      {!is_loading && is_login && slots && slots.length > 0 && (
        <MyPartyList
          merchandise_name={membership?.merchandise_name}
          slots={slots}
          goMembershipChange={goMembershipChange}
          goWaiting={goWaiting}
          goPartyInfo={goPartyInfo}
          saved_amount={is_only_matching ? only_matching_saved_amount : saved_amount}
          merchandise_amount={is_only_matching ? only_matching_merchandise_amount : merchandise_amount}
          slot_cnt={is_only_matching ? slots.length : membership?.slot_cnt}
          is_max={is_max}
          is_only_matching={is_only_matching}
        />
      )}
      {is_login && !is_loading && (!slots || slots.length === 0) && (
        <NoneParty real_name={real_name} goMatching={goMatching} />
      )}
      {!is_login && (
        <NotLogin
          openLoginModal={() => {
            openLoginModal({ history });
          }}
        />
      )}
      <Space height={8} />
      <AdBanner
        img_src={"https://asset.pickle.plus/images/img_disney_tving_reserve.webp"}
        alt="디즈니 플러스, 티빙 사전예약 배너"
        onClick={() => window.open("https://forms.gle/ug1qrWG4RqbHcJpNA")}
      />
    </Suspense>
  );
};

export default MyParty;
