import { OldSub } from "components/Text";
import React from "react";
import styled from "styled-components";

const GreenBox = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <Box>
    <OldSub color="var(--primary-700)">{children}</OldSub>
  </Box>
);

const Box = styled.div`
  padding: 10px 0 6px 0;
  text-align: center;
  background-color: var(--primary-100);
  border-radius: 8px;
`;

export default GreenBox;
