import React, { lazy, Suspense, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { loginByPhoneReqData } from "apis";
import { closeModal, ModalState, openModal } from "containers/Modal";
import { ModalType } from "components/Modal";
import button_kakao_img from "./assets/button_kakao.png";
import { LoginInputProps } from "components/Login/interface";
import { BlurLoading } from "components/Loading";
import { LOGIN_LOGIN_BY_PHONE_SAGA } from "./saga";

const PageNavigation = lazy(() =>
  import("containers/TopNavigation").then(({ PageNavigation }) => ({ default: PageNavigation })),
);
const PhoneLogin = lazy(() => import("components/Login/PhoneLogin"));

const PhoneLoginContainer = ({ previous_route }: { previous_route?: string }): JSX.Element => {
  const phone_ref = useRef<HTMLInputElement>(null);
  const password_ref = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const location = useLocation<{ redirect_url: string }>();
  const getRedirectUrl = () => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return decodeURIComponent(query.redirect_url ? query.redirect_url.toString() : "/");
  };
  const goRedirectUrl = () => {
    history.push(getRedirectUrl());
  };
  const goSetPassword = () => {
    history.push("/password");
  };

  const dispatch = useDispatch();
  const openModalDispatch = (modal_state: ModalState) => dispatch(openModal(modal_state));
  const closeModalDispatch = () => dispatch(closeModal());
  const loginByPhoneSagaDispatch = (data: loginByPhoneReqData) =>
    dispatch({ type: LOGIN_LOGIN_BY_PHONE_SAGA, payload: { data, goRedirectUrl } });

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const new_phone = { ...phone };
    const value = e.currentTarget.value;
    if (value.length === 11) {
      new_phone.is_complete = true;
      password_ref.current?.select();
    }
    new_phone.value = e.currentTarget.value;
    setPhone({
      ...new_phone,
    });
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const new_password = { ...password };
    const value = e.currentTarget.value;
    if (value.length >= 6) {
      new_password.is_complete = true;
    }
    new_password.value = e.currentTarget.value;
    setPassword({
      ...new_password,
    });
  };

  const [phone, setPhone] = useState<LoginInputProps>({
    value: "",
    is_complete: false,
    onChange: onChangePhone,
  });
  const [password, setPassword] = useState<LoginInputProps>({
    value: "",
    is_complete: false,
    onChange: onChangePassword,
  });

  const loginByPhone = () => {
    if (!phone.is_complete || !password.is_complete) return false;
    loginByPhoneSagaDispatch({ is_remember: true, phone: phone.value, password: password.value });
  };

  const openSignupModal = () => {
    openModalDispatch({
      title: "피클플러스 회원가입",
      is_dimmer: true,
      is_dimmer_close: true,
      type: ModalType.STACKED,
      buttons: [
        {
          onClick: () => {
            goKakaoLogin();
            closeModalDispatch();
          },
          text: "",
          image_src: button_kakao_img,
        },
        {
          onClick: closeModalDispatch,
          text: "취소",
          theme: "gray-ghost",
        },
      ],
    });
  };

  const goKakaoLogin = () => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    window.Kakao.Auth.authorize({
      redirectUri: `${window.location.origin}/login/kakao`,
      state: JSON.stringify({
        redirect_url: encodeURIComponent(query.redirect_url ? query.redirect_url.toString() : "/"),
      }),
    });
  };

  return (
    <Suspense fallback={<BlurLoading />}>
      <PageNavigation back_path={previous_route ? previous_route : "/login/select"} title="휴대전화 로그인" />
      <PhoneLogin
        phone={phone}
        password={password}
        phone_ref={phone_ref}
        password_ref={password_ref}
        loginByPhone={loginByPhone}
        openSignupModal={openSignupModal}
        goKakaoLogin={goKakaoLogin}
        goSetPassword={goSetPassword}
      />
    </Suspense>
  );
};

export default PhoneLoginContainer;
